import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import LeftMenuPanel from "../components/LeftMenuPanel";
import config from "../helpers/config";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import {
    read
} from './../services/page';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

const InternInformationScreen = () => {
    const url = "panduan-magang";

    const auth = useSelector((state) => state.auth);

    const [content, setContent] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        document.title = config.documentTitle + " - Program Magang";

        window.scrollTo(0, 0);

        async function getContent(slug){
            setLoading(true);

            const res = await read(slug);
            if(res.error === null){
                setContent(res.data);
            }else{
                swal('Error', res.error, 'error')
            }

            setLoading(false);
        }

        getContent(url);
    }, [url]);

    return (
        <div className="default-nav-mt">
            <Container>
                <Row>
                    <Col sm={12} md={12} lg={4} className="mb-4">
                        <LeftMenuPanel activeIndex={7}/>
                    </Col>
                    <Col sm={12} md={12} lg={8}>
                        <div className="row">
                            <div className="col-6">
                                <h3><b>Program Magang</b></h3>
                            </div>
                            <div className="col-6 text-end">
                                <form target="_blank" method="post" action={`${process.env.REACT_APP_INTERN_BASE_URL}account/login_member`}>
                                    <input type="hidden" name="token" value={auth.token} />
                                    <button type="submit" className="btn btn-default" style={{
                                    }}><FontAwesomeIcon icon={faExternalLinkAlt}/> Program Magang</button>
                                </form>
                            </div>
                        </div>
                        {
                            loading ? (
                                <div className="text-center">Mohon Tunggu...</div>
                            ) : (
                                <div className="mt-5" dangerouslySetInnerHTML={{__html: content.body}}></div>
                            )
                        }
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default InternInformationScreen;