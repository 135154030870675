import React, { useEffect, useState } from 'react';
import {
    all,
    albums as getCategoriesAPI
} from './../../services/gallery';
import swal from 'sweetalert';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen, faChevronRight, faFileAlt } from '@fortawesome/free-solid-svg-icons'
import { useParams } from 'react-router';
import moment from 'moment';

const Gallery = () => {
    const params = useParams();
    const id = params.id;

    const [categoriesCache, setCategoriesCache] = useState(null);
    const [categories, setCategories] = useState(null);
    const [files, setFiles] = useState(null);
    const [selectedCategoryId, setSelectedCategoryId] = useState(id);
    const [page, setPage] = useState(1);
    const [keyword, setKeyword] = useState('');
    const [categoryName, setCategoryName] = useState('');

    useEffect(() => {
        async function getCategories(){
            if(categoriesCache === null){
                const res = await getCategoriesAPI();
                if(res.error === null){
                    setCategories(res.data);
                    setCategoriesCache(res.data);
                    setFiles(null);
                }else{
                    swal(
                        'Error',
                        res.error,
                        'error'
                    );
                }
            }else{
                setCategories(categoriesCache);
            }
        }

        async function getAllByCategoryId(id){
            const res = await all(id, page, keyword);
            if(res.error === null){
                setFiles(res.data);
                setCategories(null);
                setCategoryName(res.gallery.title);
                setSelectedCategoryId(res.category.id);
            }else{
                swal(
                    'Error',
                    res.error,
                    'error'
                );
            }
        }
        
        if(selectedCategoryId !== null && typeof selectedCategoryId !== 'undefined'){
            getAllByCategoryId(selectedCategoryId);
        }else{
            getCategories();
        }
    }, [selectedCategoryId, page, keyword]);

    const selectCategory = (category) => {
        //set browser url
        window.history.pushState('page2', 'Title', '/gallery/' + category.id);

        setCategoryName(category.name);
        setSelectedCategoryId(category.id);
    }

    const resetCategory = () => {
        //set browser url
        window.history.pushState('page2', 'Title', '/gallery');

        setSelectedCategoryId(null);
        setCategoryName('');
        setFiles(null);
    }

    return(
        <div>
            <h4>
                <a style={{
                    cursor: 'pointer',
                }} onClick={() => {
                   resetCategory();
                }}>Gallery</a> 
                {
                    categoryName !== '' ? (
                        <span> <FontAwesomeIcon icon={faChevronRight}/> { categoryName }</span>
                    ) : null
                }
            </h4>
            <Row>
            {
                categories !== null ? (
                    categories.map((category, index) => (
                        <Col key={index} xs={6} sm={6} md={4} lg={3}>
                            <div onClick={() => selectCategory(category)} className="box p-4 text-center mb-4" style={{
                                cursor: 'pointer',
                            }}>
                                <div>
                                    <img src={ category.cover } className="img-fluid"/>
                                </div>

                                <div className="mt-3">
                                    {category.title}
                                    <div className='text-muted'>
                                        { moment(category.created_at).format('DD MMMM YYYY') }
                                    </div>
                                </div>
                            </div>
                        </Col>
                    ))
                ) : null
            }
            </Row>

            {/* files */}
            {
                files !== null ? (
                    <Row>
                    {
                        files.map((file, index) => (
                            <Col key={index} xs={6} sm={6} md={4} lg={3}>
                                <div onClick={() => {
                                    //open link in new tab
                                    window.open(file.image);
                                }} className="box p-4 text-center mb-4" style={{
                                    cursor: 'pointer',
                                }}>
                                    <div>
                                        <img src={ file.image } className="img-fluid"/>
                                    </div>

                                    <div className="mt-3">
                                        {file.title}
                                        <div className='text-muted'>
                                        { moment(file.created_at).format('DD MMMM YYYY') }
                                    </div>
                                    </div>
                                </div>
                            </Col>
                        ))
                    }
                    </Row>
                ) : null
            }
            {/* files */}
        </div>
    );
}

export default Gallery;