import React, { useEffect, useState } from "react";
//bootstrap
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faTrash, faPhone, faGlobe, faFax, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import AddEditEmployment from './AddEditEmployment';
import {
    all,
    remove
} from './../../services/employment';
import {
    setRefreshDetail
} from './../../redux/slicers/accountSlice';
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment';
import swal from 'sweetalert';
import { getWorkDuration } from "../../helpers/datetime";
import Loading from "../Loading";

const EmploymentList = (props) => {
    const auth = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [editId, setEditId] = useState(null);
    const [employments, setEmployments] = useState(null);
    const [loading, setLoading] = useState(false);
    const [refreshTime, setRefreshTime] = useState(null);

    useEffect(() => {
        if(editId !== null){
            setShowModal(true);
        }
    }, [editId]);

    useEffect(() => {
        setRefreshTime(new Date().getTime());
    }, []);

    useEffect(() => {
        async function getEmployments(token){
            setLoading(true);

            const res = await all(token);
            if(res.error === null){
                if(res.data.length > 0){
                    setEmployments(res.data);
                }else{
                    setEmployments(null);
                }
            }else{
                swal("Error!", res.error.message, "error");
            }

            setLoading(false);
        }

        if(refreshTime !== null){
            getEmployments(auth.token);
        }
    }, [refreshTime, auth]);

    const closeModal = () => {
        setEditId(null);
        setShowModal(false);
    }

    const deleteData = async (id) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then(async (willDelete) => {
            if (willDelete) {
                const res = await remove(auth.token, id);
                if(res.error === null){
                    swal("Sukses!", "Berhasil menghapus data.", "success");

                    setRefreshTime(new Date().getTime());
                }
            } 
        });
    }

    return(
        <div>
            <div className="text-end">
            <button className="btn btn-default ms-2 mt-3 btn-sm" onClick={() => setShowModal(true)}>Tambah Employment</button>
            </div>
            <AddEditEmployment id={editId} isShow={ showModal } refreshList={() => setRefreshTime(new Date().getTime())} setShowModal={closeModal}/>

            {
                loading ? <Loading /> :
                employments === null ? (
                    <div className="text-center p-4">
                        <h4>Belum ada Pekerjaan</h4>
                        <img src="img/empty.svg" style={{
                            width: "200px",
                        }}/>
                    </div>
                ) :
                employments.map((employment, index) => (
                    <div key={index}>
                        <div className="box p-3 mt-3">
                            <h6>
                                <span style={{
                                    fontWeight: "bold"
                                }}>
                                    { employment.job_title }
                                </span>
                                <div className="float-end d-inline-block">
                                    &nbsp;<a href="#!" className="action-link" onClick={() => setEditId(employment.id)}><FontAwesomeIcon icon={faCog} />Ubah</a>
                                    &nbsp;<a href="#!" className="action-link" onClick={() => deleteData(employment.id)}><FontAwesomeIcon icon={faTrash} />Hapus</a>
                                </div>
                            </h6>
                            <div style={{
                                fontSize: '14pt',
                                marginTop: '-7px'
                            }}>{ employment.company_name+' | '+employment.state+', '+employment.country }</div>
                            <div>{ getWorkDuration(employment.start_date, employment.is_current_company === '1' ? moment.now() : employment.end_date, employment.is_current_company) }</div>
                            <div style={{
                                fontSize: '10pt',
                                color: '#333',
                                marginTop: '5pt'
                            }}>
                                <span hidden={ employment.phone === null || employment.phone === "" }>&nbsp;<FontAwesomeIcon icon={faPhone} /> { employment.phone }</span>
                                <span hidden={ employment.fax === null || employment.fax === "" }>&nbsp;&nbsp;<FontAwesomeIcon icon={faFax} /> { employment.fax }</span>
                                <span hidden={ employment.website === null || employment.website === "" }>&nbsp;&nbsp;<FontAwesomeIcon icon={faGlobe} /> { employment.website }</span>
                            </div>
                            <div style={{
                                fontSize: '10pt',
                                color: '#333',
                                marginTop: '5pt'
                            }} hidden={ employment.address === null || employment.address === "" }><FontAwesomeIcon icon={faMapMarkerAlt} /> { employment.address }</div>
                        </div>
                    </div>
                ))
            }
        </div>

    );
}

export default EmploymentList;