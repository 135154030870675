export const getByOrganization = async (name) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}people/get_by_organization`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            name: name
        })
    });
    const json = await response.json();
    return json;
}