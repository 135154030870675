import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
    notification as getNotificationsAPI
} from './../../services/users';
import swal from "sweetalert";
import NotificationBox from "./NotificationBox";
import Loading from "../Loading";

const NotificationList = () => {
    const auth = useSelector((state) => state.auth);
    const notification = useSelector((state) => state.notification);

    const [notifications, setNotifications] = useState([]);
    const [page, setPage] = useState(1);
    const [reset, setReset] = useState(true);
    const [isContainMore, setIsContainMore] = useState(true);
    const [loading, setLoading] = useState(false);
    const [refreshTime, setRefreshTime] = useState(null);

    useEffect(() => {
        setRefreshTime(new Date().getTime());
    }, []);

    useEffect(() => {
        async function getNotifications(){
            setLoading(false);

            const res = await getNotificationsAPI(auth.token, page, "");
            if(res.error === null){
                if(reset){
                    setNotifications(res.data);
                }else{
                    //append
                    setNotifications(notifications => [...notifications, ...res.data]);
                }

                if(res.data.length < 1){
                    setIsContainMore(false);
                }else{
                    setIsContainMore(true);
                }
            }else{
                swal("Error!", res.error, "error");
            }

            setLoading(false);
        }

        if(notification.showNotificationScreen || refreshTime !== null){
            getNotifications();
        }
    }, [auth, notification, page, reset, refreshTime]);

    const loadMore = () => {
        setReset(false);
        //set last id
        setPage(page+1);
    }

    return (
        <div>
            {
                loading ? <Loading /> : 
                notifications.length > 0 ? (
                    <>
                        {
                        notifications.map((notification, index) => (
                            <NotificationBox notification={notification} key={index}/>
                        ))
                        }
                        <div className="text-center mt-5" hidden={!isContainMore}>
                            <Button className="btn-default" variant="danger" size="sm" onClick={loadMore}>Muat Lebih Banyak</Button>
                        </div>
                    </>
                ) : (
                    <div className="text-center mt-5">
                        <img src="/img/no_notif.svg" alt="no notification" />
                    </div>
                )
            }
        </div>
    );
}

export default NotificationList;