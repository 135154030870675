import React, { useEffect, useMemo, useState } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import config from "../helpers/config";
import LeftMenuPanel from "../components/LeftMenuPanel";
import { useSelector } from "react-redux";
import { profile } from "../services/auth";
import swal from 'sweetalert';
import moment from "moment";
import { Link } from "react-router-dom";
import { updateIAIId, validateIAIId } from "../services/account";

const DPTCheckScreen = () => {
    const account = useSelector((state) => state.account);
    const auth = useSelector((state) => state.auth);

    const [formIAIId, setFormIAIId] = useState(null);
    const [iaiId, setIaiId] = useState(null);
    const [expiredDate, setExpiredDate] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [showFormIAIId, setShowFormIAIId] = useState(false);
    const [checkIAIIdLoading, setCheckIAIIdLoading] = useState(false);
    const [formIAIIdError, setFormIAIIdError] = useState(null);

    //change title
    useEffect(() => {
        document.title = config.documentTitle + " - Cek DPT";

        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        async function getProfile(token) {
            setIsLoading(true);
            const res = await profile(token);
            if (res.error === null || res.error === "") {
                setIaiId(res.data.iai_id);
                setFormIAIId(res.data.iai_id);
            }
            setIsLoading(false);
        }

        if (auth === null || auth.token === null) return;
        getProfile(auth.token);
    }, [auth]);

    const isActiveDPT = useMemo(() => {
        const isExpired = account.is_expired;
        if (isExpired) return false;

        if (account.membership === null || typeof account.membership === 'undefined') return false;

        const expiredDateTime = account.membership.expired;
        if (expiredDateTime === null || expiredDateTime === '0000-00-00 00:00:00') return false;

        setExpiredDate(expiredDateTime);

        // check if more than 30 december 2024
        const expiredDate = new Date(expiredDateTime);
        const dptDate = new Date(2024, 11, 30);
        return expiredDate > dptDate;
    }, [account]);

    const isIAIIDValid = useMemo(() => {
        if (iaiId === null) {
            return false;
        };

        const trimmedIaiId = iaiId.trim();

        // should be 11 or 12 characters
        if (trimmedIaiId.length !== 11 && trimmedIaiId.length !== 12) {
            setShowFormIAIId(true);
            return false;
        };

        return true;
    }, [iaiId]);

    const isDPTJakarta = useMemo(() => {
        if (!isIAIIDValid) return false;

        const trimmedIaiId = iaiId.trim();

        // check last 3 digits is 100
        const lastThreeDigits = trimmedIaiId.substring(trimmedIaiId.length - 3);
        if (lastThreeDigits !== '100') return false;

        return true;
    }, [iaiId, isIAIIDValid]);



    // debounce on newIaiId change for 2 seconds
    useEffect(() => {
        if (formIAIId === null) return;

        const handleValidateIAIId = async (newIaiId) => {
            const res = await validateIAIId(auth.token, newIaiId);
            if (res.error === null || res.error === "") {
                setFormIAIIdError(null);
            } else {
                setFormIAIIdError(res.error);
            }
            setCheckIAIIdLoading(false);
        }

        setCheckIAIIdLoading(true);

        const timer = setTimeout(() => {
            handleValidateIAIId(formIAIId);
        }, 1000);

        return () => clearTimeout(timer);
    }, [formIAIId, auth]);

    const handleUpdateIAIId = async (e) => {
        e.preventDefault();

        const res = await updateIAIId(auth.token, formIAIId);
        if (res.error === null || res.error === "") {
            setShowFormIAIId(false);
            setIaiId(formIAIId);

            // show toast
            await swal({
                title: "Berhasil",
                text: "Nomor Keanggotaan IAI berhasil disimpan",
            });
        } else {
            setFormIAIIdError(res.error);
        }
    }

    const Information = () => {
        return <>
            <b>Penting: Informasi E-Vote Tahap 1</b>
            <p>Perhatian! Untuk E-Vote Tahap 1, hanya anggota IAI Jakarta yang namanya tercantum dalam Daftar Pemilih Tetap (DPT) yang dilampirkan IAI Nasional yang dapat mengikuti E-Vote Tahap 1.</p>
            <p>Cek informasi Daftar Pemilih Tetap (DPT) pada tautan berikut:
                <br />
                <a href="https://iai.or.id/berita/daftar-pemilih-tetap-pada-e-vote-tahap-1" target="_blank">https://iai.or.id/berita/daftar-pemilih-tetap-pada-e-vote-tahap-1</a>
            </p>

            <p>Namun, jangan khawatir. Pengguna yang tidak tercantum dalam dokumen DPT tetap memiliki kesempatan untuk berpartisipasi dalam E-Vote Tahap 2 jika memenuhi syarat.</p>
            <hr />
        </>
    }

    const handleFormIaiIdChange = (e) => {
        // get value
        const value = e.target.value;

        // maximum 12 characters
        if (value.length > 12) return;

        // set value
        setFormIAIId(value);
    }

    return (
        <div className="default-nav-mt">
            <Container>
                <Row>
                    <Col sm={12} md={12} lg={4} className="mb-4">
                        <LeftMenuPanel activeIndex={0} />
                    </Col>
                    <Col sm={12} md={12} lg={8}>
                        <div className="box p-4" hidden={isLoading}>
                            {
                                isActiveDPT && isDPTJakarta ? (
                                    <>
                                        <div className="text-center">
                                            <h5><b>Selamat! Anda Terdaftar Sebagai Pemilih Tetap</b></h5>
                                            <p>Anda Berhasil Terdaftar dalam Daftar Pemilih Tetap (DPT)</p>
                                        </div>
                                        <div className="text-start mt-4">
                                            <p>Kami denggan senang hati menginformasikan bahwa Anda telah memenuhi semua persyaratan dan berhasil terdaftar sebagau pemilih tetap. Dengan status ini, Anda memiliki hak untuk berpartisipasi dalam pemilihan yang akan datang</p>
                                            <br />
                                            <p style={{
                                                color: 'green'
                                            }}>Kunungi website IAI Jakarta secara berkala untuk informasi pemilihan lebih lanjut</p>
                                        </div>
                                        <Information />
                                    </>
                                ) : (
                                    <>
                                        <div className="text-center">
                                            <h5><b>Anda Belum Terdaftar Sebagai Pemilih Tetap</b></h5>
                                            <p>Mohon Maaf, Anda Belum Memenuhi Syarat untuk Terdaftar dalam Daftar Pemilih Tetap(DPT)</p>
                                        </div>
                                        <div className="text-start mt-4">
                                            <p>Saat ini, Anda tidak dapat didaftarkan sebagai pemilih tetap, adapun beberapa syarat menjadi Daftar Pemilih Tetap (DPT) sebagai berikut</p>
                                            <ul>
                                                <li>Merupakan anggota IAI Jakarta</li>
                                                <li>Menjadi Peserta Aktif dengan masa akhir keanggotaan lebih dari sama dengan 31 Desember 2024</li>
                                            </ul>
                                            <Information />
                                            <p>Masa akhir keanggotaan: <span style={{
                                                color: 'red'
                                            }}>{moment(expiredDate).format('D MMMM yyyy')}</span></p>
                                            <div className="d-flex" style={{
                                                gap: "10px"
                                            }}>
                                                <Link to={isDPTJakarta ? '/membership' : '#'} style={{
                                                    backgroundColor: isDPTJakarta ? "#AA272F" : "#ccc",
                                                    color: "#fff",
                                                    padding: "10px 20px",
                                                    borderRadius: "5px",
                                                    display: "inline-block",
                                                    marginTop: "10px",
                                                    textDecoration: "none"
                                                }}>Perpanjang Keanggotaan</Link>
                                                <a href="https://wa.me/6281231111642" style={{
                                                    border: "1px solid #25D366",
                                                    color: "#25D366",
                                                    padding: "10px 20px",
                                                    borderRadius: "5px",
                                                    display: "inline-block",
                                                    marginTop: "10px",
                                                    textDecoration: "none"
                                                }}>Hubungi Sekretariat</a>
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        </div>
                    </Col>
                </Row>
            </Container>

            <Modal show={showFormIAIId} centered>
                <Modal.Body className="p-4">
                    <div className="text-center mb-4">
                        <h3><b>Mohon Lengkapi Data</b></h3>
                        <p>Masukan Nomor Kenaggotaan IAI</p>
                    </div>
                    <form onSubmit={handleUpdateIAIId}>
                        <div className="form-group mb-4">
                            <label htmlFor="iai_id" className="form-label"><b>Nomor Keanggotaan IAI</b></label>
                            <input value={formIAIId} onChange={handleFormIaiIdChange} type="number" className="form-control" placeholder="Nomor Keanggotaan IAI" required />
                            {
                                formIAIIdError && (
                                    <p style={{
                                        color: 'red'
                                    }}>{formIAIIdError}</p>
                                )
                            }
                        </div>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}>
                            <button disabled={checkIAIIdLoading} className="btn" type="button" onClick={() => setShowFormIAIId(false)}>Isi nanti</button>
                            <button disabled={checkIAIIdLoading || formIAIIdError} className="btn btn-default" type="submit">
                                {checkIAIIdLoading ? 'Mengecek...' : 'Simpan'}
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default DPTCheckScreen;