import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Slider, { slickNext } from "react-slick";
import {
    get
} from './../../services/mediaPartner';
import swal from "sweetalert";
import Loading from "../Loading";
import config from "../../helpers/config";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

const MediaPartner = () => {
    const [partners, setPartners] = useState([]);
    const [sponsorships, setSponsorships] = useState([]);
    const [largeSponsorships, setLargeSponsorships] = useState([]);
    const [loading, setLoading] = useState(false);
    const [slideToShow, setSlideToShow] = useState(4);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        async function getPartners(){
            setLoading(true);

            const res = await get();
            if(res.error === null){
                //get only partnership
                let partnerships = res.data.filter(p => p.category == config.mediaPartner.PARTNERSHIP);
                setPartners(partnerships);

                //get only sponsorship
                let sponsorshipList = res.data.filter(p => p.category == config.mediaPartner.SPONSORSHIP);
                setSponsorships(sponsorshipList);

                //get only large sponsorship
                let largeSponsorshipList = res.data.filter(p => p.category == config.mediaPartner.SPONSORSHIP_LARGE);
                setLargeSponsorships(largeSponsorshipList);
            }else{
                swal("Error", res.error, "error");
            }

            setLoading(false);
        }

        getPartners();
    }, []);

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if(windowDimensions.width <= 768){
            setSlideToShow(1);
        }
        else if(windowDimensions.width >= 768 && windowDimensions.width <= 992){
            setSlideToShow(2);
        }else if(windowDimensions.width >= 992 && windowDimensions.width <= 1200){
            setSlideToShow(3);
        }else{
            setSlideToShow(4);
        }
    }, [windowDimensions]);

    return(
        <div>
            <div className="text-center mb-4" style={{
                fontWeight: "bold",
                fontSize: '14pt'
            }}>
                Mitra Acara Rapat Anggota IAI Jakarta 2022
            </div>
            {
                loading ? <Loading /> : (
                    <>
                        <div className="row justify-content-center">
                            {
                            largeSponsorships.map((data, index) => (
                                <div key={index} className="col-12 col-sm-6 col-md-4">
                                    <img src={data.image} alt={data.name} className="img-fluid" />
                                </div>
                            ))
                            }
                        </div>
                        <div className="row justify-content-center">
                            {
                            sponsorships.map((data, index) => (
                                <div key={index} className="col-6 col-sm-4 col-md-2">
                                    <img src={data.image} alt={data.name} className="img-fluid" />
                                </div>
                            ))
                            }
                        </div>
                    </>
                )
            }
            <div className="text-center mb-4 mt-5" style={{
                fontWeight: "bold",
                fontSize: '14pt'
            }}>
                Kemitraan
            </div>
            {
                loading ? <Loading /> : (
                    <Slider className="media_partner" dots={false} infinite={true} arrows={true} centerMode={true} speed={500} slidesToShow={slideToShow} autoplaySpeed={2000} autoplay={true} slidesToScroll={1}>
                        {
                            partners.map((partner, index) => (
                                <div key={index}>
                                    <img src={partner.image} alt={partner.name} className="img-fluid" style={{
                                        paddingLeft: '10px',
                                        paddingRight: '10px',
                                    }}/>
                                </div>
                            ))
                        }
                    </Slider>
                )
            }
        </div>
    );
}

export default MediaPartner;