import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useParams } from "react-router";
import DownloadCategories from "../components/download/DownloadCategories";
import EventDetail from "../components/events/EventDetail";
import config from "../helpers/config";

const EventDetailScreen = () => {
    const params = useParams();

    //change title
    useEffect(() => {
        document.title = config.documentTitle + " - Detil Kegiatan";

        window.scrollTo(0, 0);
    }, []);

    return (
        <Container>
            <Row>
                <Col lg={12} md={12} sm={12} className="mt-5 mb-5">
                    <EventDetail url={params.url}/>
                </Col>
            </Row>
        </Container>
    );
}

export default EventDetailScreen;