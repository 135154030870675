import React, { useEffect, useState } from "react";
import {
    read,
    order
} from './../../services/event';
import swal from "sweetalert";
import { useSelector } from "react-redux";
import { Button, Col, Row } from "react-bootstrap";
import { intToMoney } from "../../helpers/currency";
import { useNavigate } from "react-router";
import EventBox from "./EventBox";
import Loading from './../Loading';
import {
    profile
} from './../../services/auth';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import MembershipBadge from "../MembershipBadge";

const CheckoutEvent = (props) => {
    const url = props.url;

    const account = useSelector((state) => state.account);
    const auth = useSelector((state) => state.auth);
    const navigate = useNavigate();

    const [event, setEvent] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState('online');
    const [loading, setLoading] = useState(true);
    const [name, setName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [submitLoading, setSubmitLoading] = useState(false);

    useEffect(() => {
        async function getDetail(url, token){
            setLoading(true);

            const res = await read(url, auth.token);
            if(res.error === null){
                setEvent(res.data);

                //get profile
                const resProfile = await profile(token);
                if(resProfile.error === null || resProfile.error === ""){
                    setName(resProfile.data.first_name+' '+resProfile.data.last_name);
                    setPhoneNumber(resProfile.data.phone_number === null || resProfile.data.phone_number === "" ? "" : resProfile.data.phone_number);
                }else{
                    swal("Error!", res.error, "error");
                }
            }else{
                swal("Error!", res.error, "error");
            }

            setLoading(false);
        }

        getDetail(url, auth.token);
    }, [auth, url]);

    const handleOrder = async () => {
        //validate
        if(phoneNumber === "" || phoneNumber === null){
            swal("Error!", "Maaf, Harap mengisi Nomor Telepon Whatsapp", "error");
            return;
        }

        //show confirm
        swal({
            title: "Lanjutkan?",
            text: "Apakah anda yakin ingin melanjutkan proses pemesanan?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then(async (willBuy) => {
            if(willBuy){
                setSubmitLoading(true);

                const res = await order(auth.token, event.id, paymentMethod, phoneNumber);
                if(res.error == null){
                    swal("Sukses!", "Pesanan anda telah dibuat.", "success");
                    navigate('/event-transaction/order/'+res.data.id);
                }else{
                    swal("Error!", res.error, "error");

                    //check if error contains login
                    if(res.error.includes('login')){
                        navigate('/login');
                    }
                }

                setSubmitLoading(false);
            }
        });
    }

    return (
        <div className="mb-5 mt-5">
            <h4>Checkout</h4>
            
            {
                loading ? <Loading /> :
                event !== null ? (
                    <Row>
                        <Col sm={12} md={6} lg={8} className="mb-3">
                            <EventBox event={event} showRegisterButton={false}/>
                        </Col>
                        <Col sm={12} md={6} lg={4}>
                            <div className="box mb-3 p-3" style={{
                                textAlign: 'center'
                            }}>
                                Anda sedang menggunakan Akun
                                <br/>
                                {
                                    account.name !== '' ? (
                                        <div className="mt-3">
                                            <img src={account.avatar_url} alt="avatar" className="rounded avatar-m mb-3" />
                                            <div className="break-text" style={{
                                                fontSize: '12pt',
                                                fontWeight: 'bold'
                                            }}>
                                                { account.name }
                                            </div>
                                            
                                            <MembershipBadge />
                                        </div>
                                    ) : null
                                }
                            </div>
                            <div className="box mb-3 p-3" style={{
                                textAlign: 'start'
                            }}>
                                Halo <b>{ name }</b>, Mohon untuk mengisi/mengganti nomor whatsapp anda yang aktif dan dapat kami hubungi.
                                <div className="alert alert-warning mt-3">
                                    <FontAwesomeIcon icon={faInfoCircle}/> informasi kegiatan akan dikirim melalui nomor telepon yang dicantumkan.
                                </div>
                                <div className="mt-3">
                                    <strong>Nomor Telepon (Whatsapp)</strong>
                                    <input type="text" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} maxLength={50} placeholder="Nomor HP yang dapat dihubungi" className="form-control" required/>
                                </div>
                            </div>
                            <div className="box p-3">
                                <Row>
                                    <Col xs={6}>
                                        Harga Tiket
                                    </Col>
                                    <Col xs={6} className="text-end">
                                        { intToMoney(event.price) }
                                    </Col>
                                </Row>
                                <Row style={{
                                    fontWeight: 'bold',
                                    marginTop: '10px',
                                }}>
                                    <Col xs={6}>
                                        Total Biaya
                                    </Col>
                                    <Col xs={6} className="text-end">
                                        { intToMoney(event.total_cost) }
                                    </Col>
                                </Row>
                                <Button disabled={submitLoading} onClick={handleOrder} className="mt-3" variant="danger" style={{
                                    width: '100%',
                                }}>
                                    {
                                        submitLoading ? <Loading /> : 'Lanjutkan'
                                    }
                                </Button>
                            </div>
                        </Col>
                    </Row>
                ) : null
            }
        </div>
    );
}

export default CheckoutEvent;