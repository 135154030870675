import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
    search
} from './../services/news';
import swal from 'sweetalert';
import { Container, Row, Col, Button } from "react-bootstrap";
import NewsBox from "../components/news/NewsBox";
import config from "../helpers/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch} from '@fortawesome/free-solid-svg-icons'

const NewsListScreen = () => {
    const auth = useSelector((state) => state.auth);

    const [newsList, setNewsList] = useState([]);
    const [page, setPage] = useState(1);
    const [reset, setReset] = useState(true);
    const [isContainMore, setIsContainMore] = useState(true);
    const [keywordTemp, setKeywordTemp] = useState("");
    const [keyword, setKeyword] = useState("");

    //change title
    useEffect(() => {
        document.title = config.documentTitle + " - Berita";

        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        async function getEvent(){
            const res = await search(auth.token, keyword, page);
            if(res.error === null){
                if(reset){
                    setNewsList(res.data);
                }else{
                    //append
                    setNewsList(newsList => [...newsList, ...res.data]);
                }

                if(res.data.length < 1){
                    setIsContainMore(false);
                }else{
                    setIsContainMore(true);
                }
            }else{
                swal("Error!", res.error.message, "error");
            }
        }

        getEvent();
    }, [auth, page, reset, keyword]);

    const loadMore = () => {
        setReset(false);
        //set last id
        setPage(page+1);
    }

    const filter = () => {
        setReset(true);
        setPage(1);
        setKeyword(keywordTemp);
    }

    return (
        <div className="default-nav-mt">
            <Container>
                <div className="row">
                    <div className="col-sm-12 col-md-6 mt-1">
                        <h5>Berita</h5>
                    </div>
                    <div className="col-sm-12 col-md-6 text-end">
                        <div style={{
                            display: "inline-block",
                        }}>
                            <input type="text" value={keywordTemp} onChange={e => setKeywordTemp(e.target.value)} className="form-control" placeholder="Cari Artikel"/>
                        </div>
                        <button onClick={filter} className="btn btn-default ms-2">
                            <FontAwesomeIcon icon={faSearch}/>
                        </button>
                    </div>
                </div>
                <div className="row mt-5">
                    {
                        newsList.map((news, index) => (
                            <div key={news.id} className="col-12 col-sm-12 col-md-4 col-lg-3 mb-4">
                                <NewsBox news={news}/>
                            </div>
                        ))
                    }
                </div>
                <div className="text-center" hidden={!isContainMore}>
                    <Button variant="danger" className="btn-default" onClick={loadMore}>Muat Lebih Banyak</Button>
                </div>
            </Container>
        </div>
    );
}

export default NewsListScreen;