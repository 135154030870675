import React, { useEffect, useState } from "react";
import {Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import Dropzone, {useDropzone} from 'react-dropzone';
import {
    update,
    create,
    read
} from './../../services/education';
import {
    setRefreshDetail
} from './../../redux/slicers/accountSlice';
import swal from 'sweetalert';

const AddEditEducation = (props) => {
    const id = props.id;
    const refreshList = props.refreshList;

    const auth = useSelector((state) => state.auth);
    const [isActiveStudent, setIsActiveStudent] = useState(false);
    const [institution, setInstitution] = useState("");
    const [city, setCity] = useState("");
    const [strata, setStrata] = useState("");
    const [major, setMajor] = useState("");
    const [graduatedOn, setGraduatedOn] = useState("");
    const [isLoading, setIsLoading] = useState("");

    const [certificateFiles, setCertificateFiles] = useState([]);
    const [certificatesPath, setCertificatesPath] = useState('');
    const [uploadedCertificatesPath, setUploadedCertificatesPath] = useState('');

    const [studentCardFiles, setStudentCardFiles] = useState([]);
    const [studentCardPath, setStudentCardPath] = useState('');
    const [uploadedStudentCardPath, setUploadedStudentCardPath] = useState('');

    const fileToBase64 = (selectedFile, callback) => {
        let file = null;
        //Check File is not Empty
        if (selectedFile.length > 0) {
            // Select the very first file from list
            let fileToLoad = selectedFile[0];
            // FileReader function for read the file.
            let fileReader = new FileReader();
            // Onload of file read the file content
            fileReader.onload = function(fileLoadedEvent) {
                file = fileLoadedEvent.target.result;
                // Print data in console
                callback(file);
            };
            // Convert data to base64
            fileReader.readAsDataURL(fileToLoad);
        }
    }

    useEffect(() => {
        //clear form
        resetForm();
        setCertificatesPath("");
        setStudentCardPath("");

        if(id !== null){
            async function getData(id){
                const res = await read(id);
                if(res.error === null){
                    setInstitution(res.data.institution);
                    setCity(res.data.city);
                    setStrata(res.data.strata);
                    setMajor(res.data.major);
                    setGraduatedOn(res.data.graduated_on);
                    setUploadedCertificatesPath(res.data.certificates_path);
                    setIsActiveStudent(res.data.is_active_student === "0" ? false : true);
                    setUploadedStudentCardPath(res.data.student_card_path);
                }
            }

            getData(id);
        }
    }, [id]);

    const closeModal = () => {
        resetForm();
        props.setShowModal(false);
    }

    const resetForm = () => {
        //reset form
        setInstitution("");
        setCity("");
        setStrata("");
        setMajor("");
        setGraduatedOn("");
        setUploadedCertificatesPath("");
        setUploadedStudentCardPath("");
        setCertificateFiles([]);
        setStudentCardFiles([]);
    }

    const addData = async (e) => {
        e.preventDefault();

        //set loading
        setIsLoading(true);

        let res = {
            error: null,
        }

        //check if add or update
        if(id === null){
            //insert
            res = await create(
                auth.token,
                institution,
                city,
                strata,
                graduatedOn,
                certificatesPath,
                major,
                isActiveStudent,
                studentCardPath
            );
        }else{
            //edit
            res = await update(
                auth.token,
                id,
                institution,
                city,
                strata,
                graduatedOn,
                certificatesPath,
                major,
                isActiveStudent,
                studentCardPath
            );
        }

        if(res.error === null){
            //success
            swal("Sukses!", "Berhasil "+(id == null ? 'menambah' : 'mengubah')+" data.", "success");

            resetForm();

            refreshList();

            //close modal
            closeModal();
        }else{
            //error
            swal("Gagal!", res.error, "error");
        }

        setIsLoading(false);
    }

    return (
        <Modal size="lg" show={props.isShow} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>Education</Modal.Title>
            </Modal.Header>
            <form onSubmit={addData}>
            <Modal.Body>
                <div className="row">
                    <div className="col-12 col-md-6 mt-3">
                        <label>Institusi</label>
                        <input type="text" className="form-control" value={institution} onChange={e => setInstitution(e.target.value)} required={true}/>
                    </div>
                    <div className="col-12 col-md-6 mt-3">
                        <label>Kota</label>
                        <input type="text" className="form-control" value={city} onChange={e => setCity(e.target.value)} required={true}/>
                    </div>
                    <div className="col-12 col-md-6 mt-3">
                        <label>Strata</label>
                        <select className="form-select" value={strata} onChange={e => setStrata(e.target.value)} required={false}>
                            <option value="D1">D1</option>
                            <option value="D3">D3</option>
                            <option value="S1">S1</option>
                            <option value="S2">S2</option>
                        </select>
                    </div>
                    <div className="col-12 col-md-6 mt-3">
                        <label>Program Studi/Kursus</label>
                        <input type="text" className="form-control" value={major} onChange={e => setMajor(e.target.value)} required={false}/>
                    </div>
                    <div className="col-12 col-md-6 mt-3">
                        <label>Masih Sebagai Mahasiswa Aktif?</label>
                        <div className="mt-1">
                            <input type="checkbox" checked={isActiveStudent} onChange={e => setIsActiveStudent(e.target.checked)} required={false}/>
                        </div>
                    </div>
                    <div hidden={isActiveStudent} className="col-12 col-md-6 mt-3">
                        <label>Tahun Kelulusan</label>
                        <input type="date" className="form-control" value={graduatedOn} onChange={e => setGraduatedOn(e.target.value)} required={false}/>
                    </div>
                </div>

                <div className="mt-3" style={{
                    borderBottom: '1px solid #eee',
                }} hidden={!isActiveStudent}>
                    <label>Scan Kartu Mahasiswa (.pdf)</label>
                    <div className="border p-3">
                        <div hidden={ uploadedStudentCardPath === null || uploadedStudentCardPath === "" }>
                            <div className="text-center">
                                <a style={{
                                    textDecoration: 'none'
                                }} href={uploadedStudentCardPath} target="_blank" rel="noreferrer">Lihat Kartu Mahasiswa</a>
                            </div>
                            <div className="alert alert-warning mt-2">
                                Kosongkan Kolom ini apabila tidak ingin mengganti data.
                            </div>
                            <div className="text-center mb-3">
                                <button type="button" className="btn btn-default" onClick={() => {
                                    setUploadedStudentCardPath("");
                                    setStudentCardPath("remove");
                                }}>Hapus Kartu Mahasiswa</button>
                            </div>
                        </div>
                        <Dropzone accept={['application/pdf']} onDrop={(acceptedFiles) => {
                            let error = null;
                            acceptedFiles.forEach(file => {
                                if(file.type !== 'application/pdf'){
                                    error = 'File harus berformat PDF';
                                }
                            });

                            if(error === null){
                                setStudentCardFiles(acceptedFiles);
                                fileToBase64(acceptedFiles, setStudentCardPath);
                            }else{
                                swal('Error', error, 'error');
                            }
                        }} name="heroImage" multiple={false}>
                            {({getRootProps, getInputProps}) => (
                                <div {...getRootProps({className: 'dropzone'})}>
                                    <input {...getInputProps()} />
                                    <span style={{ fontSize: ".8rem" }}>
                                            Drop file, or click to select file
                                    </span>
                                </div>
                            )}
                        </Dropzone>
                        <aside className="mt-2" hidden={studentCardFiles === null || studentCardFiles.length < 1}>
                            <b>File yang dipilih:</b>
                            <ul>
                            {
                            studentCardFiles.map(file => (
                                <li key={file.path}>
                                    <a target="_blank" href={file.path}>{file.path}</a> - {(file.size / 1000)} Kb
                                </li>
                            ))
                            }
                            </ul>
                        </aside>
                    </div>
                </div>

                <div className="mt-3" style={{
                    borderBottom: '1px solid #eee',
                }} hidden={isActiveStudent}>
                    <label>Sertifikat (.pdf)</label>
                    <div className="border p-3">
                        <div hidden={ uploadedCertificatesPath === null || uploadedCertificatesPath === "" }>
                            <div className="text-center">
                                <a style={{
                                    textDecoration: 'none'
                                }} href={uploadedCertificatesPath} target="_blank" rel="noreferrer">Lihat Sertifikat</a>
                            </div>
                            <div className="alert alert-warning mt-2">
                                Kosongkan Kolom ini apabila tidak ingin mengganti data.
                            </div>
                            <div className="text-center mb-3">
                                <button type="button" className="btn btn-default" onClick={() => {
                                    setUploadedCertificatesPath("");
                                    setCertificatesPath("remove");
                                }}>Hapus Sertifikat</button>
                            </div>
                        </div>
                        <Dropzone accept={['application/pdf']} onDrop={(acceptedFiles) => {
                            let error = null;
                            acceptedFiles.forEach(file => {
                                if(file.type !== 'application/pdf'){
                                    error = 'File harus berformat PDF';
                                }
                            });

                            if(error === null){
                                setCertificateFiles(acceptedFiles);
                                fileToBase64(acceptedFiles, setCertificatesPath);
                            }else{
                                swal('Error', error, 'error');
                            }
                        }} name="heroImage" multiple={false}>
                            {({getRootProps, getInputProps}) => (
                                <div {...getRootProps({className: 'dropzone'})}>
                                    <input {...getInputProps()} />
                                    <span style={{ fontSize: ".8rem" }}>
                                            Drop file, or click to select file
                                    </span>
                                </div>
                            )}
                        </Dropzone>
                        <aside className="mt-2" hidden={certificateFiles === null || certificateFiles.length < 1}>
                            <b>File yang dipilih:</b>
                            <ul>
                            {
                            certificateFiles.map(file => (
                                <li key={file.path}>
                                    <a target="_blank" href={file.path}>{file.path}</a> - {(file.size / 1000)} Kb
                                </li>
                            ))
                            }
                            </ul>
                        </aside>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
                Tutup
            </Button>
            <Button variant="danger" type="submit" disabled={isLoading}>
                Simpan Perubahan
            </Button>
            </Modal.Footer>
            </form>
        </Modal>
    );
}

export default AddEditEducation;