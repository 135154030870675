import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
    read,
    search as searchNews
} from './../services/news';
import swal from 'sweetalert';
import { Container, Row, Col, Button } from "react-bootstrap";
import NewsBox from "../components/news/NewsBox";
import config from "../helpers/config";
import { useNavigate, useParams } from "react-router";
import Loading from "../components/Loading";
import Comments from "../components/news/Comments";
import { Link } from "react-router-dom";
import moment from "moment";

const NewsReadScreen = () => {
    const auth = useSelector((state) => state.auth);
    const params = useParams();
    const navigate = useNavigate();
    const url = params.url;

    const [news, setNews] = useState(null);
    const [latestNews, setLatestNews] = useState(null);
    const [latestNewsLoading, setLatestNewsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showComments, setShowComments] = useState(true);

    //change title
    useEffect(() => {
        document.title = config.documentTitle + " - Berita";

        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        async function getNewsDetail(url){
            setLoading(true);

            const res = await read(url, auth.token);
            if(res.error === null){
                setNews(res.data);

                document.title = config.documentTitle + " - " + res.data.title;

                //check show comments
                if(["laporan keuangan"].includes(res.data.news_type_name.toLowerCase())){
                    setShowComments(false);
                }else{
                    setShowComments(true);
                }
            }else{
                swal("Error!", res.error, "error");
                navigate('/');
            }

            setLoading(false);
        }

        async function getLatestNews(){
            setLatestNewsLoading(true);

            //get latest news
            const res = await searchNews(auth.token, '', 1);
            if(res.error === null){
                const latestNews = res.data.slice(0, 3);
                setLatestNews(latestNews);
            }else{
                swal("Error!", res.error.message, "error");
            }

            setLatestNewsLoading(false);
        }

        getNewsDetail(url);
        getLatestNews();
    }, [auth, url]);

    return (
        <div className="default-nav-mt">
            <Container className="mt-5">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/news-list">Artikel</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">{
                            news !== null ? news.title : ""
                        }</li>
                    </ol>
                </nav>
                <Row>
                    <Col className="mb-3" lg={12} md={12} sm={12}>
                        {
                            loading ? <Loading /> : (
                                <>
                                {
                                    news !== null ? (
                                        <>
                                            <div>
                                                <h3>{news.title}</h3>
                                            </div>
                                            <div className="mt-5">
                                                <div className="mt-3">
                                                    <img src={news.cover} alt={news.title} style={{
                                                        width: "100%",
                                                    }}/>
                                                </div>
                                                <div className="mt-3">
                                                    { <div dangerouslySetInnerHTML={{ __html: news.body }} /> }
                                                </div>
                                                <div className="mt-3 mb-3">
                                                    Dipublikasikan pada { moment(news.created_at).format("DD MMMM YYYY") }, oleh Administrator
                                                </div>

                                                {
                                                    showComments ? <Comments newsId={news.id}/> : null
                                                }
                                                
                                            </div>
                                        </>
                                    ) : null
                                }
                                </>
                            )
                        }
                    </Col>
                </Row>

                {
                    latestNewsLoading ? <Loading /> : (
                        latestNews !== null && latestNews.length > 0 ?
                            <div className="mt-5">
                                <h1>Berita Terbaru</h1>
                                <Row className="mt-5">
                                    {
                                        latestNews.map((newsObj, index) => (
                                            <div className="col-sm-12 col-md-4 col-lg-4" key={index} style={{
                                                marginLeft: '50px !important'
                                            }}>
                                                <NewsBox news={newsObj}/>
                                            </div>
                                        ))
                                    }
                                </Row>
                            </div>
                            : null
                    )
                }
            </Container>
        </div>
    );
}

export default NewsReadScreen;