import { createSlice } from '@reduxjs/toolkit'

export const informationSlice = createSlice({
  name: 'account',
  initialState: {
    main_logo: null,
    white_logo: null,
    faq_categories: null,
    menus: null,
    highlight_text: null,
    highlight_active: false,
  },
  reducers: {
    setData: (state, action) => {
      state.main_logo = action.payload.main_logo;
      state.white_logo = action.payload.white_logo;
      state.faq_categories = action.payload.faq_categories;
      state.menus = action.payload.menus;
      state.highlight_text = action.payload.highlight_text;
      state.highlight_active = action.payload.highlight_active === 'on' ? true : false;
    }
  },
})

// Action creators are generated for each case reducer function
export const { setData } = informationSlice.actions

export default informationSlice.reducer