import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

//bootstrap
import { Container, Row, Col } from "react-bootstrap";

//services

import LeftMenuPanel from "../components/LeftMenuPanel";
import config from "../helpers/config";
import PointHistory from "../components/points/PointHistory";

const PointHistoryScreen = () => {
    const auth = useSelector((state) => state.auth);

    //change title
    useEffect(() => {
        document.title = config.documentTitle + " - Point History";

        window.scrollTo(0, 0);
    }, []);

    return (
        <Container>
            <Row>
                <Col sm={12} md={12} lg={4} className="mb-4">
                    <LeftMenuPanel activeIndex={-1}/>
                </Col>
                <Col sm={12} md={12} lg={8}>
                    <PointHistory />
                </Col>
            </Row>
        </Container>
    );
}

export default PointHistoryScreen;