export const search = async (token) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}membership/search`, {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        }
    });
    const json = await response.json();
    return json;
}

export const order = async (token, membership_application_id, payment_method) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}membership/order`, {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            membership_application_id: membership_application_id,
            payment_method: payment_method
        })
    });
    const json = await response.json();
    return json;
}

export const orderDetail = async (token, id) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}membership/order_detail`, {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            id: id
        })
    });
    const json = await response.json();
    return json;
}

export const history = async (token, membership_application_id) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}membership/history`, {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            membership_application_id: membership_application_id
        })
    });
    const json = await response.json();
    return json;
}

export const read = async (token, id) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}membership/read`, {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            id: id
        })
    });
    const json = await response.json();
    return json;
}

export const uploadPayment = async (token, membership_order_id, image) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}membership/upload_payment`, {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            membership_order_id: membership_order_id,
            image: image,
        })
    });
    const json = await response.json();
    return json;
}

export const apply = async (token, membership_type) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}membership/apply`, {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            membership_type: membership_type
        })
    });
    const json = await response.json();
    return json;
}

export const isAbleToBuy = async (token, membership_type) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}membership/is_able_to_buy`, {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            membership_type: membership_type
        })
    });
    const json = await response.json();
    return json;
}

export const revise = async (token, membership_application_id) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}membership/revise`, {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            membership_application_id: membership_application_id
        })
    });
    const json = await response.json();
    return json;
}

export const paySubscription = async (token, totalYear) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}membership/pay_subscription`, {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            total_year: totalYear
        })
    });
    const json = await response.json();
    return json;
}

export const getMembershipInfo = async (token) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}membership/info`, {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        }
    });
    const json = await response.json();
    return json;
}

export const payReactivation = async (token) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}membership/pay_reactivation`, {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        }
    });
    const json = await response.json();
    return json;
}