import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useParams } from "react-router";
import DownloadCategories from "../components/download/DownloadCategories";
import EventDetail from "../components/events/EventDetail";
import LeftMenuPanel from "../components/LeftMenuPanel";
import config from "../helpers/config";

const EventDetailDashboardScreen = () => {
    const params = useParams();

    //change title
    useEffect(() => {
        document.title = config.documentTitle + " - Detil Kegiatan";

        window.scrollTo(0, 0);
    }, []);

    return (
        <Container>
            <Row>
                <Col sm={12} md={12} lg={4} className="mb-4">
                    <LeftMenuPanel activeIndex={9}/>
                </Col>
                <Col sm={12} md={12} lg={8}>
                    <EventDetail isAuth={true} url={params.url}/>
                </Col>
            </Row>
        </Container>
    );
}

export default EventDetailDashboardScreen;