export const all = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}province/all`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        }
    });
    const json = await response.json();
    return json;
}