import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import config from "../../helpers/config";
import EmploymentList from "../employments/EmploymentList";
import { profile } from "../../services/auth";
import { detail } from "../../services/account";
import Loading from "../Loading";
import EducationList from "../educations/EducationList";
import SKAList from "../ska/SKAList";
import { apply, order, orderDetail, isAbleToBuy as isAbleToBuyApi, revise } from "../../services/membership";
import { history } from "../../services/membershipApplication";
import { renderStatus } from "../../helpers/membership";
import { renderStatus as renderOrderStatus } from "../../helpers/membershipOrder";
import moment from "moment";
import EditProfile from "../account/EditProfile";
import EditProfilePro from "../account/EditProfilePro";
import { Link } from "react-router-dom";

const UpgradeMembershipForm = (props) => {
    const id = props.id;

    const auth = useSelector((state) => state.auth);
    const account = useSelector((state) => state.account);
    const navigate = useNavigate();
    
    const [histories, setHistories] = useState(null);
    const [historyLoading, setHistoryLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentMembershipType, setCurrentMembershipType] = useState(null);
    const [historiesRefreshTime, setHistoriesRefreshTime] = useState(null);
    const [isAbleToBuy, setIsAbleToBuy] = useState(false);
    const [isAbleToRevise, setIsAbleToRevise] = useState(false);
    const [lastApplicationId, setLastApplicationId] = useState(null);
    const [aggree, setAggree] = useState(false);

    useEffect(() => {
        async function getProfile(){
            setLoading(true);

            const res = await profile(auth.token);
            if(res.error === null || res.error === ""){
                const detailData = await detail(res.data.id);
                if(detailData.error === null){
                    setCurrentMembershipType(res.data.membership.type);
                }
            }

            setLoading(false);
        }

        getProfile();
    }, [account.refresh_detail, auth.token]);

    useEffect(() => {
        async function getApplicationHistory(){
            setHistoryLoading(true);

            let membershipDestination = null;
            if(currentMembershipType === config.membershipType.PUBLIC || currentMembershipType === config.membershipType.STUDENT){
                membershipDestination = config.membershipType.CAB;
            }
            else if(currentMembershipType === config.membershipType.MEMBER){
                membershipDestination = config.membershipType.MEMBER_PRO;
            }

            const res = await history(auth.token, membershipDestination);
            if(res.error === null){
                setHistories(res.data);

                //get is able to revise
                if(res.data !== null){
                    if(res.data.length > 0){
                        const lastApplication = res.data[0];
                        setLastApplicationId(lastApplication.id);
                        if(lastApplication.is_able_to_revise){
                            setIsAbleToRevise(true);
                        }else{
                            setIsAbleToRevise(false);
                        }
                    }
                }
            }else{
                swal("Error!", res.error, "error");
            }

            setHistoryLoading(false);
        }

        async function getIsAbleToBuy(){
            setLoading(true);

            let membershipDestination = null;
            if(
                currentMembershipType === config.membershipType.PUBLIC ||
                currentMembershipType === config.membershipType.STUDENT
            ){
                membershipDestination = config.membershipType.CAB;
            }
            else if(currentMembershipType === config.membershipType.MEMBER){
                membershipDestination = config.membershipType.MEMBER_PRO;
            }

            const res = await isAbleToBuyApi(auth.token, membershipDestination);
            if(res.error === null){
                setIsAbleToBuy(true);
            }else{
                setIsAbleToBuy(false);
            }

            setLoading(false);
        }

        if(currentMembershipType !== null){
            getApplicationHistory();
            getIsAbleToBuy();
        }
    }, [auth.token, currentMembershipType, historiesRefreshTime]);

    const applyMembership = async (membershipType) => {
        //check if checkbox is checked
        if(!aggree && membershipType === config.membershipType.CAB){
            swal("Error!", "Harap centang kotak Persetujuan", "error");
        }
        else{
            var confirm = await swal({
                title: "Apakah Anda Yakin?",
                text: "kami akan melakukan review terlebih dahulu sebelum pengajuan anda disetujui.",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            });

            if(confirm){
                setHistoryLoading(true);

                const res = await apply(auth.token, membershipType);
                if(res.error === null){
                    swal("Success!", res.data, "success");
                    setHistoriesRefreshTime(new Date().getTime());
                }else{
                    swal("Error!", res.error, "error");
                }
        
                setHistoryLoading(false);
            }
        }
    }

    const submitRevise = async (membership_application_id) => {
        var confirm = await swal({
            title: "Apakah Anda yakin?",
            text: "Pastikan data yang anda isi sudah sesuai.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        });

        if(confirm){
            setLoading(true);

            const res = await revise(auth.token, membership_application_id);
            if(res.error === null){
                swal("Success!", res.data, "success");
                setHistoriesRefreshTime(new Date().getTime());
            }else{
                swal("Error!", res.error, "error");
            }
    
            setLoading(false);
        }
    }

    const payOrder = async (id) => {
        setHistoryLoading(true);

        const res = await order(auth.token, id, "online");
        if(res.error === null){
            setHistoriesRefreshTime(new Date().getTime());
        }else{
            swal("Error!", res.error, "error");
        }

        setHistoryLoading(false);
    }

    const checkPaymentStatus = async (orderId) => {
        setHistoryLoading(true);

        const res = await orderDetail(auth.token, orderId);
        if(res.error === null){
            if(res.data.status === '2'){
                swal("Success!", "Status Keanggotaan anda telah di perbaharui.", "success");
                window.location.reload();
            }else{
                swal("Error!", "Status pembayaran anda belum lunas, Harap tunggu beberapa saat lagi.", "error");
                setHistoriesRefreshTime(new Date().getTime());
            }
        }else{
            swal("Error!", res.error, "error");
        }

        setHistoryLoading(false);
    }

    return (
        <div>
            <h4>Upgrade Keanggotaan</h4>
            {
                historyLoading ? <Loading /> :
                histories === null ? null :
                histories.map((history, index) => (
                    <div key={index} className="box p-3 mt-3">
                        <div className="row">
                            <div className="col-md-6">
                                status pengajuan: { renderStatus(history.status) }
                                {
                                    history.status === config.membershipApplicationStatus.APPROVED && history.can_generate_invoice && (currentMembershipType === config.membershipType.PUBLIC || currentMembershipType === config.membershipType.STUDENT) ? (
                                        <div>
                                            <button type="button" onClick={() => payOrder(history.id)} className="btn btn-default btn-sm">Buat Pembayaran</button>
                                        </div>
                                    ) : null
                                }
                            </div>
                            <div className="col-md-6 text-end">
                                { history.note !== null && history.note !== "" ? "catatan: "+history.note : null }
                            </div>
                        </div>
                        <div className="row mt-1">
                            <div className="col-md-12 text-end text-muted" style={{
                                fontSize: "10pt",
                            }}>
                                tanggal pengajuan: { moment(history.created_at).fromNow() }
                            </div>
                        </div>

                        {
                            history.status === config.membershipApplicationStatus.APPROVED && (currentMembershipType === config.membershipType.PUBLIC || currentMembershipType === config.membershipType.STUDENT) ? (
                                history.orders === null ? null :
                                <>
                                    <b>Riwayat Pembayaran:</b>
                                    {
                                    history.orders.map((order) => (
                                        <div key={index+'-'+order.id} className="border p-3 mb-3">
                                            <div>
                                                Nomor Invoice: #{ order.jurnal_transaction_no }
                                            </div>
                                            <div>
                                                Status Pembayaran: { renderOrderStatus(order.status) }
                                            </div>
                                            {
                                                order.status === '1' ? (
                                                    <div className="mt-3">
                                                        <div className="alert alert-warning text-center">
                                                            Harap selesaikan pembayaran sebelum
                                                            <div style={{
                                                                fontWeight: 'bold',
                                                            }}>
                                                                { moment(order.payment_expired).format("HH:mm, DD-MMMM-YYYY") }
                                                            </div>
                                                        </div>
                                                        <a target="_blank" href={order.jurnal_invoice_url} className="btn btn-default btn-sm">Pilih Pembayaran</a>
                                                        <button hidden={true} type="button" onClick={() => checkPaymentStatus(order.id)} className="btn btn-success btn-sm ms-3">Konfirmasi Pembayaran</button>
                                                    </div>
                                                ) : null
                                            }
                                            <div className="row mt-3" style={{
                                                fontSize: '10pt',
                                                color: '#bdbdbd'
                                            }}>
                                                <div className="col-6">
                                                    {
                                                        'Dibuat: '+order.created_at
                                                    }
                                                </div>
                                                <div className="col-6 text-end">
                                                    {
                                                        'Update: '+order.updated_at
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                    }
                                </>
                            ) : null
                        }
                    </div>
                ))
            }
            <div className="row">
                <div className="col-md-12">
                    {
                        (currentMembershipType === config.membershipType.PUBLIC || currentMembershipType === config.membershipType.STUDENT) ? (
                            <>
                                {
                                    loading ? <Loading /> : (
                                        <div hidden={!isAbleToBuy && !isAbleToRevise}>
                                            <EditProfile />

                                            <div className="mt-3">
                                                <EducationList/>
                                            </div>

                                            <div className="mt-3">
                                                <EmploymentList />
                                            </div>

                                            <div className="mt-5 p-1 d-flex">
                                                <input type="checkbox" style={{
                                                    height: '23px'
                                                }} checked={aggree} onChange={e => setAggree(e.target.checked)}/>
                                                <div className="ms-3">
                                                    Melalui surat ini, Saya menyatakan bahwa Saya akan mengikuti seluruh proses Pendaftaran Anggota Baru yang telah ditetapkan oleh Ikatan Arsitek Indonesia Jakarta, yang terdiri dari :
                                                    <ol>
                                                        <li>Aktif mengikuti kegiatan Calon Anggota Baru di Jakarta secara online ataupun offline di Jakarta</li>
                                                        <li>Mengikuti Penataran Kode Etik dan Pelantikan yang diselangarakan IAI Jakarta, baik secara online ataupun offline di Jakarta</li>
                                                    </ol>
                                                    Demikian pernyataan ini Saya buat untuk dapat digunakan sebagaimana mestinya
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }

                                <button hidden={!isAbleToBuy} onClick={() => applyMembership(config.membershipType.CAB)} className="btn btn-default mt-5" style={{
                                    width: "100%",
                                }}>Ajukan Menjadi Calon Anggota</button>

                                {/* check if able to revise */}
                                <button hidden={!isAbleToRevise} onClick={() => submitRevise(lastApplicationId)} className="btn btn-default mt-5" style={{
                                    width: "100%",
                                }}>Ajukan Revisi</button>
                            </>
                        )

                        : currentMembershipType === config.membershipType.CAB ? (
                            <div className="box mt-3 p-3 text-center">
                                <div>Selamat, Anda Berhasil Menjadi Calon Anggota</div>
                                <div>Silahkan Ikuti Kode Etik untuk Dilantik menjadi <b>Anggota</b></div>
                                <Link to="/events" className="btn btn-default mt-3">Lihat Daftar Kegiatan</Link>
                            </div>
                        )

                        : currentMembershipType === config.membershipType.MEMBER ? (
                            <>
                                {
                                    loading ? <Loading /> : (
                                        <>
                                            <EditProfilePro />

                                            <div className="mt-3">
                                                <SKAList/>
                                            </div>
                                        </>
                                    )
                                }

                                <button hidden={!isAbleToBuy} onClick={() => applyMembership(config.membershipType.MEMBER_PRO)} className="btn btn-default mt-5" style={{
                                    width: "100%",
                                }}>Ajukan Menjadi Anggota Profesional</button>

                                {/* check if able to revise */}
                                <button hidden={!isAbleToRevise} onClick={() => submitRevise(lastApplicationId)} className="btn btn-default mt-5" style={{
                                    width: "100%",
                                }}>Ajukan Revisi</button>
                            </>
                        )

                        : null
                    }
                </div>
            </div>
        </div>
    );
}

export default UpgradeMembershipForm;