import React, { useEffect, useState } from "react";
//bootstrap
import {
    all,
    update,
    remove
} from './../../services/userShippingAddress';
import { useSelector, useDispatch } from "react-redux";
import swal from 'sweetalert';
import AddEditShippingAddress from "./AddEditShippingAddress";
import {
    setShippingAddressChanged
} from './../../redux/slicers/cartSlice';

const ShippingAddressList = (props) => {
    const isCheckout = props.isCheckout;

    const auth = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const [addresses, setAddresses] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [editId, setEditId] = useState(null);

    const getData = async (token) => {
        const res = await all(token);
        if(res.error === null){
            setAddresses(res.data);
        }else{
            swal("Error!", res.error, "error");
        }
    }

    useEffect(() => {
        async function getList(){
            const res = await all(auth.token);
            if(res.error === null){
                setAddresses(res.data);
            }else{
                swal("Error!", res.error, "error");
            }
        }

        getList();
    }, [auth]);

    useEffect(() => {
        if(editId !== null){
            setShowModal(true);
        }
    }, [editId]);

    const closeModal = () => {
        setEditId(null);
        setShowModal(false);
    }

    const deleteData = async (id) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then(async (willDelete) => {
            if (willDelete) {
                const res = await remove(auth.token, id);
                if(res.error === null){
                    swal("Sukses!", "Berhasil menghapus data.", "success");

                    getData(auth.token);
                }
            } 
        });
    }

    const refreshData = async () => {
        getData(auth.token);
    }

    const setToMainShippingAddress = async (data) => {
        const res = await update(
            auth.token,
            data.id,
            data.address_label,
            data.receiver_name,
            data.phone_number,
            data.city,
            data.city_id,
            data.full_address,
            data.postcode,
            data.note,
            true
        );

        if(res.error === null){
            //show success swal
            swal("Sukses!", "Berhasil mengubah data.", "success");

            refreshData();

            //dispatch address changed
            dispatch(setShippingAddressChanged(new Date().getTime()));
            
            //close modal
            props.closeModal();
        }else{
            swal("Error!", res.error, "error");
        }
    }

    return(
        <div className="p-0">
            <h3><b>Alamat Pengiriman</b></h3>
            <div className="text-end">
            <button className="btn btn-default ms-2 btn-sm" onClick={() => setShowModal(true)}>Tambah Baru</button>
            </div>
            <AddEditShippingAddress id={editId} isShow={ showModal } setShowModal={closeModal} refreshCallback={refreshData}/>

            {
                addresses.length < 1 ?
                (
                    <div className="text-center p-4">
                        <h4>Belum ada Alamat Pengiriman</h4>
                        <img src="img/empty.svg" style={{
                            width: "200px",
                        }}/>
                    </div>
                )
                : addresses.map((address, index) => (
                    <div key={index} className="box p-3 mt-3 mb-3" style={{
                        fontSize: "10pt",
                        border: address.is_main_address === '1' ? "1px solid #a10f2b" : '',
                    }}>
                        <div>
                            <b>{ address.receiver_name }</b> ({ address.address_label })
                            <span hidden={ address.is_main_address !== '1' } className="badge bg-danger ms-2">Utama</span>
                        </div>
                        <div className="text-muted">
                            { address.phone_number }
                        </div>
                        <div className="text-muted mt-2">
                            { address.full_address  }
                        </div>
                        <div className="text-muted">
                            { address.city + ' ' + address.postcode }
                        </div>
                        <div style={{
                            border: '1px solid #ccc',
                            borderRadius: '5px',
                            padding: '5px',
                            display: 'inline-block',
                        }} hidden={ address.note === null || address.note === '' } className="text-muted mt-2">
                            <b>catatan:</b>
                            <br/>
                            { address.note }
                        </div>
                        <div className="mt-3">
                            <button hidden={!isCheckout} className="btn btn-secondary btn-sm me-2" onClick={() => setToMainShippingAddress(address)}>Pilih Alamat</button>
                            <button className="btn btn-secondary btn-sm me-2" onClick={() => setEditId(address.id)}>Ubah</button>
                            <button hidden={isCheckout} className="btn btn-default btn-sm" onClick={() => deleteData(address.id)}>Hapus</button>
                        </div>
                    </div>
                ))
            }
        </div>

    );
}

export default ShippingAddressList;