import React, { useEffect, useState }from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import swal from "sweetalert";
import { forgotPassword, resetPassword, validateResetPassword } from "../services/auth";
import { useParams, useNavigate } from 'react-router';
import Loading from './../components/Loading';
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const ResetPasswordScreen = () => {
    const params = useParams();
    const navigate = useNavigate();

    const information = useSelector((state) => state.information);

    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [loading, setLoading] = useState(true);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [error, setError] = useState(null);
    const [user, setUser] = useState(null);

    useEffect(() => {
        async function checkToken(token){
            setLoading(true);

            const res = await validateResetPassword(token);
            if(res.error == null){
                setUser(res.data);
            }else{
                setError(res.error);
            }

            setLoading(false);
        }

        checkToken(params.token);
    }, []);

    const submit = async e => {
        e.preventDefault();

        setSubmitLoading(true);
        
        const res = await resetPassword(
            params.token,
            newPassword,
            confirmPassword
        );
        if(res.error === null){
            swal(
                "Success",
                res.data,
                "success"
            );

            navigate('/login');
        }else{
            swal(
                "Error",
                res.error,
                "error"
            );
        }

        setSubmitLoading(false);
    }

    return (
        <>
        <div className="login-container">
            <div className="login-image d-none d-md-none d-xl-block d-xxl-block" style={{
                    backgroundImage: `url(/img/register.png)`,
                    backgroundSize: "cover",
                }}>
                    <img onClick={() => navigate('/') } style={{
                        cursor: "pointer",
                    }} src={information.white_logo} className="icon"/>
            </div>
            <div className="login-content">
                <img onClick={() => navigate('/') } style={{
                        cursor: "pointer",
                    }} src={information.main_logo} className="m-4 d-block d-md-block d-xl-none d-xxl-none" style={{
                    width: "150px",
                }}/>
                <div className="text-end p-5 d-none d-md-none d-xl-block d-xxl-block">
                    Sudah memiliki akun? <Link to="/login" className="def-link">Masuk</Link>
                </div>
                <Container>
                    <Row className="justify-content-center">
                        <Col 
                        sm={{
                            span: 12
                        }} 
                        md={{
                            span: 10
                        }}
                        lg={{
                            span: 10
                        }}
                        xl={{
                            span: 10
                        }}
                        xxl={{
                            span: 6
                        }}
                        >
                            <div className="p-3">
                                <div style={{
                                    fontWeight: "bold",
                                    fontSize: "14pt",
                                }}>
                                    Reset Password
                                </div>
                                <div className="text-muted mt-4">
                                    {
                                        user !== null ? (
                                            <>Halo <b>{user.first_name}</b></>
                                        ) : null
                                    } reset password anda
                                </div>
                                <div className="alert alert-warning">
                                    Password harus minimal 6 karakter
                                </div>
                                <form onSubmit={submit} className="mt-4">
                                    <div className="mt-3">
                                        <label><b>Kata Sandi</b></label>
                                        <input type="password"  disabled={submitLoading} value={newPassword} onChange={e => setNewPassword(e.target.value)} className="form-control" placeholder="Password Baru" required/>
                                    </div>
                                    <div className="mt-3">
                                        <label><b>Konfirmasi Kata Sandi</b></label>
                                        <input type="password"  disabled={submitLoading} value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} className="form-control" placeholder="Konfirmasi Password Baru" required/>
                                    </div>
                                    <div className="mt-5">
                                        <button disabled={submitLoading} type="submit" className="btn btn-default" style={{
                                            width: '100%'
                                        }}>{
                                            submitLoading ? <Loading/> : 'Reset Password'
                                        }</button>
                                    </div>
                                </form>
                            </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    );
}

export default ResetPasswordScreen;