import React, { useEffect, useState } from "react";
import { Row, Col, Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
    update,
    create,
    read
} from './../../services/employment';
import {
    setRefreshDetail
} from './../../redux/slicers/accountSlice';
import swal from 'sweetalert';

const AddEditEmployment = (props) => {
    const id = props.id;
    const refreshList = props.refreshList;

    const auth = useSelector((state) => state.auth);

    const [companyName, setCompanyName] = useState("");
    const [jobTitle, setJobTitle] = useState("");
    const [isCurrentCompany, setIsCurrentCompany] = useState(false);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [phone, setPhone] = useState("");
    const [fax, setFax] = useState("");
    const [website, setWebsite] = useState("");
    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [address, setAddress] = useState("");
    const [isLoading, setIsLoading] = useState("");

    useEffect(() => {
        if(id !== null){
            async function getData(id){
                const res = await read(id);
                if(res.error === null){
                    setCompanyName(res.data.company_name);
                    setJobTitle(res.data.job_title);
                    setIsCurrentCompany(res.data.is_current_company === "0" ? false : true);
                    setStartDate(res.data.start_date);
                    setEndDate(res.data.end_date);
                    setPhone(res.data.phone);
                    setFax(res.data.fax);
                    setWebsite(res.data.website);
                    setCountry(res.data.country);
                    setState(res.data.state);
                    setAddress(res.data.address);
                }
            }

            getData(id);
        }
    }, [id]);

    const closeModal = () => {
        resetForm();
        props.setShowModal(false);
    }

    const resetForm = () => {
        //reset form
        setCompanyName("");
        setJobTitle("");
        setIsCurrentCompany(false);
        setStartDate("2017-01-02");
        setEndDate("2018-01-01");
        setPhone("");
        setFax("");
        setWebsite("");
        setCountry("");
        setState("");
        setAddress("");
    }

    const addData = async (e) => {
        e.preventDefault();

        //set loading
        setIsLoading(true);

        let res = {
            error: null,
        }

        //check if add or update
        if(id === null){
            //insert
            res = await create(
                auth.token,
                companyName,
                jobTitle,
                isCurrentCompany,
                startDate,
                endDate,
                phone,
                fax,
                website,
                country,
                state,
                address
            );
        }else{
            //edit
            res = await update(
                auth.token,
                id,
                companyName,
                jobTitle,
                isCurrentCompany,
                startDate,
                endDate,
                phone,
                fax,
                website,
                country,
                state,
                address
            );
        }

        if(res.error === null){
            //success
            swal("Sukses!", "Berhasil "+(id == null ? 'menambah' : 'mengubah')+" data.", "success");

            resetForm();

            //set refresh detail
            refreshList();

            //close modal
            closeModal();
        }

        setIsLoading(false);
    }

    return (
        <Modal size="lg" show={props.isShow} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>Pekerjaan</Modal.Title>
            </Modal.Header>
            <form onSubmit={addData}>
            <Modal.Body>
                <Row>
                    <Col lg={6}>
                        <div>
                            <label>Nama Perusahaan</label>
                            <input type="text" className="form-control" value={companyName} onChange={e => setCompanyName(e.target.value)} required={true}/>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div>
                            <label>Jabatan</label>
                            <input type="text" className="form-control" value={jobTitle} onChange={e => setJobTitle(e.target.value)} required={true}/>
                        </div>
                    </Col>
                </Row>
                <div className="mt-3">
                    <label>Perusahaan Saat ini?!</label>
                    &nbsp;&nbsp;<input type="checkbox" checked={isCurrentCompany} onChange={e => setIsCurrentCompany(e.target.checked)} required={false}/>
                </div>
                <Row className="mt-3">
                    <Col lg={6}>
                        <div>
                            <label>Tanggal Mulai</label>
                            <input type="date" className="form-control" value={startDate} onChange={e => setStartDate(e.target.value)} required={true}/>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div hidden={isCurrentCompany}>
                            <label>Tanggal Selesai</label>
                            <input type="date" className="form-control" value={endDate} onChange={e => setEndDate(e.target.value)} required={isCurrentCompany ? false : true}/>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col lg={6}>
                        <div>
                            <label>Nomor Telefon</label>
                            <input type="text" className="form-control" value={phone} onChange={e => setPhone(e.target.value)} required={false}/>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div>
                            <label>Fax</label>
                            <input type="text" className="form-control" value={fax} onChange={e => setFax(e.target.value)} required={false}/>
                        </div>
                    </Col>
                </Row>
                <div className="mt-3">
                    <label>Website</label>
                    <input type="text" className="form-control" value={website} onChange={e => setWebsite(e.target.value)} required={false}/>
                </div>
                <Row className="mt-3">
                    <Col lg={6}>
                        <div>
                            <label>Negara</label>
                            <input type="text" className="form-control" value={country} onChange={e => setCountry(e.target.value)} required={false}/>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div>
                            <label>Provinsi</label>
                            <input type="text" className="form-control" value={state} onChange={e => setState(e.target.value)} required={false}/>
                        </div>
                    </Col>
                </Row>
                <div className="mt-3">
                    <label>Alamat</label>
                    <textarea  className="form-control" value={address} onChange={e => setAddress(e.target.value)} required={false}/>
                </div>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
                Tutup
            </Button>
            <Button variant="danger" type="submit" disabled={isLoading}>
                Simpan Perubahan
            </Button>
            </Modal.Footer>
            </form>
        </Modal>
    );
}

export default AddEditEmployment;