import { createSlice } from '@reduxjs/toolkit'

import config from '../../helpers/config';

//get token from localStorage
let token = null;
const localToken = localStorage.getItem(config.storageKey.token);
if(localToken != null){
    token = localToken;
}

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isAuthenticated: token !== null ? true : false,
    token: token,
    redirectUrl : null
  },
  reducers: {
    setAuthentication: (state, action) => {
        //insert into local storage
        if(action.payload.token !== null){
            window.localStorage.setItem(config.storageKey.token, action.payload.token);
        }

        state.isAuthenticated = action.payload.isAuthenticated;
        state.token = action.payload.token;
    },
    logout: (state) => {
        state.isAuthenticated = false;
        state.token = null;

        //remove from local storage
        window.localStorage.removeItem(config.storageKey.token);
    },
    setRedirectUrl: (state, action) => {
      state.redirectUrl = action.payload;
    }
  },
})

// Action creators are generated for each case reducer function
export const { setAuthentication, logout, setRedirectUrl } = authSlice.actions

export default authSlice.reducer