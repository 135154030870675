import React from 'react';
import { Breadcrumb, Col, Row } from 'react-bootstrap';

const PageNav = (props) => {
    const title = props.title;
    const links = props.links;

    return (
        <Row>
            <Col xs={6}>
                <h4>{title}</h4>
            </Col>
            <Col xs={6}>
                <Breadcrumb style={{
                    float: 'right'
                }}>
                    {
                        links !== null ? (
                            links.map((link, index) => (
                                <Breadcrumb.Item key={index} href={link.url}>{link.label}</Breadcrumb.Item>
                            ))
                        ) : null
                    }
                </Breadcrumb>
            </Col>
        </Row>
        
    );
}

export default PageNav;