import React, { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
    profile as getProfile
} from './../../services/auth';
import {
    Link as RouterLink,
} from "react-router-dom";
import Loading from "../Loading";

const PointBox = () => {
    const auth = useSelector((state) => state.auth);
    const [profile, setProfile] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        //get profile
        if(auth.token !== null){
            setLoading(true);

            getProfile(auth.token).then(res=> {
                setProfile(res.data);

                setLoading(false);
            })
        }
    }, [auth]);

    const trimmedPoint = useMemo(() => {
        if (!profile) return '';

        const { point } = profile;

        // check not if decimal(doesnt contains dot)
        if (!point.toString().includes('.')) return point;

        const [integer, decimal] = point.toString().split('.');
        return `${integer}.${decimal.slice(0, 1)}`;
    }, [profile])

    return (
        <>
            {
                loading ? <Loading /> :
                profile !== null ? (
                    <div style={{
                        display: "flex",
                    }}>
                        <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M56 29C56 43.9117 43.9117 56 29 56C14.0883 56 2 43.9117 2 29C2 14.0883 14.0883 2 29 2C43.9117 2 56 14.0883 56 29Z" fill="#FFBB0D"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M58 29C58 45.0163 45.0163 58 29 58C12.9837 58 0 45.0163 0 29C0 12.9837 12.9837 0 29 0C45.0163 0 58 12.9837 58 29ZM29 56C43.9117 56 56 43.9117 56 29C56 14.0883 43.9117 2 29 2C14.0883 2 2 14.0883 2 29C2 43.9117 14.0883 56 29 56Z" fill="#E89B05"/>
                            <path d="M49 29C49 40.0457 40.0457 49 29 49C17.9543 49 9 40.0457 9 29C9 17.9543 17.9543 9 29 9C40.0457 9 49 17.9543 49 29Z" fill="#FFDD28"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M51 29C51 41.1503 41.1503 51 29 51C16.8497 51 7 41.1503 7 29C7 16.8497 16.8497 7 29 7C41.1503 7 51 16.8497 51 29ZM29 49C40.0457 49 49 40.0457 49 29C49 17.9543 40.0457 9 29 9C17.9543 9 9 17.9543 9 29C9 40.0457 17.9543 49 29 49Z" fill="#E89B05"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M29 46C38.3888 46 46 38.3888 46 29C46 28.4477 46.4477 28 47 28C47.5523 28 48 28.4477 48 29C48 39.4934 39.4934 48 29 48C28.4477 48 28 47.5523 28 47C28 46.4477 28.4477 46 29 46Z" fill="#ECC704"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M29 12C19.6112 12 12 19.6112 12 29C12 29.5523 11.5523 30 11 30C10.4477 30 10 29.5523 10 29C10 18.5066 18.5066 10 29 10C29.5523 10 30 10.4477 30 11C30 11.5523 29.5523 12 29 12Z" fill="#FFEF9C"/>
                            <path d="M18 26.4L24.875 25L26.9375 21.5L29 18L33.125 25L40 26.4L34.5 32L35.875 39L29 35.5L22.125 39L23.5 32L18 26.4Z" fill="#FFEF9C"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M24.875 25L29 18L33.125 25L40 26.4L34.5 32L35.875 39L29 35.5L22.125 39L23.5 32L18 26.4L24.875 25ZM21.3343 32.6491L16.5732 27.8014C16.0595 27.2784 15.8731 26.5162 16.0875 25.8152C16.3018 25.1141 16.8826 24.5865 17.601 24.4402L23.6038 23.2178L27.277 16.9846C27.6365 16.3745 28.2919 16 29 16C29.7082 16 30.3636 16.3745 30.7231 16.9846L34.3963 23.2178L40.3991 24.4402C41.1175 24.5865 41.6982 25.1141 41.9126 25.8152C42.127 26.5162 41.9406 27.2784 41.4269 27.8014L36.6658 32.6491L37.8375 38.6145C37.985 39.3654 37.6911 40.1346 37.0805 40.5959C36.4699 41.0571 35.6496 41.1295 34.9677 40.7823L29 37.7443L23.0324 40.7823C22.3505 41.1295 21.5302 41.0571 20.9196 40.5959C20.309 40.1346 20.0151 39.3654 20.1626 38.6145L21.3343 32.6491Z" fill="#E89B05"/>
                        </svg>
                        <div className="ms-3 mt-1">
                            <div>
                                <span style={{
                                    fontSize: "14pt",
                                    fontWeight: "bold",
                                    color: "#AA272F",
                                    marginRight: "5px",
                                }}>
                                    { trimmedPoint }
                                </span>
                                <span style={{
                                    fontSize: "12pt",
                                    fontWeight: "bold",
                                }}>
                                    Poin KUM
                                </span>
                            </div>
                            <div>
                                <RouterLink to="/point-history" className="def-link">Lihat Riwayat</RouterLink>
                            </div>
                        </div>
                    </div>
                ) : null
            }
        </>
    );
}

export default PointBox;