export const all = async (token) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}shipping_address/all`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        }
    });
    const json = await response.json();
    return json;
}

export const create = async (
    token,
    address_label,
    receiver_name,
    phone_number,
    city,
    city_id,
    full_address,
    postcode,
    note,
    is_main_address
    ) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}shipping_address/create`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            address_label: address_label,
            receiver_name: receiver_name,
            phone_number: phone_number,
            city: city,
            city_id: city_id,
            full_address: full_address,
            postcode: postcode,
            note: note,
            is_main_address: is_main_address,
        })
    });
    const json = await response.json();
    return json;
}

export const update = async (
    token, 
    id,
    address_label,
    receiver_name,
    phone_number,
    city,
    city_id,
    full_address,
    postcode,
    note,
    is_main_address
    ) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}shipping_address/update`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            user_shipping_address_id: id,
            address_label: address_label,
            receiver_name: receiver_name,
            phone_number: phone_number,
            city: city,
            city_id: city_id,
            full_address: full_address,
            postcode: postcode,
            note: note,
            is_main_address: is_main_address,
        })
    });
    const json = await response.json();
    return json;
}

export const remove = async (token, user_shipping_address_id) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}shipping_address/delete`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            user_shipping_address_id: user_shipping_address_id,
        })
    });
    const json = await response.json();
    return json;
}

export const read = async (token, id) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}shipping_address/read`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            id: id,
        })
    });
    const json = await response.json();
    return json;
}

export const getCities = async (token) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}shipping_address/get_cities`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        }
    });
    const json = await response.json();
    return json;
}