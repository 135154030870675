import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight} from '@fortawesome/free-solid-svg-icons'
import React, { useState } from "react";
import { useEffect } from "react";
import swal from "sweetalert";
import { latest } from "../../services/downloads";
import Loading from "../Loading";
import moment from "moment";

const LatestDownloads = () => {
    const [downloads, setDownloads] = useState(null);
    const [loading, setLoading] = useState(null);

    useEffect(() => {
        async function getLatest(){
            setLoading(true);

            const res = await latest();
            if(res.error === null){
                setDownloads(res.data);
            }else{
                swal('Error', res.error, 'error');
            }

            setLoading(false);
        }
        
        getLatest();
    }, []);

    return (
        <>
        {
            loading ? <Loading /> : (
                <div>
                    {
                        downloads !== null ? 
                            downloads.map((download, index) => (
                                <div className="p-3 mt-3" style={{
                                    borderBottom: '1px solid #ccc',
                                }} key={index}>
                                    <div className="row">
                                        <div className="col-4">
                                            <b>{ moment(download.created_at).format("DD MMMM YYYY") }</b>
                                        </div>
                                        <div className="col-4">
                                            <a href={download.path} className="text-dark" target="_blank">{download.category_name} - {download.title}</a>
                                        </div>
                                        <div className="col-4 text-end">
                                            <a href={download.path} className="text-dark" target="_blank">
                                                <FontAwesomeIcon icon={faArrowRight}/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            ))
                        : null
                    }
                </div>
            )
        }
        </>
    );
}

export default LatestDownloads;