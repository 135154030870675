export const search = async (token, keyword, page) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}users/search`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            keyword: keyword,
            page: page
        })
    });
    const json = await response.json();
    return json;
}

export const totalNotification = async (token) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}users/total_notification`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        }
    });
    const json = await response.json();
    return json;
}

export const notification = async (token, page, keyword) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}users/notification`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            page: page,
            keyword: keyword
        })
    });
    const json = await response.json();
    return json;
}

export const readNotification = async (token, notification_id) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}users/read_notification`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            notification_id: notification_id,
        })
    });
    const json = await response.json();
    return json;
}