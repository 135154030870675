import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router";
import ProductDetail from "../components/products/ProductDetail";

const ProductDetailScreen = () => {
    let params = useParams();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="default-nav-mt">
            <Container>
                <Row>
                    <Col>
                        <div className="p-3">
                            <ProductDetail id={params.id}/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default ProductDetailScreen;