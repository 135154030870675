import React, { useEffect, useState } from 'react';
import {
    all
} from './../../services/downloads';
import swal from 'sweetalert';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';
import Loading from '../Loading';

const DownloadSearch = () => {
    const [files, setFiles] = useState(null);
    const [page, setPage] = useState(1);
    const [keyword, setKeyword] = useState('');
    const [keywordValue, setKeywordValue] = useState('');
    const [reset, setReset] = useState(true);
    const [isContainMore, setIsContainMore] = useState(true);
    const [loading, setLoading] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);

    useEffect(() => {
        async function getAll(){
            if(reset){
                setLoading(true);
            }else{
                setLoadingMore(true);
            }

            const res = await all(null, page, keyword);
            if(res.error === null){
                if(reset){
                    setFiles(res.data);
                }else{
                    setFiles(files => [...files, ...res.data]);
                }

                if(res.data.length < 1){
                    setIsContainMore(false);
                }else{
                    setIsContainMore(true);
                }
            }else{
                swal(
                    'Error',
                    res.error,
                    'error'
                );
            }

            if(reset){
                setLoading(false);
            }else{
                setLoadingMore(false);
            }
        }
        
        if(keywordValue !== null && keywordValue !== ''){
            getAll();
        }
    }, [page, keywordValue]);

    const loadMore = () => {
        setReset(false);
        //set last id
        setPage(page+1);
    }

    const searchData = e => {
        e.preventDefault();

        setReset(true);
        setKeywordValue(keyword);
        setPage(1);
    }

    return(
        <div>
            <form onSubmit={searchData} className='mt-3 mb-3 box p-3'>
                <div className='row'>
                    <div className='col-sm-12 col-md-8 col-lg-10'>
                        <b>Kata Kunci</b>
                        <input type="text" value={keyword} onChange={e => setKeyword(e.target.value)} className='form-control' placeholder='Kata Kunci Pencarian' required/>
                    </div>
                    <div className='col-sm-12 col-md-4 col-lg-2 mt-4'>
                        <button type="submit" className='btn btn-default' style={{
                            width:'100%'
                        }}>Cari</button>
                    </div>
                </div>
            </form>
            {/* files */}
            {
                loading ? <Loading /> :
                files !== null && files.length > 0 ? (
                    <>
                        <Row>
                        {
                            files.map((file, index) => (
                                <Col key={index} xs={6} sm={6} md={4} lg={3}>
                                    <div onClick={() => {
                                        //open link in new tab
                                        window.open(file.path);
                                    }} className="box p-4 text-center mb-4" style={{
                                        cursor: 'pointer',
                                    }}>
                                        <FontAwesomeIcon icon={faFileAlt} style={{
                                            fontSize: '40pt',
                                        }}/>

                                        <div className="mt-3">
                                            {file.title}
                                            <div className='def-link'>
                                                { file.category_name }
                                            </div>
                                            <div className='text-muted'>
                                                { moment(file.created_at).format('DD MMMM YYYY') }
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            ))
                        }
                        </Row>
                        <div className='text-center'>
                            <button disabled={loadingMore} hidden={!isContainMore} onClick={() => loadMore()} className='btn btn-default'>
                                { loadingMore ? <Loading /> : 'Muat Lebih Banyak' }
                            </button>
                        </div>
                    </>
                ) : (
                    <div className="text-center p-4">
                        <h4>Hasil Pencarian Disini</h4>
                        <img src="/img/data_not_found.svg" />
                    </div>
                )
            }
            {/* files */}
        </div>
    );
}

export default DownloadSearch;