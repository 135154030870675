import React,{ useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import UserDetail from "./UserDetail";
import { showUserDetail } from './../../redux/slicers/userSlice';

const UserDetailModal = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const [show, setShow] = useState(false);

    useEffect(() => {
        if(user.selectedUserId !== null){  
            setShow(true);
        }else{
            setShow(false);
        }
    }, [user]);

    const handleClose = () => {
        dispatch(showUserDetail(null));
    };

    return (
        <Modal backdrop={false} size="lg" style={{
            zIndex: '1040',
        }} show={show} onHide={handleClose} className="hard-shadow" onEscapeKeyDown={handleClose}>
            <Modal.Body style={{
                padding: '0px',
                overflow: 'hidden',
                borderRadius: '10px',
            }}>
                <UserDetail />

                <div className="p-3">
                    <Button variant="danger" className="btn-default" onClick={handleClose} style={{
                        width: '100%',
                    }}>Tutup</Button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default UserDetailModal;