import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faArrowAltCircleRight} from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Slider, { slickNext } from "react-slick";
import {
    get
} from './../services/banner';
import Loading from "./Loading";

const Banner = () => {
    const [banners, setBanners] = useState([]);
    const [current, setCurrent] = useState(0);
    const [loading, setLoading] = useState(false);
    const windowWidth = window.innerWidth;

    useEffect(() => {
        async function getBanners(){
            setLoading(true);

            const res = await get();
            if(res.error === null){
                setBanners(res.data);
            }else{
                console.log(res);
            }

            setLoading(false);
        }

        getBanners();
    }, []);

    return(
        <div className="banner-header-t">
            {
                loading ? <Loading /> : (
                    <div style={{
                        background: 'url(/img/frame.png) no-repeat center right fixed, #1d1d1d',
                        paddingTop: windowWidth < 767 ? '10px' : '90px',
                        paddingBottom:  windowWidth < 767 ? '20px' : '110px'
                    }}>
                        <Slider dots={true} infinite={true} arrows={false} centerMode={windowWidth > 767} ref={c => (setCurrent(c))} speed={500} slidesToScroll={1} slidesToShow={1} autoplaySpeed={10000} autoplay={true} style={{
                            backgroundColor: 'transparent',
                        }}>
                            {
                                banners.map((banner, index) => (
                                    <div key={index}>
                                        <div className="d-none d-md-block d-xl-block d-xxl-block">
                                            <div className="row p-5 align-items-center" style={{
                                            }}>
                                                <div className="col-md-6 col-lg-6 col-xl-4 offset-xl-1 col-xxl-3 offset-xxl-2">
                                                    <img src="/img/dots.png" style={{
                                                        position: 'absolute',
                                                        width: '100px',
                                                        marginLeft: '224px',
                                                        marginTop: '-50px',
                                                        zIndex: '-1'
                                                    }}/>
                                                    <div className="box p-1">
                                                        <img src={ banner.cover } alt="" className="img-fluid" style={{
                                                            width: '100%'
                                                        }}/>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-6 col-xl-7 col-xxl-7">
                                                    <div>
                                                        <div style={{
                                                            padding: '50px',
                                                            zIndex: '2'
                                                        }}>
                                                            <div style={{
                                                                fontSize: '36pt',
                                                                fontWeight: 'bold',
                                                                marginBottom: '10px',
                                                                color: '#fff',
                                                                fontFamily: 'Noto Serif JP'
                                                            }}>
                                                                { banner.title }
                                                            </div>
                                                            <div style={{
                                                                fontSize: '14pt',
                                                                fontWeight: 'light',
                                                                color: '#e6e6e6'
                                                            }}>
                                                                { banner.description }
                                                            </div>
                                                            <div className="mt-3">
                                                                <a href={banner.link} className="def-link">Lebih Lanjut <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginLeft: '5px'
                                                                }}>
                                                                <circle cx="15.5" cy="15.5" r="15" stroke="#C7B37F"/>
                                                                <path fillRule="evenodd" clipRule="evenodd" d="M17.7574 20.8038L22.5303 16.0308C22.8232 15.7379 22.8232 15.263 22.5303 14.9701L17.7574 10.1972C17.4645 9.90427 16.9896 9.90427 16.6967 10.1972C16.4038 10.4901 16.4038 10.9649 16.6967 11.2578L20.1893 14.7505H9C8.58579 14.7505 8.25 15.0863 8.25 15.5005C8.25 15.9147 8.58579 16.2505 9 16.2505H20.1893L16.6967 19.7431C16.4038 20.036 16.4038 20.5109 16.6967 20.8038C16.9896 21.0967 17.4645 21.0967 17.7574 20.8038Z" fill="#C7B37F"/>
                                                                </svg>
                                                                </a> 
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-6 col-xl-4 offset-xl-1 col-xxl-3 offset-xxl-2" style={{
                                                                display: 'flex',
                                                                marginTop: '20px',
                                                            }}>
                                                    <div className="circle-btn next-btn me-3" onClick={() => current.slickPrev() }>
                                                        <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M5.24264 1.19721L0.46967 5.97018C0.176776 6.26307 0.176776 6.73795 0.46967 7.03084L5.24264 11.8038C5.53553 12.0967 6.01041 12.0967 6.3033 11.8038C6.59619 11.5109 6.59619 11.036 6.3033 10.7431L2.81066 7.25051L14 7.25051C14.4142 7.25051 14.75 6.91472 14.75 6.50051C14.75 6.0863 14.4142 5.75051 14 5.75051L2.81066 5.75051L6.3033 2.25787C6.59619 1.96498 6.59619 1.4901 6.3033 1.19721C6.01041 0.904315 5.53553 0.904315 5.24264 1.19721Z" fill="#C7B37F"/>
                                                        </svg>
                                                    </div>
                                                    <div className="circle-btn next-btn" onClick={() => current.slickNext() }>
                                                        <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M9.75736 11.8038L14.5303 7.0308C14.8232 6.7379 14.8232 6.26303 14.5303 5.97014L9.75736 1.19717C9.46447 0.904272 8.98959 0.904272 8.6967 1.19717C8.40381 1.49006 8.40381 1.96493 8.6967 2.25783L12.1893 5.75047H1C0.585786 5.75047 0.25 6.08625 0.25 6.50047C0.25 6.91468 0.585786 7.25047 1 7.25047H12.1893L8.6967 10.7431C8.40381 11.036 8.40381 11.5109 8.6967 11.8038C8.98959 12.0967 9.46447 12.0967 9.75736 11.8038Z" fill="#C7B37F"/>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-block d-md-none d-xl-none d-xxl-none mt-1 mb-2" style={{
                                            marginLeft: '25px',
                                            marginRight: '25px',
                                        }}>
                                            <div className="p-1 mt-3">
                                                <img src={ banner.cover } alt="" className="img-fluid mt-3 mb-2" style={{
                                                    width: '100%'
                                                }}/>
                                            </div>
                                            <div style={{
                                                fontSize: '24pt',
                                                fontWeight: 'bold',
                                                marginTop: '10px',
                                                marginBottom: '20px',
                                                color: '#e6e6e6',
                                                fontFamily: 'Noto Serif JP'
                                            }}>
                                                { banner.title }
                                            </div>
                                            <div style={{
                                                fontSize: '13pt',
                                                fontWeight: 'light',
                                                color: '#e6e6e6'
                                            }}>
                                                { banner.description }
                                            </div>
                                            <div className="mt-3">
                                                <a href={banner.link} className="def-link">Lebih Lanjut <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginLeft: '5px'
                                                                }}>
                                                                <circle cx="15.5" cy="15.5" r="15" stroke="#C7B37F"/>
                                                                <path fillRule="evenodd" clipRule="evenodd" d="M17.7574 20.8038L22.5303 16.0308C22.8232 15.7379 22.8232 15.263 22.5303 14.9701L17.7574 10.1972C17.4645 9.90427 16.9896 9.90427 16.6967 10.1972C16.4038 10.4901 16.4038 10.9649 16.6967 11.2578L20.1893 14.7505H9C8.58579 14.7505 8.25 15.0863 8.25 15.5005C8.25 15.9147 8.58579 16.2505 9 16.2505H20.1893L16.6967 19.7431C16.4038 20.036 16.4038 20.5109 16.6967 20.8038C16.9896 21.0967 17.4645 21.0967 17.7574 20.8038Z" fill="#C7B37F"/>
                                                                </svg></a> 
                                            </div>
                                            <div style={{
                                                display: 'flex',
                                                marginTop: '30px',
                                                marginBottom: '30px',
                                            }}>
                                                <div className="circle-btn next-btn me-3" onClick={() => current.slickPrev() }>
                                                    <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M5.24264 1.19721L0.46967 5.97018C0.176776 6.26307 0.176776 6.73795 0.46967 7.03084L5.24264 11.8038C5.53553 12.0967 6.01041 12.0967 6.3033 11.8038C6.59619 11.5109 6.59619 11.036 6.3033 10.7431L2.81066 7.25051L14 7.25051C14.4142 7.25051 14.75 6.91472 14.75 6.50051C14.75 6.0863 14.4142 5.75051 14 5.75051L2.81066 5.75051L6.3033 2.25787C6.59619 1.96498 6.59619 1.4901 6.3033 1.19721C6.01041 0.904315 5.53553 0.904315 5.24264 1.19721Z" fill="#C7B37F"/>
                                                    </svg>
                                                </div>
                                                <div className="circle-btn next-btn" onClick={() => current.slickNext() }>
                                                    <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M9.75736 11.8038L14.5303 7.0308C14.8232 6.7379 14.8232 6.26303 14.5303 5.97014L9.75736 1.19717C9.46447 0.904272 8.98959 0.904272 8.6967 1.19717C8.40381 1.49006 8.40381 1.96493 8.6967 2.25783L12.1893 5.75047H1C0.585786 5.75047 0.25 6.08625 0.25 6.50047C0.25 6.91468 0.585786 7.25047 1 7.25047H12.1893L8.6967 10.7431C8.40381 11.036 8.40381 11.5109 8.6967 11.8038C8.98959 12.0967 9.46447 12.0967 9.75736 11.8038Z" fill="#C7B37F"/>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </Slider>
                    </div>
                )
            }
        </div>
    );
}

export default Banner;