import React, { useEffect, useState } from "react";
import {
    Offcanvas, Row, Col
} from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";
import { showPortfolioDetail } from './../../redux/slicers/portfolioSlice';
import { read } from './../../services/portfolio';
import swal from 'sweetalert';
import UserBox from './../users/UserBox';

const PortfolioDetail = () => {
    const dispatch = useDispatch();
    const portfolio = useSelector((state) => state.portfolio);
    const [show, setShow] = useState(false);
    const [portfolioDetail, setPortfolioDetail] = useState(null);

    useEffect(() => {
        if(portfolio.selectedPortfolioId !== null){
            async function getDetail(id){
                const res = await read(id);
                if(res.error === null){
                    setPortfolioDetail(res.data);
                }else{
                    swal("Error!", res.error.message, "error");
                }
            }

            setShow(true);

            getDetail(portfolio.selectedPortfolioId);
        }else{
            setShow(false);
        }
    }, [portfolio]);

    const handleClose = () => {
        dispatch(showPortfolioDetail(null));
    };

    return(
        <Offcanvas className="portfolio_detail_canvas" show={show} onHide={handleClose} placement="end">
            <Offcanvas.Header closeButton>
            <Offcanvas.Title>
                {portfolioDetail !== null ? portfolioDetail.title : ''}
            </Offcanvas.Title>
            </Offcanvas.Header>
                <Offcanvas.Body>
                    {portfolioDetail !== null ? (
                        <div className="pb-5 porto-body">
                            <img src={ portfolioDetail.cover } alt={ portfolioDetail.title } className="img-fluid mb-3" style={{
                                width: '100%'
                            }}/>
                            { <div dangerouslySetInnerHTML={{ __html: portfolioDetail.body }} /> }
                            <Row>
                                <Col xl={3} lg={6} md={4} sm={6} xs={6} className="mt-3">
                                    <div>
                                        {
                                            portfolioDetail.user !== null ? (
                                                <UserBox user={portfolioDetail.user}/>
                                            ) : null
                                        }
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={4} className="mt-3">
                                    <b>Website</b>
                                    <div>
                                        { portfolioDetail.link }
                                    </div>
                                </Col>
                                <Col lg={4} className="mt-3">
                                    <b>Instagram</b>
                                    <div>
                                        { portfolioDetail.instagram }
                                    </div>
                                </Col>
                                <Col lg={4} className="mt-3">
                                    <b>Facebook</b>
                                    <div>
                                        { portfolioDetail.facebook }
                                    </div>
                                </Col>
                                <Col lg={12} className="mt-3">
                                    <b>Client</b>
                                    <div>
                                        { portfolioDetail.client }
                                    </div>
                                </Col>  
                            </Row>
                        </div>
                    ) : null}
                </Offcanvas.Body>
        </Offcanvas>
    );
}

export default PortfolioDetail;