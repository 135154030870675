import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
    eventOrder
} from './../../services/transaction';
import {
    uploadPayment
} from './../../services/event';
import swal from 'sweetalert';
import { Col, Row, Nav, Button} from "react-bootstrap";
import { intToMoney } from "../../helpers/currency";
import moment from "moment";
import { renderStatus } from "../../helpers/event_order";
import {useDropzone} from 'react-dropzone';
import BankList from "../bank/BankList";
import Loading from './../Loading';
import EventDetail from "../events/EventDetail";

const EventOrderDetail = (props) => {
    const auth = useSelector((state) => state.auth);

    const id = props.id;

    const [order, setOrder] = useState(null);
    const [image, setImage] = useState("");
    const [uploadedImage, setUploadedImage] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function getDetail(id){
            setLoading(true);

            const res = await eventOrder(auth.token, id);
            if(res.error === null){
                setOrder(res.data);

                if(res.data.payment_proof_path !== null){
                    setUploadedImage(res.data.payment_proof_path);
                }
            }else{
                swal("Error!", res.error, "error");
            }

            setLoading(false);
        }

        getDetail(id);
    }, [auth]);

    const {acceptedFiles, fileRejections, getRootProps, getInputProps} = useDropzone({
        maxFiles:1,
        accept: 'image/jpeg, image/png',
        onDrop: files => {
            fileToBase64(files);
        }
    });
  
    const files = acceptedFiles.map(file => (
        <div key={file.path}>
            <img src={image} alt={file.name} className="img-thumbnail" style={{
                width: '100px',
            }}/>
        </div>
    ));

    const fileRejectionItems = fileRejections.map(({ file, errors  }) => { 
        return (
          <li key={file.path}>
               {file.path} - {(file.size/ 1000)} Kb
               <ul>
                 {errors.map(e => <li key={e.code}>{e.message}</li>)}
              </ul>
          </li>
        ) 
    });

    const fileToBase64 = (selectedFile) => {
        let file = null;
        //Check File is not Empty
        if (selectedFile.length > 0) {
            // Select the very first file from list
            let fileToLoad = selectedFile[0];
            // FileReader function for read the file.
            let fileReader = new FileReader();
            // Onload of file read the file content
            fileReader.onload = function(fileLoadedEvent) {
                file = fileLoadedEvent.target.result;
                // Print data in console
                setImage(file);
            };
            // Convert data to base64
            fileReader.readAsDataURL(fileToLoad);
        }
    }

    const handleUploadPayment = async () => {
        //confirm
        swal({
            title: "Apakah anda yakin?",
            text: "Unggah bukti pembayaran ini?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then(async (willUpload) => {
            if(willUpload){
                const res = await uploadPayment(auth.token, id, image);
                if(res.error === null){
                    swal('Berhasil', 'Bukti pembayaran berhasil diunggah', 'success');
                    
                    window.location.reload();
                }else{
                    swal('Error', res.error, 'error');
                }
            }
        });
    }
    
    return(
        <div>
            {
                loading ? <Loading /> :
                order !== null ? (
                    <>
                        <div className="box p-3 mb-3">
                            <Row>
                                <Col sm={12} md={8} lg={8}>
                                    <Row>
                                        <Col xs={6}>Status</Col>
                                        <Col xs={6} className="text-end">{ renderStatus(order.status_value, order.status) }</Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col xs={6}>No. Invoice</Col>
                                        <Col xs={6} className="text-end">#{ order.jurnal_transaction_no }</Col>
                                    </Row>
                                    <Row>
                                        <Col xs={6}>Tanggal Pembelian</Col>
                                        <Col xs={6} className="text-end">{ moment(order.created_at).format("HH:mm, DD-MMMM-YYYY") }</Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col sm={12}>
                                            <strong>Rincian Pembayaran:</strong>
                                            <Row>
                                                <Col>
                                                    Metode Pembayaran
                                                </Col>
                                                <Col className="text-end">
                                                    { order.payment_method }
                                                </Col>
                                            </Row>
                                            <Row style={{
                                                fontWeight: 'bold',
                                            }}>
                                                <Col>
                                                    Total Harga
                                                </Col>
                                                <Col className="text-end">
                                                    { intToMoney(order.total_cost) }
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    {
                                        order.payment_method === 'bank transfer' ? (
                                            <div className="mt-5">
                                                <BankList />
                                                <label><b>Unggah Bukti Transfer:</b></label>
                                                <div hidden={ uploadedImage === null || uploadedImage === "" }>
                                                    <div className="text-center">
                                                    <img src={ uploadedImage } alt="bukti transfer" style={{ width: '200px' }} />
                                                    </div>
                                                    <div hidden={order.status_value != 1} className="alert alert-warning mt-2">
                                                        Masukan foto apabila ingin mengubah Bukti Transfer
                                                    </div>
                                                </div>
                                                {
                                                    order.status_value == 1 ? (
                                                        <>
                                                            <div {...getRootProps({className: 'dropzone'})}>
                                                                <input {...getInputProps()} />
                                                                <p className="text-center">
                                                                    Drag 'n' drop some files here<br/>or click to select files
                                                                    <em>(Only *.jpeg and *.png images will be accepted)</em>
                                                                </p>
                                                            </div>
                                                            <aside className="mt-2">
                                                                <b>Selected Image:</b>
                                                                {files}
                                                                {
                                                                    fileRejectionItems.length > 0 ? (
                                                                        <>
                                                                            <b>Rejected files</b>
                                                                            <ul>{fileRejectionItems}</ul>
                                                                        </>
                                                                    ) : null
                                                                }
                                                            </aside>
                                                            {
                                                                files.length > 0 && fileRejectionItems.length === 0 ? (
                                                                    <div className="mt-2">
                                                                        <Button onClick={handleUploadPayment} variant="danger" style={{
                                                                            width: '100%',
                                                                        }}>Unggah</Button>
                                                                    </div>
                                                                ) : null
                                                            }
                                                        </>
                                                    ) : null
                                                }
                                            </div>
                                        ) : null
                                    }
                                </Col>
                                <Col sm={12} md={4} lg={4} className="text-end">
                                    {
                                        order.status_value == 1 ? (
                                            <>
                                            <div className="alert alert-warning text-center">
                                                Harap selesaikan pembayaran sebelum
                                                <div style={{
                                                    fontWeight: 'bold',
                                                }}>
                                                    { moment(order.payment_expired).format("HH:mm, DD-MMMM-YYYY") }
                                                </div>
                                            </div>
                                            <a href={order.jurnal_invoice_url} target="_blank" className="btn btn-sm btn-success" style={{
                                                width: '100%',
                                            }}>Bayar</a>
                                            <button hidden={true} onClick={() => window.location.reload() } className="btn btn-sm btn-danger mt-3" style={{
                                                width: '100%',
                                            }}>Saya Sudah Melakukan Pembayaran</button>
                                            </>
                                        ) : (
                                            <a href={order.jurnal_invoice_url} target="_blank" className="btn btn-sm btn-danger" style={{
                                                width: '100%',
                                            }}>Lihat Invoice</a>
                                        )
                                    }
                                </Col>
                            </Row>
                        </div>

                        <EventDetail url={order.event.url}/>
                    </>
                ) : null
            }
        </div>
    );
}

export default EventOrderDetail;