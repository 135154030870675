export const search = async (token, keyword, page) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}product/search`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            keyword: keyword,
            page: page
        })
    });
    const json = await response.json();
    return json;
}

export const read = async (token, id) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}product/read`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            id: id
        })
    });
    const json = await response.json();
    return json;
}

export const getByIds = async (token, ids) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}product/get_by_ids`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            ids: ids
        })
    });
    const json = await response.json();
    return json;
}

export const order = async (
    token, 
    products, 
    shipping_address_id, 
    payment_method,
    shipping_cost,
    shipping_courier,
    shipping_service,
    shipping_etd
    ) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}product/order`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            products: products,
            shipping_address_id: shipping_address_id,
            payment_method: payment_method,
            shipping_cost: shipping_cost,
            shipping_courier: shipping_courier,
            shipping_service: shipping_service,
            shipping_etd: shipping_etd
        })
    });
    const json = await response.json();
    return json;
}

export const cancel = async (token, product_order_id) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}product/cancel`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            product_order_id: product_order_id
        })
    });
    const json = await response.json();
    return json;
}

export const getShippingCost = async (token, products, shipping_address_id) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}product/get_shipping_cost`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            products: products,
            shipping_address_id: shipping_address_id
        })
    });
    const json = await response.json();
    return json;
}