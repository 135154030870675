import './App.css';
import {
    Routes,
    Route
} from "react-router-dom";
import { useLocation } from 'react-router';

//components
import PortfolioDetail from './components/portfolios/PortfolioDetail';

import HomeScreen from './screens/HomeScreen';
import LoginScreen from './screens/LoginScreen';
import RegisterScreen from './screens/RegisterScreen';
import DashboardScreen from './screens/DashboardScreen';
import PointHistoryScreen from './screens/PointHistoryScreen';
import ProfileScreen from './screens/ProfileScreen';
import EventDetailScreen from './screens/EventDetailScreen';
import EventTransactionScreen from './screens/EventTransactionScreen';
import EventTransactionDetailScreen from './screens/EventTransactionDetailScreen';
import ProductTransactionScreen from './screens/ProductTransactionScreen';
import EventListScreen from './screens/EventListScreen';
import ProductListScreen from './screens/ProductListScreen';
import ProductDetailScreen from './screens/ProductDetailScreen';
import EditProfileScreen from './screens/EditProfileScreen';
import NewsListScreen from './screens/NewsListScreen';
import UserListScreen from './screens/UserListScreen';
import MembershipScreen from './screens/MembershipScreen';
import MembershipOrderScreen from './screens/MembershipOrderScreen';
import ScanScreen from './screens/ScanScreen';
import UserDetailModal from './components/users/UserDetailModal';
import NotificationScreen from './screens/NotificationScreen';
import CartScreen from './screens/CartScreen';
import CheckoutScreen from './screens/CheckoutScreen';
import ShippingAddressScreen from './screens/ShippingAddressScreen';
import DownloadScreen from './screens/DownloadScreen';
import Layout from './components/Layout';

//redux
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from 'react';
import {
    logout
} from './redux/slicers/authSlice';
import {
    setAccount
} from './redux/slicers/accountSlice';
import {
    setTotalUnreadNotifications
} from './redux/slicers/notificationSlice';
import {
    setData
} from './redux/slicers/informationSlice';

//services
import {
    profile
} from './services/auth';
import {
    totalNotification
} from './services/users';
import ForgotPasswordScreen from './screens/ForgotPasswordScreen';
import MembershipCheckoutScreen from './screens/MembershipCheckoutScreen';
import EventCheckoutScreen from './screens/EventCheckoutScreen';
import ProductTransactionDetailScreen from './screens/ProductTransactionDetailScreen';
import UserLicenseScreen from './screens/UserLicenseScreen';
import UserLicenseOrderScreen from './screens/UserLicenseOrderScreen';
import CertificatesScreen from './screens/CertificatesScreen';
import SecurityScreen from './screens/SecurityScreen';
import VoteScreen from './screens/VoteScreen';
import HonorariumArsitekScreen from './screens/static/HonorariumArsitekScreen';
import GalleryScreen from './screens/GalleryScreen';
import ResetPasswordScreen from './screens/ResetPasswordScreen';
import { get } from './services/information';
import ValidationScreen from './screens/ValidationScreen';
import AttendLinkScreen from './screens/AttendLinkScreen';
import NewsReadScreen from './screens/NewsReadScreen';
import CertificateCheckScreen from './screens/CertificateCheckScreen';
import swal from 'sweetalert';
import FAQScreen from './screens/FAQScreen';
import PrivateRoute from './components/PrivateRoute';
import UnauthorizedRoute from './components/UnauthorizedRoute';
import RequestPointScreen from './screens/RequestPointScreen';
import FileSourceScreen from './screens/FIleSourceScreen';
import EventsUpcomingScreen from './screens/EventsUpcomingScreen';
import EventsPastScreen from './screens/EventsPastScreen';
import MyEventsScreen from './screens/MyEventsScreen';
import EventDetailDashboardScreen from './screens/EventDetailDashboardScreen';
import UpgradeMembershipScreen from './screens/UpgradeMembershipScreen';
import UserLicenseAddEditScreen from './screens/UserLicenseAddEditScreen';
import PortfolioScreen from './screens/PortfolioScreen';
import EducationsScreen from './screens/EducationsScreen';
import EmploymentsScreen from './screens/EmploymentsScreen';
import SKAScreen from './screens/SKAScreen';
import UserLicenseRevisionScreen from './screens/UserLicenseRevisionScreen';
import ContentPageScreen from './screens/ContentPageScreen';
import OrganizationScreen from './screens/OrganizationScreen';
import SearchScreen from './screens/SearchScreen';
import NotFoundScreen from './screens/NotFoundScreen';
import InternInformationScreen from './screens/InternInformationScreen';

//firebase
import { firebaseInit } from './helpers/firebase';
import { getMessaging, onMessage, getToken } from "firebase/messaging";
import { updateFCMToken } from './services/account';

//analytics
import ReactGA from 'react-ga';
import UpgradeStudentScreen from './screens/UpgradeStudentScreen';
import UpgradeDirectMemberScreen from './screens/UpgradeDirectMemberScreen';
import DPTCheckScreen from './screens/DPTCheckScreen';

//init firebase
firebaseInit();

function App() {
    const dispatch = useDispatch();
    let location = useLocation();
    const auth = useSelector((state) => state.auth);
    const [fcmToken, setFcmToken] = useState(null);

    //track google analytics
    useEffect(() => {
        //track
        ReactGA.initialize("UA-90548118-1");
        ReactGA.pageview(location.pathname + location.search);
    }, [location]);

    //listen to notification push from firebase
    const messaging = getMessaging();
    // Get registration token. Initially this makes a network call, once retrieved
    // subsequent calls to getToken will return from cache.
    getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY }).then((currentToken) => {
        if (currentToken) {
            // Send the token to your server and update the UI if necessary
            // ...
            setFcmToken(currentToken);
        } else {
            // Show permission request UI
            console.log('No registration token available. Request permission to generate one.');
            // ...
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // ...
    });

    const showNotificationToast = (title, text, link) => {
        swal({
            title: title,
            text: text,
            icon: "info",
            button: "OK",
        }).then(() => {
            if (link) {
                window.location.href = link;
            }
        });
    }

    onMessage(messaging, (payload) => {
        console.log('Message received. ', payload);
        showNotificationToast(payload.notification.title, payload.notification.body, payload.data.link);
    });

    useEffect(() => {
        //check auth
        if (auth.token !== null) {
            profile(auth.token).then(res => {
                if (!res.is_authenticated) {
                    //remove token
                    dispatch(logout());
                } else {
                    //check if expired
                    if (res.data.is_expired) {
                        //show alert error
                        swal({
                            title: "Masa Keanggotaan Telah Expired",
                            text: "Masa keanggotaan anda telah habis, silahkan perpanjang keanggotaan anda",
                            icon: "warning",
                            button: "OK",
                        });
                    }

                    dispatch(setAccount(res.data));

                    //get total notification
                    totalNotification(auth.token).then(res => {
                        dispatch(setTotalUnreadNotifications(res.data));
                    });
                }
            });

        }
    }, [auth, dispatch]);

    useEffect(() => {
        if (fcmToken !== null) {
            updateFCMToken(auth.token, fcmToken).then(res => { }).catch(err => { });
        }
    }, [fcmToken]);

    useEffect(() => {
        async function getInformation() {
            const res = await get();
            if (res.error === null) {
                dispatch(setData({
                    main_logo: res.data.main_logo,
                    white_logo: res.data.white_logo,
                    faq_categories: res.data.faq_categories,
                    highlight_text: res.data.highlight_text,
                    highlight_active: res.data.highlight_active,
                    menus: res.data.menus
                }));
            }
        }

        getInformation();
    }, [dispatch]);

    return (
        <div className="App">
            <Routes>
                {/* Routes */}
                <Route path="/" element={<Layout><HomeScreen /></Layout>} />
                <Route path="/users" element={<Layout><UserListScreen /></Layout>} />
                <Route path="/events" element={<Layout><EventListScreen /></Layout>} />
                <Route path="/event/:url" element={<Layout><EventDetailScreen /></Layout>} />
                <Route path="/event-checkout/:url" element={<Layout><EventCheckoutScreen /></Layout>} />

                <Route path="/events/upcoming" element={<Layout userNavbar={true}><PrivateRoute><EventsUpcomingScreen /></PrivateRoute></Layout>} />
                <Route path="/events/mylist" element={<Layout userNavbar={true}><PrivateRoute><MyEventsScreen /></PrivateRoute></Layout>} />
                <Route path="/events/past" element={<Layout userNavbar={true}><PrivateRoute><EventsPastScreen /></PrivateRoute></Layout>} />
                <Route path="/event-detail/:url" element={<Layout userNavbar={true}><PrivateRoute><EventDetailDashboardScreen /></PrivateRoute></Layout>} />

                <Route path="/upgrade-membership" element={<Layout userNavbar={true}><PrivateRoute><UpgradeMembershipScreen /></PrivateRoute></Layout>} />
                <Route path="/upgrade-student" element={<Layout userNavbar={true}><PrivateRoute><UpgradeStudentScreen /></PrivateRoute></Layout>} />
                <Route path="/upgrade-direct-member" element={<Layout userNavbar={true}><PrivateRoute><UpgradeDirectMemberScreen /></PrivateRoute></Layout>} />

                <Route path="/news-list" element={<Layout><NewsListScreen /></Layout>} />
                <Route path="/news/:url" element={<Layout><NewsReadScreen /></Layout>} />
                <Route path="/certificate" element={<Layout><CertificateCheckScreen /></Layout>} />
                <Route path="/certificate/:token" element={<Layout><CertificateCheckScreen /></Layout>} />
                <Route path="/products" element={<Layout><ProductListScreen /></Layout>} />
                <Route path="/product/:id" element={<Layout><ProductDetailScreen /></Layout>} />
                <Route path="/faq/:slug" element={<Layout><FAQScreen /></Layout>} />
                <Route path="/downloads" element={<Layout><PrivateRoute><DownloadScreen /></PrivateRoute></Layout>} />
                <Route path="/downloads/:id" element={<Layout><PrivateRoute><DownloadScreen /></PrivateRoute></Layout>} />
                <Route path="/gallery" element={<Layout><PrivateRoute><GalleryScreen /></PrivateRoute></Layout>} />
                <Route path="/gallery/:id" element={<Layout><PrivateRoute><GalleryScreen /></PrivateRoute></Layout>} />
                <Route path="/forgot-password" element={<UnauthorizedRoute><ForgotPasswordScreen /></UnauthorizedRoute>} />
                <Route path="/reset-password/:token" element={<UnauthorizedRoute><ResetPasswordScreen /></UnauthorizedRoute>} />
                <Route path="/activate/:token" element={<UnauthorizedRoute><ValidationScreen /></UnauthorizedRoute>} />
                <Route path="/login" element={<UnauthorizedRoute><LoginScreen /></UnauthorizedRoute>} />
                <Route path="/register" element={<UnauthorizedRoute><RegisterScreen /></UnauthorizedRoute>} />
                <Route path="/dashboard" element={<Layout userNavbar={true}><PrivateRoute><DashboardScreen /></PrivateRoute></Layout>} />
                <Route path="/point-history" element={<Layout userNavbar={true}><PrivateRoute><PointHistoryScreen /></PrivateRoute></Layout>} />
                <Route path="/request-point" element={<Layout userNavbar={true}><PrivateRoute><RequestPointScreen /></PrivateRoute></Layout>} />

                <Route path="/educations" element={<Layout userNavbar={true}><PrivateRoute><EducationsScreen /></PrivateRoute></Layout>} />
                <Route path="/employments" element={<Layout userNavbar={true}><PrivateRoute><EmploymentsScreen /></PrivateRoute></Layout>} />
                <Route path="/ska" element={<Layout userNavbar={true}><PrivateRoute><SKAScreen /></PrivateRoute></Layout>} />
                <Route path="/portfolios" element={<Layout userNavbar={true}><PrivateRoute><PortfolioScreen /></PrivateRoute></Layout>} />
                <Route path="/profile" element={<Layout userNavbar={true}><PrivateRoute><ProfileScreen /></PrivateRoute></Layout>} />

                <Route path="/event-transaction" element={<Layout userNavbar={true}><PrivateRoute><EventTransactionScreen /></PrivateRoute></Layout>} />
                <Route path="/event-transaction/order/:id" element={<Layout userNavbar={true}><PrivateRoute><EventTransactionDetailScreen /></PrivateRoute></Layout>} />
                <Route path="/product-transaction" element={<Layout userNavbar={true}><PrivateRoute><ProductTransactionScreen /></PrivateRoute></Layout>} />
                <Route path="/product-transaction/order/:id" element={<Layout userNavbar={true}><PrivateRoute><ProductTransactionDetailScreen /></PrivateRoute></Layout>} />
                <Route path="/edit-profile" element={<Layout userNavbar={true}><PrivateRoute><EditProfileScreen /></PrivateRoute></Layout>} />
                <Route path="/security" element={<Layout userNavbar={true}><PrivateRoute><SecurityScreen /></PrivateRoute></Layout>} />

                <Route path="/membership" element={<Layout userNavbar={true}><PrivateRoute><MembershipScreen /></PrivateRoute></Layout>} />
                <Route path="/membership/order/:id" element={<Layout userNavbar={true}><PrivateRoute><MembershipOrderScreen /></PrivateRoute></Layout>} />

                <Route path="/shipping-address" element={<Layout userNavbar={true}><PrivateRoute><ShippingAddressScreen /></PrivateRoute></Layout>} />
                <Route path="/scan" element={<Layout><PrivateRoute><ScanScreen /></PrivateRoute></Layout>} />
                <Route path="/certificates" element={<Layout userNavbar={true}><PrivateRoute><CertificatesScreen /></PrivateRoute></Layout>} />
                <Route path="/file-sources" element={<Layout userNavbar={true}><PrivateRoute><FileSourceScreen /></PrivateRoute></Layout>} />
                <Route path="/checkout" element={<Layout><PrivateRoute><CheckoutScreen /></PrivateRoute></Layout>} />
                <Route path="/internship" element={<Layout userNavbar={true}><PrivateRoute><InternInformationScreen /></PrivateRoute></Layout>} />

                <Route path="/user-licenses" element={<Layout userNavbar={true}><PrivateRoute><UserLicenseScreen /></PrivateRoute></Layout>} />
                <Route path="/manage-user-license" element={<Layout userNavbar={true}><PrivateRoute><UserLicenseAddEditScreen /></PrivateRoute></Layout>} />
                <Route path="/user-licenses/revision/:id" element={<Layout userNavbar={true}><PrivateRoute><UserLicenseRevisionScreen /></PrivateRoute></Layout>} />
                <Route path="/user-licenses/order/:id" element={<Layout userNavbar={true}><PrivateRoute><UserLicenseOrderScreen /></PrivateRoute></Layout>} />

                <Route path="/membership-checkout/:id" element={<Layout><PrivateRoute><MembershipCheckoutScreen /></PrivateRoute></Layout>} />
                <Route path="/vote/:id" element={<Layout><PrivateRoute><VoteScreen /></PrivateRoute></Layout>} />
                <Route path="/attend/:token" element={<Layout><PrivateRoute><AttendLinkScreen /></PrivateRoute></Layout>} />

                <Route path="/check-dpt" element={<Layout userNavbar={true}><PrivateRoute><DPTCheckScreen /></PrivateRoute></Layout>} />

                {/* static pages */}
                <Route path="/honorarium-arsitek" element={<Layout><HonorariumArsitekScreen /></Layout>} />

                <Route path="/page/:url" element={<Layout><ContentPageScreen /></Layout>} />
                <Route path="/organisasi/:name" element={<Layout><OrganizationScreen /></Layout>} />

                <Route path="/search" element={<Layout><SearchScreen /></Layout>} />
                <Route path="/search/:keyword" element={<Layout><SearchScreen /></Layout>} />

                <Route path='*' exact={true} element={<Layout><NotFoundScreen /></Layout>} />
            </Routes>

            {/* Global Components */}
            <PortfolioDetail />
            <UserDetailModal />
            <NotificationScreen />
            <CartScreen />
        </div>
    );
}

export default App;