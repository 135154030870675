import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import config from "../../helpers/config";
import EmploymentList from "../employments/EmploymentList";
import { profile } from "../../services/auth";
import { detail } from "../../services/account";
import Loading from "../Loading";
import EducationList from "../educations/EducationList";
import { apply, order, orderDetail, isAbleToBuy as isAbleToBuyApi, revise } from "../../services/membership";
import { history } from "../../services/membershipApplication";
import { renderStatus } from "../../helpers/membership";
import { renderStatus as renderOrderStatus } from "../../helpers/membershipOrder";
import moment from "moment";
import EditProfile from "../account/EditProfile";
import EditProfilePro from "../account/EditProfilePro";
import { Link } from "react-router-dom";

const UpgradeStudentForm = (props) => {
    const id = props.id;

    const auth = useSelector((state) => state.auth);
    const account = useSelector((state) => state.account);
    const navigate = useNavigate();
    
    const [histories, setHistories] = useState(null);
    const [historyLoading, setHistoryLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentMembershipType, setCurrentMembershipType] = useState(null);
    const [historiesRefreshTime, setHistoriesRefreshTime] = useState(null);
    const [isAbleToBuy, setIsAbleToBuy] = useState(false);
    const [isAbleToRevise, setIsAbleToRevise] = useState(false);
    const [lastApplicationId, setLastApplicationId] = useState(null);

    useEffect(() => {
        async function getProfile(){
            setLoading(true);

            const res = await profile(auth.token);
            if(res.error === null || res.error === ""){
                const detailData = await detail(res.data.id);
                if(detailData.error === null){
                    setCurrentMembershipType(res.data.membership.type);
                }
            }

            setLoading(false);
        }

        getProfile();
    }, [account.refresh_detail, auth.token]);

    useEffect(() => {
        async function getApplicationHistory(){
            setHistoryLoading(true);

            const membershipDestination = config.membershipType.STUDENT;

            const res = await history(auth.token, membershipDestination);
            if(res.error === null){
                setHistories(res.data);

                //get is able to revise
                if(res.data !== null){
                    if(res.data.length > 0){
                        const lastApplication = res.data[0];
                        setLastApplicationId(lastApplication.id);
                        if(lastApplication.is_able_to_revise){
                            setIsAbleToRevise(true);
                        }else{
                            setIsAbleToRevise(false);
                        }
                    }
                }
            }else{
                swal("Error!", res.error, "error");
            }

            setHistoryLoading(false);
        }

        async function getIsAbleToBuy(){
            setLoading(true);

            const membershipDestination = config.membershipType.STUDENT;

            const res = await isAbleToBuyApi(auth.token, membershipDestination);
            if(res.error === null){
                setIsAbleToBuy(true);
            }else{
                setIsAbleToBuy(false);
            }

            setLoading(false);
        }

        if(currentMembershipType !== null){
            getApplicationHistory();
            getIsAbleToBuy();
        }
    }, [auth.token, currentMembershipType, historiesRefreshTime]);

    const applyMembership = async (membershipType) => {
        var confirm = await swal({
            title: "Apakah Anda Yakin?",
            text: "kami akan melakukan review terlebih dahulu sebelum pengajuan anda disetujui.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        });

        if(confirm){
            setHistoryLoading(true);

            const res = await apply(auth.token, membershipType);
            if(res.error === null){
                swal("Success!", res.data, "success");
                setHistoriesRefreshTime(new Date().getTime());
            }else{
                swal("Error!", res.error, "error");
            }
    
            setHistoryLoading(false);
        }
    }

    const submitRevise = async (membership_application_id) => {
        var confirm = await swal({
            title: "Apakah Anda yakin?",
            text: "Pastikan data yang anda isi sudah sesuai.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        });

        if(confirm){
            setLoading(true);

            const res = await revise(auth.token, membership_application_id);
            if(res.error === null){
                swal("Success!", res.data, "success");
                setHistoriesRefreshTime(new Date().getTime());
            }else{
                swal("Error!", res.error, "error");
            }
    
            setLoading(false);
        }
    }

    return (
        <div>
            <h4>Upgrade Menjadi <b>Mahasiswa</b></h4>
            {
                historyLoading ? <Loading /> :
                histories === null ? null :
                histories.map((history, index) => (
                    <div key={index} className="box p-3 mt-3">
                        <div className="row">
                            <div className="col-md-6">
                                status pengajuan: { renderStatus(history.status) }
                            </div>
                            <div className="col-md-6 text-end">
                                { history.note !== null && history.note !== "" ? "catatan: "+history.note : null }
                            </div>
                        </div>
                        <div className="row mt-1">
                            <div className="col-md-12 text-end text-muted" style={{
                                fontSize: "10pt",
                            }}>
                                tanggal pengajuan: { moment(history.created_at).fromNow() }
                            </div>
                        </div>
                    </div>
                ))
            }
            <div className="row">
                <div className="col-md-12">
                    {
                        currentMembershipType === config.membershipType.PUBLIC ? (
                            <>
                                {
                                    loading ? <Loading /> : (
                                        <div hidden={!isAbleToBuy && !isAbleToRevise}>
                                            <EditProfile />

                                            <div className="mt-3">
                                                <EducationList/>
                                            </div>
                                        </div>
                                    )
                                }

                                <button hidden={!isAbleToBuy} onClick={() => applyMembership(config.membershipType.STUDENT)} className="btn btn-default mt-5" style={{
                                    width: "100%",
                                }}>Ajukan Menjadi Mahasiswa</button>

                                {/* check if able to revise */}
                                <button hidden={!isAbleToRevise} onClick={() => submitRevise(lastApplicationId)} className="btn btn-default mt-5" style={{
                                    width: "100%",
                                }}>Ajukan Revisi</button>
                            </>
                        )    

                        : null
                    }
                </div>
            </div>
        </div>
    );
}

export default UpgradeStudentForm;