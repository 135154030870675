import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
    intToMoney
} from '../../helpers/currency';
import {
    updateQuantity,
    removeFromCart
} from './../../redux/slicers/cartSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import swal from 'sweetalert';

const CartItem = (props) => {
    const isCheckout = props.isCheckout;
    const item = props.item;

    const dispatch = useDispatch();

    const image = item.images.length > 0 ? item.images[0] : '';
    const [qty, setQty] = useState(item.quantity);

    useEffect(() => {
        if(qty > 0){
            dispatch(updateQuantity({
                id : item.id,
                quantity : qty
            }));
        }
    }, [qty]);

    const remove = () => {
        //show confirm
        swal({
            title: "Are you sure?",
            text: "Delete "+item.name+" from cart?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then((willDelete) => {
            if(willDelete){
                dispatch(removeFromCart({
                    id : item.id
                }));
            }
        });
    }

    return (
        <div className="p-3">
            <div className="d-flex">
                <img src={image} alt={item.name} style={{width: '70px'}}/>
                <div className="ms-3" style={{
                    width: '100%',
                }}>
                    <div style={{
                        fontSize: '10pt',
                        fontWeight: 'bold'
                    }}>
                        {item.name}
                    </div>
                    <div style={{
                        fontSize: '10pt',
                    }}>
                        <Row>
                            <Col xs={6}>
                                <select hidden={isCheckout} style={{
                                    width: '40px',
                                }} value={qty} onChange={e => setQty(e.target.value)}>
                                    <option value="0">Pilih jumlah</option>
                                    {
                                        Array.from(Array(parseInt(item.stock)).keys()).map(i => (
                                            <option key={i+1}>{ i+1 }</option>
                                        ))
                                    }
                                </select>
                                <span hidden={!isCheckout}>{qty}</span>
                                &nbsp;x { 
                                item.price_default != item.total_cost ? (
                                    <>
                                        <span style={{
                                            textDecoration: 'line-through',
                                            marginRight: '5px'
                                        }}>{intToMoney(item.price_default)}</span>
                                        <span>{intToMoney(item.total_cost)}</span>
                                    </>
                                ) : (
                                    <>{intToMoney(item.total_cost)}</>
                                )
                                }
                            </Col>
                            <Col xs={6} className="text-end">
                                <b>{ intToMoney(item.quantity * item.total_cost) }</b>
                            </Col>
                        </Row>
                    </div>
                    <div hidden={isCheckout}>
                        <FontAwesomeIcon style={{
                            cursor: 'pointer',
                            color: 'red',
                            fontSize: '10pt',
                        }} icon={faTrashAlt} onClick={() => remove() }/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CartItem;