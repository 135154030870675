import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import EditProfile from "../components/account/EditProfile";
import Certificates from "../components/certificates/Certificates";
import LeftMenuPanel from "../components/LeftMenuPanel";
import config from "../helpers/config";

const CertificatesScreen = () => {
    //change title
    useEffect(() => {
        document.title = config.documentTitle + " - Sertifikat";

        window.scrollTo(0, 0);
    }, []);

    return (
        <Container>
            <Row>
                <Col sm={12} md={12} lg={4} className="mb-4">
                    <LeftMenuPanel activeIndex={7}/>
                </Col>
                <Col sm={12} md={12} lg={8}>
                    <Certificates />
                </Col>
            </Row>
        </Container>
    );
}

export default CertificatesScreen;