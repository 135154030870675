import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
    event
} from './../../services/transaction';
import swal from 'sweetalert';
import { Col, Row, Nav, Button} from "react-bootstrap";
import { intToMoney } from "../../helpers/currency";
import moment from "moment";
import { renderStatus } from "../../helpers/event_order";
import { useNavigate } from "react-router";
import {
    cancel as cancelApi
} from './../../services/event';

const EventOrders = () => {
    const navigate = useNavigate();

    const auth = useSelector((state) => state.auth);

    const [orders, setOrders] = useState(null);
    const [status, setStatus] = useState(null);
    const [keyword, setKeyword] = useState("");
    const [page, setPage] = useState(1);
    const [reset, setReset] = useState(true);
    const [isContainMore, setIsContainMore] = useState(true);

    useEffect(() => {
        async function getOrders(){
            const res = await event(
                auth.token,
                status,
                keyword,
                page
            );

            if(res.error === null){
                if(reset){
                    setOrders(res.data);
                }else{
                    //append
                    setOrders(orders => [...orders, ...res.data]);
                }

                if(res.data.length < 1){
                    setIsContainMore(false);
                }else{
                    setIsContainMore(true);
                }
            }else{
                swal('Error', res.error, 'error');
            }
        }

        getOrders();
    }, [auth, page, keyword, reset, status]);

    const loadMore = () => {
        setReset(false);
        //set last id
        setPage(page+1);
    }

    const filterStatus = (newStatus) => {
        setReset(true);
        setPage(1);
        setKeyword("");
        setStatus(newStatus);
    }

    const cancel = (order) => {
        //show confirm
        swal({
            title: "Konfirmasi",
            text: "Apakah anda yakin ingin membatalkan pesanan tiket "+order.event_title+"?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then(async (willDelete) => {
            if(willDelete){
                const res = await cancelApi(auth.token, order.id);
                if(res.error == null){
                    swal('Berhasil', 'Pesanan berhasil dibatalkan', 'success');
                    filterStatus(-1)
                }else{
                    swal('Error', res.error, 'error');
                }
            }
        });
    }
    
    return(
        <div>
            <Nav variant="pills" defaultActiveKey="#semua">
                <Nav.Item>
                    <Nav.Link active={status == null} href="#semua" onClick={() => filterStatus(null)}>Semua</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link active={status == 1} href="#menunggu-pembayaran" onClick={() => filterStatus(1)}>Menunggu Pembayaran</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link active={status == 2} href="#berhasil" onClick={() => filterStatus(2)}>Berhasil</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link active={status == 3} href="#pembayaran-gagal" onClick={() => filterStatus(3)}>Pembayaran Gagal</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link active={status == -1} href="#dibatalkan" onClick={() => filterStatus(-1)}>Dibatalkan</Nav.Link>
                </Nav.Item>
            </Nav>
            {
                orders !== null && orders.length > 0 ? 
                orders.map((order, index) => (
                    <div className="box p-4 mb-3 mt-3">
                        <Row style={{
                            cursor: 'pointer'
                        }} onClick={
                            () => navigate('/event-transaction/order/'+order.id)
                        }>
                            <Col md={6}>
                                <div>
                                    <span style={{
                                        fontSize: '14px',
                                        color: '#696969',
                                        fontWeight: 'bold'
                                    }}>
                                        Tiket
                                    </span>
                                    <span style={{
                                        fontSize: '12px',
                                        color: '#868686',
                                        marginLeft: '10px'
                                    }}>
                                        { moment(order.created_at).format('DD MMM YYYY') }
                                    </span>
                                    <span style={{
                                        fontSize: '12px',
                                        color: '#868686',
                                        marginLeft: '10px'
                                    }}>
                                        { renderStatus(order.status_value, order.status) }
                                    </span>
                                    <span style={{
                                        fontSize: '12px',
                                        color: '#868686',
                                        marginLeft: '10px'
                                    }}>
                                        #{ order.jurnal_transaction_no }
                                    </span>
                                </div>
                                <Row className="mt-3">
                                    <Col sm={4}>
                                        <img src={order.event_cover} alt={order.event_title} className="img-fluid"/>
                                    </Col>
                                    <Col sm={8}>
                                        <div>
                                            { order.event_title }
                                        </div>
                                        <div style={{
                                            fontSize: '12px',
                                            color: '#868686'
                                        }}>
                                            { '1 x '+intToMoney(order.total_cost) }
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={6} className="text-end ">
                                <div style={{
                                    fontSize: '14px',
                                    fontWeight: 'light',
                                    color: '#828282'
                                }}>Total Harga:</div>
                                <div style={{
                                    fontSize: '14px',
                                    fontWeight: 'bold'
                                }}>
                                    { intToMoney(order.total_cost) }
                                </div>
                            </Col>
                        </Row>
                        <div hidden={order.status_value === "-1" || order.status_value === "2" || order.status_value === "3"} className="text-end">
                            <span style={{
                                color: "blue",
                                cursor: "pointer"
                            }} onClick={() => cancel(order)}>Batalkan</span>
                        </div>
                    </div>
                )) : (
                    <div className="text-center mt-5">
                        <img src="/img/belum_ada_transaksi.svg" alt="No News" />
                    </div>
                )
            }

            <div className="text-center">
                <Button hidden={!isContainMore} onClick={loadMore} variant="danger" className="btn-default">Muat Lebih Banyak</Button>
            </div>
        </div>
    );
}

export default EventOrders;