import React, { useEffect } from "react";

//bootstrap
import { Container, Row, Col } from "react-bootstrap";

import LeftMenuPanel from "../components/LeftMenuPanel";
import config from "../helpers/config";
import ProfileNav from "../components/ProfileNav";
import EmploymentList from "../components/employments/EmploymentList";

const EmploymentsScreen = () => {
    //change title
    useEffect(() => {
        document.title = config.documentTitle + " - Portfolio Saya";

        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="default-nav-mt">
            <Container>
                <Row>
                    <Col sm={12} md={12} lg={4} className="mb-4">
                        <LeftMenuPanel activeIndex={0}/>
                    </Col>
                    <Col sm={12} md={12} lg={8}>
                        <ProfileNav activeIndex={5}/>
                        <EmploymentList />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default EmploymentsScreen;