import React, { useEffect, useState } from "react";
//bootstrap
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faTrash } from "@fortawesome/free-solid-svg-icons";
import {
    remove
} from './../../services/portfolio';
import AddEditPortfolio from './AddEditPortfolio';
import { useSelector, useDispatch } from "react-redux";
import swal from 'sweetalert';
import {
    myList
} from './../../services/portfolio';
import {
    showPortfolioDetail
} from './../../redux/slicers/portfolioSlice';
import PortfolioBox from "./PortfolioBox";

const PortfolioList = () => {
    const auth = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    const [showModal, setShowModal] = useState(false);
    const [editId, setEditId] = useState(null);
    const [refreshList, setRefreshList] = useState(null);
    const [portfolios, setPortfolios] = useState(null);

    useEffect(() => {
        async function getList(){
            const res = await myList(auth.token);
            if(res.error === null){
                if(res.data.length > 0){
                    setPortfolios(res.data);
                }else{
                    setPortfolios(null);
                }
            }else{
                swal("Error!", res.error.message, "error");
            }
        }

        getList();
    }, [auth, refreshList])

    useEffect(() => {
        if(editId !== null){
            setShowModal(true);
        }
    }, [editId]);

    const closeModal = () => {
        setEditId(null);
        setShowModal(false);
    }

    const deleteData = async (id) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then(async (willDelete) => {
            if (willDelete) {
                const res = await remove(auth.token, id);
                if(res.error === null){
                    swal("Sukses!", "Berhasil menghapus data.", "success");

                    setRefreshList(new Date());
                }else{
                    swal("Error!", res.error, "error");
                }
            } 
        });
    }

    const showDetail = id => {
        dispatch(showPortfolioDetail(id));
    }

    return(
        <div className="p-4">
            <div className="text-end">
                <button className="btn btn-default btn-sm" onClick={() => setShowModal(true)}>Tambah</button>
            </div>

            <AddEditPortfolio id={editId} isShow={ showModal } setShowModal={closeModal} setRefreshList={ setRefreshList }/>

            {
                portfolios === null ? (
                    <div className="text-center p-4">
                        <h4>No Portfolio</h4>
                        <img src="img/empty.svg" style={{
                            width: "200px",
                        }}/>
                    </div>
                ) :
                <>
                <Row className="mt-3">
                    {
                    portfolios.map((portfolio, index) => (
                        <Col sm={12} md={6} lg={4} key={portfolio.id}>
                            <PortfolioBox portfolio={portfolio}>
                                <div className="text-end me-3 ms-3 mb-3">
                                    &nbsp;<a href="#!" className="action-link" onClick={() => setEditId(portfolio.id)}><FontAwesomeIcon icon={faCog} />Ubah</a>
                                    &nbsp;<a href="#!" className="action-link" onClick={() => deleteData(portfolio.id)}><FontAwesomeIcon icon={faTrash} />Hapus</a>
                                </div>
                            </PortfolioBox>
                        </Col>
                    ))
                    }
                </Row>
                </>
            }
        </div>

    );
}

export default PortfolioList;