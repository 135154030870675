import moment from 'moment';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Comments from './Comments';
import { useNavigate } from 'react-router';

const NewsBox = (props) => {
    const news = props.news;
    const navigate = useNavigate();

    const newsImage = news.cover !== null && news.cover !== "" ? news.cover : 'https://via.placeholder.com/300x200';
    const [viewAll, setViewAll] = useState(false);

    return (
        <div
        style={{
        }}>
            <div className="image-box" style={{
                padding: '0px',
                marginBottom:'20px',
                overflow: 'hidden',
            }}>
                <div onClick={() => navigate('/news/'+news.url) } style={{
                    cursor: 'pointer',
                }}>
                    <img src={ newsImage } alt="" className='img-fluid'/>
                </div>
                <div style={{
                    color: '#AA272F',
                    marginTop: '10px',
                    fontWeight: 'bold',
                    marginBottom: '10px',
                }}>{ news.news_type_name }</div>
                <div>
                    <p style={{
                        fontSize:18,
                        marginBottom:10,
                        fontWeight:'bold',
                        marginBottom: '10px'
                    }}><Link to={"/news/"+news.url} className="link-dark" style={{
                        textDecoration: 'none',
                    }}>{ news.title }</Link></p>
                    <div style={{
                        width:'200px',
                        height:'1px',
                        backgroundColor:'#AA272F',
                        marginTop: '20px',
                        marginBottom: '20px'
                    }}></div>
                    <div style={{
                        height: viewAll ? '100%' : '100px',
                    }} className={ viewAll ? "" : "toggle-height-body" }>
                        { <div dangerouslySetInnerHTML={{ __html: news.body }} /> }
                    </div>
                    <div style={{
                        fontSize:'10pt',
                        marginBottom:'5px',
                        fontWeight:'bold',
                        marginTop: '10px'
                    }}>
                        { moment(news.created_at).format('DD MMM YYYY') }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NewsBox;