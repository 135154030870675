export const search = async (token, keyword, page) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}news/search`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            keyword: keyword,
            page: page
        })
    });
    const json = await response.json();
    return json;
}

export const submitComment = async (token, news_id, body) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}news/submit_comment`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            news_id: news_id,
            body: body
        })
    });
    const json = await response.json();
    return json;
}

export const comments = async (token, news_id, last_id) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}news/comments`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            news_id: news_id,
            last_id: last_id
        })
    });
    const json = await response.json();
    return json;
}

export const read = async (url, token) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}news/read`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            url: url,
        })
    });
    const json = await response.json();
    return json;
}