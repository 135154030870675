import config from "./config";

export const renderStatus = (status) => {
    if(status === config.licenseStatus.PENDING) {
        return <span className="badge bg-light text-dark">Pending</span>;
    }
    else if(status === config.licenseStatus.WAITING_FOR_PAYMENT) {
        return <span className="badge bg-warning">Menunggu Pembayaran</span>;
    }
    else if(status === config.licenseStatus.PAID) {
        return <span className="badge bg-success">Lunas</span>;
    }
    else if(status === config.licenseStatus.PAYMENT_FAILED) {
        return <span className="badge bg-danger">Pembayaran Gagal</span>;
    }
    else if(status === config.licenseStatus.CANCELLED) {
        return <span className="badge bg-danger">Dibatalkan</span>;
    }
    else if(status === config.licenseStatus.PROCESSED) {
        return <span className="badge bg-secondary">Diproses</span>;
    }
    else if(status === config.licenseStatus.REVISION) {
        return <span className="badge bg-danger">Perlu Revisi</span>;
    }
    else if(status === config.licenseStatus.APPROVED) {
        return <span className="badge bg-success">Disetujui</span>;
    }
    else if(status === config.licenseStatus.COMPLETED) {
        return <span className="badge bg-success">Selesai</span>;
    }
    else if(status === config.licenseStatus.DRAFT) {
        return <span className="badge bg-warning">Draft</span>;
    }
}

export const renderLicenseType = (type) => {
    if(type === config.licenseType.NEW){
        return 'Baru';
    }
    else if(type === config.licenseType.CONVERSION){
        return 'Konversi';
    }
    else if(type === config.licenseType.RENEW){
        return 'Perpanjangan';
    }
}