import React, { useState } from "react";
import moment from "moment";
import {
    readNotification
} from './../../services/users';
import { Col, Row, Spinner } from "react-bootstrap";
import swal from 'sweetalert';
import { useSelector } from "react-redux";

const NotificationBox = (props) => {
    const notification = props.notification;
    const auth = useSelector((state) => state.auth);

    const [loading, setLoading] = useState(false);

    const read = async () => {
        setLoading(true);

        const res = await readNotification(auth.token, notification.id);

        setLoading(false);

        if(res.error === null){
            //redirect
            window.location.href = notification.link;
        }else{
            swal("Error!", res.error, "error");
        }
    }

    return (
        <div onClick={() => read()} className={"per_notif" + (notification.read_at !== null ? " notif_read" : '')}>
            <div style={{
                fontWeight: "bold",
            }}>
                { notification.title }
            </div>
            <div style={{
                fontSize: "10pt",
            }}>
                { notification.message }
            </div>
            <Row style={{
                height: "20px",
            }}>
                <Col sm={6}>
                    { loading ? (
                        <Spinner animation="border" size="sm" variant="primary"/>
                    ) : null }
                </Col>
                <Col sm={6}>
                    <div style={{
                        fontSize: "10pt",
                        textAlign: "right",
                    }}>
                        { moment(notification.created_at).fromNow() }
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default NotificationBox;