import config from "./config";

export const renderStatus = (status, status_label) => {
    const statusLabel = status.toLowerCase();
    if(statusLabel === '-1') {
        return <span className="badge bg-danger">Gagal</span>;
    }
    else if(statusLabel === '0') {
        return <span className="badge bg-secondary">Pending</span>;
    }
    else if(statusLabel === '1') {
        return <span className="badge bg-warning">Menunggu Pembayaran</span>;
    }
    else if(statusLabel === '2') {
        return <span className="badge bg-success">Lunas</span>;
    }
    else if(statusLabel === '3') {
        return <span className="badge bg-danger">Pembayaran Gagal</span>;
    }
}

export const renderOrderType = (type) => {
    if(type === config.membershipOrderType.NEW) {
        return <div>Baru</div>;
    }
    else if(type === config.membershipOrderType.EXTENSION) {
        return <div>Iuran Perpanjangan</div>;
    }
}