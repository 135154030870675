import React, { useEffect, useState } from "react";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Button, Col, Row } from "react-bootstrap";
import Dropzone, {useDropzone} from 'react-dropzone';
import {
    editProfile
} from './../../services/account';
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import {
    profile
} from './../../services/auth';
import {
    setAccount
} from './../../redux/slicers/accountSlice';
import { all } from "../../services/province";
import Loading from './../Loading';

const EditProfile = () => {
    const dispatch = useDispatch();
    const account = useSelector((state) => state.account);
    const auth = useSelector((state) => state.auth);

    const [loading, setLoading] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [username, setUsername] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [gender, setGender] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState("");
    const [address, setAddress] = useState("");
    const [provinces, setProvinces] = useState(null);
    const [provinceId, setProvinceId] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [ktpNumber, setKtpNumber] = useState("");
    const [npwpNumber, setNpwpNumber] = useState("");

    const [contactEmailAddress, setContactEmailAddress] = useState("");
    const [contactInstagram, setContactInstagram] = useState("");
    const [contactFacebook, setContactFacebook] = useState("");
    const [contactLinkedin, setContactLinkedin] = useState("");

    const [refreshProfileTime, setRefreshProfileTime] = useState(null);

    const [scanKtpFiles, setScanKtpFiles] = useState([]);
    const [scanKtp, setScanKtp] = useState('');
    const [uploadedScanKtp, setUploadedScanKtp] = useState('');

    const [scanNpwpFiles, setScanNpwpFiles] = useState([]);
    const [scanNpwp, setScanNpwp] = useState('');
    const [uploadedScanNpwp, setUploadedScanNpwp] = useState('');

    const fileToBase64WithCallback = (selectedFile, callback) => {
        let file = null;
        //Check File is not Empty
        if (selectedFile.length > 0) {
            // Select the very first file from list
            let fileToLoad = selectedFile[0];
            // FileReader function for read the file.
            let fileReader = new FileReader();
            // Onload of file read the file content
            fileReader.onload = function(fileLoadedEvent) {
                file = fileLoadedEvent.target.result;
                // Print data in console
                callback(file);
            };
            // Convert data to base64
            fileReader.readAsDataURL(fileToLoad);
        }
    }

    const [crop, setCrop] = useState({ aspect: 1 / 1 });
    const [image, setImage] = useState(null);
    const [editedImage, setEditedImage] = useState(null);
    const [onEdit, setOnEdit] = useState(false);
    const [onComplete, setOnComplete] = useState(false);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        async function getProfile(token){
            setLoading(true);

            //reset form
            setScanKtpFiles([]);
            setScanNpwpFiles([]);

            //get province first
            await getProvinces();

            const res = await profile(token);
            if(res.error === null || res.error === ""){
                setFirstName(res.data.first_name);
                setLastName(res.data.last_name);
                setPhoneNumber(res.data.phone_number === null || res.data.phone_number === "" ? "" : res.data.phone_number);
                setGender(res.data.gender);
                setDateOfBirth(res.data.date_of_birth);
                setAddress(res.data.address);
                setProvinceId(res.data.province_id);
                setKtpNumber(res.data.ktp_number);
                setUploadedScanKtp(res.data.scan_ktp);
                setZipCode(res.data.zip_code);
                setNpwpNumber(res.data.npwp_number);
                setUploadedScanNpwp(res.data.scan_npwp);
                setUsername(res.data.username);
                setContactEmailAddress(res.data.contact_email_address);
                setContactInstagram(res.data.contact_instagram);
                setContactFacebook(res.data.contact_facebook);
                setContactLinkedin(res.data.contact_linkedin);
            }else{
                swal("Error!", res.error, "error");
            }

            setLoading(false);
        }

        async function getProvinces(){
            const res = await all();
            if(res.error === null){
                setProvinces(res.data);
            }else{
                swal("Error!", res.error, "error");
            }
        }

        getProfile(auth.token);
    }, [auth, refreshProfileTime]);

    const {acceptedFiles, fileRejections, getRootProps, getInputProps} = useDropzone({
        maxFiles:1,
        accept: 'image/jpeg, image/png',
        onDrop: files => {
            fileToBase64(files);

            setOnEdit(true);
        }
    });

    const fileRejectionItems = fileRejections.map(({ file, errors  }) => { 
        return (
          <li key={file.path}>
               {file.path} - {(file.size/ 1000)} Kb
               <ul>
                 {errors.map(e => <li key={e.code}>{e.message}</li>)}
              </ul>
          </li>
        ) 
    });

    const fileToBase64 = (selectedFile) => {
        let file = null;
        //Check File is not Empty
        if (selectedFile.length > 0) {
            // Select the very first file from list
            let fileToLoad = selectedFile[0];
            // FileReader function for read the file.
            let fileReader = new FileReader();
            // Onload of file read the file content
            fileReader.onload = function(fileLoadedEvent) {
                file = fileLoadedEvent.target.result;
                // Print data in console
                setImage(file);
            };
            // Convert data to base64
            fileReader.readAsDataURL(fileToLoad);
        }
    }

    const getCroppedImg = (imageBase64, crop) => {
        let image = new Image();
        image.src = imageBase64;
        image.height = editedImage.height;
        image.width = editedImage.width;

        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");
      
        // New lines to be added
        const pixelRatio = window.devicePixelRatio;
        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = "high";

        console.log(crop);
      
        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
        );
      
        // As Base64 string
        const base64Image = canvas.toDataURL("image/jpeg");
        return base64Image;
    }

    const applyCrop = async () => {
        const croppedImg = await getCroppedImg(image, crop);
        setImage(croppedImg);
        setOnComplete(true);
    }

    const resetCrop = () => {
        setImage(null);
        setCrop({ aspect: 1 / 1 });
        setOnEdit(false);
        setOnComplete(false);
    }

    const save = async (e) => {
        e.preventDefault();

        setSubmitLoading(true);

        const res = await editProfile(
            auth.token,
            firstName,
            lastName,
            phoneNumber,
            image,
            gender,
            dateOfBirth,
            address,
            provinceId,
            zipCode,
            ktpNumber,
            scanKtp,
            npwpNumber,
            scanNpwp,
            username,
            contactEmailAddress,
            contactInstagram,
            contactFacebook,
            contactLinkedin
        )

        if(res.error == null){
            swal("Success!", "Berhasil mengubah Profil!", "success");

            resetAccountStore();

            //get profile
            setRefreshProfileTime(new Date().getTime());
        }else{
            swal("Error!", res.error, "error");
        }

        setSubmitLoading(false);
    }

    const resetAccountStore = () => {
        profile(auth.token).then(res=> {
            if(res.is_authenticated){
                dispatch(setAccount(res.data));
            }
        });
    }

    return (
        <>
            {
                loading ? <Loading/> : (
                    <form onSubmit={save} className="mt-3 box p-3">
                        <Row>
                            <Col sm={12}>
                                <div className="alert alert-warning">
                                    * = Wajib diisi
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className="mt-3 mb-3" hidden={!onComplete}>
                                    <label>Pas Foto Baru</label>
                                    <br/>
                                    <img src={image} alt="new avatar" className="img-fluid img-thumbnail"/>
                                    <div className="mt-3">
                                        <Button variant="danger" onClick={() => resetCrop()}>Change Image</Button>
                                    </div>
                                </div>
                                <div hidden={onComplete}>
                                    <div className="mt-3 col-sm-12 col-md-6 col-lg-6" hidden={onEdit}>
                                        <label>Pas Foto</label>
                                        <div {...getRootProps({className: 'dropzone'})}>
                                            <input {...getInputProps()} />
                                            <p className="text-center">
                                                Drag 'n' drop some files here<br/>or click to select files
                                                <em>(Only *.jpeg and *.png images will be accepted)</em>
                                            </p>
                                        </div>
                                        <aside className="mt-2">
                                            {
                                                fileRejectionItems.length > 0 ? (
                                                    <>
                                                        <b>Rejected files</b>
                                                        <ul>{fileRejectionItems}</ul>
                                                    </>
                                                ) : null
                                            }
                                        </aside>
                                    </div>
                                    <div hidden={!onEdit} className="mt-3 mb-3">
                                        <ReactCrop onImageLoaded={editedImage => setEditedImage(editedImage) } src={image} crop={crop} circularCrop={true} onChange={newCrop => setCrop(newCrop)}/>
                                        <br/>
                                        <Button variant="danger" onClick={() => applyCrop()}>Apply Crop</Button>
                                    </div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3">
                                    <label>Jabatan Depan + Nama Depan*</label>
                                    <input type="text" placeholder="Drs. Agung" value={firstName} onChange={e => setFirstName(e.target.value)} className="form-control" required={true}/>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3">
                                    <label>Nama Belakang + Jabatan Belakang*</label>
                                    <input type="text" placeholder="Sanjaya, S.T." value={lastName} onChange={e => setLastName(e.target.value)}  className="form-control" required={true}/>
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className="mb-3">
                                    <label>Email Address*</label>
                                    <input type="text" value={account.email_address} className="form-control" required={true} disabled={true}/>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3">
                                    <label>Username*</label>
                                    <input type="text" value={username} onChange={e => setUsername(e.target.value)}  className="form-control" required={true}/>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3">
                                    <label>Nomor Handphone*</label>
                                    <input type="text" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)}  className="form-control" required={true}/>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3">
                                    <label>Jenis Kelamin*</label>
                                    <div className="mt-2">
                                        <input type="radio" name="gender" onChange={e => setGender('L')} checked={ gender === 'L' }/>&nbsp;Laki-laki
                                        &nbsp;
                                        <input type="radio" name="gender" onChange={e => setGender('P')} checked={ gender === 'P' }/>&nbsp;Perempuan
                                    </div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3">
                                    <label>Tanggal Lahir*</label>
                                    <input type="date" value={dateOfBirth} onChange={e => setDateOfBirth(e.target.value)} className="form-control" required={true}/>
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className="mb-3">
                                    <label>Alamat*</label>
                                    <textarea value={address} onChange={e => setAddress(e.target.value)} className="form-control" required={true}></textarea>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3">
                                    <label>Provinsi Tinggal*</label>
                                    <select value={provinceId} onChange={e => setProvinceId(e.target.value)} className="form-select" required={true}>
                                        <option value="">Pilih Provinsi</option>
                                        {
                                            provinces !== null ? 
                                            provinces.map(province => (
                                                <option value={province.id} key={province.id}>{province.name}</option>
                                            )) : null
                                        }
                                    </select>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3">
                                    <label>Kode Pos*</label>
                                    <input type="text" value={zipCode} onChange={e => setZipCode(e.target.value)} className="form-control" required={true}/>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3">
                                    <label>Nomor KTP*</label>
                                    <input type="text" value={ktpNumber} onChange={e => setKtpNumber(e.target.value)}  className="form-control" required={true}/>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3">
                                    <label>Nomor NPWP</label>
                                    <input type="text" value={npwpNumber} onChange={e => setNpwpNumber(e.target.value)}  className="form-control" required={false}/>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3">
                                    <label>Foto Scan KTP*</label>
                                    <div hidden={ uploadedScanKtp === null || uploadedScanKtp === "" }>
                                        <div className="text-center">
                                            <a style={{
                                                textDecoration: 'none'
                                            }} href={uploadedScanKtp} target="_blank" rel="noreferrer">Lihat Hasil Scan KTP</a>
                                        </div>
                                        <div className="alert alert-warning mt-2">
                                            Kosongkan Kolom ini apabila tidak ingin mengganti data.
                                        </div>
                                    </div>
                                    <Dropzone accept={['image/png', 'image/jpg', 'image/jpeg']} onDrop={(acceptedFiles) => {
                                        setScanKtpFiles(acceptedFiles);
                                        fileToBase64WithCallback(acceptedFiles, setScanKtp);
                                    }} name="heroImage" multiple={false}>
                                        {({getRootProps, getInputProps}) => (
                                            <div {...getRootProps({className: 'dropzone'})}>
                                                <input {...getInputProps()} />
                                                <span style={{ fontSize: ".8rem" }}>
                                                    Foto harus berformat .png .jpg atau .jpeg
                                                </span>
                                            </div>
                                        )}
                                    </Dropzone>
                                    <aside className="mt-2">
                                        <b>File yang dipilih:</b>
                                        {
                                        scanKtpFiles.map(file => (
                                            <div key={file.path}>
                                                {
                                                    file.type !== 'image/png' && file.type !== 'image/jpg' && file.type !== 'image/jpeg' ?
                                                    (
                                                        <div className="alert alert-danger">File harus berformat .png, .jpg atau .jpeg</div>
                                                    ) :
                                                    (
                                                        <>
                                                            {file.path} - {(file.size / 1000)} Kb
                                                            <img src={scanKtp} className="w-100"/>
                                                        </>
                                                    )
                                                }
                                                
                                            </div>
                                        ))
                                        }
                                    </aside>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3">
                                    <label>Foto Scan NPWP</label>
                                    <div hidden={ uploadedScanNpwp === null || uploadedScanNpwp === "" }>
                                        <div className="text-center">
                                            <a style={{
                                                textDecoration: 'none'
                                            }} href={uploadedScanNpwp} target="_blank" rel="noreferrer">Lihat Hasil Scan NPWP</a>
                                        </div>
                                        <div className="alert alert-warning mt-2">
                                            Kosongkan Kolom ini apabila tidak ingin mengganti data.
                                        </div>
                                    </div>
                                    <Dropzone accept={['image/png', 'image/jpg', 'image/jpeg']} onDrop={(acceptedFiles) => {
                                        setScanNpwpFiles(acceptedFiles);
                                        fileToBase64WithCallback(acceptedFiles, setScanNpwp);
                                        console.log(acceptedFiles);
                                    }} name="heroImage" multiple={false}>
                                        {({getRootProps, getInputProps}) => (
                                            <div {...getRootProps({className: 'dropzone'})}>
                                                <input {...getInputProps()} />
                                                <span style={{ fontSize: ".8rem" }}>
                                                Foto harus berformat .png .jpg atau .jpeg
                                                </span>
                                            </div>
                                        )}
                                    </Dropzone>
                                    <aside className="mt-2">
                                        <b>File yang dipilih:</b>
                                        {
                                        scanNpwpFiles.map(file => (
                                            <div key={file.path}>
                                                {
                                                    file.type !== 'image/png' && file.type !== 'image/jpg' && file.type !== 'image/jpeg' ?
                                                    (
                                                        <div className="alert alert-danger">File harus berformat .png, .jpg atau .jpeg</div>
                                                    ) :
                                                    (
                                                        <>
                                                            {file.path} - {(file.size / 1000)} Kb
                                                            <img src={scanNpwp} className="w-100"/>
                                                        </>
                                                    )
                                                }
                                                
                                            </div>
                                        ))
                                        }
                                    </aside>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <h3>Informasi Kontak:</h3>
                            <Col md={6}>
                                <div className="mb-3">
                                    <label>Alamat Email</label>
                                    <input type="text" value={contactEmailAddress} maxLength={150} onChange={e => setContactEmailAddress(e.target.value)} className="form-control" required={false}/>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3">
                                    <label>Instagram</label>
                                    <input type="text" value={contactInstagram} maxLength={150} onChange={e => setContactInstagram(e.target.value)}  className="form-control" required={false}/>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3">
                                    <label>Facebook</label>
                                    <input type="text" value={contactFacebook} maxLength={150} onChange={e => setContactFacebook(e.target.value)}  className="form-control" required={false}/>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3">
                                    <label>LinkedIn</label>
                                    <input type="text" value={contactLinkedin} maxLength={150} onChange={e => setContactLinkedin(e.target.value)}  className="form-control" required={false}/>
                                </div>
                            </Col>
                        </Row>
                        <div className="text-end mt-5">
                            <button type="submit" className="btn btn-default">
                                {
                                    submitLoading ? <Loading /> : 'Simpan'
                                }
                            </button>
                        </div>
                    </form>
                )
            }
        </>
    );
}

export default EditProfile;