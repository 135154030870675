import React, { useEffect, useState } from "react";
//bootstrap
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faTrash, faMapMarkerAlt, faFileDownload } from "@fortawesome/free-solid-svg-icons";
import {
    all,
    remove
} from './../../services/ska';
import AddEditSKA from './AddEditSKA';
import { useDispatch, useSelector } from "react-redux";
import swal from 'sweetalert';
import Loading from "../Loading";
import moment from "moment";

const SKAList = () => {
    const auth = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [editId, setEditId] = useState(null);
    const [skas, setSKAs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [refreshTime, setRefreshTime] = useState(null);

    useEffect(() => {
        if(editId !== null){
            setShowModal(true);
        }
    }, [editId]);

    useEffect(() => {
        setRefreshTime(new Date().getTime());
    }, []);

    useEffect(() => {
        async function getSKAs(token){
            setLoading(true);

            const res = await all(token);
            if(res.error === null){
                if(res.data.length > 0){
                    setSKAs(res.data);
                }else{
                    setSKAs(null);
                }
            }else{
                swal("Error!", res.error.message, "error");
            }

            setLoading(false);
        }

        if(refreshTime !== null){
            getSKAs(auth.token);
        }
    }, [refreshTime, auth]);

    const closeModal = () => {
        setEditId(null);
        setShowModal(false);
    }

    const deleteData = async (id) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then(async (willDelete) => {
            if (willDelete) {
                const res = await remove(auth.token, id);
                if(res.error === null){
                    swal("Sukses!", "Berhasil menghapus data.", "success");

                    setRefreshTime(new Date().getTime());
                }
            } 
        });
    }

    return(
        <div>
            <div className="text-end">
                <button className="btn btn-default ms-2 mt-3 btn-sm" onClick={() => setShowModal(true)}>Tambah SKA</button>
            </div>

            <AddEditSKA id={editId} isShow={ showModal } refreshList={() => setRefreshTime(new Date().getTime())} setShowModal={closeModal}/>

            {
                loading ? <Loading /> :
                skas === null ? (
                    <div className="text-center p-4">
                        <h4>Belum ada SKA</h4>
                        <img src="img/empty.svg" style={{
                            width: "200px",
                        }}/>
                    </div>
                ) : 
                skas.map((ska, index) => (
                    <div key={ska.id}>
                        <div className="box p-3 mt-3">
                            <h6>
                                <span style={{
                                    fontWeight: "bold"
                                }}>
                                    { ska.ska_no }
                                    {
                                        ska.ska_category !== null && ska.ska_category !== "" ? (
                                            ' | '+ska.ska_category
                                        ) : null
                                    }
                                </span>
                                <div className="float-end d-inline-block">
                                    &nbsp;<a href="#!" className="action-link" onClick={() => setEditId(ska.id)}><FontAwesomeIcon icon={faCog} />Ubah</a>
                                    &nbsp;<a href="#!" className="action-link" onClick={() => deleteData(ska.id)}><FontAwesomeIcon icon={faTrash} />Hapus</a>
                                </div>
                            </h6>
                            <div style={{
                                fontSize: "14px",
                                fontStyle: 'italic',
                                color: "#333"
                            }}>Issued Date: { ska.issued_date !== null ? moment(ska.issued_date).format('DD MMMM YYYY') : '-' }</div>
                           
                            <div style={{
                                fontSize: "14px",
                                marginTop: '5px'
                            }} hidden={ska.scan_photo === null || ska.scan_photo === ""}>
                                <a style={{
                                    textDecoration: "none",
                                }} href={ska.scan_photo} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faFileDownload}/> Download Scan Photo</a>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>

    );
}

export default SKAList;