import moment from 'moment';

export const dateRange = (dateStart, dateEnd) => {
    const dateStartResult = moment(dateStart);

    let result = dateStartResult.format('DD MMMM YYYY');

    if(dateEnd !== null && dateEnd !== ""){
        const dateEndResult = moment(dateEnd);

        const endResult = dateEndResult.format('DD MMMM YYYY');

        if(result !== endResult){
            result = `${result} - ${endResult}`;
        }
    }

    return result;
}


export const timeRange = (dateStart, dateEnd) => {
    const dateStartResult = moment(dateStart);

    let result = dateStartResult.format('HH:mm');

    if(dateEnd !== null && dateEnd !== ""){
        const dateEndResult = moment(dateEnd);

        const endResult = dateEndResult.format('HH:mm');

        result = `${result} - ${endResult}`;
    }

    return result;
}

export const diffDaysFromNow = (dateEnd) => {
    const a = moment();
    const b = moment(dateEnd);
    const result = b.diff(a, 'days')+ ' Hari';

    return result;
}

export const getWorkDuration = (startDate, endDate, isCurrentCompany) => {
    const start = moment(startDate);

    let end = moment();
    if(endDate != null){
        end = moment(endDate);
    }
    const durationYear = end.diff(start, 'years');
    const durationMonth = end.diff(start, 'months');
    const month = durationMonth % 12;

    let result = "";

    //get range
    const from = moment(startDate).format('MMMM YYYY');
    result += from;

    if(isCurrentCompany === '0'){
        const to = moment(endDate).format('MMMM YYYY');
        result += ' - ' + to;
    }else{
        result += ' - Sekarang';
    }

    result += ' (';
    if(durationYear > 0){
        result += durationYear+' Tahun ';
    }

    if(month > 0){
        result += month+' Bulan';
    }
    result += ')';

    return result;
}