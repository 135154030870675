import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import LeftMenuPanel from "../components/LeftMenuPanel";
import ProductOrders from "../components/transaction/ProductOrders";
import config from "../helpers/config";

const ProductTransactionScreen = () => {
    //change title
    useEffect(() => {
        document.title = config.documentTitle + " - Pembelian Produk";

        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="default-nav-mt">
            <Container>
                <Row>
                    <Col sm={12} md={12} lg={4} className="mb-4">
                        <LeftMenuPanel activeIndex={5}/>
                    </Col>
                    <Col sm={12} md={12} lg={8}>
                        <h3 className="mb-3"><b>Daftar Transaksi Pembelian <i>Merchandise</i></b></h3>
                        <ProductOrders />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default ProductTransactionScreen;