import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { history as getHistoriesAPI } from '../../services/points';
import swal from 'sweetalert';
import { Button, Col, Row } from 'react-bootstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { renderStatus } from '../../helpers/point';

const PointHistory = () => {
    const auth = useSelector((state) => state.auth);

    const [histories, setHistories] = useState([]);
    const [page, setPage] = useState(1);
    const [reset, setReset] = useState(true);
    const [isContainMore, setIsContainMore] = useState(true);

    useEffect(() => {
        async function getHistories(){
            const res = await getHistoriesAPI(
                auth.token,
                page
            );

            if(res.error === null){
                if(reset){
                    setHistories(res.data);
                }else{
                    //append
                    setHistories(histories => [...histories, ...res.data]);
                }

                if(res.data.length < 1){
                    setIsContainMore(false);
                }else{
                    setIsContainMore(true);
                }
            }else{
                swal('Error', res.error, 'error');
            }
        }

        getHistories();
    }, [page, reset, auth]);

    const loadMore = () => {
        setReset(false);
        //set last id
        setPage(page+1);
    }

    return (
        <div>
            {
                histories !== null ? (
                    <div>
                        <div className='mb-4'>
                            <Link to="/request-point" className='btn btn-default'>Request Point (KUM)</Link>
                        </div>
                        <b>Point History:</b>
                        {
                            histories.map((history, index) => (
                                <div key={index} className='box p-3 mb-3'>
                                    <Row>
                                        <Col md={6}>
                                            {
                                                renderStatus(history.status)
                                            }
                                        </Col>
                                        <Col md={6}>
                                            <div className='text-end text-muted'>
                                            {
                                                history.notes
                                            }
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4}>
                                            { history.point_from }
                                        </Col>
                                        <Col md={4} className='text-center'>
                                            { history.point } Point
                                        </Col>
                                        <Col md={4} className='text-end'>
                                            { moment(history.created_at).fromNow() }
                                        </Col>
                                    </Row>
                                </div>
                            ))
                        }

                        <div className="text-center">
                            <Button hidden={!isContainMore} onClick={loadMore} variant="danger">Load More</Button>
                        </div>
                    </div>
                ) : null
            }
        </div>
    );
}

export default PointHistory;