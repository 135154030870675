import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router';
const IsExpired = () => {
    const navigate = useNavigate();

    const account = useSelector((state) => state.account);

    useEffect(() => {
        if(account !== null){
            if(account.is_expired){
                navigate('/membership');
            }
        }
    }, [account]);

    return (
       <></>
    );
}

export default IsExpired;