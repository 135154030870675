import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
    search
} from './../services/users';
import swal from 'sweetalert';
import { Container, Row, Col, Button } from "react-bootstrap";
import UserBox from "../components/users/UserBox";
import config from "../helpers/config";
import Loading from "../components/Loading";

const UserListScreen = () => {
    const auth = useSelector((state) => state.auth);

    const [total, setTotal] = useState([]);
    const [users, setUsers] = useState([]);
    const [page, setPage] = useState(1);
    const [reset, setReset] = useState(true);
    const [isContainMore, setIsContainMore] = useState(true);
    const [keywordTemp, setKeywordTemp] = useState("");
    const [keyword, setKeyword] = useState("");
    const [loading, setLoading] = useState(false);
    const [loadMoreLoading, setLoadMoreLoading] = useState(false);

    //change title
    useEffect(() => {
        document.title = config.documentTitle + " - Anggota";

        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        async function getUsers(){
            if(reset){
                setLoading(true);
            }else{
                setLoadMoreLoading(true);
            }

            const res = await search(auth.token, keyword, page);
            if(res.error === null){
                if(reset){
                    setUsers(res.data);
                    setTotal(res.total);
                }else{
                    //append
                    setUsers(users => [...users, ...res.data]);
                }

                if(res.data.length < 1){
                    setIsContainMore(false);
                }else{
                    setIsContainMore(true);
                }
            }else{
                swal("Error!", res.error.message, "error");
            }

            if(reset){
                setLoading(false);
            }else{
                setLoadMoreLoading(false);
            }
        }

        getUsers();
    }, [auth, page, keyword, reset]);

    const loadMore = () => {
        setReset(false);
        //set last id
        setPage(page+1);
    }

    const filter = (e) => {
        e.preventDefault();

        setReset(true);
        setPage(1);
        setKeyword(keywordTemp);
    }

    return (
        <div className="default-nav-mt">
            <Container>
                <Row className="mb-5">
                    <Col sm={12} className="text-center">
                        <h1>Cari Arsitek</h1>
                        <p>Cari arsitek yang terdaftar dalam Ikatan Arsitek Indonesia Jakarta</p>
                        <form onSubmit={filter}>
                            <div className="row">
                                <div className="col-10">
                                    <input type="text" style={{
                                        border: "1px solid #ccc",
                                    }} value={keywordTemp} onChange={e => setKeywordTemp(e.target.value)} className="form-control" maxLength={20} placeholder="Cari..."/>
                                </div>
                                <div className="col-2">
                                    <button type="submit" style={{
                                        width: '100%',
                                    }} className="btn btn-default">Cari</button>
                                </div>
                            </div>
                        </form>
                    </Col>
                </Row>

                {
                    loading ? <Loading /> :
                    users !== null && users.length > 0 ?
                    (
                        <Row>
                            <Col sm={12} md={12} lg={12}>
                                Menemukan {total} member
                                <Row className="mt-2">
                                    {
                                        users.map((user, index) => (
                                            <Col className="mb-3" key={user.id} xl={3} lg={4} md={6} sm={12}>
                                                <UserBox user={user} />
                                            </Col>
                                        ))
                                    }
                                </Row>
                                <div className="text-center" hidden={!isContainMore}>
                                    <Button disabled={loadMoreLoading} variant="danger" className="btn-default" onClick={loadMore}>
                                        { loadMoreLoading ? <Loading/> : 'Muat Lebih Banyak' }
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    ) : (
                        <div className="text-center mt-5">
                            <img src="/img/data_not_found.svg" />
                        </div>
                    )
                }
                
            </Container>
        </div>
    );
}

export default UserListScreen;