import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import FileSource from "../components/file_source/FileSource";
import LeftMenuPanel from "../components/LeftMenuPanel";
import config from "../helpers/config";

const FileSourceScreen = () => {
    //change title
    useEffect(() => {
        document.title = config.documentTitle + " - Materi Kegiatan";

        window.scrollTo(0, 0);
    }, []);

    return (
        <Container>
            <Row>
                <Col sm={12} md={12} lg={4} className="mb-4">
                    <LeftMenuPanel activeIndex={8}/>
                </Col>
                <Col sm={12} md={12} lg={8}>
                    <FileSource />
                </Col>
            </Row>
        </Container>
    );
}

export default FileSourceScreen;