import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
    search
} from './../services/event';
import swal from 'sweetalert';
import { Container, Row, Col, Button } from "react-bootstrap";
import EventBox from "../components/events/EventBox";
import config from "../helpers/config";
import ContentView from "../components/ContentView";

const EventListScreen = () => {
    const auth = useSelector((state) => state.auth);

    const [events, setEvents] = useState([]);
    const [page, setPage] = useState(1);
    const [reset, setReset] = useState(true);
    const [isContainMore, setIsContainMore] = useState(true);
    const [keyword, setKeyword] = useState("");

    //change title
    useEffect(() => {
        document.title = config.documentTitle + " - Kegiatan";

        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        async function getEvent(){
            const res = await search(auth.token, keyword, page);
            if(res.error === null){
                if(reset){
                    setEvents(res.data);
                }else{
                    //append
                    setEvents(events => [...events, ...res.data]);
                }

                if(res.data.length < 1){
                    setIsContainMore(false);
                }else{
                    setIsContainMore(true);
                }
            }else{
                swal("Error!", res.error.message, "error");
            }
        }

        getEvent();
    }, [auth, page, keyword, reset]);

    const loadMore = () => {
        setReset(false);
        //set last id
        setPage(page+1);
    }

    return (
        <div className="default-nav-mt">
            <Container>
                <div className="mb-4">
                    <ContentView slug="kegiatan-header"/>
                </div>
                <Row hidden={true}>
                    <Col lg={3}>
                        <div>
                            <label>Sort by:</label>
                            <select className="form-control select">
                                <option value="id">Terbaru</option>
                                <option value="start_datetime">Terbaru</option>
                            </select>
                        </div>
                    </Col>
                </Row>
                <div className="mt-5"></div>
                <Row>
                    {
                        events.map((event, index) => (
                            <Col className="mb-3" key={event.id} xl={12} lg={12} md={12} sm={12}>
                                <EventBox event={event} />
                            </Col>
                        ))
                    }
                </Row>
                <div className="text-center" hidden={!isContainMore}>
                    <Button variant="danger" className="btn-default" onClick={loadMore}>Muat Lebih Banyak</Button>
                </div>
            </Container>
        </div>
    );
}

export default EventListScreen;