export const renderStatus = (status, status_label) => {
    const statusLabel = status.toLowerCase();
    if(statusLabel === '-1') {
        return <span className="badge bg-danger">{status_label}</span>;
    }
    else if(statusLabel === '0') {
        return <span className="badge bg-secondary">{status_label}</span>;
    }
    else if(statusLabel === '1') {
        return <span className="badge bg-warning">{status_label}</span>;
    }
    else if(statusLabel === '2') {
        return <span className="badge bg-success">{status_label}</span>;
    }
    else if(statusLabel === '3') {
        return <span className="badge bg-danger">{status_label}</span>;
    }
}