import React, { useState ,useEffect } from "react";
import swal from "sweetalert";
import PeopleBox from "../../components/peoples/PeopleBox";
import { Col, Row } from "react-bootstrap";
import {
    getByOrganization
} from './../../services/people';

const PeopleList = (props) => {
    const name = props.name;

    const [peoples, setPeoples] = useState(null);

    useEffect(() => {
        async function getContent(slug){
            const res = await getByOrganization(slug);
            if(res.error === null){
                setPeoples(res.data);
                console.log(peoples);
            }else{
                swal('Error', res.error, 'error')
            }
        }

        getContent(name);
    }, [name]);

    return (
        <div>
            {
                peoples !== null ? (
                    <>
                    <Row>
                    {
                        peoples.peoples.map((people, index) => (
                            <Col sm={12} md={4} lg={3} key={index}>
                                <PeopleBox people={people}/>
                            </Col>
                        ))
                    }
                    </Row>

                    {
                        peoples.childs !== null ? 
                        (
                            peoples.childs.map((child, index) => (
                                <div key={index}>
                                    <h4>{ child.name }</h4>

                                    <Row>
                                    {
                                        child.peoples.map((people, index) => (
                                            <Col sm={12} md={4} lg={3} key={index}>
                                                <PeopleBox people={people}/>
                                            </Col>
                                        ))
                                    }
                                    </Row>
                                </div>
                            ))
                        ) : null
                    }
                    </>
                ) : null
            }
        </div>
    );
}

export default PeopleList;