import React, { useEffect, useState }from "react";
import { Offcanvas } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import CartList from "../components/cart/CartList";
import {
    setShowCartScreen
} from './../redux/slicers/cartSlice';

const CartScreen = () => {
    const dispatch = useDispatch();
    const cart = useSelector((state) => state.cart);
    const [show, setShow] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        setShow(cart.showCartScreen);
    }, [cart]);

    const handleClose = () => {
        dispatch(setShowCartScreen(false));
    };

    return (
        <Offcanvas show={show} onHide={handleClose} placement="end">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Keranjang Belanja</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body style={{
                padding: '0px'
            }}>
                <CartList isCheckout={false}/>
            </Offcanvas.Body>
        </Offcanvas>
    );
}

export default CartScreen;