import React, { useEffect, useState } from "react";
import {
    read
} from './../../services/membership';
import swal from "sweetalert";
import { useSelector } from "react-redux";
import { Button, Col, Row } from "react-bootstrap";
import { intToMoney } from "../../helpers/currency";
import {
    order
} from './../../services/membership';
import { useNavigate } from "react-router";

const CheckoutMembership = (props) => {
    const id = props.id;

    const auth = useSelector((state) => state.auth);
    const navigate = useNavigate();

    const [membership, setMembership] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState('bank transfer');

    useEffect(() => {
        async function getDetail(id){
            const res = await read(auth.token, id);
            if(res.error === null){
                setMembership(res.data);
            }else{
                swal("Error!", res.error, "error");
            }
        }

        getDetail(id);
    }, [auth, id]);

    const handleOrder = async () => {
        //show confirm
        swal({
            title: "Lanjutkan?",
            text: "Apakah anda yakin ingin melanjutkan proses pemesanan?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then(async (willBuy) => {
            if(willBuy){
                const res = await order(auth.token, id, paymentMethod);
                if(res.error === null){
                    swal("Sukses!", "Pesanan anda telah dibuat.", "success");
                    navigate('/membership/order/' + res.data.id);
                }else{
                    swal("Error!", res.error, "error");
                }
            }
        });
    }

    return (
        <div>
            <h4>Checkout</h4>
            
            {
                membership !== null ? (
                    <Row>
                        <Col sm={12} md={6} lg={8}>
                            <div className="mt-3">
                                Tipe Membership: <b>{ membership.name}</b>
                            </div>
                            <div>
                                Masa Aktif: <b>{ membership.active_days } Hari</b>
                            </div>
                            <hr/>
                            <div className="mt-3">
                                <label>Metode Pembayaran:</label>
                                <select value={paymentMethod} onChange={e => setPaymentMethod(e.target.value)} className="form-select" style={{
                                    width: '200px',
                                }}>
                                    <option value="bank transfer">Bank Transfer</option>
                                </select>
                            </div>
                        </Col>
                        <Col sm={12} md={6} lg={4}>
                            <div className="mt-3 box p-3">
                                <Row>
                                    <Col xs={6}>
                                        Total Biaya
                                    </Col>
                                    <Col xs={6} className="text-end">
                                        { intToMoney(membership.price) }
                                    </Col>
                                </Row>
                                <Button onClick={handleOrder} className="mt-3" variant="danger" style={{
                                    width: '100%',
                                }}>Lanjutkan</Button>
                            </div>
                        </Col>
                    </Row>
                ) : null
            }
        </div>
    );
}

export default CheckoutMembership;