export const create = async (token, institution, city, strata, graduated_on, certificates_path, major, is_active_student, student_card_path) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}education/create`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            institution: institution,
            city: city,
            strata: strata,
            graduated_on: graduated_on,
            certificates_path: certificates_path,
            major: major,
            is_active_student: is_active_student,
            student_card_path: student_card_path
        })
    });
    const json = await response.json();
    return json;
}

export const update = async (token, id, institution, city, strata, graduated_on, certificates_path, major, is_active_student, student_card_path) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}education/update`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            education_id: id,
            institution: institution,
            city: city,
            strata: strata,
            graduated_on: graduated_on,
            certificates_path: certificates_path,
            major: major,
            is_active_student: is_active_student,
            student_card_path: student_card_path
        })
    });
    const json = await response.json();
    return json;
}

export const remove = async (token, education_id) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}education/delete`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            education_id: education_id,
        })
    });
    const json = await response.json();
    return json;
}

export const read = async (id) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}education/read`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            id: id,
        })
    });
    const json = await response.json();
    return json;
}

export const all = async (token) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}education/all`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        }
    });
    const json = await response.json();
    return json;
}