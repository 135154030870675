import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    getMembershipInfo,
    history, payReactivation, paySubscription
} from './../../services/membership';
import swal from 'sweetalert';
import { Button, Col, Dropdown, Form, Modal, Row } from 'react-bootstrap';
import moment from 'moment';
import { intToMoney } from '../../helpers/currency';
import { useNavigate } from 'react-router';
import MembershipInfo from './MembershipInfo';
import Loading from '../Loading';
import { renderOrderType, renderStatus } from '../../helpers/membershipOrder';

const MembershipOrderList = () => {
    const navigate = useNavigate();
    const auth = useSelector((state) => state.auth);
    const account = useSelector((state) => state.account);

    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [infoLoading, setInfoLoading] = useState(false);
    const [perYearCost, setPerYearCost] = useState(null);
    const [selectedYear, setSelectedYear] = useState(1);
    const [userExpiredYear, setUserExpiredYear] = useState(null);

    const isNeedToReactivate = useMemo(() => {
        if (!account) return null;

        if (!account.membership) return null;

        const expiredDate = account.membership.expired;

        // check if expiredDate is more that 5 years ago
        const isNeedToReactivate = moment(expiredDate).isBefore(moment().subtract(5, 'years'));

        return isNeedToReactivate;
    }, [account]);

    useEffect(() => {
        async function getList() {
            setLoading(true);

            const res = await history(auth.token);
            if (res.error === null) {
                setOrders(res.data);
            } else {
                swal('Error', res.error, 'error');
            }

            setLoading(false);
        }

        getList();
    }, [auth]);

    const invokePayment = async (e) => {
        e.preventDefault();

        //show confirmation
        const confirm = await swal({
            title: 'Konfirmasi',
            text: 'Apakah anda yakin ingin membayar tagihan ini?',
            icon: 'warning',
            buttons: true,
            dangerMode: true,
        });

        if (confirm) {
            setSubmitLoading(true);

            const res = await paySubscription(auth.token, selectedYear);
            if (res.error === null) {
                //go to payment
                navigate('/membership/order/' + res.data.id);
            } else {
                swal('Error', res.error, 'error');
            }

            setSubmitLoading(false);
        }
    }

    const getInfo = async () => {
        setShowModal(true);
        setInfoLoading(true);

        const res = await getMembershipInfo(auth.token);
        if (res.error === null) {
            setPerYearCost(res.data.cost_per_year);
            setUserExpiredYear(parseInt(res.data.expired_year));
        } else {
            swal('Error', res.error, 'error');
            setShowModal(false);
        }

        setInfoLoading(false);
    }

    const handleClickPay = () => {
        getInfo();
    }

    const handleSelectedYearChange = (e) => {
        setSelectedYear(e.target.value);
    }

    const handleClickReactivation = async () => {
        //show confirmation
        const confirm = await swal({
            title: 'Konfirmasi',
            text: 'Apakah anda yakin ingin membayar Re-aktivasi Anggota?',
            icon: 'warning',
            buttons: true,
            dangerMode: true,
        });

        if (confirm) {
            setSubmitLoading(true);

            const res = await payReactivation(auth.token);
            if (res.error === null) {
                //go to payment
                navigate('/membership/order/' + res.data.id);
            } else {
                swal('Error', res.error, 'error');
            }

            setSubmitLoading(false);
        }
    }

    const yearsPeriod = useMemo(() => {
        const yearToday = userExpiredYear - 1;
        const period = [];
        for (let i = 1; i <= selectedYear; i++) {
            period.push(yearToday + i);
        }

        return period;
    }, [selectedYear, userExpiredYear]);

    return loading ? <Loading /> : (
        <div>
            <h3><b>Riwayat Pembayaran Iuran</b></h3>
            <div className='box mt-3 p-3'>
                <MembershipInfo />
            </div>
            {!isNeedToReactivate && (
                <button disabled={submitLoading} onClick={handleClickPay} className='btn btn-default mt-3'>
                    {submitLoading ? <Loading /> : 'Bayar Iuran'}
                </button>
            )}
            {isNeedToReactivate && (
                <button disabled={submitLoading} onClick={handleClickReactivation} className='btn btn-default mt-3'>
                    {submitLoading ? <Loading /> : 'Bayar Re-aktivasi'}
                </button>
            )}
            {
                orders !== null ? (
                    orders.map((order, index) => (
                        <div onClick={
                            () => navigate('/membership/order/' + order.id)
                        } key={index} className="box p-4 mb-3 mt-3" style={{
                            cursor: 'pointer'
                        }}>
                            <Row>
                                <Col md={8} lg={8}>
                                    <div>
                                        <span style={{
                                            fontSize: '14px',
                                            color: '#696969',
                                            fontWeight: 'bold'
                                        }}>
                                            {order.membership_name}
                                        </span>
                                        <span style={{
                                            fontSize: '12px',
                                            color: '#868686',
                                            marginLeft: '10px'
                                        }}>
                                            {moment(order.created_at).format('HH:mm, DD MMM YYYY')}
                                        </span>
                                        <span style={{
                                            fontSize: '12px',
                                            color: '#868686',
                                            marginLeft: '10px'
                                        }}>
                                            {renderStatus(order.status)}
                                        </span>
                                        <span style={{
                                            fontSize: '12px',
                                            color: '#868686',
                                            marginLeft: '10px'
                                        }}>
                                            #{order.jurnal_transaction_no}
                                        </span>
                                    </div>
                                    <Row>
                                        <Col sm={8}>
                                            <div>
                                                {
                                                    order.is_reactivation ? `Re-aktivasi (${order.start_year} - ${order.end_year})` : renderOrderType(order.type)
                                                }
                                            </div>
                                            <div style={{
                                                fontSize: '12px',
                                                color: '#868686'
                                            }}>
                                                {intToMoney(order.total_cost)}
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={4} lg={4} className="text-end ">
                                    <div style={{
                                        fontSize: '14px',
                                        fontWeight: 'light',
                                        color: '#828282'
                                    }}>Total Harga:</div>
                                    <div style={{
                                        fontSize: '14px',
                                        fontWeight: 'bold'
                                    }}>
                                        {intToMoney(order.total_cost)}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    ))
                ) : null
            }

            <Modal show={showModal} onHide={() => { }}>
                <Modal.Header closeButton={!submitLoading}>
                    <Modal.Title>Pilih Periode Perpanjangan</Modal.Title>
                </Modal.Header>
                <Form onSubmit={invokePayment}>
                    <Modal.Body>
                        {infoLoading ? (<Loading />) : (
                            <>
                                <Form.Group className="mb-3">
                                    <Form.Label>Periode</Form.Label>
                                    <Form.Select disabled={submitLoading} value={selectedYear} onChange={handleSelectedYearChange}>
                                        <option value="1">1 Tahun</option>
                                        <option value="2">2 Tahun</option>
                                        <option value="3">3 Tahun</option>
                                        <option value="4">4 Tahun</option>
                                        <option value="5">5 Tahun</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Total yang harus dibayarkan:</Form.Label>
                                    <div>
                                        <b>{intToMoney(perYearCost * selectedYear)}</b>
                                    </div>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Tahun Perpanjangan:</Form.Label>
                                    <ul>
                                        {
                                            yearsPeriod.map((year, index) => (
                                                <li key={index}>
                                                    {year}
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </Form.Group>
                            </>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button disabled={submitLoading} type='button' className="btn btn-secondary" onClick={() => setShowModal(false)}>
                            Tutup
                        </Button>
                        <Button disabled={submitLoading} type='submit' className="btn btn-default">
                            {submitLoading ? 'Mohon Tunggu...' : 'Bayar'}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </div>
    );
}

export default MembershipOrderList;