import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import {
    upcoming
} from './../services/event';
import {
    search
} from './../services/product';
import {
    search as searchNews
} from './../services/news';
import EventBox from "../components/events/EventBox";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import ProductBox from "../components/products/ProductBox";
import NewsBox from "../components/news/NewsBox";
import config from "../helpers/config";
import Banner from "../components/Banner";
import EventCalendar from "../components/events/EventCalendar";
import LatestDownloads from "../components/download/LatestDownloads";
import Loading from "../components/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight} from '@fortawesome/free-solid-svg-icons'
import MediaPartner from "../components/media_partner/MediaPartner";
import LatestPortfolio from "../components/portfolios/LatestPortfolio";
import { Link } from "react-router-dom";
import ContentView from "../components/ContentView";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

const HomeScreen = () => {
    const auth = useSelector((state) => state.auth);

    const [products, setProducts] = useState([]);
    const [events, setEvents] = useState([]);
    const [eventCategories, setEventCategories] = useState([]);
    const [selectedEventCategory, setSelectedEventCategory] = useState(null);
    const [news, setNews] = useState([]);
    const [newsSlide, setNewsSlide] = useState(null);

    const [productsLoading, setProductsLoading] = useState(true);
    const [eventsLoading, setEventsLoading] = useState(true);
    const [newsLoading, setNewsLoading] = useState(true);

    const [slideToShowEvent, setSlideToShowEvent] = useState(3);
    const [slideToShowProduct, setSlideToShowProduct] = useState(6);
    const [slideToShowNews, setSlideToShowNews] = useState(3);

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    //change title
    useEffect(() => {
        document.title = config.documentTitle + " - Beranda";

        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        async function getUpcomingEvent(){
            setEventsLoading(true);

            //get upcoming event
            const upcomingEvent = await upcoming(auth.token, 10);
            if(upcomingEvent.error === '' || upcomingEvent.error===null){
                setEvents(upcomingEvent.data);

                //get event categories
                let eventCategories = [];
                for(let i=0; i<upcomingEvent.categories.length; i++){
                    let category = upcomingEvent.categories[i];
                    eventCategories.push(category.name);
                }
                setEventCategories(eventCategories);
            }

            setEventsLoading(false);
        }
        
        async function getProducts(){
            setProductsLoading(true);

            const res = await search(auth.token, '', 1);
            if(res.error === null){
                setProducts(res.data);
            }else{
                swal("Error!", res.error.message, "error");
            }

            setProductsLoading(false);
        }

        async function getLatestNews(){
            setNewsLoading(true);

            //get latest news
            const res = await searchNews(auth.token, '', 1);
            if(res.error === null){
                const latestNews = res.data.slice(0, 4);
                setNews(latestNews);
            }else{
                swal("Error!", res.error.message, "error");
            }

            setNewsLoading(false);
        }

        getUpcomingEvent();

        getProducts();

        getLatestNews();
    }, [auth])

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if(windowDimensions.width <= 768){
            setSlideToShowEvent(1);
            setSlideToShowProduct(2);
            setSlideToShowNews(1);
        }
        else if(windowDimensions.width >= 768 && windowDimensions.width <= 992){
            setSlideToShowEvent(2);
            setSlideToShowProduct(4);
            setSlideToShowNews(2);
        }else if(windowDimensions.width >= 992 && windowDimensions.width <= 1200){
            setSlideToShowEvent(3);
            setSlideToShowProduct(6);
            setSlideToShowNews(2);
        }else{
            setSlideToShowEvent(3);
            setSlideToShowProduct(6);
            setSlideToShowNews(2);
        }
    }, [windowDimensions]);

    const selectEventCategory = category => {
        setSelectedEventCategory(category);
    }

    return (
        <div>
            <Banner /> 
            <Container className="mt-5">
                <h1 className="text-center mb-5 text-default" style={{fontFamily:'Noto Serif JP'}}>Acara yang Akan Datang</h1>
                {
                eventsLoading ? <Loading /> :
                <div className="row">
                    <div className="col-sm-12 col-md-4 col-lg-3">
                        <div className={"link-default" + (selectedEventCategory === null ? ' active' : '')} onClick={() => selectEventCategory(null)} style={{
                                    borderBottom: '1px solid #bdbdbd'
                                }}>
                            Seluruh Acara Akan Datang
                            <FontAwesomeIcon className="ms-2" icon={faArrowRight}/>
                        </div>
                        {
                            eventCategories.map((category, index) => (
                                <div className={"link-default mt-3" + (selectedEventCategory === category ? ' active' : '')} key={index} onClick={() => selectEventCategory(category)} style={{
                                    borderBottom: '1px solid #bdbdbd'
                                }}>
                                    { category }
                                    <FontAwesomeIcon className="ms-2 text-end" icon={faArrowRight}/>
                                </div>
                            ))
                        }
                        <div className="mt-5">
                            <Link to="/events" className="text-default">
                                Lihat Lebih Banyak
                                <FontAwesomeIcon className="ms-2" icon={faArrowRight}/>
                            </Link>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-8 col-lg-9">
                        {
                            events.filter(e => selectedEventCategory !== null ? e.event_category_name === selectedEventCategory : true).length > 0 ?
                                events.filter(e => selectedEventCategory !== null ? e.event_category_name === selectedEventCategory : true).map((event, index) => (
                                    <div key={index} className="mb-4">
                                        <EventBox event={event}/>
                                    </div>
                                ))
                                : (
                                    <div className="text-center">
                                        <img src="/img/tidak_ada_kegiatan.svg" alt="No News" />
                                    </div>
                                )
                        }
                    </div>
                </div>
                }
            </Container>
            <Container>
                {
                    products.length > 0 ?
                        <Row className="mt-4" style={{ display: 'none' }}>
                            <Col>
                                <h4>Produk Merchandise Kami</h4>
                                <Row>
                                {
                                    products.map((product, index) => (
                                        <Col key={index} sm={6} md={4} lg={3}>
                                            <ProductBox product={product} />
                                        </Col>
                                    ))
                                }
                                </Row>
                            </Col>
                        </Row>
                    : null
                }
                <Row className="mt-5">
                    <Col sm={12} md={12} lg={12}>
                        <EventCalendar />
                    </Col>
                </Row>
            </Container>
            <div className="p-2" style={{
                background: '#e6e6e6'
            }}>
                <Container>
                    <Row className="mt-4">
                        <Col sm={12} md={6} lg={6}>
                            <div style={{
                                color: '#AA272F',
                                fontSize: '36px',
                                fontFamily: 'Noto Serif JP'
                            }}>Sorotan</div>
                            <div className="mt-3" style={{
                                color: '#221F1F'
                            }}>Informasi seputar Kegiatan, Berita ataupun pengumuman terkait IAI Jakarta</div>
                        </Col>
                        <Col sm={12} md={6} lg={6} className="text-end">
                           <Link to="/news-list" className="text-default mt-3"
                           style={{
                            color: '#C7B37F !important'
                            }}>
                               Lihat Lebih Banyak
                               <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg" style={{
                                marginLeft: '10px'
                            }}>
                                <circle cx="15.5" cy="15.5" r="15" stroke="#C7B37F"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M17.7574 20.8038L22.5303 16.0308C22.8232 15.7379 22.8232 15.263 22.5303 14.9701L17.7574 10.1972C17.4645 9.90427 16.9896 9.90427 16.6967 10.1972C16.4038 10.4901 16.4038 10.9649 16.6967 11.2578L20.1893 14.7505H9C8.58579 14.7505 8.25 15.0863 8.25 15.5005C8.25 15.9147 8.58579 16.2505 9 16.2505H20.1893L16.6967 19.7431C16.4038 20.036 16.4038 20.5109 16.6967 20.8038C16.9896 21.0967 17.4645 21.0967 17.7574 20.8038Z" fill="#C7B37F"/>
                                </svg>
                           </Link>
                        </Col>
                    </Row>
                    {
                        newsLoading ? <Loading /> : (
                            news.length > 0 ?
                                <Row className="mt-5">
                                    {
                                        news.map((newsObj, index) => (
                                            <div className="col-sm-12 col-md-4 col-lg-3" key={index} style={{
                                                marginLeft: '50px !important'
                                            }}>
                                                <NewsBox news={newsObj}/>
                                            </div>
                                        ))
                                    }
                                </Row>
                                : (
                                    <div className="text-center">
                                        <img src="/imgs/tidak_ada_kegiatan.svg" alt="No News" />
                                    </div>
                                )
                        )
                    }
                </Container>
            </div>
            <div className="p-5" style={{
                background: '#fff',
            }}>
                <Container>
                    <Row>
                        <Col>
                            <MediaPartner />
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container className="mt-5 mb-5">
                <h1 className="text-center mb-5 text-default" style={{fontFamily:'Noto Serif JP'}}>Download dan Memo IAI Jakarta</h1>
                <LatestDownloads />
                <div className="mt-5">
                    <ContentView slug="home-video"/>
                </div>
            </Container>
        </div>
    );
}

export default HomeScreen;