import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import config from "../../helpers/config";
import { updatePrivacy } from "../../services/account";
import { profile } from "../../services/auth";
import Loading from "../Loading";
import ChangePassword from "./ChangePassword";

const Security = () => {
    const account = useSelector((state) => state.account);
    const auth = useSelector((state) => state.auth);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [privacyHideProfile, setPrivacyHideProfile] = useState(false);
    const [loading, setLoading] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);

    useEffect(() => {
        async function getPrivacy(token){
            const res = await profile(token);
            if(res.error === null || res.error === ""){
                setPrivacyHideProfile(res.data.privacy_hide_profile === "0" ? false : true);
            }else{
                //show swal error
                swal("Error!", res.error, "error");
            }
        }

        getPrivacy(auth.token);
    },[auth]);

    const savePrivacy = async (e) => {
        e.preventDefault();

        setSubmitLoading(true);

        const res = await updatePrivacy(
            auth.token,
            privacyHideProfile
        )

        if(res.error === null){
            //show swal success
            swal("Success!", "Privasi telah berhasil disimpan.", "success");
        }else{
            //show swal error
            swal("Error!", res.error, "error");
        }

        setSubmitLoading(false);
    }

    return (
        <>
            <div className="mt-3 box p-3">
                <h4><b>Keamanan Akun</b></h4>
                <div className="text-center">
                    Klik tombol dibawah untuk mengubah Kata Sandi anda.
                    <br/>
                    <span style={{
                        cursor: "pointer",
                    }} className="btn btn-default" onClick={handleShow}>Ubah Password</span>
                </div>
            </div>

            {
                account.membership !== null && typeof account.membership !== 'undefined' ? (
                    account.membership.type == config.membershipType.MEMBER_PRO || account.membership.type == config.membershipType.MEMBER ? (
                        <>
                             <div className="mt-3 box p-3">
                                <h4><b>Privasi</b></h4>
                                <form onSubmit={savePrivacy}>
                                    <div className="">
                                        <input type="checkbox" className="me-3" checked={privacyHideProfile} onChange={e => setPrivacyHideProfile(e.target.checked)}/>
                                        <label>Sembunyikan Profil</label>
                                    </div>
                                    <div className="mt-3">
                                        <button className="btn btn-default" disabled={submitLoading} type="submit">
                                            { submitLoading ? <Loading /> : 'Simpan' }
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </>
                    )
                    : null
                ) : null
            }

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Ganti Password</Modal.Title>
                </Modal.Header>
                    <Modal.Body>
                        <ChangePassword />
                    </Modal.Body>
                <Modal.Footer>
                <Button className="btn-default" onClick={handleClose}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Security;