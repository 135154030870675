import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

//bootstrap
import { Container, Row, Col } from "react-bootstrap";

//services

import LeftMenuPanel from "../components/LeftMenuPanel";
import config from "../helpers/config";
import RequestPoint from "../components/points/RequestPoint";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from 'react-router';

const RequestPointScreen = () => {
    const auth = useSelector((state) => state.auth);
    const navigate = useNavigate();

    //change title
    useEffect(() => {
        document.title = config.documentTitle + " - Request";

        window.scrollTo(0, 0);
    }, []);

    return (
        <Container>
            <Row>
                <Col sm={12} md={12} lg={4} className="mb-4">
                    <LeftMenuPanel activeIndex={-1}/>
                </Col>
                <Col sm={12} md={12} lg={8}>
                    <h3 className="mb-3"><b><FontAwesomeIcon style={{
                        cursor: "pointer"
                    }} onClick={() => navigate(-1)} icon={faChevronLeft} className="me-3"/>Pengajuan Poin (KUM)</b></h3>
                    <RequestPoint />
                </Col>
            </Row>
        </Container>
    );
}

export default RequestPointScreen;