import React, { useEffect } from "react";

//bootstrap
import { Container, Row, Col } from "react-bootstrap";

//services

import LeftMenuPanel from "../components/LeftMenuPanel";
import config from "../helpers/config";
import IsExpired from "../components/account/IsExpired";
import RevisionUserLicense from "../components/user_license/RevisionUserLicense";
import { useParams } from "react-router";

const UserLicenseRevisionScreen = () => {
    const params = useParams();

    //change title
    useEffect(() => {
        document.title = config.documentTitle + " - Revisi Lisensi";

        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="default-nav-mt">
            <IsExpired />

            <Container className="mb-5 mt-5">
                <Row>
                    <Col sm={12} md={12} lg={4} className="mb-4">
                        <LeftMenuPanel activeIndex={6}/>
                    </Col>
                    <Col sm={12} md={12} lg={8}>
                        <RevisionUserLicense id={params.id}/>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default UserLicenseRevisionScreen;