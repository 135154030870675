import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { search } from "../../services/event";
import swal from "sweetalert";
import EventBoxMini from "./EventBoxMini";
import Loading from "../Loading";

const EventList = (props) => {
    const condition = props.condition;

    const navigate = useNavigate();

    const auth = useSelector((state) => state.auth);

    const [events, setEvents] = useState(null);
    const [page, setPage] = useState(1);
    const [reset, setReset] = useState(true);
    const [isContainMore, setIsContainMore] = useState(true);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function getEvents(){
            setLoading(true);

            const res = await search(
                auth.token,
                "",
                page,
                condition
            );

            if(res.error === null){
                if(reset){
                    setEvents(res.data);
                }else{
                    //append
                    setEvents(orders => [...orders, ...res.data]);
                }

                if(res.data.length < 1){
                    setIsContainMore(false);
                }else{
                    setIsContainMore(true);
                }
            }else{
                swal('Error', res.error, 'error');
            }

            setLoading(false);
        }

        getEvents();
    }, [auth, page, condition]);

    const loadMore = () => {
        setReset(false);
        //set last id
        setPage(page+1);
    }

    return (
        <div>
            {
                loading && page === 1 ? <Loading /> :
                events !== null ? (
                    <>
                        {
                            events.length > 0 ? (
                                <div className="row">
                                    {
                                        events.map((event, index) => (
                                        <div key={index} className="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                            <EventBoxMini event={event}/>
                                        </div>
                                        ))
                                    }
                                </div>
                            ) : (
                                <div className="text-center">
                                    <img src="/img/tidak_ada_kegiatan.svg" alt="No News" />
                                </div>
                            )
                        }
                       
                        <div className="text-center mt-5">
                            <button disabled={loading} hidden={!isContainMore} onClick={loadMore} className="btn btn-default">
                                { loading ? <Loading /> : "Muat Lebih Banyak" }
                            </button>
                        </div>
                    </>
                ) : null
            }
        </div>
    );
}

export default EventList;