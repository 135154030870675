import { createSlice } from '@reduxjs/toolkit'

export const accountSlice = createSlice({
  name: 'account',
  initialState: {
    name: '',
    avatar_url: '',
    email_address: '',
    refresh_detail: null,
    membership: null,
    is_admin: false,
    is_expired: false,
    is_age_priviledge: false
  },
  reducers: {
    setAccount: (state, action) => {
        state.name = action.payload.first_name+ ' ' + action.payload.last_name;
        state.avatar_url = action.payload.avatar;
        state.email_address = action.payload.email_address;
        state.membership = action.payload.membership;
        state.is_admin = action.payload.is_admin == "1" ? true : false;
        state.is_expired = action.payload.is_expired;
        state.is_age_priviledge = action.payload.is_age_priviledge;
    },
    setRefreshDetail: (state) => {
      state.refresh_detail = new Date();
    }
  },
})

// Action creators are generated for each case reducer function
export const { setAccount, setRefreshDetail } = accountSlice.actions

export default accountSlice.reducer