import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    read
} from './../../services/product';
import swal from 'sweetalert';
import { Button, Col, Row } from "react-bootstrap";
import { intToMoney } from "../../helpers/currency";
import config from "../../helpers/config";
import { addToCart, setShowCartScreen } from './../../redux/slicers/cartSlice';

const ProductDetail = (props) => {
    const { id } = props;

    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);

    const [product, setProduct] = useState(null);
    const [selectedImage, setSelecteImage] = useState(null);
    const [subTotal, setSubTotal] = useState(0);
    const [qty, setQty] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [total, setTotal] = useState(0);

    //change title
    useEffect(() => {
        document.title = config.documentTitle + " - Merchandise";
    }, []);

    useEffect(() => {
        async function getProduct(id){
            const res = await read(auth.token, id);
            if(res.error === null){
                setProduct(res.data);

                setQty(1);

                document.title = config.documentTitle + " - Merchandise - " + res.data.name;

                if(res.data.images.length > 0){
                    setSelecteImage(res.data.images[0]);
                }
            }else{
                swal('Error', res.error, 'error');
            }
        }

        getProduct(id);
    }, [id, auth]);

    useEffect(() => {
        if(product !== null){
            //calculate subtotal
            setSubTotal(qty * product.price_default);
            //calculate discount
            const dicountPrice = product.total_cost - product.price_default;
            setDiscount(qty * dicountPrice);
            //calculate total
            setTotal(qty *  product.total_cost);
        }
    }, [qty]);

    const addItemToCart = () => {
        dispatch(addToCart({
            id : product.id,
            quantity : qty
        }));

        swal('Success', 'Item has been added to cart', 'success');

        setQty(1);

        //open cart
        dispatch(setShowCartScreen(true));
    }

    return (
        <div>
            { product !== null ? (
                <Row>
                    <Col className="mb-3" sm={12} md={12} lg={4}>
                        <img src={selectedImage} alt={product.title} style={{ 
                            width: '100%' 
                        }}/>
                        <div className="d-flex mt-3">
                            { product.images.map(image => (
                                    <div className="image-box-image" style={{
                                        width: '50px',
                                        height: '50px',
                                        overflow: 'hidden',
                                        border: '1px solid #ddd',
                                        borderRadius: '5px',
                                        marginRight: '10px',
                                        cursor: 'pointer',
                                    }} onClick={() => {
                                        setSelecteImage(image);
                                    }}>
                                        <img src={ image } alt="" className="img-fluid"/>
                                    </div>
                            )) }
                        </div>
                    </Col>
                    <Col className="mb-3" sm={12} md={6} lg={5}>
                        <div style={{
                            fontSize: '20px',
                            fontWeight: 'bold',
                        }}>{ product.name }</div>
                        <div style={{
                            fontSize: '12px',
                        }}>{ product.product_category_name }</div>
                        <div style={{
                            fontSize: '30px',
                            fontWeight: 'bold',
                            marginTop: '20px',
                        }}>{ intToMoney(product.price_default) }</div>
                        <div style={{
                            marginTop: '10px',
                            padding: '10px',
                            borderTop: '1px solid #ddd',
                            borderBottom: '1px solid #ddd',
                            color: '#8b8b8b',
                        }}>
                            Detail
                        </div>
                        <div className="mt-3" dangerouslySetInnerHTML={{ __html: product.description }} />
                    </Col>
                    <Col sm={12} md={6} lg={3}>
                        <div className="box p-3">
                            <div className="mb-3">
                                <span style={{
                                }}>
                                    Subtotal
                                </span>
                                <span className="float-end">
                                    { intToMoney(subTotal) }
                                </span>
                            </div>
                            <div className="mb-3" style={{
                                    fontWeight: 'bold',
                                }}>
                                <span>
                                    Total Harga
                                </span>
                                <span className="float-end">
                                    { intToMoney(total) }
                                </span>
                            </div>

                            <div className="mb-3" hidden={product.stock < 1}>
                                <label>Jumlah Barang:</label>
                                <select value={qty} onChange={e => setQty(e.target.value)} className="form-select">
                                    <option value="0">Pilih jumlah</option>
                                    {
                                        Array.from(Array(parseInt(product.stock)).keys()).map(i => (
                                            <option>{ i+1 }</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <Button disabled={product.stock < 1} onClick={() => addItemToCart()} variant="danger" style={{
                                width: '100%',
                            }}>{ product.stock < 1 ? 'Out of Stock' : '+ Keranjang' }</Button>
                        </div>
                    </Col>
                </Row>
            ) : <></> }
        </div>
    );
}

export default ProductDetail;