import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane, faSpinner } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
    submitComment,
    comments
} from './../../services/news';
import swal from "sweetalert";
import { Button } from "react-bootstrap";
import Comment from "./Comment";

const Comments = (props) => {
    const newsId = props.newsId; 
    const account = useSelector((state) => state.account);
    const auth = useSelector((state) => state.auth);

    const [commentList, setCommentList] = useState([]);
    const [totalComment, setTotalComment] = useState("0");
    const [lastId, setLastId] = useState(null);
    const [body, setBody] = useState('');
    const [submitLoading, setSubmitLoading] = useState(false);
    const [loadCommentLoading, setLoadCommentLoading] = useState(false);
    const [isRefresh, setIsRefresh] = useState(null);
    const [isContainMore, setIsContainMore] = useState(true);

    useEffect(() => {
        async function getComments(lastId){
            setLoadCommentLoading(true);

            const res = await comments(auth.token, newsId, lastId);
            if(res.error === null){
                setTotalComment(res.total);

                if(lastId !== null){
                    setCommentList(lastestComments => [...res.data.reverse(), ...lastestComments]);
                }else{
                    setCommentList(res.data.reverse());
                }

                setIsContainMore(res.data.length > 0);
            }else{
                swal("Error!", res.error.message, "error");
            }

            setLoadCommentLoading(false);
        }

        getComments(lastId);
    }, [lastId, isRefresh]);
 
    const submit = async (e) => {
        e.preventDefault();

        let errors = [];

        //validate data
        if(!auth.isAuthenticated){
            errors.push("You must login to comment");
        }
        
        if(errors.length < 1){
            //if body is empty
            if(body === null || body.trim() === ''){
                errors.push("Comment is required");
            }
        }

        if(errors.length > 0){
            swal("Error!", errors.join("\n"), "error");
        }else{
            setSubmitLoading(true);

            //submit comment
            const res = await submitComment(auth.token, newsId, body);

            setSubmitLoading(false);

            if(res.error === null){
                //succes, reset body
                setBody('');

                //get comments
                setLastId(null);
                setIsRefresh(new Date());
            }else{
                swal("Error!", res.error, "error");
            }
        }
    }

    const loadMore = () => {
        //get last id
        setLastId(commentList[0].id);
    }

    return (
        <div>
            <b>{ totalComment } Comments:</b>
            {
                loadCommentLoading ? (
                    <div>
                        <FontAwesomeIcon icon={faSpinner} spin/>
                    </div>
                ) : (
                    <>
                    <div hidden={!isContainMore}>
                        <a href="#!" onClick={() => loadMore()}>Lihat Komentar Sebelumnya</a>
                    </div>
                    <div hidden={isContainMore} className="text-muted text-center">
                        Tidak Ada Komentar
                    </div>
                    </>
                )
            }
            
            {
                commentList.length > 0 ? (
                    <div className="mt-3">
                    {
                    commentList.map((comment, index) => (
                        <Comment comment={comment} key={comment.id}/>
                    ))
                    }
                    </div>
                ) : null
            }
            <div hidden={ !auth.isAuthenticated }>
                <div className="d-flex mt-3">
                    <img src={ account.avatar_url } style={{
                        width: '30px',
                        height: '30px',
                        marginTop: '3px',
                        marginRight: '10px',
                        borderRadius: '50%',
                    }}/>
                    <form onSubmit={submit} className="d-flex" style={{
                        width: '100%',
                    }}>
                        <input value={body} onChange={e => setBody(e.target.value)} disabled={submitLoading} type="text" maxLength={150} className="form-control" style={{
                            borderRadius: '20px',
                        }} placeholder="Maksimal 150 Karakter"/>
                        <Button type="submit" disabled={submitLoading} style={{
                            backgroundColor: 'transparent',
                            border: 'none',
                            color: '#1c1c1c',
                            position: 'relative',
                            marginLeft: '-40px',
                            cursor: 'pointer',
                            marginTop: '3px',
                            marginRight: '10px',
                        }}>
                            { submitLoading ? <FontAwesomeIcon icon={faSpinner} spin/> : <FontAwesomeIcon icon={faPaperPlane}/> }
                        </Button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Comments;