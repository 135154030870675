import { configureStore } from '@reduxjs/toolkit'

import authReducer from '../slicers/authSlice';
import accountSlice from '../slicers/accountSlice';
import portfolioSlice from '../slicers/portfolioSlice';
import userSlice from '../slicers/userSlice';
import notificationSlice from '../slicers/notificationSlice';
import cartSlice from '../slicers/cartSlice';
import informationSlice from '../slicers/informationSlice';

export default configureStore({
  reducer: {
    auth : authReducer,
    account : accountSlice,
    portfolio : portfolioSlice,
    user : userSlice,
    notification : notificationSlice,
    cart : cartSlice,
    information : informationSlice,
  },
})