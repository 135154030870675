import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import config from '../helpers/config';

const MembershipBadge = (props) => {
    const membershipType = props.membershipType;
    const membershipLabel = props.membershipLabel;
    const account = useSelector((state) => state.account);

    const [type, setType] = useState(null);
    const [label, setLabel] = useState("");
    
    useEffect(() => {
        if(typeof membershipType !== 'undefined' && membershipType !== null){
            setType(membershipType);
            setLabel(membershipLabel);
        }else{
            if(account !== null){
                if(typeof account.membership !== 'undefined' && account.membership !== null){
                    setType(account.membership.type);
                    setLabel(account.membership.name);
                }
            }
        }
    }, [membershipType, account]);

    return(
        <>
            {
                type !== null ? (
                    <div className="mt-2">
                        {
                            type === config.membershipType.PUBLIC ? (
                                <span className="break-text membership-badge membership-public">
                                    { label }
                                </span>
                            ) : null
                        }

                        {
                            type === config.membershipType.STUDENT ? (
                                <span className="break-text membership-badge membership-public">
                                    { label }
                                </span>
                            ) : null
                        }

                        {
                            type === config.membershipType.CAB ? (
                                <span className="break-text membership-badge membership-cab">
                                    { label }
                                </span>
                            ) : null
                        }

                        {
                            type === config.membershipType.MEMBER ? (
                                <span className="break-text membership-badge membership-pro">
                                    <FontAwesomeIcon icon={faChevronUp} style={{
                                        color: '#FED362',
                                        marginRight: '10px'
                                    }}/>
                                    { label }
                                </span>
                            ) : null
                        }

                        {
                            type === config.membershipType.MEMBER_PRO ? (
                                <span className="break-text membership-badge membership-pro">
                                    <svg className='me-2' style={{
                                        marginTop: '-5px'
                                    }} width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 13.618C0 13.2393 0.214002 12.893 0.552786 12.7236L7.55279 9.22361C7.83431 9.08284 8.16569 9.08284 8.44721 9.22361L15.4472 12.7236C15.786 12.893 16 13.2393 16 13.618V16.382C16 17.1253 15.2177 17.6088 14.5528 17.2764L8.44721 14.2236C8.16569 14.0828 7.83431 14.0828 7.55279 14.2236L1.44721 17.2764C0.782312 17.6088 0 17.1253 0 16.382V13.618Z" fill="#FED362"/>
                                        <path d="M0 4.61803C0 4.23926 0.214002 3.893 0.552786 3.72361L7.55279 0.223607C7.83431 0.0828431 8.16569 0.0828431 8.44721 0.223607L15.4472 3.72361C15.786 3.893 16 4.23926 16 4.61803V7.38197C16 8.12535 15.2177 8.60884 14.5528 8.27639L8.44721 5.22361C8.16569 5.08284 7.83431 5.08284 7.55279 5.22361L1.44721 8.27639C0.782312 8.60884 0 8.12535 0 7.38197V4.61803Z" fill="#FED362"/>
                                    </svg>

                                    { label }
                                </span>
                            ) : null
                        }
                    </div>
                ) : null
            }
        </>
    );
}

export default MembershipBadge;