import React, { useState ,useEffect } from "react";
import { Container } from "react-bootstrap";
import PageNav from "../../components/PageNav";
import {
    read
} from './../../services/page';
import swal from "sweetalert";
import HonorariumArsitekCalculator from "../../components/HonorariumArsitekCalculator";

const HonorariumArsitekScreen = () => {
    const [content, setContent] = useState('');

    useEffect(() => {
        async function getContent(slug){
            const res = await read(slug);
            if(res.error === null){
                setContent(res.data);
            }else{
                swal('Error', res.error, 'error')
            }
        }

        getContent('honorarium-arsitek');
    }, []);

    return (
        <div className="default-nav-mt">
            <Container>
                <PageNav title="Honorarium Arsitek" links={
                    [
                        {
                            label: "Informasi",
                            url: "/"
                        },
                        {
                            label: "Honorarium Arsitek",
                            url: "/honorarium-arsitek"
                        }
                    ]
                }/>
            
                <br/>
                {/* <h4>Kalkulator</h4>
                <HonorariumArsitekCalculator /> */}

                <div className="mt-5" dangerouslySetInnerHTML={{__html: content.body}}></div>

            </Container>
        </div>
    );
}

export default HonorariumArsitekScreen;