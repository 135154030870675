const config = {
    documentTitle : 'IAI',
    storageKey : {
        token : 'iai_token',
        cart : 'iai_cart',
    },
    membershipType : {
        PUBLIC : 'PUBLIC',
        MEMBER : 'MEMBER',
        MEMBER_PRO : 'MEMBER_PRO',
        CAB : 'CAB',
        STUDENT : 'STUDENT',
        MEMBER_NON_JAKARTA : 'MEMBER_NON_JAKARTA',
    },
    membershipOrderType : {
        NEW: '1',
        EXTENSION : '2'
    },
    membershipApplicationStatus : {
        PENDING : '0',
        PROCESSING : '1',
        APPROVED : '2',
        REVISE : '3',
        FAILED : '-1',
    },
    licenseType : {
        NEW : '1',
        CONVERSION : '2',
        RENEW : '3',
    },
    licenseStatus : {
        PENDING : '0',
        WAITING_FOR_PAYMENT : '1',
        PAID : '2',
        PAYMENT_FAILED : '3',
        PROCESSED : '4',
        REVISION : '5',
        APPROVED : '6',
        COMPLETED : '7',
        DRAFT : '8',
        CANCELLED : '-1',
    },
    mediaPartner : {
        PARTNERSHIP: '0',
        SPONSORSHIP : '1',
        SPONSORSHIP_LARGE : '2'
    },
}

export default config;