import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useParams } from "react-router";
import config from "../helpers/config";
import { attend } from "../services/event";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import Loading from "../components/Loading";
import EventDetail from "../components/events/EventDetail";
import moment from "moment";

const AttendLinkScreen = () => {
    const params = useParams();

    const auth = useSelector((state) => state.auth);
    const token = params.token;

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);

    //change title
    useEffect(() => {
        document.title = config.documentTitle + " - Attendace";

        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        async function attendEvent(token, qrToken){
            setLoading(true);

            const res = await attend(token, qrToken);
            setData(res.data);
            if(res.error === null){
                swal("Success", "Anda telah berhasil menghadiri Kegiatan", "success");
            }else{
                swal("Oops!", res.error, "error");
            }

            setLoading(false);
        }

        attendEvent(auth.token, token);
    }, [auth.token, token]);

    return (
        <div className="default-nav-mt">
            <Container>
                <Row>
                    <Col sm={12} md={12} lg={12}>
                        {
                            loading ? <Loading /> : (
                                data === null ? null :
                                <div>
                                    {
                                        data.event_agenda === null ? null : (
                                            <div className="box p-3 mb-3">
                                                <h3><b>Informasi Kegiatan</b></h3>
                                                <div>
                                                    Hari ke: {data.event_agenda.day}
                                                </div>
                                                <div>
                                                    Tanggal: {moment(data.event_agenda.date).format("DD MMMM YYYY")}
                                                </div>
                                                <div>
                                                    Jam: {data.event_agenda.start_time+' - '+data.event_agenda.end_time}
                                                </div>
                                                <div hidden={data.visitor === null || data.visitor === '' || typeof data.visitor === 'undefined'}>
                                                    Jumlah Hadir: {data.visitor}
                                                </div>
                                            </div>
                                        ) 
                                    }
                                    {
                                        data.event === null ? null : (
                                            <EventDetail url={data.event.url}/>
                                        ) 
                                    }
                                </div>
                            )
                        }
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default AttendLinkScreen;