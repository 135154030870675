export const renderStatus = (status) => {
    if(status === '0') {
        return <span className="badge bg-secondary">Pending</span>;
    }
    else if(status === '1') {
        return <span className="badge bg-warning">Sedang Diproses</span>;
    }
    else if(status === '2') {
        return <span className="badge bg-success">Disetujui</span>;
    }
    else if(status === '3') {
        return <span className="badge bg-danger">Perlu Revisi</span>;
    }
    else if(status === '-1') {
        return <span className="badge bg-danger">Gagal</span>;
    }
    else{
        return <span className="badge bg-secondary">{status}</span>;
    }
}