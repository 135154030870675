import moment from "moment";
import React from "react";
import { useDispatch } from "react-redux";
import {
    showPortfolioDetail
} from './../../redux/slicers/portfolioSlice';

const PortfolioBox = (props) => {
    const portfolio = props.portfolio;

    const dispatch = useDispatch();

    const showDetail = (id) => {
        dispatch(showPortfolioDetail(id));
    }

    return(
        <div className="box image-box mt-3" style={{
            padding: '0px',
            marginBottom:'10px',
            overflow: 'hidden',
        }}
        >
            <div hidden={ portfolio.cover === null || portfolio.cover === "" } className="image-box-image" style={{
                height: '150px',
                backgroundColor: '#1c1c1c',
            }}>
                <img src={ portfolio.cover } alt="" className="img-fluid"/>
            </div>
            <div className="p-3">
                <div style={{
                    fontSize: '12pt',
                    fontWeight: 'bold',
                    cursor: 'pointer'
                }} onClick={() => showDetail(portfolio.id) }>{ portfolio.title }</div>
                <div style={{
                    fontSize: '10pt',
                    textAlign: 'right',
                }}>
                    { moment(portfolio.created_at).fromNow() }
                </div>
            </div>
            { props.children }
        </div>
    );
}

export default PortfolioBox;