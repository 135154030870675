export const order = async (
    token, 
    portfolios, 
    license_id, 
    license_type, 
    payment_method,
    data_recommendation_letter_path,
    data_previous_license_path,
    data_stra_path,
    data_rule_resume_path,
    data_certificate_path,
    data_portfolio_detail_path,
    status,
    projects
    ) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}license/order`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            portfolios: portfolios,
            license_id: license_id,
            license_type: license_type,
            payment_method: payment_method,
            data_recommendation_letter_path: data_recommendation_letter_path,
            data_previous_license_path: data_previous_license_path,
            data_stra_path: data_stra_path,
            data_rule_resume_path: data_rule_resume_path,
            data_certificate_path: data_certificate_path,
            data_portfolio_detail_path: data_portfolio_detail_path,
            status: status,
            projects: JSON.stringify(projects)
        })
    });
    const json = await response.json();
    return json;
}

export const revision = async (
    token, 
    id, 
    portfolios, 
    data_recommendation_letter_path,
    data_previous_license_path,
    data_stra_path,
    data_rule_resume_path,
    data_certificate_path,
    data_portfolio_detail_path,
    status,
    projects
    ) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}license/revision`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            id: id,
            portfolios: portfolios,
            data_recommendation_letter_path: data_recommendation_letter_path,
            data_previous_license_path: data_previous_license_path,
            data_stra_path: data_stra_path,
            data_rule_resume_path: data_rule_resume_path,
            data_certificate_path: data_certificate_path,
            data_portfolio_detail_path: data_portfolio_detail_path,
            status: status,
            projects: JSON.stringify(projects)
        })
    });
    const json = await response.json();
    return json;
}

export const search = async (token) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}license/search`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        }
    });
    const json = await response.json();
    return json;
}

export const cancel = async (token, user_license_id) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}license/cancel`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            user_license_id: user_license_id
        })
    });
    const json = await response.json();
    return json;
}

export const uploadPayment = async (token, user_license_id, image) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}license/upload_payment`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            user_license_id: user_license_id,
            image: image,
        })
    });
    const json = await response.json();
    return json;
}