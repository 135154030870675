import React, { useEffect, useState } from "react";
import { Container, Row, Col, Accordion } from "react-bootstrap";
import { useParams } from "react-router";
import Loading from "../components/Loading";
import config from "../helpers/config";
import swal from "sweetalert";
import { all } from "../services/faq";

const FAQScreen = () => {
    const params = useParams();

    const slug = params.slug;

    const [faq, setFaq] = useState(null);
    const [faqFiltered, setFaqFiltered] = useState(null);
    const [category, setCategory] = useState(null);
    const [loading, setLoading] = useState(false);
    const [keyword, setKeyword] = useState('');

    //change title
    useEffect(() => {
        document.title = config.documentTitle + " - FAQ";

        window.scrollTo(0, 0);
    }, []);

    //get detail
    useEffect(() => {
        async function getFaqs(slug){
            setLoading(true);

            const res = await all(slug);
            if(res.error === null){
                setFaq(res.data);
                setFaqFiltered(res.data);
                setCategory(res.category);
            }else{
                swal("Error", res.error, "error");
            }

            setLoading(false);
        }

        getFaqs(slug);
    }, [slug]);

    useEffect(() => {
        if(keyword !== ''){
            const filtered = faq.filter(item => {
                return item.question.toLowerCase().includes(keyword.toLowerCase()) || item.answer.toLowerCase().includes(keyword.toLowerCase());
            }).map(item => {
                return item;
            }).sort((a, b) => {
                return a.question.localeCompare(b.question);
            }).map(item => {
                return item;
            }).reverse();

            setFaqFiltered(filtered);
        }else{
            setFaqFiltered(faq);
        }
    }, [keyword]);

    return (
        <div className="default-nav-mt">
            <Container>
                <Row>
                    <Col sm={12} md={12} lg={12} className="mt-5">
                        {
                            loading ? <Loading /> : (
                                <>
                                {
                                    category !== null ? (
                                        <h4>{ category.name }</h4>
                                    ) : null
                                }

                                <input type="text" value={keyword} onChange={e => setKeyword(e.target.value)} className="form-control bg-white mb-3" placeholder="Cari Kata Kunci..."/>

                                {
                                    faqFiltered !== null ? (
                                        <>
                                        <Accordion defaultActiveKey="0">
                                        {
                                            faqFiltered.map((item, index) => (
                                                <>
                                                <Accordion.Item eventKey={index}>
                                                    <Accordion.Header>{ item.question }</Accordion.Header>
                                                    <Accordion.Body>
                                                        { <div dangerouslySetInnerHTML={{ __html: item.answer }} /> }
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                </>
                                            ))
                                        }
                                        </Accordion>
                                        </>
                                    ) : null
                                }
                                </>
                            )
                        }
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default FAQScreen;