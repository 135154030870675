import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import LeftMenuPanel from "../components/LeftMenuPanel";
import config from "../helpers/config";
import EventOrders from './../components/transaction/EventOrders';

const EventTransactionScreen = () => {
    //change title
    useEffect(() => {
        document.title = config.documentTitle + " - Pembelian Tiket Event";

        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="default-nav-mt">
            <Container>
                <Row>
                    <Col sm={12} md={12} lg={4} className="mb-4">
                        <LeftMenuPanel activeIndex={1}/>
                    </Col>
                    <Col sm={12} md={12} lg={8}>
                        <h3 className="mb-3"><b>Daftar Transaksi Pembelian Tiket</b></h3>
                        <EventOrders />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default EventTransactionScreen;