import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { read, voting } from '../../services/vote';
import swal from 'sweetalert';
import moment from 'moment';

const Vote = (props) => {
    const id = props.id;

    const auth = useSelector((state) => state.auth);
    const navigate = useNavigate();
    // const dispatch = useDispatch();

    const [data, setDate] = useState(null);
    const [votedCandidates, setVotedCandidates] = useState([]);
    const [candidates, setCandidates] = useState([]);

    const [triggerReloadTimestamp, setTriggerReloadTimestamp] = useState(null);

    useEffect(() => {
        async function getDetail(id) {
            const res = await read(auth.token, id);
            if (res.error == null) {
                setDate(res.data);
                setCandidates(res.data.candidates);
                setVotedCandidates(res.data.voted_candidates);
            } else {
                swal("Gagal", res.error, "error");

                navigate('/');
            }
        }

        getDetail(id);
    }, [auth, id, navigate, triggerReloadTimestamp]);

    // const viewDetail = (id) => {
    //     dispatch(showUserDetail(id));
    // }

    const votingUser = async (user) => {
        //show confirm
        var confirm = await swal({
            title: "Konfirmasi",
            text: "Apakah anda yakin ingin memilih " + (user.first_name + ' ' + user.last_name) + "?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        });

        if (confirm) {
            const res = await voting(auth.token, id, user.id);
            if (res.error === null) {
                swal("Berhasil", "Pemenang telah dipilih", "success");

                setTriggerReloadTimestamp(new Date().getTime());
            } else {
                swal("Gagal", res.error, "error");
            }
        }
    }

    const candidatesNormalized = useMemo(() => {
        console.log('debug', candidates, votedCandidates)

        // add flag is_voted if user already voted
        return candidates.map(candidate => {
            const isVoted = votedCandidates.find(votedCandidate => votedCandidate.user_id === candidate.user_id) !== undefined;
            return {
                ...candidate,
                is_voted: isVoted
            }
        });
    }, [candidates, votedCandidates])

    return (
        <div>
            {
                data != null ? (
                    <div className='text-center'>
                        <h4>{data.name}</h4>
                        <div className='box p-3'>
                            <div>
                                <b>Waktu <i>Voting</i>:</b>
                            </div>
                            {
                                moment(data.start_datetime).format('HH:mm, DD MMMM YYYY') + ' - ' + moment(data.end_datetime).format('HH:mm, DD MMMM YYYY')
                            }
                        </div>
                        <div className='box p-3 mt-3'>
                            <p style={{
                                fontWeight: 'bold'
                            }}>{data.description}</p>
                            <div className='mb-2' dangerouslySetInnerHTML={{ __html: data.body }} />
                        </div>
                        <div className='box p-3 mt-3'>
                            <div>
                                Maksimum Pilihan: <b>{data.max_choice}</b>
                                <br />
                                Kuota Tersisa: <b>{data.max_choice - votedCandidates.length}</b>
                            </div>
                        </div>

                        <br />
                        <h1>Kandidat</h1>

                        {
                            candidatesNormalized.map((candidate, index) => (
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    border: '1px solid #ccc',
                                    marginBottom: 10,
                                    background: '#f9f9f9',
                                    padding: 10
                                }}>
                                    <div style={{
                                        width: '200px'
                                    }}>
                                        {candidate.iai_id === null || candidate.iai_id === '' ? '-' : candidate.iai_id}
                                    </div>
                                    <div>
                                        {candidate.first_name + ' ' + candidate.last_name}
                                    </div>
                                    <div>
                                        <button disabled={candidate.is_voted} onClick={() => candidate.is_voted ? null : votingUser(candidate)} className='btn btn-sm btn-default'>Pilih</button>
                                    </div>
                                </div>

                            ))
                        }
                    </div>
                ) : null
            }
        </div >
    );
}

export default Vote;