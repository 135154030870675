import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
    attendance
} from './../../services/event';
import swal from 'sweetalert';
import { Col, Row, Nav, Button} from "react-bootstrap";
import moment from "moment";
import { useNavigate } from "react-router";

const Certificates = () => {
    const navigate = useNavigate();

    const auth = useSelector((state) => state.auth);

    const [orders, setOrders] = useState(null);
    const [status, setStatus] = useState(null);
    const [keyword, setKeyword] = useState("");
    const [page, setPage] = useState(1);
    const [reset, setReset] = useState(true);
    const [isContainMore, setIsContainMore] = useState(true);

    useEffect(() => {
        async function getOrders(){
            const res = await attendance(
                auth.token,
                status,
                keyword,
                page
            );

            if(res.error === null){
                if(reset){
                    setOrders(res.data);
                }else{
                    //append
                    setOrders(orders => [...orders, ...res.data]);
                }

                if(res.data.length < 1){
                    setIsContainMore(false);
                }else{
                    setIsContainMore(true);
                }
            }else{
                swal('Error', res.error, 'error');
            }
        }

        getOrders();
    }, [auth, page, keyword, reset, status]);

    const loadMore = () => {
        setReset(false);
        //set last id
        setPage(page+1);
    }

    const filterStatus = (newStatus) => {
        setReset(true);
        setPage(1);
        setKeyword("");
        setStatus(newStatus);
    }

    return (
        <div>
            <h3>Certificates</h3>
            {
                orders !== null ?
                    <>
                    {
                    orders.map((order, index) => (
                        <div className='box p-3 mb-3' key={index}>
                            <Row>
                                <Col>
                                    { order.event_title }
                                </Col>
                                <Col>
                                    KUM: { order.kum }
                                </Col>
                                <Col>
                                    { moment(order.created_at).format('HH:mm, DD MMMM YYYY') }
                                </Col>
                                <Col>
                                    { order.event_title }
                                </Col>
                                <Col>
                                    <a href={ order.certificate_path } target="_blank">Download Sertifikat</a>
                                </Col>
                            </Row>
                        </div>
                    ))
                    }
                    <div className="text-center">
                        <Button hidden={!isContainMore} onClick={loadMore} variant="danger">Load More</Button>
                    </div>
                    </>
                : null
            }
        </div>
    );
}

export default Certificates;