import React, { useEffect, useState } from 'react';
import {
    all,
    categories as getCategoriesAPI
} from './../../services/downloads';
import swal from 'sweetalert';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen, faChevronRight, faFileAlt } from '@fortawesome/free-solid-svg-icons'
import { useParams } from 'react-router';
import moment from 'moment';
import Loading from '../Loading';

const DownloadCategories = () => {
    const params = useParams();
    const id = params.id;

    const [categoriesCache, setCategoriesCache] = useState(null);
    const [categories, setCategories] = useState(null);
    const [files, setFiles] = useState(null);
    const [selectedCategoryId, setSelectedCategoryId] = useState(id);
    const [resetCategories, setResetCategories] = useState(true);
    const [categoriesPage, setCategoriesPage] = useState(1);
    const [categoriesKeyword, setCategoriesKeyword] = useState('');
    const [reset, setReset] = useState(true);
    const [page, setPage] = useState(1);
    const [keyword, setKeyword] = useState('');
    const [categoryName, setCategoryName] = useState('');
    const [isContainMoreCategories, setIsContainMoreCategories] = useState(true);
    const [isContainMore, setIsContainMore] = useState(true);
    const [loadingCategories, setLoadingCategories] = useState(false);
    const [loadingMoreCategories, setLoadingMoreCategories] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);

    useEffect(() => {
        async function getCategories(){
            if(categoriesCache === null){
                if(resetCategories){
                    setLoadingCategories(true);
                }else{
                    setLoadingMoreCategories(true);
                }

                const res = await getCategoriesAPI(categoriesPage, categoriesKeyword);
                if(res.error === null){
                    setFiles(null);

                    if(resetCategories){
                        setCategories(res.data);
                        setCategoriesCache(res.data);
                    }else{
                        //append
                        console.log(...categories);
                        const newDataList = [...categories, ...res.data];
                        setCategories(newDataList);
                        setCategoriesCache(newDataList);
                    }

                    if(res.data.length < 1){
                        setIsContainMoreCategories(false);
                    }else{
                        setIsContainMoreCategories(true);
                    }
                }else{
                    swal(
                        'Error',
                        res.error,
                        'error'
                    );
                }

                if(resetCategories){
                    setLoadingCategories(false);
                }else{
                    setLoadingMoreCategories(false);
                }
            }else{
                setCategories(categoriesCache);
            }
        }

        async function getAllByCategoryId(id){
            if(reset){
                setLoading(true);
            }else{
                setLoadingMore(true);
            }

            const res = await all(id, page, keyword);
            if(res.error === null){
                setCategories(null);
                setCategoryName(res.category.name);
                setSelectedCategoryId(res.category.id);

                if(reset){
                    setFiles(res.data);
                }else{
                    //append
                    const newDataList = [...files, ...res.data];
                    setFiles(newDataList);
                }

                if(res.data.length < 1){
                    setIsContainMore(false);
                }else{
                    setIsContainMore(true);
                }
            }else{
                swal(
                    'Error',
                    res.error,
                    'error'
                );
            }

            if(reset){
                setLoading(false);
            }else{
                setLoadingMore(false);
            }
        }
        
        if(selectedCategoryId !== null && typeof selectedCategoryId !== 'undefined'){
            getAllByCategoryId(selectedCategoryId);
        }else{
            getCategories();
        }
    }, [selectedCategoryId, categoriesPage, page, keyword, categoriesKeyword]);

    const selectCategory = (category) => {
        //set browser url
        window.history.pushState('page2', 'Title', '/downloads/' + category.id);

        setCategoryName(category.name);
        setSelectedCategoryId(category.id);
    }

    const resetCategory = () => {
        //set browser url
        window.history.pushState('page2', 'Title', '/downloads');

        setSelectedCategoryId(null);
        setCategoryName('');
        setFiles(null);

        //reset files
        setReset(true);
        setPage(1);
    }

    const loadMoreCategories = () => {
        setResetCategories(false);
        setCategoriesCache(null);
        setCategoriesPage(categoriesPage+1);
    }

    const loadMore = () => {
        setReset(false);
        setPage(page+1);
    }

    return(
        <div>
            <h4>
                <a style={{
                    cursor: 'pointer',
                }} onClick={() => {
                   resetCategory();
                }}>Downloads</a> 
                {
                    categoryName !== '' ? (
                        <span> <FontAwesomeIcon icon={faChevronRight}/> { categoryName }</span>
                    ) : null
                }
            </h4>
            {
                loadingCategories ? <Loading /> :
                categories !== null ? (
                    <>
                        <Row>
                        {
                        categories.map((category, index) => (
                            <Col key={index} xs={6} sm={6} md={4} lg={3}>
                                <div onClick={() => selectCategory(category)} className="box p-4 text-center mb-4" style={{
                                    cursor: 'pointer',
                                }}>
                                    <FontAwesomeIcon icon={faFolderOpen} style={{
                                        fontSize: '40pt',
                                    }}/>

                                    <div className="mt-3">
                                        {category.name}
                                        <div className='text-muted'>
                                            { moment(category.created_at).format('DD MMMM YYYY') }
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        ))
                        }
                        </Row>
                        <div className='text-center'>
                            <button disabled={loadingMoreCategories} hidden={!isContainMoreCategories} onClick={() => loadMoreCategories()} className='btn btn-default'>
                                { loadingMoreCategories ? <Loading /> : 'Muat Lebih Banyak' }
                            </button>
                        </div>
                    </>
                ) : null
            }   

            {/* files */}
            {
                files !== null ? (
                    <>
                        <Row>
                        {
                            files.map((file, index) => (
                                <Col key={index} xs={6} sm={6} md={4} lg={3}>
                                    <div onClick={() => {
                                        //open link in new tab
                                        window.open(file.path);
                                    }} className="box p-4 text-center mb-4" style={{
                                        cursor: 'pointer',
                                    }}>
                                        <FontAwesomeIcon icon={faFileAlt} style={{
                                            fontSize: '40pt',
                                        }}/>

                                        <div className="mt-3">
                                            {file.title}
                                            <div className='text-muted'>
                                            { moment(file.created_at).format('DD MMMM YYYY') }
                                        </div>
                                        </div>
                                    </div>
                                </Col>
                            ))
                        }
                        </Row>

                        <div className='text-center'>
                            <button disabled={loadingMore} hidden={!isContainMore} onClick={() => loadMore()} className='btn btn-default'>
                                { loadingMore ? <Loading /> : 'Muat Lebih Banyak' }
                            </button>
                        </div>
                    </>
                ) : null
            }
            {/* files */}
        </div>
    );
}

export default DownloadCategories;