import React, { useEffect, useState } from "react";
import { get } from "../../services/bank";
import swal from "sweetalert";
import { Col, Row } from "react-bootstrap";

const BankList = () => {
    const [banks, setBanks] = useState(null);

    useEffect(() => {
        async function getBankList(){
            const res = await get();
            if(res.error == null){
                setBanks(res.data);
            }else{
                swal("Error", res.error, "error");
            }
        }

        getBankList();
    }, []);

    return (
        <>
        {
            banks !== null ? 
                <>
                <div className="mb-2">
                    <b>Daftar Bank:</b>
                </div>
                {
                banks.map((bank, index) => (
                    <div className="box p-3 mb-3" key={index}>
                        <Row>
                            <Col>
                                <div style={{
                                    fontSize: '12pt',
                                }}>
                                    <b>Nama Bank:</b><br/>
                                    { bank.name }
                                </div>
                            </Col>
                            <Col className="text-center">
                                <div style={{
                                    fontSize: '12pt',
                                }}>
                                    <b>atas nama:</b><br/>{ bank.account_holder }
                                </div>
                            </Col>
                            <Col className="text-end">
                                <div style={{
                                    fontSize: '12pt',
                                }}>
                                    <b>Nomor Rekening:</b><br/>{ bank.account_number }
                                </div>
                            </Col>
                        </Row>
                    </div>
                ))
                }
                </>
            : null
        }
        </>
    );
}

export default BankList;