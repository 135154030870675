import React, { useState ,useEffect } from "react";
import { Container } from "react-bootstrap";
import PageNav from "../components/PageNav";
import {
    read
} from './../services/page';
import swal from "sweetalert";
import { useParams } from "react-router";
import Loading from "../components/Loading";
import { Link } from "react-router-dom";

const ContentPageScreen = () => {
    const params = useParams();
    const url = params.url;

    const [content, setContent] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function getContent(slug){
            setLoading(true);

            const res = await read(slug);
            if(res.error === null){
                setContent(res.data);
            }else{
                swal('Error', res.error, 'error')
            }

            setLoading(false);
        }

        getContent(url);
    }, [url]);

    return (
        <div className="default-nav-mt">
            {
                loading ? <Loading /> : 
                content === null ? (
                    <Container>
                        <div className="text-center">
                            <h1>404</h1>
                            <h2>Page Not Found</h2>
                            <Link to="/">Back To Home</Link>
                        </div>
                    </Container>
                ) : (
                    <Container>
                        <PageNav title={content.title} links={
                            [
                                {
                                    label: "Beranda",
                                    url: "/"
                                },
                                {
                                    label: content.title,
                                    url: content.url
                                }
                            ]
                        }/>
                    
                        <div className="mt-5" dangerouslySetInnerHTML={{__html: content.body}}></div>
                    </Container>
                )
            }
        </div>
    );
}

export default ContentPageScreen;