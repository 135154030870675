import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

//bootstrap
import { Container, Row, Col } from "react-bootstrap";

//services

import LeftMenuPanel from "../components/LeftMenuPanel";
import config from "../helpers/config";
import PointBox from "../components/users/PointBox";
import NotificationList from "../components/notifications/NotificationList";
import MembershipInfo from "../components/membership/MembershipInfo";

const DashboardScreen = () => {
    const auth = useSelector((state) => state.auth);

    //change title
    useEffect(() => {
        document.title = config.documentTitle + " - Dashboard";

        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="default-nav-mt">
            <Container>
                <Row>
                    <Col sm={12} md={12} lg={4} className="mb-4">
                        <LeftMenuPanel activeIndex={-1}/>
                    </Col>
                    <Col sm={12} md={12} lg={8}>
                        <div className="box p-3">
                            <MembershipInfo showButton={true}/>
                        </div>
                        <div className="box p-3 mt-3">
                            <h3>Notifikasi</h3>
                            <NotificationList />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default DashboardScreen;