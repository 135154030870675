import React from "react";

const PeopleBox = (props) => {
    const people = props.people;
    return (
        <div className="box image-box p-3 m-3 text-center">
            <div className="image-box-image" style={{
                borderRadius: '50%',
                height: '100px',
                width: '100px',
                margin: '0 auto',
            }}>
                <img src={ people.photo } alt="" className="img-fluid"/>
            </div>
            <div className="mt-4">
                { people.name }
            </div>
            <div className="mt-2">
                { people.description }
            </div>
        </div>
    );
}

export default PeopleBox;