export const create = async (
	token,
	ska_no,
	ska_category,
	issued_date,
	scan_photo,
) => {
	const response = await fetch(
		`${process.env.REACT_APP_API_URL}ska/create`,
		{
			crossDomain: true,
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: token,
			},
			body: JSON.stringify({
				ska_no: ska_no,
				ska_category: ska_category,
				issued_date: issued_date,
				scan_photo: scan_photo,
			}),
		}
	);
	const json = await response.json();
	return json;
};

export const update = async (
	token,
	id,
	ska_no,
	ska_category,
	issued_date,
	scan_photo,
) => {
	const response = await fetch(
		`${process.env.REACT_APP_API_URL}ska/update`,
		{
			crossDomain: true,
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: token,
			},
			body: JSON.stringify({
				ska_id: id,
				ska_no: ska_no,
				ska_category: ska_category,
				issued_date: issued_date,
				scan_photo: scan_photo,
			}),
		}
	);
	const json = await response.json();
	return json;
};

export const remove = async (token, ska_id) => {
	const response = await fetch(
		`${process.env.REACT_APP_API_URL}ska/delete`,
		{
			crossDomain: true,
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: token,
			},
			body: JSON.stringify({
				ska_id: ska_id,
			}),
		}
	);
	const json = await response.json();
	return json;
};

export const read = async (id) => {
	const response = await fetch(
		`${process.env.REACT_APP_API_URL}ska/read`,
		{
			crossDomain: true,
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				id: id,
			}),
		}
	);
	const json = await response.json();
	return json;
};

export const all = async (token) => {
	const response = await fetch(
		`${process.env.REACT_APP_API_URL}ska/all`,
		{
			crossDomain: true,
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: token,
			},
		}
	);
	const json = await response.json();
	return json;
};
