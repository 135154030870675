import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getByIds } from "../../services/product";
import swal from "sweetalert";
import CartItem from "./CartItem";
import { Button, Col, Row } from "react-bootstrap";
import {
    setTotalCost, setShowCartScreen, setTotalPrice
} from "../../redux/slicers/cartSlice";
import { intToMoney } from "../../helpers/currency";
import { useNavigate } from "react-router";

const CartList = (props) => {
    const isCheckout = props.isCheckout;

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const cart = useSelector((state) => state.cart);

    const [items, setItems] = useState([]);

    useEffect(() => {
        async function getProductsByIds(){
            //array to separate comma
            let ids = '';
            cart.cart.forEach((item, index) => {
                if(index === 0){
                    ids += item.id;
                }else{
                    ids += `,${item.id}`;
                }
            })

            //get products
            if(ids !== '' && ids !== null){
                const res = await getByIds(auth.token, ids.toString());
                if(res.error === null){
                    let results = [];
                    let totalCost = 0;
                    let totalPrice = 0;
                    res.data.map(product => {
                        //find product in cart
                        const cartItem = cart.cart.find(item => item.id === product.id);
                        results.push({
                            ...product,
                            quantity: cartItem.quantity
                        });

                        //calculate total
                        totalCost += product.total_cost * cartItem.quantity;
                        totalPrice += parseInt(product.price_default) * cartItem.quantity;
                    })
                    setItems(results);

                    //set total cost
                    dispatch(setTotalCost(totalCost));
                    dispatch(setTotalPrice(totalPrice));
                }else{
                    swal('Error', res.error, 'error');
                }
            }else{
                setItems([]);
            }
        }

        if(cart.showCartScreen || isCheckout){
            getProductsByIds();
        }
    }, [auth, cart]);

    return (
        <>
            {
                items.length > 0 ? (
                    <div>
                        {
                            items.map((product, index) => (
                                <CartItem key={index} item={product} isCheckout={isCheckout}/>
                            ))
                        }

                        <div hidden={items.length < 1} className="p-3">
                            <Row className="mb-3" style={{
                                    fontWeight: 'bold',
                                    fontSize: '14pt'
                                }}>
                                <Col xs={6}>Total Cost</Col>
                                <Col xs={6} className="text-end">{ intToMoney(cart.totalCost) }</Col>
                            </Row>
                            <Button hidden={isCheckout} onClick={() => {
                                dispatch(setShowCartScreen(false));
                                navigate('/checkout');
                            }} variant="danger" style={{
                                width: '100%',
                            }}>Checkout</Button>
                        </div>
                    </div>
                ) : (
                    <div className="text-center mt-5">
                        <img src="/img/empty-cart.svg" alt="empty cart" />
                    </div>
                )
            }
            
        </>
    );
}

export default CartList;