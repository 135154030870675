import React from "react";
import DefaultNavbar from "./DefaultNavbar";
import Footer from "./Footer";

const Layout = (props) => {
    const children = props.children;
    const userNavbar = typeof props.userNavbar !== 'undefined' ? props.userNavbar : false;

    return (
        <>
            <DefaultNavbar userNavbar={userNavbar}/>

            <div className='d-none d-md-none d-xl-block d-xxl-block'></div>

            <div className="banner-header"></div>

            {
                children
            }

            {
                userNavbar ? (
                    <div style={{
                        height: '100px'
                    }}></div>
                ) : <Footer/>
            }
        </>
    );
}

export default Layout;