import React, { useState ,useEffect } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
    myList
} from './../../services/portfolio';
import {
    search,
    order,
    revision
} from './../../services/license';
import swal from "sweetalert";
import { useNavigate } from "react-router";
import {
    showPortfolioDetail
} from './../../redux/slicers/portfolioSlice';
import { intToMoney } from "../../helpers/currency";
import PortfolioBox from "../portfolios/PortfolioBox";
import config from "../../helpers/config";
import Dropzone from 'react-dropzone';
import Loading from "../Loading";
import { licenseOrder } from "../../services/transaction";
import { renderLicenseType } from "../../helpers/license";

const RevisionUserLicense = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const id = props.id;
    const auth = useSelector((state) => state.auth);

    const [seletedPortfolios, setSelectedPortfolios] = useState([]);
    const [licenseType, setLicenseType] = useState(null);
    const [data, setData] = useState(null);
    const [status, setStatus] = useState(null);
    const [portfolios, setPortfolios] = useState(null);
    const [submitLoading, setSubmitLoading] = useState(false);

    const [projects, setProjects] = useState([]);

    const [recommendationFiles, setRecommendationFiles] = useState([]);
    const [dataRecommendationLetterPath, setDataRecommendationLetterPath] = useState('');
    const [uploadedDataRecommendationLetterPath, setUploadedDataRecommendationLetterPath] = useState('');

    const [previousLicenseFiles, setPreviousLicenseFiles] = useState([]);
    const [dataPreviousLicensePath, setDataPreviousLicensePath] = useState('');
    const [uploadedDataPreviousLicensePath, setUploadedDataPreviousLicensePath] = useState('');

    const [straFiles, setStraFiles] = useState([]);
    const [dataStraPath, setDataStraPath] = useState('');
    const [uploadedDataStraPath, setUploadedDataStraPath] = useState('');

    const [resumeFiles, setResumeFiles] = useState([]);
    const [dataRuleResumePath, setDataRuleResumePath] = useState('');
    const [uploadedDataRuleResumePath, setUploadedDataRuleResumePath] = useState('');

    const [certificateFiles, setCertificateFiles] = useState([]);
    const [dataCertificatePath, setDataCertificatePath] = useState('');
    const [uploadedDataCertificatePath, setUploadedDataCertificatePath] = useState('');

    const [portfolioDetailFiles, setPortfolioDetailFiles] = useState([]);
    const [dataPortfolioDetailPath, setDataPortfolioDetailPath] = useState('');
    const [uploadedDataPortfolioDetailPath, setUploadedDataPortfolioDetailPath] = useState('');

    const fileToBase64 = (selectedFile, callback) => {
        let file = null;
        //Check File is not Empty
        if (selectedFile.length > 0) {
            // Select the very first file from list
            let fileToLoad = selectedFile[0];
            // FileReader function for read the file.
            let fileReader = new FileReader();
            // Onload of file read the file content
            fileReader.onload = function(fileLoadedEvent) {
                file = fileLoadedEvent.target.result;
                // Print data in console
                callback(file);
            };
            // Convert data to base64
            fileReader.readAsDataURL(fileToLoad);
        }
    }
    /* data recommendation */


    useEffect(() => {
        //get portfolio list
        async function getPortfolios(token){
            const res = await myList(token);
            if(res.error === null){
                setPortfolios(res.data);
            }else{
                swal('Error', res.error, 'error');
            }
        }

        //get licenses
        async function getData(token){
            const res = await licenseOrder(token, id);
            if(res.error === null){
                setData(res.data);
                setLicenseType(res.data.license_type);

                setUploadedDataRecommendationLetterPath(res.data.data_recommendation_letter_path);
                setUploadedDataPreviousLicensePath(res.data.data_previous_license_path);
                setUploadedDataStraPath(res.data.data_stra_path);
                setUploadedDataRuleResumePath(res.data.data_rule_resume_path);
                setUploadedDataCertificatePath(res.data.data_certificate_path);
                setUploadedDataPortfolioDetailPath(res.data.data_portfolio_detail_path);

                setProjects(res.data.projects);
            }else{
                swal('Error', res.error, 'error');
            }
        }

        getData(auth.token);
        getPortfolios(auth.token);
    }, [id, auth]);

    const resetForm = () => {
        setSelectedPortfolios([]);
        setDataRecommendationLetterPath('');
        setUploadedDataRecommendationLetterPath('');
        setDataPreviousLicensePath('');
        setUploadedDataPreviousLicensePath('');
        setDataStraPath('');
        setUploadedDataStraPath('');
        setDataRuleResumePath('');
        setUploadedDataRuleResumePath('');
        setDataCertificatePath('');
        setUploadedDataCertificatePath('');
        setDataPortfolioDetailPath('');
        setUploadedDataPortfolioDetailPath('');

        setRecommendationFiles([]);
        setPreviousLicenseFiles([]);
        setStraFiles([]);
        setResumeFiles([]);
        setCertificateFiles([]);
        setPortfolioDetailFiles([]);
    }

    useEffect(() => {
        if(status !== null){
            submit(null);
        }
    }, [status]);

    const submit = async (e) => {
        if(e !== null){
            e.preventDefault();
        }

        setStatus(null);

        //show confirm
        const confirm = await swal({
            title: "Apakah anda yakin?",
            text: "Pastikan data sudah terisi dengan benar",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        });

        if(confirm){
            //check if at least 1 portfolio
            if(status !== 'draft'){
                if(projects.length < 3){
                    swal('Error', 'Masukan setidak nya 3 proyek', 'error');
                    return;
                }
            }

            setSubmitLoading(true);

            const res = await revision(
                auth.token,
                id,
                null,
                dataRecommendationLetterPath,
                dataPreviousLicensePath,
                dataStraPath,
                dataRuleResumePath,
                dataCertificatePath,
                dataPortfolioDetailPath,
                status,
                projects
            )
            if(res.error === null){
                if(status === 'draft'){
                    //reload page
                    window.location.reload();
                }else{
                    resetForm();

                    navigate('/user-licenses/order/'+res.data.user_license_id);
                }
            }else{
                //show error
                swal('Error', res.error, 'error');
            }

            setSubmitLoading(false);
        }
        
    }

    const addPortfolio = (portfolio, isAdd) => {
        if(isAdd){
            //check if portfolio is already added
            if(seletedPortfolios.indexOf(portfolio) === -1){
                setSelectedPortfolios([...seletedPortfolios, portfolio]);

                //remove from list
                setPortfolios(portfolios.filter(p => p.id !== portfolio.id));
            }
        }else{
            //remove from selected
            setSelectedPortfolios(seletedPortfolios.filter(p => p.id !== portfolio.id));

            //add to list
            setPortfolios([...portfolios, portfolio]);
        }
    }

    return (
        <form onSubmit={submit}>
            {
                data !== null ? (
                    <div className="box p-3">
                        <b>Pengajuan Lisensi:</b>
                        <div>
                        {
                            data.license_name+' - '+renderLicenseType(data.license_type)
                        }
                        </div>
                        <div className="mt-3"><b>Catatan:</b></div>
                        <div>
                        {
                            data.note
                        }
                        </div>
                    </div>
                ) : null
            }
            <div className="box mt-3 p-3" hidden={licenseType === null || licenseType === ''}>
                <h4>Formulir Revisi</h4>
          
                <div className="mt-3" style={{
                    borderBottom: '1px solid #eee',
                }}>
                    <label>Scan Surat Permohonan Rekomendasi (.pdf)</label>
                    <div hidden={ uploadedDataRecommendationLetterPath === null || uploadedDataRecommendationLetterPath === "" }>
                        <div className="text-center">
                            <a style={{
                                textDecoration: 'none'
                            }} href={uploadedDataRecommendationLetterPath} target="_blank" rel="noreferrer">Lihat Surat Permohonan Rekomendasi</a>
                        </div>
                        <div className="alert alert-warning mt-2">
                            Kosongkan Kolom ini apabila tidak ingin mengganti data.
                        </div>
                    </div>
                    <Dropzone accept={['application/pdf']} onDrop={(acceptedFiles) => {
                        let error = null;
                        acceptedFiles.forEach(file => {
                            if(file.type !== 'application/pdf'){
                                error = 'File harus berformat PDF';
                            }
                        });

                        if(error === null){
                            setRecommendationFiles(acceptedFiles);
                            fileToBase64(acceptedFiles, setDataRecommendationLetterPath);
                        }else{
                            swal('Error', error, 'error');
                        }
                    }} name="heroImage" multiple={false}>
                        {({getRootProps, getInputProps}) => (
                            <div {...getRootProps({className: 'dropzone'})}>
                                <input {...getInputProps()} />
                                <span style={{ fontSize: ".8rem" }}>
                                        Drop file, or click to select file
                                </span>
                            </div>
                        )}
                    </Dropzone>
                    <aside className="mt-2" hidden={recommendationFiles === null || recommendationFiles.length < 1}>
                        <b>File yang dipilih:</b>
                        <ul>
                        {
                        recommendationFiles.map(file => (
                            <li key={file.path}>
                                <a target="_blank" href={file.path}>{file.path}</a> - {(file.size / 1000)} Kb
                            </li>
                        ))
                        }
                        </ul>
                    </aside>
                </div>

                <div className="mt-3" style={{
                    borderBottom: '1px solid #eee',
                }} hidden={licenseType === config.licenseType.NEW}>
                    <label>Scan IPTB/Lisensi Sebelumnya (.pdf)</label>
                    <div hidden={ uploadedDataPreviousLicensePath === null || uploadedDataPreviousLicensePath === "" }>
                        <div className="text-center">
                            <a style={{
                                textDecoration: 'none'
                            }} href={uploadedDataPreviousLicensePath} target="_blank" rel="noreferrer">Lihat IPTB/Lisensi Sebelumnya</a>
                        </div>
                        <div className="alert alert-warning mt-2">
                            Kosongkan Kolom ini apabila tidak ingin mengganti data.
                        </div>
                    </div>
                    <Dropzone accept={['application/pdf']} onDrop={(acceptedFiles) => {
                        let error = null;
                        acceptedFiles.forEach(file => {
                            if(file.type !== 'application/pdf'){
                                error = 'File harus berformat PDF';
                            }
                        });

                        if(error === null){
                            setPreviousLicenseFiles(acceptedFiles);
                            fileToBase64(acceptedFiles, setDataPreviousLicensePath);
                        }else{
                            swal('Error', error, 'error');
                        }
                    }} name="heroImage" multiple={false}>
                        {({getRootProps, getInputProps}) => (
                            <div {...getRootProps({className: 'dropzone'})}>
                                <input {...getInputProps()} />
                                <span style={{ fontSize: ".8rem" }}>
                                        Drop file, or click to select file
                                </span>
                            </div>
                        )}
                    </Dropzone>
                    <aside className="mt-2" hidden={previousLicenseFiles === null || previousLicenseFiles.length < 1}>
                        <b>File yang dipilih:</b>
                        <ul>
                        {
                        previousLicenseFiles.map(file => (
                            <li key={file.path}>
                                <a target="_blank" href={file.path}>{file.path}</a> - {(file.size / 1000)} Kb
                            </li>
                        ))
                        }
                        </ul>
                    </aside>
                </div>

                <div className="mt-3" style={{
                    borderBottom: '1px solid #eee',
                }}>
                    <label>Scan STRA (.pdf)</label>
                    <div hidden={ uploadedDataStraPath === null || uploadedDataStraPath === "" }>
                        <div className="text-center">
                            <a style={{
                                textDecoration: 'none'
                            }} href={uploadedDataStraPath} target="_blank" rel="noreferrer">Lihat STRA</a>
                        </div>
                        <div className="alert alert-warning mt-2">
                            Kosongkan Kolom ini apabila tidak ingin mengganti data.
                        </div>
                    </div>
                    <Dropzone accept={['application/pdf']} onDrop={(acceptedFiles) => {
                        let error = null;
                        acceptedFiles.forEach(file => {
                            if(file.type !== 'application/pdf'){
                                error = 'File harus berformat PDF';
                            }
                        });

                        if(error === null){
                            setStraFiles(acceptedFiles);
                            fileToBase64(acceptedFiles, setDataStraPath);
                        }else{
                            swal('Error', error, 'error');
                        }
                    }} name="heroImage" multiple={false}>
                        {({getRootProps, getInputProps}) => (
                            <div {...getRootProps({className: 'dropzone'})}>
                                <input {...getInputProps()} />
                                <span style={{ fontSize: ".8rem" }}>
                                        Drop file, or click to select file
                                </span>
                            </div>
                        )}
                    </Dropzone>
                    <aside className="mt-2" hidden={straFiles === null || straFiles.length < 1}>
                        <b>File yang dipilih:</b>
                        <ul>
                        {
                        straFiles.map(file => (
                            <li key={file.path}>
                                <a target="_blank" href={file.path}>{file.path}</a> - {(file.size / 1000)} Kb
                            </li>
                        ))
                        }
                        </ul>
                    </aside>
                </div>

                <div className="mt-3" style={{
                    borderBottom: '1px solid #eee',
                }} hidden={licenseType === config.licenseType.CONVERSION}>
                    <label>Resume Peraturan di DKI (.pdf)</label>
                    <div hidden={ uploadedDataRuleResumePath === null || uploadedDataRuleResumePath === "" }>
                        <div className="text-center">
                            <a style={{
                                textDecoration: 'none'
                            }} href={uploadedDataRuleResumePath} target="_blank" rel="noreferrer">Lihat Resume Peraturan di DKI</a>
                        </div>
                        <div className="alert alert-warning mt-2">
                            Kosongkan Kolom ini apabila tidak ingin mengganti data.
                        </div>
                    </div>
                    <Dropzone accept={['application/pdf']} onDrop={(acceptedFiles) => {
                        let error = null;
                        acceptedFiles.forEach(file => {
                            if(file.type !== 'application/pdf'){
                                error = 'File harus berformat PDF';
                            }
                        });

                        if(error === null){
                            setResumeFiles(acceptedFiles);
                            fileToBase64(acceptedFiles, setDataRuleResumePath);
                        }else{
                            swal('Error', error, 'error');
                        }
                    }} name="heroImage" multiple={false}>
                        {({getRootProps, getInputProps}) => (
                            <div {...getRootProps({className: 'dropzone'})}>
                                <input {...getInputProps()} />
                                <span style={{ fontSize: ".8rem" }}>
                                        Drop file, or click to select file
                                </span>
                            </div>
                        )}
                    </Dropzone>
                    <aside className="mt-2" hidden={resumeFiles === null || resumeFiles.length < 1}>
                        <b>File yang dipilih:</b>
                        <ul>
                        {
                        resumeFiles.map(file => (
                            <li key={file.path}>
                                <a target="_blank" href={file.path}>{file.path}</a> - {(file.size / 1000)} Kb
                            </li>
                        ))
                        }
                        </ul>
                    </aside>
                </div>

                <div className="mt-3" style={{
                    borderBottom: '1px solid #eee',
                }} hidden={licenseType === config.licenseType.CONVERSION}>
                    <label>Sertifikat PKA 1 atau Strata 1 dan Strata 2 IAI Jakarta (Bagi anggota IAI non Jakarta) (.pdf)</label>
                    <div hidden={ uploadedDataCertificatePath === null || uploadedDataCertificatePath === "" }>
                        <div className="text-center">
                            <a style={{
                                textDecoration: 'none'
                            }} href={uploadedDataCertificatePath} target="_blank" rel="noreferrer">Lihat Sertifikat</a>
                        </div>
                        <div className="alert alert-warning mt-2">
                            Kosongkan Kolom ini apabila tidak ingin mengganti data.
                        </div>
                    </div>
                    <Dropzone accept={['application/pdf']} onDrop={(acceptedFiles) => {
                        let error = null;
                        acceptedFiles.forEach(file => {
                            if(file.type !== 'application/pdf'){
                                error = 'File harus berformat PDF';
                            }
                        });

                        if(error === null){
                            setCertificateFiles(acceptedFiles);
                            fileToBase64(acceptedFiles, setDataCertificatePath);
                        }else{
                            swal('Error', error, 'error');
                        }
                    }} name="heroImage" multiple={false}>
                        {({getRootProps, getInputProps}) => (
                            <div {...getRootProps({className: 'dropzone'})}>
                                <input {...getInputProps()} />
                                <span style={{ fontSize: ".8rem" }}>
                                        Drop file, or click to select file
                                </span>
                            </div>
                        )}
                    </Dropzone>
                    <aside className="mt-2" hidden={certificateFiles === null || certificateFiles.length < 1}>
                        <b>File yang dipilih:</b>
                        <ul>
                        {
                        certificateFiles.map(file => (
                            <li key={file.path}>
                                <a target="_blank" href={file.path}>{file.path}</a> - {(file.size / 1000)} Kb
                            </li>
                        ))
                        }
                        </ul>
                    </aside>
                </div>

                <div className="mt-3">
                    <label>Portfolio 1 Proyek (siteplan, denah tampak, potongan, foto konstruksi) Sesuai Golongan (.pdf)</label>
                    <div hidden={ uploadedDataPortfolioDetailPath === null || uploadedDataPortfolioDetailPath === "" }>
                        <div className="text-center">
                            <a style={{
                                textDecoration: 'none'
                            }} href={uploadedDataPortfolioDetailPath} target="_blank" rel="noreferrer">Lihat File Portfolio</a>
                        </div>
                        <div className="alert alert-warning mt-2">
                            Kosongkan Kolom ini apabila tidak ingin mengganti data.
                        </div>
                    </div>
                    <Dropzone accept={['application/pdf']} onDrop={(acceptedFiles) => {
                        let error = null;
                        acceptedFiles.forEach(file => {
                            if(file.type !== 'application/pdf'){
                                error = 'File harus berformat PDF';
                            }
                        });

                        if(error === null){
                            setPortfolioDetailFiles(acceptedFiles);
                            fileToBase64(acceptedFiles, setDataPortfolioDetailPath);
                        }else{
                            swal('Error', error, 'error');
                        }
                    }} name="heroImage" multiple={false}>
                        {({getRootProps, getInputProps}) => (
                            <div {...getRootProps({className: 'dropzone'})}>
                                <input {...getInputProps()} />
                                <span style={{ fontSize: ".8rem" }}>
                                        Drop file, or click to select file
                                </span>
                            </div>
                        )}
                    </Dropzone>
                    <aside className="mt-2" hidden={portfolioDetailFiles === null || portfolioDetailFiles.length < 1}>
                        <b>File yang dipilih:</b>
                        <ul>
                        {
                        portfolioDetailFiles.map(file => (
                            <li key={file.path}>
                                <a target="_blank" href={file.path}>{file.path}</a> - {(file.size / 1000)} Kb
                            </li>
                        ))
                        }
                        </ul>
                    </aside>
                </div>
            </div>

            <div className="mt-4">
                <h4>List Proyek (Minimal 3)</h4>
                {
                    projects.map((project, indexProject) => (
                        <div key={indexProject} className="box p-3 mb-3">
                            <div className="row pb-3">
                                <div className="col-12 col-md-6 col-lg-3 mt-3">
                                    <label>Nama Proyek</label>
                                    <br/>
                                    <input type="text" maxLength={250} className="form-control" value={project.project_name} onChange={e => setProjects(
                                        projects.map((project, index) => {
                                            if(index === indexProject){
                                                project.project_name = e.target.value;
                                            }

                                            return project;
                                        })
                                    )}/>
                                </div>
                                <div className="col-12 col-md-6 col-lg-3 mt-3">
                                    <label>Jenis Bangunan</label>
                                    <br/>
                                    <input type="text" maxLength={150} className="form-control" value={project.building_category} onChange={e => setProjects(
                                        projects.map((project, index) => {
                                            if(index === indexProject){
                                                project.building_category = e.target.value;
                                            }

                                            return project;
                                        })
                                    )}/>
                                </div>
                                <div className="col-12 col-md-6 col-lg-2 mt-3">
                                    <label>Jumlah Lantai</label>
                                    <br/>
                                    <input type="number" maxLength={20} className="form-control" value={project.floor_level} onChange={e => setProjects(
                                        projects.map((project, index) => {
                                            if(index === indexProject){
                                                project.floor_level = e.target.value;
                                            }
                                            
                                            return project;
                                        })
                                    )}/>
                                </div>
                                <div className="col-12 col-md-6 col-lg-4 mt-3">
                                    <label>Lokasi</label>
                                    <br/>
                                    <input type="text" maxLength={250} className="form-control" value={project.location} onChange={e => setProjects(
                                        projects.map((project, index) => {
                                            if(index === indexProject){
                                                project.location = e.target.value;
                                            }
                                            
                                            return project;
                                        })
                                    )}/>
                                </div>
                                <div className="col-12 col-md-6 col-lg-4 mt-3">
                                    <label>Tanggal Mulai</label>
                                    <br/>
                                    <input type="date" maxLength={50} className="form-control" value={project.start_date} onChange={e => setProjects(
                                        projects.map((project, index) => {
                                            if(index === indexProject){
                                                project.start_date = e.target.value;
                                            }
                                            
                                            return project;
                                        })
                                    )}/>
                                </div>
                                <div className="col-12 col-md-6 col-lg-4 mt-3">
                                    <label>Tanggal Selesai</label>
                                    <br/>
                                    <input type="date" maxLength={50} className="form-control" value={project.end_date} onChange={e => setProjects(
                                        projects.map((project, index) => {
                                            if(index === indexProject){
                                                project.end_date = e.target.value;
                                            }
                                            
                                            return project;
                                        })
                                    )}/>
                                </div>
                                <div className="col-12 col-md-6 col-lg-4 mt-3">
                                    <label>Jabatan</label>
                                    <br/>
                                    <input type="text" maxLength={100} className="form-control" value={project.job_position} onChange={e => setProjects(
                                        projects.map((project, index) => {
                                            if(index === indexProject){
                                                project.job_position = e.target.value;
                                            }
                                            
                                            return project;
                                        })
                                    )}/>
                                </div>
                            </div>
                            <div>
                                <button type="button" className="btn btn-default btn-sm" onClick={() => {
                                    //show alert
                                    swal({
                                        title: "Hapus Proyek",
                                        text: "Apakah anda yakin ingin menghapus proyek "+project.project_name+"?",
                                        icon: "warning",
                                        buttons: true,
                                        dangerMode: true,
                                    })
                                    .then((willDelete) => {
                                        if (willDelete) {
                                            setProjects(
                                                projects.filter((project, index) => index !== indexProject)
                                            )
                                        }
                                    });
                                }}
                                >Hapus</button>
                            </div>
                        </div>
                    ))
                }
                <button type="button" className="btn btn-default btn-sm mt-2" onClick={() => {
                    setProjects([
                        ...projects,
                        {
                            project_name: "",
                            building_category: "",
                            floor_level: 1,
                            location: "",
                            start_date: "",
                            end_date: "",
                            job_position: ""
                        }
                    ])
                }
                }>Tambah Proyek +</button>
            </div>

            <div className="text-end mt-3">
                {
                    data !== null ?
                        data.status === config.licenseStatus.DRAFT ? (
                            <>
                            <button type="button" onClick={(e) => {
                                setStatus('draft');
                            }} disabled={submitLoading} className="btn btn-light me-3">{ submitLoading ? <Loading /> : 'Simpan Sebagai Draft' }</button>
                            <button type="button" onClick={(e) => {
                                setStatus('submit');
                            }} disabled={submitLoading} className="btn btn-default">{ submitLoading ? <Loading /> : 'Ajukan Lisensi' }</button>
                            </>
                        )

                        : data.status === config.licenseStatus.PENDING || data.status === config.licenseStatus.REVISION ? (
                            <button type="submit" disabled={submitLoading} className="btn btn-default">{ submitLoading ? <Loading /> : 'Ajukan Revisi' }</button>
                        )
                        : null
                    : null
                }
            </div>
        </form>
    );
}

export default RevisionUserLicense;