import React, { useEffect, useState } from "react";
import config from "../helpers/config";

// To use Html5Qrcode (more info below)
import {Html5Qrcode} from "html5-qrcode";

import swal from 'sweetalert';

import {
    attend
} from './../services/event';

import { useSelector } from "react-redux";
import {
    useNavigate,
} from 'react-router-dom';

import moment from "moment";
import { Button } from "react-bootstrap";

const ScanScreen = () => {
    const navigate = useNavigate();

    const auth = useSelector((state) => state.auth);

    //const [cameraId, setCameraId] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [url, setUrl] = useState(null);

    const [event, setEvent] = useState(null);

    //change title
    useEffect(() => {
        document.title = config.documentTitle + " - Scan Tiket";

        window.scrollTo(0, 0);

        const html5QrCodeObjInit = new Html5Qrcode("reader");

        // This method will trigger user permissions
        Html5Qrcode.getCameras().then(devices => {
            /**
             * devices would be an array of objects of type:
             * { id: "id", label: "label" }
             */
            if (devices && devices.length) {
                //check if back exist
                var cameraId = null;
                if(devices.length > 1){
                    cameraId = devices[1].id;
                }else{
                    cameraId = devices[0].id;
                }
                // .. use this to start scanning.
                //setCameraId(cameraId);
                
                //setHtml5QrCode(html5QrCodeObj);

                html5QrCodeObjInit.start(
                    cameraId, 
                    {
                        fps: 10,    // Optional, frame per seconds for qr code scanning
                        qrbox: { width: 250, height: 250 }  // Optional, if you want bounded box UI
                    },
                    (decodedText, decodedResult) => {
                        // do something when code is read
                        if(!isSubmitting){
                            setUrl(decodedText);
                        }
                    },
                    (errorMessage) => {
                        // parse error, ignore it.
                        //console.log(errorMessage);
                    }
                )
                .catch((err) => {
                    // Start failed, handle it.
                    //console.log(err);
                });
            }
        }).catch(err => {
            // handle err
            console.log(err);
        });

        return () => {
            html5QrCodeObjInit.stop();
        }
    }, [isSubmitting]);

    useEffect(() => {
        async function submitAttend(){
            setIsSubmitting(true);
            
            const res = await attend(auth.token, url);
            if(res.error === null){
                setEvent(res.data);
                swal('Success', 'Attendance success', 'success').then(() => {
                    //navigate('/');
                });
            }else{
                swal('Error', res.error, 'error').then(function(){
                    setIsSubmitting(false);
                    setUrl(null);
                });
            }
        }

        if(url !== null){
            submitAttend();
        }
    }, [auth, url]);

    return (
        <div>
            <div className="text-center mb-4">
                <h4>Scan Tiket</h4>
            </div>
            {
                event !== null ? (
                    <>
                        <table className="table table-stripped">
                            <tbody>
                                <tr>
                                    <td colspan="2"><h5>{ event.event.title }</h5></td>
                                </tr>
                                <tr>
                                    <td>Waktu Datang</td>
                                    <td>{ moment(event.attend_datetime).format('DD MMMM YYYY, HH:mm') }</td>
                                </tr>
                                <tr>
                                    <td>Pengunjung</td>
                                    <td>{ event.visitor+'/'+event.event.quota }</td>
                                </tr>
                            </tbody>
                        </table>  
                    </>
                ) : (
                    <>
                        <div hidden={isSubmitting} id="reader"></div>
                        <div hidden={!isSubmitting} className="text-center">Please Wait...</div>
                    </>
                )
            }

            <div className="text-center mt-4 mb-4">
                <Button variant="danger" className="btn-default" onClick={() => navigate('/')}>Kembali</Button>
            </div>
        </div>
    );
}

export default ScanScreen;