export const myList = async (token) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}portfolio/my_list`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            
        })
    });
    const json = await response.json();
    return json;
}

export const create = async (token, title, body, link, instagram, facebook, client, image) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}portfolio/create`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            title: title,
            body: body,
            link: link,
            instagram: instagram,
            facebook: facebook,
            client: client,
            image: image,
        })
    });
    const json = await response.json();
    return json;
}

export const update = async (token, id, title, body, link, instagram, facebook, client, image) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}portfolio/update`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            portfolio_id: id,
            title: title,
            body: body,
            link: link,
            instagram: instagram,
            facebook: facebook,
            client: client,
            image: image,
        })
    });
    const json = await response.json();
    return json;
}

export const remove = async (token, portfolio_id) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}portfolio/delete`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            portfolio_id: portfolio_id,
        })
    });
    const json = await response.json();
    return json;
}

export const read = async (id) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}portfolio/read`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            id: id,
        })
    });
    const json = await response.json();
    return json;
}

export const getLatest = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}portfolio/get_latest`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        }
    });
    const json = await response.json();
    return json;
}