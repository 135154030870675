import React, { useEffect, useState }from "react";
import { Offcanvas } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import NotificationList from "../components/notifications/NotificationList";
import {
    setShowNotificationScreen
} from './../redux/slicers/notificationSlice';

const NotificationScreen = () => {
    const dispatch = useDispatch();
    const notification = useSelector((state) => state.notification);
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(notification.showNotificationScreen);
    }, [notification]);

    const handleClose = () => {
        dispatch(setShowNotificationScreen(false));
    };

    return (
        <Offcanvas show={show} onHide={handleClose} placement="end">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Notifications</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body style={{
                padding: '0px'
            }}>
                <NotificationList />
            </Offcanvas.Body>
        </Offcanvas>
    );
}

export default NotificationScreen;