import React, { useEffect, useState } from "react";
import Loading from "./Loading";
import { read } from "../services/page";

const ContentView = (props) => {
    const slug = props.slug;

    const [content, setContent] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function getContent(slug){
            setLoading(true);

            const res = await read(slug);
            if(res.error === null){
                setContent(res.data);
            }else{
                console.log(res);
            }

            setLoading(false);
        }

        getContent(slug);
    }, [slug]);

    return(
        <div>
            {
                loading ? <Loading /> :
                content === null ? null :
                <div dangerouslySetInnerHTML={{__html: content.body}}></div>
            }
        </div>
    );
}

export default ContentView;