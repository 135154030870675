import { createSlice } from '@reduxjs/toolkit'
import config from '../../helpers/config';

//read cart from localStorage
const cart = JSON.parse(localStorage.getItem(config.storageKey.cart)) || [];

export const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    cart: cart,
    showCartScreen : false,
    totalPrice : 0,
    totalDiscount : 0,
    totalCost : 0,
    shippingAddressChanged : null,
  },
  reducers: {
    setShippingAddressChanged : (state, action) => {
      state.shippingAddressChanged = action.payload;
    },
    setTotalCost : (state, action) => {
      state.totalCost = action.payload;
    },
    setTotalPrice : (state, action) => {
      state.totalPrice = action.payload;
    },
    setTotalDiscount : (state, action) => {
      state.totalDiscount = action.payload;
    },
    addToCart: (state, action) => {
        //check if item already in cart
        let item = state.cart.find(item => item.id === action.payload.id);
        if(item){
          //parse quantity to int
          item.quantity = parseInt(item.quantity) + parseInt(action.payload.quantity);
        }else{
          state.cart.push(action.payload);
        }

        //save cart to localStorage
        localStorage.setItem(config.storageKey.cart, JSON.stringify(state.cart));
    },
    updateQuantity: (state, action) => {
        //find item
        let item = state.cart.find(item => item.id === action.payload.id);
        if(item){
            item.quantity = parseInt(action.payload.quantity);
        }

        //save cart to localStorage
        localStorage.setItem(config.storageKey.cart, JSON.stringify(state.cart));
    },
    removeFromCart: (state, action) => {
        //find item
        let index = state.cart.findIndex(item => item.id === action.payload.id);
        if(index > -1){
            state.cart.splice(index, 1);
        }

        //save cart to localStorage
        localStorage.setItem(config.storageKey.cart, JSON.stringify(state.cart));
    },
    setShowCartScreen: (state, action) => {
      state.showCartScreen = action.payload;
    },
    clear: (state, action) => {
      state.cart = [];
      state.totalPrice = 0;
      state.totalDiscount = 0;
      state.totalCost = 0;

      //save cart to localStorage
      localStorage.setItem(config.storageKey.cart, JSON.stringify(state.cart));
    },
  },
})

// Action creators are generated for each case reducer function
export const { setShippingAddressChanged, setTotalCost, setTotalPrice, setTotalDiscount, addToCart, setShowCartScreen, updateQuantity, removeFromCart, clear } = cartSlice.actions

export default cartSlice.reducer