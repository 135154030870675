export const all = async (faq_category_slug) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}faq/all`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            faq_category_slug: faq_category_slug
        })
    });
    const json = await response.json();
    return json;
}