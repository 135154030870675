import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    showUserDetail
} from './../../redux/slicers/userSlice';
import { showPortfolioDetail } from './../../redux/slicers/portfolioSlice';
import MembershipBadge from './../MembershipBadge';

const UserBox = (props) => {
    const user = props.user;

    const dispatch = useDispatch();

    const avatar = user.avatar !== null && user.avatar !== "" ? user.avatar : 'https://via.placeholder.com/300x300';

    const viewDetail = (id) => {
        //hide portfolio
        dispatch(showPortfolioDetail(null));

        //show user
        dispatch(showUserDetail(id));
    }

    return (
        <div
        style={{
        }}>
            <div className="box" onClick={() => viewDetail(user.id)} style={{
                padding: '0px',
                marginBottom:'20px',
                overflow: 'hidden',
                cursor: 'pointer',
            }}>
                <div className="p-3">
                    <img src={ avatar } alt="user avatar" style={{
                        width: '100%',
                        borderRadius: '5px',
                    }}/>
                </div>
                <div className='text-center p-3 mb-3'>
                    <p style={{
                        fontSize:'12pt',
                        fontWeight:'bold',
                        cursor:'pointer',
                        marginBottom:10,
                        textTransform:'capitalize',
                    }}>{ user.first_name+' '+user.last_name }</p>
                    <MembershipBadge membershipType={user.membership_type} membershipLabel={user.membership_name}/>
                </div>
            </div>
        </div>
    );
}

export default UserBox;