import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { changePassword } from "../../services/account";
import swal from "sweetalert";
import Loading from './../Loading';

const ChangePassword = () => {
    const auth = useSelector((state) => state.auth);

    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [loading, setLoading] = useState(false);

    const resetForm = () => {
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
    }

    const submit = async e => {
        e.preventDefault();

        setLoading(true);

        const res = await changePassword(
            auth.token,
            oldPassword,
            newPassword,
            confirmPassword
        );

        if(res.error === null){
            swal("Success!", "Berhasil mengubah password", "success");
            resetForm();
        }else{
            swal("Error!", res.error, "error");
        }

        setLoading(false);
    }

    return (
        <>
           <form onSubmit={submit}>
                <div className="mb-3">
                    <label>Password Lama</label>
                    <input disabled={loading} value={oldPassword} onChange={e => setOldPassword(e.target.value) } type="password" className="form-control" placeholder="Password Lama Anda" required/>
                </div>
                <div className="mb-3">
                    <label>Password Baru</label>
                    <input disabled={loading} value={newPassword} onChange={e => setNewPassword(e.target.value) } type="password" className="form-control" placeholder="Password Baru Anda" required/>
                </div>
                <div className="mb-3">
                    <label>Konfirmasi Password Baru</label>
                    <input disabled={loading} value={confirmPassword} onChange={e => setConfirmPassword(e.target.value) } type="password" className="form-control" placeholder="Konfirmasi Password Baru Anda" required/>
                </div>
                <div className="mb-3 text-end">
                    <button type="button" disabled={loading} className="btn btn-secondary me-3" onClick={() => resetForm() }>Reset</button>
                    <button type="submit" disabled={loading} className="btn btn-default" onClick={() => {} }>
                        {
                            loading ? <Loading /> : 'Ganti Password'
                        }
                    </button>
                </div>
           </form>
        </>
    );
}

export default ChangePassword;