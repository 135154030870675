import React, { useEffect, useState } from "react";
import { Calendar as MyCalendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {
    upcoming,
    read,
    search
} from './../../services/event';
import swal from "sweetalert";
import { faArrowRight} from '@fortawesome/free-solid-svg-icons'
import { Button, Modal } from "react-bootstrap";
import EventBoxMini from './EventBoxMini';
import { useSelector } from "react-redux";
import Loading from "../Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Setup the localizer by providing the moment (or globalize) Object
// to the correct localizer.
const localizer = momentLocalizer(moment) // or globalizeLocalizer

const EventCalendar = () => {
    const auth = useSelector(state => state.auth);

    const [events, setEvents] = useState([]);
    const [eventsByMonth, setEventsByMonth] = useState([]);
    const [show, setShow] = useState(false);
    const [event, setEvent] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedEventUrl, setSelectedEventUrl] = useState(null);

    const [loading, setLoading] = useState(true);
    const [upcomingLoading, setUpcomingLoading] = useState(true);
    const [eventMonthLoading, setEventMonthLoading] = useState(true);

    const handleClose = () => {
        setSelectedEventUrl(null);
        setShow(false);
    }

    useEffect(() => {
        async function getUpcomingEvent(){
            setUpcomingLoading(true);

            setSelectedDate(new Date());

            const res = await upcoming(auth.token, 100);
            if(res.error === null){
                const result = res.data;
                let results = [];
                result.map(event => {
                    results.push({
                        id: event.id,
                        title: event.title,
                        start: moment(event.start_datetime),
                        end: moment(event.end_datetime),
                        resource : {
                            description : event.description,
                            url: event.url,
                        }
                    });
                });

                setEvents(results);
            }else{
                swal('Error', res.error, 'error')
            }

            setUpcomingLoading(false);
        }

        //getUpcomingEvent();
        setSelectedDate(new Date());
    }, [auth]);

    useEffect(() => {
        async function getDetail(url){
            setLoading(true);

            const res = await read(url, auth.token);
            if(res.error === null){
                setEvent(res.data);
            }else{
                swal('Error', res.error, 'error')
            }

            setLoading(false);
        }

        if(selectedEventUrl !== null){
            setShow(true);
            getDetail(selectedEventUrl);
        }else{
            setShow(false);
            setEvent(null);
        }
    }, [auth, selectedEventUrl]);

    const handleSelectEvent = (event) => {
        setSelectedEventUrl(event.resource.url);
    }

    const handleNavigate = (date) => {
        setSelectedDate(date);
    }

    useEffect(() => {
        async function getEventByMonth(date){
            setEventMonthLoading(true);

            const currentMonth = date.getMonth() + 1;
            const currentYear = date.getFullYear();

            const res = await search(auth.token, '', 1, 'calendar', currentMonth, currentYear);
            if(res.error === null){
                setEventsByMonth(res.data);

                let results = [];
                res.data.map(event => {
                    if(typeof event.agenda !== 'undefined' && event.agenda !== null){
                        const event_start = moment(event.agenda.date);
                        const event_end = moment(event.agenda.date);

                        results.push({
                            id: event.id,
                            title: event.title,
                            start: moment(event_start),
                            end: moment(event_end),
                            resource : {
                                description : event.description,
                                url: event.url,
                            }
                        });
                    }
                });

                setEvents(results);
            }else{
                swal('Error', res.error, 'error')
            }

            setEventMonthLoading(false);
            setUpcomingLoading(false);
        }

        if(selectedDate !== null){
            getEventByMonth(selectedDate);
        }
    }, [auth, selectedDate])

    return (
        <div>
            {
                upcomingLoading ? <Loading /> : ( 
                    <>
                        <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton style={{
                                borderBottom: 'none'
                            }}>
                            </Modal.Header>
                            <Modal.Body>
                                {
                                    loading ? <Loading /> :
                                    event !== null ? (
                                        <EventBoxMini event={event}/>
                                    ) : null
                                }
                            </Modal.Body>
                        </Modal>
                        
                        <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <div className="box p-3 mb-5">
                                    <MyCalendar
                                        style={{
                                            height: '500px'
                                        }}
                                        localizer={localizer}
                                        events={events}
                                        startAccessor="start"
                                        endAccessor="end"
                                        views={['month']}
                                        onSelectEvent={handleSelectEvent}
                                        onNavigate={handleNavigate}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <h4>Jadwal Kegiatan <span className="text-default">{ moment(selectedDate.getMonth() + 1+' '+selectedDate.getFullYear(), 'MM YYYY').format('MMMM YYYY') }</span></h4>
                                {
                                    eventMonthLoading ? <Loading /> :
                                    eventsByMonth.length > 0 ? (
                                        <div>
                                            {
                                                eventsByMonth.map((event, index) => (
                                                    <div style={{
                                                        borderBottom: '1px solid #e6e6e6',
                                                        marginBottom: '20px',
                                                        marginTop: '20px',
                                                        cursor: 'pointer'
                                                    }} onClick={() => setSelectedEventUrl(event.url)} key={index} className="row">
                                                        <div className="col-5">
                                                            { event.title }
                                                        </div>
                                                        <div className="col-5">
                                                            { 
                                                                event.agenda !== null ?
                                                                moment(event.agenda.date).format('DD MMMM YYYY')
                                                                : null 
                                                            }
                                                        </div>
                                                        <div className="col-2 text-end">
                                                            <FontAwesomeIcon icon={faArrowRight}/>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    ) : (
                                        <div className="text-center">
                                            <img src="/img/tidak_ada_kegiatan.svg" alt="No News" />
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </>
                )
            }   
        </div>
    );
}

export default EventCalendar;