import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import EditProfile from "../components/account/EditProfile";
import LeftMenuPanel from "../components/LeftMenuPanel";
import ProfileNav from "../components/ProfileNav";
import config from "../helpers/config";

const EditProfileScreen = () => {
    //change title
    useEffect(() => {
        document.title = config.documentTitle + " - Ubah Profil";

        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="default-nav-mt">
            <Container>
                <Row>
                    <Col sm={12} md={12} lg={4} className="mb-4">
                        <LeftMenuPanel activeIndex={0}/>
                    </Col>
                    <Col sm={12} md={12} lg={8}>
                        <ProfileNav activeIndex={1}/>
                        <EditProfile />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default EditProfileScreen;