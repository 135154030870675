import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import DownloadCategories from "../components/download/DownloadCategories";
import DownloadSearch from "../components/download/DownloadSearch";
import config from "../helpers/config";

const DownloadScreen = () => {
    //change title
    useEffect(() => {
        document.title = config.documentTitle + " - Dashboard";

        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="default-nav-mt">
            <Container>
                <Row>
                    <Col lg={12} md={12} sm={12}>
                        <DownloadSearch />
                        <DownloadCategories />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default DownloadScreen;