import React, { useEffect, useState } from "react";

//bootstrap
import { Container, Row, Col } from "react-bootstrap";

//services
import {
    validate
} from './../services/auth';

//redux
import { useDispatch, useSelector } from "react-redux";
import {
    setAuthentication
} from './../redux/slicers/authSlice';
import {
    setAccount
} from './../redux/slicers/accountSlice';
import config from "../helpers/config";
import { Link } from "react-router-dom";
import swal from "sweetalert";

import Loading from "../components/Loading";
import { useParams } from "react-router";

const ValidationScreen = () => {
    const dispatch = useDispatch();
    const params = useParams();

    const information = useSelector((state) => state.information);

    const [loading, setLoading] = useState(false);

    //change title
    useEffect(() => {
        document.title = config.documentTitle + " - Validasi";

        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        async function validateToken(token){
            setLoading(true);

            const res = await validate(token);
            if(res.error == null){
                //set account
                dispatch(setAccount(res.data));
                //login success
                dispatch(setAuthentication({
                    isAuthenticated: true,
                    token: res.token
                }));
            }else{
                swal("Gagal", res.error, "error");
            }

            setLoading(false);
        }

        validateToken(params.token);
    }, [params, dispatch]);

    return (
        <>
            <div className="login-container">
                <div className="login-image d-none d-md-none d-xl-block d-xxl-block">
                    <img src={information.white_logo} alt="logo" className="icon"/>
                </div>
                <div className="login-content">
                    <img src={information.main_logo} alt="logo" className="m-4 d-block d-md-block d-xl-none d-xxl-none" style={{
                        width: "150px",
                    }}/>
                    <Container>
                        <Row className="justify-content-center">
                            <Col 
                            sm={{
                                span: 12
                            }} 
                            md={{
                                span: 10
                            }}
                            lg={{
                                span: 5
                            }}
                            >
                                <div className="pb-5 p-4">
                                    {
                                        loading ? <Loading/> : (
                                            <div className="text-center mt-5">
                                                <h4>Terdapat Kesalahan</h4><br/>
                                                Kembali ke <Link to="/" className="def-link">Beranda</Link>
                                            </div>
                                        )
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    );
}

export default ValidationScreen;