import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useState } from "react";

//bootstrap
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router";

import {
    read,
    order
} from './../../services/event';
import {
    intToMoney
} from './../../helpers/currency';
import { useDispatch, useSelector } from "react-redux";
import swal from 'sweetalert';
import config from "../../helpers/config";
import {
    showUserDetail
} from './../../redux/slicers/userSlice';
import moment from "moment";
import Loading from "../../components/Loading";
import { setRedirectUrl } from './../../redux/slicers/authSlice';
import { Link } from "react-router-dom";

const EventDetail = (props) => {
    const url = props.url;
    const isAuth = props.isAuth;
    const navigate = useNavigate();
    const location = useLocation();
    const auth = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    const [event, setEvent] = useState(null);
    const [loading, setLoading] = useState(true);

    const [showCertificates, setShowCertificates] = useState(false);

    const handleCloseCertificates = () => setShowCertificates(false);
    const handleShowCertificates = () => setShowCertificates(true);

    //change title
    useEffect(() => {
        document.title = config.documentTitle + " - Kegiatan";

        window.scrollTo(0, 0);
    }, []);

    const buyTicket = async () => {
        //check if login
        if(auth.token === null){
            swal("Error!", "Silahkan login terlebih dahulu", "error");
            dispatch(setRedirectUrl('/event/' + url));
            navigate('/login');
        }else{
            swal({
                title: "Pembelian Tiket",
                text: "Anda yakin akan membeli tiket: "+event.title+" seharga "+intToMoney(event.total_cost)+" ?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
            .then(async (willDelete) => {
                if (willDelete) {
                    navigate('/event-checkout/'+event.url);
                } 
            });
        }
    }

    useEffect(() => {
        async function getEventDetail(url){
            setLoading(true);

            const response = await read(url, auth.token);

            if(response.error === null){
                setEvent(response.data);

                document.title = config.documentTitle + " - Kegiatan - " + response.data.title;
            }else{
                swal("Error!", response.error, "error");
            }

            setLoading(false);
        }

        if(url !== undefined){
            getEventDetail(url);
        }
    }, [url, auth]);

    const viewDetail = (id) => {
        dispatch(showUserDetail(id));
    }

    return (
        <>
            {
                isAuth ? (
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                            <li className="breadcrumb-item"><Link to="/events/mylist">Kegiatan Saya</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">{
                                event !== null ? event.title : ""
                            }</li>
                        </ol>
                    </nav>
                ) : null
            }
            <Container className="box p-4">
                {
                    loading ? <Loading /> : (
                        <>
                            {
                                event !== null ? <>
                                    <a className="d-block d-md-block d-xl-none d-xxl-none" href={event.cover} target="_blank">
                                        <img src={event.cover} alt="event" style={{
                                            width: '100%'
                                        }} />
                                    </a>
                                    </> : null
                            }
                            <Row>
                                <Col
                                    sm={{
                                        span: 12,
                                        offset: 0
                                    }}
                                    md={{
                                        span: 6,
                                        offset: 0
                                    }}
                                    lg={{
                                        span: 8,
                                        offset: 0
                                    }}
                                >
                                    <div style={{
                                        overflow: 'hidden',
                                    }}>
                                        {
                                            event !== null ? (
                                                <>
                                                    
                                                    <div>
                                                        <div style={{
                                                            color: '#C7B37F',
                                                            fontSize: '14pt',
                                                            fontWeight: 'bold',
                                                        }}>{ event.event_category_name }</div>
                                                        <h1><b>{ event.title }</b></h1>
                                                        <div className="mt-3" style={{
                                                            textAlign: 'justify',
                                                        }}>{ event.description }</div>
                                                        <div className="mt-3" dangerouslySetInnerHTML={{ __html: event.body }} />
                                                    </div>

                                                    <div className="mb-4" hidden={ event.speakers.length < 1 && event.moderators.length < 1 }>
                                                        {
                                                            event.speakers !== null && event.speakers.length > 0 ? (
                                                                <>
                                                                    <h4>Speakers:</h4>
                                                                    {
                                                                        event.speakers.map((speaker, index) => (
                                                                            <div key={index} onClick={() => viewDetail(speaker.id)} className="avatar-container p-2">
                                                                                <div className="avatar-frame">
                                                                                    <img src={ speaker.avatar } alt="avatar"/>
                                                                                </div>
                                                                                <div className="avatar-title">{ speaker.name }</div>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </>
                                                            ) : null
                                                        }

                                                        {
                                                            event.moderators !== null && event.moderators.length > 0 ? (
                                                                <>
                                                                    <h4 className="mt-3">Moderators:</h4>
                                                                    {
                                                                        event.moderators.map((speaker, index) => (
                                                                            <div key={index} onClick={() => viewDetail(speaker.id)} className="avatar-container p-2">
                                                                                <div className="avatar-frame">
                                                                                    <img src={ speaker.avatar } alt="avatar"/>
                                                                                </div>
                                                                                <div className="avatar-title">{ speaker.name }</div>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </>
                                                            ) : null
                                                        }
                                                        
                                                        {/* {
                                                            event.commitees !== null && event.commitees.length > 0 ? (
                                                                <>
                                                                    <h4 className="mt-3">Commitee:</h4>
                                                                    {
                                                                        event.commitees.map((speaker, index) => (
                                                                            <div key={index} onClick={() => viewDetail(speaker.id)} className="avatar-container p-2">
                                                                                <div className="avatar-frame">
                                                                                    <img src={ speaker.avatar } alt="avatar"/>
                                                                                </div>
                                                                                <div className="avatar-title">{ speaker.name }</div>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </>
                                                            ) : null
                                                        } */}
                                                    </div>
                                                </>
                                            ) : <></>
                                        }
                                    </div>
                                </Col>
                                <Col
                                >
                                    {
                                        event !== null ? <>
                                            <a className="d-none d-md-none d-xl-block d-xxl-block" href={event.cover} target="_blank">
                                                <img src={event.cover} alt="event" style={{
                                                    width: '100%'
                                                }} />
                                            </a>

                                            <div className="mt-3">
                                                <div className="mt-3" style={{
                                                    display: 'flex',
                                                }}>
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M6 5V1M14 5V1M5 9H15M3 19H17C18.1046 19 19 18.1046 19 17V5C19 3.89543 18.1046 3 17 3H3C1.89543 3 1 3.89543 1 5V17C1 18.1046 1.89543 19 3 19Z" stroke="#C4C4C4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </svg>

                                                    <span className="ms-3">
                                                        {
                                                            event.agendas.map((agenda, index) => (
                                                                <div className="mb-1" key={index}>
                                                                    { moment(agenda.date).format('DD MMMM YYYY') }
                                                                    <div>
                                                                        { agenda.start_time+ ' - ' + agenda.end_time } WIB
                                                                        <a hidden={agenda.google_calendar_link === null} href={agenda.google_calendar_link} target="_blank" className="btn btn-default" style={{
                                                                            fontSize: '8pt',
                                                                            padding: '2px 5px',
                                                                            marginLeft: '5px',
                                                                        }}>
                                                                            <FontAwesomeIcon icon={faCalendarAlt}/>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                    </span>
                                                </div>
                                                <div className="mt-3" hidden={event.place === null || event.place === ""}>
                                                    <svg width="17" height="25" style={{
                                                        marginRight: '3px'
                                                    }} viewBox="0 0 17 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M8.15625 17.5812C10.2507 15.8896 11.8316 14.2634 12.899 12.7026C13.9663 11.1418 14.5 9.68681 14.5 8.3375C14.5 7.14931 14.2835 6.14236 13.8505 5.31667C13.4175 4.49097 12.8839 3.81632 12.2495 3.29271C11.6151 2.7691 10.9253 2.39149 10.1802 2.1599C9.43507 1.9283 8.76042 1.8125 8.15625 1.8125C7.55208 1.8125 6.87743 1.9283 6.13229 2.1599C5.38715 2.39149 4.6974 2.7691 4.06302 3.29271C3.42865 3.81632 2.89497 4.49097 2.46198 5.31667C2.02899 6.14236 1.8125 7.14931 1.8125 8.3375C1.8125 9.68681 2.34618 11.1418 3.41354 12.7026C4.4809 14.2634 6.06181 15.8896 8.15625 17.5812ZM8.15625 19.8771C5.39722 17.8028 3.34809 15.8191 2.00885 13.926C0.669618 12.033 0 10.1701 0 8.3375C0 6.96806 0.246702 5.76476 0.740104 4.7276C1.23351 3.69045 1.87292 2.81944 2.65833 2.11458C3.44375 1.40972 4.31979 0.881076 5.28646 0.528646C6.25313 0.176215 7.20972 0 8.15625 0C9.10278 0 10.0594 0.176215 11.026 0.528646C11.9927 0.881076 12.8687 1.40972 13.6542 2.11458C14.4396 2.81944 15.079 3.69045 15.5724 4.7276C16.0658 5.76476 16.3125 6.96806 16.3125 8.3375C16.3125 10.1701 15.6429 12.033 14.3036 13.926C12.9644 15.8191 10.9153 17.8028 8.15625 19.8771ZM8.15625 10.2708C8.74028 10.2708 9.23872 10.0644 9.65156 9.65156C10.0644 9.23872 10.2708 8.74028 10.2708 8.15625C10.2708 7.57222 10.0644 7.07378 9.65156 6.66094C9.23872 6.24809 8.74028 6.04167 8.15625 6.04167C7.57222 6.04167 7.07378 6.24809 6.66094 6.66094C6.24809 7.07378 6.04167 7.57222 6.04167 8.15625C6.04167 8.74028 6.24809 9.23872 6.66094 9.65156C7.07378 10.0644 7.57222 10.2708 8.15625 10.2708ZM0 24.1667V22.3542H16.3125V24.1667H0Z" fill="#C4C4C4"/>
                                                    </svg>

                                                    <span className="ms-3">
                                                    {
                                                        event.place
                                                    }
                                                    </span>
                                                </div>
                                                <div className="mt-3" hidden={event.place_online === null || event.place_online === ""}>
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1.05493 9H3C4.10457 9 5 9.89543 5 11V12C5 13.1046 5.89543 14 7 14C8.10457 14 9 14.8954 9 16V18.9451M6 1.93552V3.5C6 4.88071 7.11929 6 8.5 6H9C10.1046 6 11 6.89543 11 8C11 9.10457 11.8954 10 13 10C14.1046 10 15 9.10457 15 8C15 6.89543 15.8954 6 17 6L18.0645 6M13 18.4879V16C13 14.8954 13.8954 14 15 14H18.0645M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z" stroke="#C4C4C4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </svg>

                                                    <span className="ms-3">
                                                    {
                                                        event.place_online
                                                    }
                                                    </span>
                                                </div>
                                                <div className="mt-3">
                                                    <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M10.049 1.92664C10.3483 1.00537 11.6517 1.00538 11.951 1.92664L13.4699 6.60055C13.6038 7.01254 13.9877 7.29148 14.4209 7.29149L19.3354 7.29168C20.3041 7.29172 20.7068 8.53127 19.9232 9.10067L15.9474 11.9895C15.5969 12.2441 15.4503 12.6955 15.5841 13.1075L17.1026 17.7815C17.4019 18.7028 16.3475 19.4689 15.5638 18.8995L11.5878 16.011C11.2373 15.7564 10.7627 15.7564 10.4122 16.011L6.43622 18.8995C5.65252 19.4689 4.5981 18.7028 4.8974 17.7815L6.41589 13.1075C6.54974 12.6955 6.40309 12.2441 6.05263 11.9895L2.07683 9.10067C1.29317 8.53127 1.69592 7.29172 2.66461 7.29168L7.57911 7.29149C8.01231 7.29148 8.39623 7.01254 8.53011 6.60055L10.049 1.92664Z" stroke="#C4C4C4" strokeWidth="2"/>
                                                    </svg>

                                                    <span className="ms-3">
                                                        {
                                                            event.is_certificate == 1 ? "Tersedia E-Sertifikat" : "Sertifikat Tidak Tersedia"
                                                        }
                                                    </span>
                                                </div>
                                                {
                                                    auth.isAuthenticated ? (
                                                        event.event_type == "1" && parseInt(event.total_cost) > 0 ? (
                                                            <div className="mt-3">
                                                                <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M19 6V7C19.5523 7 20 6.55228 20 6H19ZM19 10H20C20 9.44771 19.5523 9 19 9V10ZM1 10V9C0.447715 9 0 9.44771 0 10H1ZM1 6H0C0 6.55228 0.447715 7 1 7L1 6ZM14 1C14 0.447715 13.5523 0 13 0C12.4477 0 12 0.447715 12 1H14ZM12 3C12 3.55228 12.4477 4 13 4C13.5523 4 14 3.55228 14 3H12ZM14 7C14 6.44772 13.5523 6 13 6C12.4477 6 12 6.44772 12 7H14ZM12 9C12 9.55229 12.4477 10 13 10C13.5523 10 14 9.55229 14 9H12ZM14 13C14 12.4477 13.5523 12 13 12C12.4477 12 12 12.4477 12 13H14ZM12 15C12 15.5523 12.4477 16 13 16C13.5523 16 14 15.5523 14 15H12ZM3 0C1.34315 0 0 1.34315 0 3H2C2 2.44772 2.44772 2 3 2V0ZM17 0H3V2H17V0ZM20 3C20 1.34315 18.6569 0 17 0V2C17.5523 2 18 2.44772 18 3H20ZM20 6V3H18V6H20ZM18 8C18 7.44771 18.4477 7 19 7V5C17.3431 5 16 6.34315 16 8H18ZM19 9C18.4477 9 18 8.55229 18 8H16C16 9.65685 17.3431 11 19 11V9ZM20 13V10H18V13H20ZM17 16C18.6569 16 20 14.6569 20 13H18C18 13.5523 17.5523 14 17 14V16ZM3 16H17V14H3V16ZM0 13C0 14.6569 1.34315 16 3 16V14C2.44772 14 2 13.5523 2 13H0ZM0 10V13H2V10H0ZM2 8C2 8.55228 1.55228 9 1 9V11C2.65685 11 4 9.65685 4 8H2ZM1 7C1.55228 7 2 7.44772 2 8H4C4 6.34315 2.65685 5 1 5V7ZM0 3V6H2V3H0ZM12 1V3H14V1H12ZM12 7V9H14V7H12ZM12 13V15H14V13H12Z" fill="#C4C4C4"/>
                                                                </svg>

                                                                <span className="ms-3">{ intToMoney(event.total_cost) }</span>
                                                            </div>
                                                        ) : (
                                                            <div className="mt-3">
                                                                <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M19 6V7C19.5523 7 20 6.55228 20 6H19ZM19 10H20C20 9.44771 19.5523 9 19 9V10ZM1 10V9C0.447715 9 0 9.44771 0 10H1ZM1 6H0C0 6.55228 0.447715 7 1 7L1 6ZM14 1C14 0.447715 13.5523 0 13 0C12.4477 0 12 0.447715 12 1H14ZM12 3C12 3.55228 12.4477 4 13 4C13.5523 4 14 3.55228 14 3H12ZM14 7C14 6.44772 13.5523 6 13 6C12.4477 6 12 6.44772 12 7H14ZM12 9C12 9.55229 12.4477 10 13 10C13.5523 10 14 9.55229 14 9H12ZM14 13C14 12.4477 13.5523 12 13 12C12.4477 12 12 12.4477 12 13H14ZM12 15C12 15.5523 12.4477 16 13 16C13.5523 16 14 15.5523 14 15H12ZM3 0C1.34315 0 0 1.34315 0 3H2C2 2.44772 2.44772 2 3 2V0ZM17 0H3V2H17V0ZM20 3C20 1.34315 18.6569 0 17 0V2C17.5523 2 18 2.44772 18 3H20ZM20 6V3H18V6H20ZM18 8C18 7.44771 18.4477 7 19 7V5C17.3431 5 16 6.34315 16 8H18ZM19 9C18.4477 9 18 8.55229 18 8H16C16 9.65685 17.3431 11 19 11V9ZM20 13V10H18V13H20ZM17 16C18.6569 16 20 14.6569 20 13H18C18 13.5523 17.5523 14 17 14V16ZM3 16H17V14H3V16ZM0 13C0 14.6569 1.34315 16 3 16V14C2.44772 14 2 13.5523 2 13H0ZM0 10V13H2V10H0ZM2 8C2 8.55228 1.55228 9 1 9V11C2.65685 11 4 9.65685 4 8H2ZM1 7C1.55228 7 2 7.44772 2 8H4C4 6.34315 2.65685 5 1 5V7ZM0 3V6H2V3H0ZM12 1V3H14V1H12ZM12 7V9H14V7H12ZM12 13V15H14V13H12Z" fill="#C4C4C4"/>
                                                                </svg>

                                                                <span className="ms-3">Gratis</span>
                                                            </div>
                                                        )
                                                    ) : (
                                                        <div className="mt-3">
                                                            <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M19 6V7C19.5523 7 20 6.55228 20 6H19ZM19 10H20C20 9.44771 19.5523 9 19 9V10ZM1 10V9C0.447715 9 0 9.44771 0 10H1ZM1 6H0C0 6.55228 0.447715 7 1 7L1 6ZM14 1C14 0.447715 13.5523 0 13 0C12.4477 0 12 0.447715 12 1H14ZM12 3C12 3.55228 12.4477 4 13 4C13.5523 4 14 3.55228 14 3H12ZM14 7C14 6.44772 13.5523 6 13 6C12.4477 6 12 6.44772 12 7H14ZM12 9C12 9.55229 12.4477 10 13 10C13.5523 10 14 9.55229 14 9H12ZM14 13C14 12.4477 13.5523 12 13 12C12.4477 12 12 12.4477 12 13H14ZM12 15C12 15.5523 12.4477 16 13 16C13.5523 16 14 15.5523 14 15H12ZM3 0C1.34315 0 0 1.34315 0 3H2C2 2.44772 2.44772 2 3 2V0ZM17 0H3V2H17V0ZM20 3C20 1.34315 18.6569 0 17 0V2C17.5523 2 18 2.44772 18 3H20ZM20 6V3H18V6H20ZM18 8C18 7.44771 18.4477 7 19 7V5C17.3431 5 16 6.34315 16 8H18ZM19 9C18.4477 9 18 8.55229 18 8H16C16 9.65685 17.3431 11 19 11V9ZM20 13V10H18V13H20ZM17 16C18.6569 16 20 14.6569 20 13H18C18 13.5523 17.5523 14 17 14V16ZM3 16H17V14H3V16ZM0 13C0 14.6569 1.34315 16 3 16V14C2.44772 14 2 13.5523 2 13H0ZM0 10V13H2V10H0ZM2 8C2 8.55228 1.55228 9 1 9V11C2.65685 11 4 9.65685 4 8H2ZM1 7C1.55228 7 2 7.44772 2 8H4C4 6.34315 2.65685 5 1 5V7ZM0 3V6H2V3H0ZM12 1V3H14V1H12ZM12 7V9H14V7H12ZM12 13V15H14V13H12Z" fill="#C4C4C4"/>
                                                            </svg>

                                                            <span className="ms-3">Login untuk melihat harga.</span>
                                                        </div>
                                                    )
                                                }
                                            
                                                <div className="alert alert-warning" hidden={true}>
                                                    Pendaftaran/Pembelian Tiket dapat dilakukan pada tanggal dan jam yang telah ditentukan.
                                                    <div className="mt-3" style={{
                                                        fontWeight: 'bold'
                                                    }}>
                                                        { moment(event.register_start_datetime).format('DD MMMM YYYY HH:mm') } - { moment(event.register_end_datetime).format('DD MMMM YYYY HH:mm') }
                                                    </div>
                                                </div>

                                                <br/>

                                                {
                                                    !event.is_already_bought_ticket ? (
                                                        event.is_register_date ? (
                                                            event.stock_left > 0 ? (
                                                                <button onClick={() => buyTicket()} className="btn btn-default" style={{
                                                                    width: '100%',
                                                                }}>
                                                                    Daftar
                                                                    {
                                                                        event.event_type == "1" && parseInt(event.total_cost) > 0 ? (
                                                                            <>
                                                                                :&nbsp;{ intToMoney(event.total_cost) }
                                                                            </>
                                                                        ) : null
                                                                    }
                                                                </button>
                                                            ) : (
                                                                <button disabled={true} className="btn btn-default" style={{
                                                                    width: '100%',
                                                                }}>
                                                                    Stok Habis
                                                                </button>
                                                            )
                                                        ) : (
                                                            <div className="alert alert-danger">{ event.register_date_error }</div>
                                                        )
                                                    ) : (
                                                        <>
                                                        <button variant="danger" disabled={true} className="btn btn-default" style={{
                                                            width: '100%',
                                                        }}>
                                                            Tiket Sudah Dibeli
                                                        </button>
                                                        <div style={{
                                                            textAlign: 'right',
                                                            cursor: 'pointer',
                                                            color: 'blue',
                                                            marginTop: '15px'
                                                        }} onClick={() => navigate('/event-transaction')}>Lihat Riwayat Pembelian <FontAwesomeIcon icon={faChevronRight}/></div>
                                                        </>
                                                    )
                                                }

                                                {/* certificates */}
                                                {
                                                    event.certificates !== null ? (
                                                        <button onClick={handleShowCertificates} className="btn btn-default-outline mt-3" style={{
                                                            width: '100%',
                                                        }}>Unduh Sertifikat</button>
                                                    ) : null
                                                }
                                                <Modal show={showCertificates} onHide={handleCloseCertificates}>
                                                    <Modal.Body closeButton>
                                                        {
                                                            event !== null ? 
                                                                event.certificates !== null ?
                                                                    event.certificates.map((certificate, index) => (
                                                                        <div key={index} style={{
                                                                            padding: '10px',
                                                                            borderBottom: '1px solid #ddd'
                                                                        }}>
                                                                            <div style={{
                                                                                display: 'inline-block',
                                                                                width: '50%',
                                                                            }}>
                                                                                { 'Hari '+certificate.day+': '+moment(certificate.date).format('DD MMMM YYYY') }
                                                                            </div>
                                                                            <div style={{
                                                                                display: 'inline-block',
                                                                                width: '50%',
                                                                                textAlign: 'right',
                                                                            }}>
                                                                                <a href={certificate.path} target="_blank" className="def-link">Unduh Sertifikat</a>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                : null
                                                            : null
                                                        }
                                                    </Modal.Body>
                                                </Modal>

                                                {/* materi */}
                                                {
                                                    event.materi !== null ? (
                                                        <a href={event.materi} target="_blank" className="btn btn-default-outline mt-3" style={{
                                                            width: '100%',
                                                        }}>Unduh Materi</a>
                                                    ) : null
                                                }
                                            </div>
                                        </> : <></>
                                    }   
                                </Col>
                            </Row>
                        </>
                    )
                }
            </Container>
        </>
    );
}

export default EventDetail;