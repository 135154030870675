import React, { useEffect, useState }from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import swal from "sweetalert2";
import Loading from "../components/Loading";
import { forgotPassword } from "../services/auth";
import { Link, useNavigate } from "react-router-dom";
import withReactContent from 'sweetalert2-react-content';
const MySwal = withReactContent(swal);

const ForgotPasswordScreen = () => {
    const information = useSelector((state) => state.information);
    const navigate = useNavigate();

    const [username, setUsername] = useState("");
    const [loading, setLoading] = useState(false);

    const submit = async e => {
        e.preventDefault();

        setLoading(true);
        
        const res = await forgotPassword(username);
        if(res.error === null){
            MySwal.fire({
                html : (
                    <div className="text-center p-4">
                        <img src={information.main_logo} alt="logo" style={{width: "150px"}}/>
                        <div className="mt-3" style={{
                            fontSize: "18pt",
                            fontWeight: "bold",
                        }}>
                            Periksa Email anda
                        </div>
                        <div className="mt-3 text-muted" style={{
                            fontSize: "12pt",
                            fontWeight: "normal",
                        }}>
                            Kami telah mengirimkan intruksi untuk atur ulang Kata Sandi ke alamat email<br/>{res.data}.
                        </div>
                        <div className="mt-3 text-muted" style={{
                            fontSize: "12pt",
                            fontWeight: "normal",
                        }}>
                            Tidak mendapatkan email? Coba periksa folder spam atau folder email masuk lainnya.
                        </div>
                    </div>
                ),
                showConfirmButton: false,
            });

            setUsername("");
        }else{
            new swal(
                "Error",
                res.error,
                "error"
            );
        }

        setLoading(false);
    }

    return (
        <>
        <div className="login-container">
            <div className="login-image d-none d-md-none d-xl-block d-xxl-block" style={{
                    backgroundImage: `url(/img/register.png)`,
                    backgroundSize: "cover",
                }}>
                    <img onClick={() => navigate('/') } style={{
                        cursor: "pointer",
                    }} src={information.white_logo} className="icon"/>
            </div>
            <div className="login-content">
                <img onClick={() => navigate('/') } style={{
                        cursor: "pointer",
                        width: "150px",
                }} src={information.main_logo} className="m-4 d-block d-md-block d-xl-none d-xxl-none" />
                <div className="text-end p-5 d-none d-md-none d-xl-block d-xxl-block">
                    Sudah memiliki akun? <Link to="/login" className="def-link">Masuk</Link>
                </div>
                <Container>
                    <Row className="justify-content-center">
                        <Col 
                        sm={{
                            span: 12
                        }} 
                        md={{
                            span: 10
                        }}
                        lg={{
                            span: 10
                        }}
                        xl={{
                            span: 10
                        }}
                        xxl={{
                            span: 6
                        }}
                        >
                            <div className="pb-5 p-4">
                                <div style={{
                                    fontWeight: "bold",
                                    fontSize: "14pt",
                                }}>
                                    Lupa Kata Sandi
                                </div>
                                <div className="mt-3 mb-3">
                                    Jangan khawatir! Masukkan alamat email, username atau nomor handphone lalu kami akan mengirimkan instruksi untuk atur ulang Kata Sandi.
                                </div>
                                <form onSubmit={submit}>
                                    <label><b>Email / Username</b></label>
                                    <input type="text" disabled={loading} value={username} onChange={e => setUsername(e.target.value)} className="form-control mt-3" placeholder="E-mail atau Username" required/>
                                    <div className="mt-5">
                                        <button disabled={loading} type="submit" className="btn btn-default">
                                            {
                                                loading ? <Loading/> : 'Atur Ulang Kata Sandi'
                                            }
                                        </button>
                                    </div>
                                </form>
                                <div className="text-center p-5 d-block d-md-block d-xl-none d-xxl-none">
                                        Sudah memiliki akun? <Link to="/login" className="def-link">Masuk</Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    );
}

export default ForgotPasswordScreen;