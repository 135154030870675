import { createSlice } from '@reduxjs/toolkit'

export const portfolioSlice = createSlice({
  name: 'portfolio',
  initialState: {
    selectedPortfolioId: null,
  },
  reducers: {
    showPortfolioDetail: (state, action) => {
      state.selectedPortfolioId = action.payload;
    }
  },
})

// Action creators are generated for each case reducer function
export const { showPortfolioDetail } = portfolioSlice.actions

export default portfolioSlice.reducer