import React, { useEffect, useState } from "react";
import { Row, Col, Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
    update,
    create,
    read,
    getCities
} from './../../services/userShippingAddress';
import swal from 'sweetalert';
import Loading from "../Loading";

const AddEditShippingAddress = (props) => {
    const id = props.id;

    const auth = useSelector((state) => state.auth);

    const [loading, setLoading] = useState(false);

    const [addressLabel, setAddressLabel] = useState("");
    const [receiverName, setReceiverName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [city, setCity] = useState("");
    const [cityId, setCityId] = useState('');
    const [fullAddress, setFullAddress] = useState("");
    const [postCode, setPostCode] = useState("");
    const [note, setNote] = useState("");
    const [isMainAddress, setIsMainAddress] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [cities, setCities] = useState([]);

    useEffect(() => {
        async function getCityList(isAdd){
            if(isAdd === null){
                setLoading(true);
            }

            const res = await getCities(auth.token);
            if(res.error === null){
                setCities(res.data);
            }else{
                swal('Error', res.error, 'Error');
            }

            if(isAdd === null){
                setLoading(false);
            }
        }

        if(id !== null){
            async function getData(id){
                setLoading(true);

                await getCityList(false);

                const res = await read(auth.token, id);
                if(res.error === null){
                    setAddressLabel(res.data.address_label);
                    setReceiverName(res.data.receiver_name);
                    setPhoneNumber(res.data.phone_number);
                    setCity(res.data.city);
                    setCityId(res.data.city_id);
                    setFullAddress(res.data.full_address);
                    setPostCode(res.data.postcode);
                    setNote(res.data.note);
                    setIsMainAddress(res.data.is_main_address === "0" ? false : true);
                }

                setLoading(false);
            }

            getData(id);
        }else{
            getCityList(true);
        }
    }, [auth, id]);

    const closeModal = () => {
        resetForm();
        props.setShowModal(false);
    }

    const resetForm = () => {
        //reset form
        setAddressLabel("");
        setReceiverName("");
        setPhoneNumber("");
        setCity("");
        setCityId("");
        setFullAddress("");
        setPostCode("");
        setNote("");
        setIsMainAddress(false);
    }

    const addData = async (e) => {
        e.preventDefault();

        //set loading
        setIsLoading(true);

        let res = {
            error: null,
        }

        //check if add or update
        if(id === null){
            //insert
            res = await create(
                auth.token,
                addressLabel,
                receiverName,
                phoneNumber,
                city,
                cityId,
                fullAddress,
                postCode,
                note,
                isMainAddress
            );
        }else{
            //edit
            res = await update(
                auth.token,
                id,
                addressLabel,
                receiverName,
                phoneNumber,
                city,
                cityId,
                fullAddress,
                postCode,
                note,
                isMainAddress
            );
        }

        if(res.error === null){
            //success
            swal("Sukses!", "Berhasil "+(id == null ? 'menambah' : 'mengubah')+" data.", "success");

            resetForm();

            //set refresh detail
            props.refreshCallback();

            //close modal
            closeModal();
        }

        setIsLoading(false);
    }

    return (
        <Modal size="lg" show={props.isShow} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>Alamat Pengiriman</Modal.Title>
            </Modal.Header>
            {
                loading ? <Loading /> : (
                    <form onSubmit={addData}>
                        <Modal.Body>
                            <Row>
                                <Col lg={6}>
                                    <div>
                                        <label>Judul Alamat</label>
                                        <input type="text" className="form-control" value={addressLabel} onChange={e => setAddressLabel(e.target.value)} required={true}/>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div>
                                        <label>Nama Penerima</label>
                                        <input type="text" className="form-control" value={receiverName} onChange={e => setReceiverName(e.target.value)} required={true}/>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col lg={6}>
                                    <div>
                                        <label>Nomor Handphone</label>
                                        <input pattern="[0-9]{0,50}" type="text" className="form-control" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} required={true}/>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="mt-3">
                                        <label>Alamat Utama</label>
                                        &nbsp;&nbsp;<input type="checkbox" checked={isMainAddress} onChange={e => setIsMainAddress(e.target.checked)} required={false}/>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col lg={6}>
                                    <div>
                                        <label>Kota</label>
                                        <select value={cityId} onChange={e => {
                                            setCityId(e.target.value)

                                            //get object by city id
                                            const selectedCity = cities.find(c => c.city_id === e.target.value);
                                            if(selectedCity !== undefined){
                                                setCity(selectedCity.city_name);
                                            }
                                        }} className="form-select" required>
                                            <option value="">-- Select City --</option>
                                            {
                                                cities.map(city => (
                                                    <option data-city={city.city_name} value={city.city_id} key={city.city_id}>{ city.city_name+' ('+city.province+') - Area '+city.postal_code }</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div>
                                        <label>Kode Pos (Spesifik)</label>
                                        <input type="text" className="form-control" value={postCode} onChange={e => setPostCode(e.target.value)} required={false}/>
                                    </div>
                                </Col>
                            </Row>
                    
                            <div className="mt-3">
                                <label>Alamat Lengkap</label>
                                <textarea  className="form-control" value={fullAddress} onChange={e => setFullAddress(e.target.value)} required={false}/>
                            </div>
                            <div className="mt-3">
                                <label>Catatan Pengiriman</label>
                                <textarea  className="form-control" value={note} onChange={e => setNote(e.target.value)} required={false}/>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                        <Button variant="secondary" onClick={closeModal}>
                            Tutup
                        </Button>
                        <Button variant="danger" type="submit" disabled={isLoading}>
                            Simpan
                        </Button>
                        </Modal.Footer>
                    </form>
                )
            }
        </Modal>
    );
}

export default AddEditShippingAddress;