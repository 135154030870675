import React, { useEffect, useState }from "react";
import { Col, Container, Row } from "react-bootstrap";
import Checkout from "../components/cart/Checkout";
import config from "../helpers/config";

const CheckoutScreen = () => {
    //change title
    useEffect(() => {
        document.title = config.documentTitle + " - Checkout";

        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="default-nav-mt">
            <Container>
                <Row>
                    <Col sm={12}>
                        <Checkout />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default CheckoutScreen;