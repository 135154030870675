import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingBag, faBell, faQrcode, faCaretDown, faHome, faUser, faCaretUp, faSearch, faWindowClose } from '@fortawesome/free-solid-svg-icons'
import {
    Link as RouterLink,
} from "react-router-dom";
import {
    Navbar,
    Container,
    Nav,
    Offcanvas,
    NavDropdown,
    Tabs,
    Tab,
    Row,
    Col,
} from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import {
    logout
} from './../redux/slicers/authSlice';
import swal from 'sweetalert';
import {
    setShowNotificationScreen
} from './../redux/slicers/notificationSlice';
import { setShowCartScreen } from "../redux/slicers/cartSlice";
import {
    Menu,
    SubMenu,
    MenuItem
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import { useNavigate, useLocation } from "react-router";
import MembershipBadge from "./MembershipBadge";

const DefaultNavbar = (props) => {
    const userNavbar = typeof props.userNavbar !== 'undefined' ? props.userNavbar : false;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const information = useSelector((state) => state.information);
    const auth = useSelector((state) => state.auth);
    const account = useSelector((state) => state.account);
    const notification = useSelector((state) => state.notification);
    const cart = useSelector((state) => state.cart);

    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const [isMenuCollapse, setIsMenuCollapse] = useState(true);
    const [keywordSearch, setKeywordSearch] = useState('');
    const [hideHighlight, setHideHighlight] = useState(false);

    const logoutConfirm = () => {
        //confirm
        swal({
            title: "Are you sure?",
            text: "Logout from this account?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then(async (willDelete) => {
            if (willDelete) {
                dispatch(logout());
            } 
        });
    }

    const LoggedInNavbar = () => {
        return(
            <>
                <div className="d-none d-md-none d-xl-block d-xxl-block" style={{
                    backgroundColor: 'transparent',
                    width: '100%',
                    height: '30px',
                    top:0,
                    position: 'fixed',
                    zIndex: '999',
                }}>
                    <Container>
                        <ul className="logged-in-navbar">
                            <li>
                                <a href="#logout">Selamat Datang, <b>{ account.name }</b></a>
                            </li>
                            <li>
                                <form target="_blank" method="post" action={`${process.env.REACT_APP_BASE_URL}admin/account/login_member`}>
                                    <input type="hidden" name="token" value={auth.token} />
                                    <button type="submit">CMS</button>
                                </form>
                            </li>
                            <li>
                                <RouterLink to="/dashboard">Profile</RouterLink>
                            </li>
                            <li>
                                <a href="#logout" onClick={() => logoutConfirm()}>Logout</a>
                            </li>
                        </ul>
                    </Container>
                </div>
                <div className="d-block d-md-block d-xl-none d-xxl-none">
                    <div><b>{ account.name }</b></div>
                    <div>
                        <MembershipBadge />
                    </div>
                </div>
            </>
        );
    }

    const openNotification = () => {
        dispatch(setShowNotificationScreen(true));
    }

    const openCart = () => {
        dispatch(setShowCartScreen(true));
    }

    const mobileNavigate = (url) => {
        //close canvas
        setShowMobileMenu(false);

        //check if start with http
        if (url.indexOf('http') === -1) {
            navigate(url);
        }else{
            window.open(url, '_blank');
        }
    }

    const dekstopNavigate = (url) => {
        //close canvas
        setIsMenuCollapse(true);

        //check if start with http
        if (url.indexOf('http') === -1) {
            navigate(url);
        }else{
            window.open(url, '_blank');
        }
    }

    return (
        <>
            {/* navbar on dekstop */}
            <Navbar className="d-none d-md-none d-xl-block d-xxl-block" collapseOnSelect fixed="top" expand="lg">
                {
                    information !== null ? (
                        information.highlight_active && !hideHighlight ? (
                            <div className="highlight-area text-center">
                                <div dangerouslySetInnerHTML={{ __html: information.highlight_text }} />

                                {/* close button */}
                                <div style={{
                                    position: 'absolute',
                                    right: '0',
                                    top: '5px',
                                    paddingRight: '10px',
                                    cursor: 'pointer',
                                }} onClick={() => {
                                    setHideHighlight(true);
                                }}>
                                    <FontAwesomeIcon icon={faWindowClose}/>
                                </div>
                            </div>
                        ) : null
                    ) : null
                }
                <Container className="pt-1">
                    <Navbar.Brand onClick={() => {
                        navigate('/');
                    }}>
                        <img alt="logo" src={information.white_logo} style={{
                            width:'100px',
                            cursor: 'pointer'
                        }}/>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav hidden={userNavbar} className="me-auto" style={{
                            marginLeft:'17px'
                        }}>
                            <span className="nav-link" style={{
                                cursor: 'pointer',
                                borderLeft: '1px solid #e6e6e6',
                                paddingLeft: '20px',
                            }} onClick={() => setIsMenuCollapse(!isMenuCollapse) }>
                                Menu <FontAwesomeIcon className="ms-2" icon={isMenuCollapse ? faCaretDown : faCaretUp}/>
                            </span>

                            <form onSubmit={(e) => {
                                e.preventDefault();
                                setKeywordSearch('');
                                navigate('/search/'+keywordSearch);
                            }} style={{
                                marginLeft: '27px',
                                width: '600px'
                            }}>
                                <FontAwesomeIcon icon={faSearch} style={{
                                    position: 'absolute',
                                    color: '#fff',
                                    marginTop: '10px',
                                    marginLeft: '10px',
                                }}/>
                                <input type="text" value={keywordSearch} onChange={e => setKeywordSearch(e.target.value)} className="form-control" style={{
                                    backgroundColor: '#373737',
                                    color: '#fff',
                                    paddingLeft: '40px',
                                }}/>
                            </form>

                            
                        </Nav>
                        <Nav hidden={!userNavbar} className="me-auto">
                            <RouterLink className="nav-link" to="/">Beranda</RouterLink>
                            <RouterLink className="nav-link" to="/events">Acara &amp; Kegiatan</RouterLink>
                            <RouterLink className="nav-link" to="/products">Gerai</RouterLink>
                        </Nav>
                        <Nav>
                            {
                                auth.isAuthenticated ?
                                (
                                    <>
                                        {
                                            account.is_admin ? (
                                                <form target="_blank" className="me-4" method="post" action={`${process.env.REACT_APP_BASE_URL}admin/account/login_member`}>
                                                    <input type="hidden" name="token" value={auth.token} />
                                                    <button type="submit" className="btn btn-default-outline">Masuk Portal</button>
                                                </form>
                                            ) : null
                                        }
                                    </>
                                ) : null
                            }

                            <a href="#!" className="nav-link" onClick={() => openCart()}>
                                <span hidden={ cart.cart.length < 1 } style={{
                                    position: 'absolute',
                                    backgroundColor: '#e6e6e6',
                                    color: '#890e25',
                                    width: '15px',
                                    height: '15px',
                                    borderRadius: '50%',
                                    fontSize: '11px',
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    marginTop: '-10px',
                                    marginLeft: '8px',
                                }}>{ cart.cart.length }</span>
                                <FontAwesomeIcon icon={faShoppingBag} />
                            </a>

                            {
                                auth.isAuthenticated ?
                                (
                                    <>
                                        <a href="#!" className="nav-link" onClick={() => openNotification()}>
                                            <span hidden={ notification.totalUnreadNotifications < 1 } style={{
                                                position: 'absolute',
                                                backgroundColor: '#e6e6e6',
                                                color: '#890e25',
                                                width: '15px',
                                                height: '15px',
                                                borderRadius: '50%',
                                                fontSize: '11px',
                                                fontWeight: 'bold',
                                                textAlign: 'center',
                                                marginTop: '-10px',
                                                marginLeft: '8px',
                                            }}>{ notification.totalUnreadNotifications }</span>
                                            <FontAwesomeIcon icon={faBell}/>
                                        </a>
                                        <RouterLink className="nav-link me-4" to="/scan">
                                            <FontAwesomeIcon icon={faQrcode}/>
                                        </RouterLink>
                                    </>
                                )
                                :
                                (
                                    <>
                                    <RouterLink className="btn btn-default ms-4" to="/login">Masuk</RouterLink>
                                    </>
                                )
                            }
                          
                            {
                                auth.isAuthenticated ? (
                                    <Menu menuButton={
                                        <div style={{
                                            borderLeft:'1px solid #bdbdbd',
                                            paddingLeft: '25px'
                                        }}>
                                            <img alt="user iai avatar" src={account.avatar_url} style={{
                                                width:'40px',
                                                height:'40px',
                                                borderRadius:'50%',
                                                cursor:'pointer'
                                            }}/>
                                            <FontAwesomeIcon className="ms-2" style={{
                                                color:'#a10f2b',
                                                cursor:'pointer'
                                            }} icon={faCaretDown} />
                                        </div>
                                    }>
                                        <MenuItem><RouterLink className="dropdown-item" to="/dashboard">Profil</RouterLink></MenuItem>
                                        <MenuItem><a href="#logout" className="dropdown-item" onClick={() => logoutConfirm()}>Logout</a></MenuItem>
                                    </Menu>
                                ) : null
                            }
                        </Nav>
                    </Navbar.Collapse>
                </Container>
                <div className="big-menu" hidden={isMenuCollapse || userNavbar} style={{
                    background: '#1d1d1d url("/img/mask-navbar-img.png")',
                    width: '100%',
                    left: 0,
                    borderTop: '1px solid #6A707E',
                }}>
                    <div style={{
                        width:'100%',
                        height:'1px',
                        backgroundColor:'#6A707E',
                        position:'absolute',
                        marginTop:'49px',
                    }}></div>
                    <div className="container">
                        <Tabs
                            defaultActiveKey={0}
                            transition={false}
                            id="noanim-tab-example"
                            className="mt-2 menu-nav-tabs"
                        >
                            {
                            information.menus === null ? null :
                                information.menus.map((menu, menuIndex) => (
                                    menu.sub_menus === null || menu.sub_menus.length < 1 ? (
                                        <Tab key={'menu_'+menuIndex} eventKey={'menu_'+menuIndex} title={menu.label}></Tab>
                                    )
                                    : (
                                        <Tab key={'menu_'+menuIndex} eventKey={'menu_'+menuIndex} title={menu.label} disabled={menu.is_disabled === '1'}>
                                            {
                                                menuIndex === 0 ? (
                                                    <div className="pt-3 pb-5 mb-3 sub_submenu">
                                                        {
                                                            menu.sub_menus.map((sub_menu, subMenuIndex) => (
                                                                <Nav.Link key={'subsubmenu_'+menuIndex+'_'+subMenuIndex} onClick={() => dekstopNavigate(sub_menu.link)} style={{
                                                                    float:'left'
                                                                }} disabled={sub_menu.is_disabled === '1'}>{ sub_menu.label }</Nav.Link>
                                                            ))
                                                        }
                                                    </div>
                                                ) : (
                                                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                                        <Row>
                                                            <Col sm={3} className="pt-3 pb-3" style={{
                                                                borderRight: '1px solid #6A707E',
                                                            }}>
                                                                <Nav variant="pills" className="flex-column menu-nav-pills">
                                                                {
                                                                    menu.sub_menus.map((sub_menu, subMenuIndex) => (
                                                                        <Nav.Item key={'submenu_'+menuIndex+'_'+subMenuIndex}>
                                                                            <Nav.Link eventKey={ 'submenu_'+menuIndex+'_'+subMenuIndex } disabled={sub_menu.is_disabled === '1'}>{ sub_menu.label }</Nav.Link>
                                                                        </Nav.Item>
                                                                    ))
                                                                }
                                                                </Nav>
                                                            </Col>
                                                            <Col sm={9} className="pt-3 pb-3 sub_submenu">
                                                                <Tab.Content>
                                                                    {
                                                                        menu.sub_menus.map((sub_menu, subMenuIndex) => (
                                                                            <Tab.Pane key={'submenu_'+menuIndex+'_'+subMenuIndex} eventKey={ 'submenu_'+menuIndex+'_'+subMenuIndex }>
                                                                                {
                                                                                    sub_menu.sub_menus.map((sub_sub_menu, subSubMenuIndex) => (
                                                                                        <Nav.Link key={'subsubmenu_'+menuIndex+'_'+subMenuIndex+'_'+subSubMenuIndex} className="nav-link" onClick={() => dekstopNavigate(sub_sub_menu.link)} style={{
                                                                                            float:'left'
                                                                                        }} disabled={sub_sub_menu.is_disabled === '1'}>{ sub_sub_menu.label }</Nav.Link>
                                                                                    ))
                                                                                }
                                                                            </Tab.Pane>
                                                                        ))
                                                                    }
                                                                </Tab.Content>
                                                            </Col>
                                                        </Row>
                                                    </Tab.Container>
                                                )
                                            }
                                        </Tab>
                                    )
                                ))
                            }
                        </Tabs>
                    </div>
                </div>
            </Navbar>

            <div className="d-none d-md-none d-xl-block d-xxl-block" style={{
                height: location.pathname === '/' ? '0px' : '50px'
            }}>
            </div>

            {/* navbar on mobile */}
            <Navbar sticky="top" className="navbar-mobile m-nav d-block d-md-block d-xl-none d-xxl-none" bg="light" expand={false}>
                {
                    information !== null ? (
                        information.highlight_active ? (
                            <div className="highlight-area text-center">
                                <div dangerouslySetInnerHTML={{ __html: information.highlight_text }} />
                            </div>
                        ) : null
                    ) : null
                }
                
                {
                    auth.isAuthenticated ? (
                        <div className="mobile-bottom-nav">
                            <div className="row">
                            <div className="col-2 text-center">
                                <a href="#!" className="nav-link" onClick={() =>  mobileNavigate('/')}>
                                    <FontAwesomeIcon icon={faHome} />
                                </a>
                            </div>
                            <div className="col-2 text-center">
                                <a href="#!" className="nav-link" onClick={() => openCart()}>
                                    <span hidden={ cart.cart.length < 1 } style={{
                                        position: 'absolute',
                                        backgroundColor: '#e6e6e6',
                                        color: '#890e25',
                                        width: '15px',
                                        height: '15px',
                                        borderRadius: '50%',
                                        fontSize: '11px',
                                        fontWeight: 'bold',
                                        textAlign: 'center',
                                        marginTop: '-10px',
                                        marginLeft: '8px',
                                    }}>{ cart.cart.length }</span>
                                    <FontAwesomeIcon icon={faShoppingBag} />
                                </a>
                            </div>
                            <div className="col-4 text-center">
                                <RouterLink className="btn btn-default btn-scan-mobile" to="/scan">
                                    <FontAwesomeIcon icon={faQrcode}/>
                                </RouterLink>
                            </div>
                        
                            <div className="col-2 text-center">
                                <a href="#!" className="nav-link" onClick={() => openNotification()}>
                                    <span hidden={ notification.totalUnreadNotifications < 1 } style={{
                                        position: 'absolute',
                                        backgroundColor: '#e6e6e6',
                                        color: '#890e25',
                                        width: '15px',
                                        height: '15px',
                                        borderRadius: '50%',
                                        fontSize: '11px',
                                        fontWeight: 'bold',
                                        textAlign: 'center',
                                        marginTop: '-10px',
                                        marginLeft: '8px',
                                    }}>{ notification.totalUnreadNotifications }</span>
                                    <FontAwesomeIcon icon={faBell}/>
                                </a>
                            </div>
                            <div className="col-2 text-center">
                                <a href="#!" className="nav-link" onClick={() =>  mobileNavigate('/dashboard')}>
                                    <FontAwesomeIcon icon={faUser}/>
                                </a>
                            </div>
                        </div>
                    </div>
                    ) : (
                        <div></div>
                    )
                }

                <Container fluid className="mt-3">
                    <Navbar.Brand href="#" style={{
                        marginLeft: '10px',
                    }}>
                        <span href="#!" onClick={() => mobileNavigate('/') }>
                            <img alt="logo" src={information.white_logo} style={{
                                width:'70px',
                                cursor: 'pointer'
                            }}/>
                        </span>
                    </Navbar.Brand>
                    <Offcanvas
                        placement="end"
                        show={showMobileMenu}
                        onHide={() => setShowMobileMenu(false)}
                    >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id="offcanvasNavbarLabel">
                                <a href="#!" onClick={() => mobileNavigate('/') }>
                                    <img alt="logo" src={information.main_logo} style={{
                                        width:'100px',
                                        cursor: 'pointer'
                                    }}/>
                                </a>
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            {
                                auth.isAuthenticated ? <LoggedInNavbar /> : null
                            }
                            {
                                auth.isAuthenticated ?
                                (
                                    <>
                                        {
                                            account.is_admin ? (
                                                <form target="_blank" className="mt-4" method="post" action={`${process.env.REACT_APP_BASE_URL}admin/account/login_member`}>
                                                    <input type="hidden" name="token" value={auth.token} />
                                                    <button type="submit" className="btn btn-default-outline">Masuk Portal</button>
                                                </form>
                                            ) : null
                                        }
                                    </>
                                ) : null
                            }
                            <Nav className="justify-content-end flex-grow-1 pe-3 mt-3"
                                style={{
                                    overflowX: 'scroll',
                                }}>
                                {
                                    information.menus === null ? null :
                                    information.menus.map((menu, index) => (
                                        menu.sub_menus === null || menu.sub_menus.length < 1 ? (
                                            <Nav.Link disabled={menu.is_disabled === '1'} key={index} onClick={() => mobileNavigate(menu.link) }>{ menu.label }</Nav.Link>
                                        ) : (
                                            <NavDropdown disabled={menu.is_disabled === '1'} key={index} className="mobile-dropdown" title={menu.label} id="offcanvasNavbarDropdown">
                                                {
                                                    menu.sub_menus.map((sub_menu, index) => (
                                                        sub_menu.sub_menus === null || sub_menu.sub_menus.length < 1 ? (
                                                            <NavDropdown.Item disabled={sub_menu.is_disabled === '1'} key={'submenu'+index} className="m-link-m" onClick={() => mobileNavigate(sub_menu.link) }>{ sub_menu.label }</NavDropdown.Item>
                                                        ) : (
                                                            <div key={'submenu'+index}>
                                                                <NavDropdown.Item disabled={sub_menu.is_disabled === '1'}><b>{sub_menu.label}</b></NavDropdown.Item>
                                                                {
                                                                    sub_menu.sub_menus.map((sub_sub_menu, index) => (
                                                                        <NavDropdown.Item disabled={sub_sub_menu.is_disabled === '1' || sub_menu.is_disabled === '1'} key={'subsubmenu'+index} className="m-link-m" onClick={() => mobileNavigate(sub_sub_menu.link) }>{ sub_sub_menu.label }</NavDropdown.Item>
                                                                    ))
                                                                }
                                                            </div>
                                                        )
                                                    ))
                                                }
                                            </NavDropdown>
                                        )
                                    ))
                                }

                                {
                                    auth.isAuthenticated ? (
                                        <>
                                            <Nav.Link onClick={() => mobileNavigate('/dashboard') }>Profil</Nav.Link>
                                            <Nav.Link onClick={() => logoutConfirm()}>Logout</Nav.Link>
                                        </>
                                    ) : (
                                        <Nav.Link onClick={() => mobileNavigate('/login') }>Masuk</Nav.Link>
                                    )
                                }
                            </Nav>
                            {/* <Form className="d-flex">
                                <FormControl
                                    type="search"
                                    placeholder="Search"
                                    className="me-2"
                                    aria-label="Search"
                                />
                                <Button variant="outline-success">Search</Button>
                            </Form> */}
                        </Offcanvas.Body>
                    </Offcanvas>
                    <button type="button btn" style={{
                        background: 'transparent',
                        border: 'none',
                        outline: 'none',
                    }} onClick={() => setShowMobileMenu(true)}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4 6H20M4 12H20M13 18H20" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </button>

                </Container>
            </Navbar>
        </>
    );
}

export default DefaultNavbar;