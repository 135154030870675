import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router";
import CheckoutMembership from "../components/membership/CheckoutMembership";

const MembershipCheckoutScreen = () => {
    const params = useParams();
    const id = params.id; 

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container>
            <Row>
                <Col sm={12}>
                    <CheckoutMembership id={id}/>
                </Col>
            </Row>
        </Container>
    );
}

export default MembershipCheckoutScreen;