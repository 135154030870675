import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import config from '../helpers/config';

const ProfileNav = (props) => {
    const account = useSelector((state) => state.account);
    const activeIndex = props.activeIndex;

    return(
        <ul className="nav nav-pills">
            <li className="nav-item">
                <Link className={"nav-link" + (activeIndex === 1 ? ' active' : '')} to="/edit-profile">Ubah Profil</Link>
            </li>
            <li className="nav-item">
                <Link className={"nav-link" + (activeIndex === 2 ? ' active' : '')} to="/security">Keamanan</Link>
            </li>

            {
                account.membership !== null && typeof account.membership !== 'undefined' ? (
                    account.membership.type == config.membershipType.MEMBER_PRO || account.membership.type == config.membershipType.MEMBER ? (
                        <li className="nav-item">
                            <Link className={"nav-link" + (activeIndex === 3 ? ' active' : '')} to="/portfolios">Portofolio</Link>
                        </li>
                    )
                    : null
                ) : null
            }
            
            <li className="nav-item">
                <Link className={"nav-link" + (activeIndex === 4 ? ' active' : '')} to="/educations">Educations</Link>
            </li>
            <li className="nav-item">
                <Link className={"nav-link" + (activeIndex === 5 ? ' active' : '')} to="/employments">Employments</Link>
            </li>
            <li className="nav-item">
                <Link className={"nav-link" + (activeIndex === 6 ? ' active' : '')} to="/ska">SKA</Link>
            </li>
        </ul>
    );
}

export default ProfileNav;