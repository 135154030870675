import React, { useEffect } from "react";
import { Container, Row, Col, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import EventList from "../components/events/EventList";
import LeftMenuPanel from "../components/LeftMenuPanel";
import config from "../helpers/config";

const EventsPastScreen = () => {
    //change title
    useEffect(() => {
        document.title = config.documentTitle + " - Kegiatan";

        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="default-nav-mt">
            <Container>
                <Row>
                    <Col sm={12} md={12} lg={4} className="mb-4">
                        <LeftMenuPanel activeIndex={9}/>
                    </Col>
                    <Col sm={12} md={12} lg={8}>
                        <h3><b>Kegiatan Saya</b></h3>
                        <div className="mt-3 mb-3">Anda dapat melihat kegiatan yang sedang berlangsung ataupun kegiatan yang akan anda ikuti</div>
                        <Nav variant="pills" className="mb-3">
                            <Nav.Item>
                                <Link className="nav-link" to="/events/upcoming">Kegiatan</Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Link className="nav-link" to="/events/mylist">Kegiatan Saya</Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Link className="nav-link active" to="/events/past">Telah Berakhir</Link>
                            </Nav.Item>
                        </Nav>
                        <EventList condition="past"/>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default EventsPastScreen;