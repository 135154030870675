export const renderStatus = (status) => {
    if(status === '0') {
        return <span className="badge bg-light text-dark">Pending</span>;
    }
    else if(status === '1') {
        return <span className="badge bg-success">Aktif</span>;
    }
    else if(status === '2') {
        return <span className="badge bg-secondary">Telah Digunakan</span>;
    }
    else if(status === '-1') {
        return <span className="badge bg-danger">Ditolak</span>;
    }
}