export const categories = async (page, keyword) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}downloads/categories`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            page: page,
            keyword: keyword
        })
    });
    const json = await response.json();
    return json;
}

export const latest = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}downloads/latest`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        }
    });
    const json = await response.json();
    return json;
}

export const all = async (download_category_id, page, keyword) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}downloads/all`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            download_category_id: download_category_id,
            page: page,
            keyword: keyword
        })
    });
    const json = await response.json();
    return json;
}