import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import Loading from "../components/Loading";
import config from "../helpers/config";
import { search as searchEvent } from "../services/event";
import { search as searchNews } from "../services/news";
import { search as searchProduct } from "../services/product";
import swal from "sweetalert";
import EventBox from "../components/events/EventBox";
import NewsBox from "../components/news/NewsBox";
import ProductBox from "../components/products/ProductBox";

const SearchScreen = () => {
    const auth = useSelector((state) => state.auth);

    const params = useParams();
    const [keyword, setKeyword] = useState('');
    const [keywordValue, setKeywordValue] = useState('');

    const [events, setEvents] = useState(null);
    const [articles, setArticles] = useState(null);
    const [products, setProducts] = useState(null);
    const [total, setTotal] = useState(0);

    const [loading, setLoading] = useState(false);

    //change title
    useEffect(() => {
        document.title = config.documentTitle + " - Pencarian";

        window.scrollTo(0, 0);

        //check if contain params
        if(typeof params.keyword !== 'undefined'){
            setKeyword(params.keyword);
            setKeywordValue(params.keyword);
        }
    }, [params.keyword]);

    useEffect(() => {
        async function search(keyword){
            setLoading(true);

            let total = 0;

            const eventRes = await searchEvent(auth.token, keyword, 1, null);
            if(eventRes.error === null || eventRes.error === ""){
                setEvents(eventRes.data);
                total += eventRes.data.length;
            }else{
                //show swal error
                swal("Error!", eventRes.error, "error");
            }

            const articleRes = await searchNews(auth.token, keyword, 1);
            if(articleRes.error === null || articleRes.error === ""){
                setArticles(articleRes.data);
                total += articleRes.data.length;
            }else{
                //show swal error
                swal("Error!", articleRes.error, "error");
            }

            const productRes = await searchProduct(auth.token, keyword, 1);
            if(productRes.error === null || productRes.error === ""){
                setProducts(productRes.data);
                total += productRes.data.length;
            }else{
                //show swal error
                swal("Error!", productRes.error, "error");
            }

            setTotal(total);
            setLoading(false);
        }

        if(keywordValue !== "" && keywordValue !== null){
            search(keywordValue);
        }
    }, [auth.token, keywordValue]);

    const startSearch = e => {
        e.preventDefault();

        setKeywordValue(keyword);
    }

    return (
        <div className="default-nav-mt">
            <Container>
                <Row>
                    <Col sm={12} md={12} lg={12}>
                        <h3><b>Pencarian: {keyword}</b></h3>
                        <div>
                            {total} Hasil Pencarian
                        </div>
                        <form onSubmit={startSearch} className="box p-3 mt-3 mb-3">
                            <input value={keyword} onChange={e => setKeyword(e.target.value)} type="text" maxLength={50} placeholder="Masukan kata kunci pencarian" className="form-control" required={true}/>
                            <div>
                                <button type="submit" className="btn btn-default mt-3">Cari</button>
                            </div>
                        </form>

                        {
                            loading ? <Loading /> : (
                                <>
                                    <div className="mt-5">
                                        <h5>Kegiatan</h5>
                                        {
                                            events !== null && events.length > 0 ? (
                                                events.map((event, index) => (
                                                    <div key={index}>
                                                        <EventBox event={event}/>
                                                    </div>
                                                ))
                                            ) : (
                                                <div className="text-center mt-5">
                                                    <img src="/img/tidak_ada_kegiatan.svg" />
                                                </div>
                                            )
                                        }
                                    </div>
                                    <div className="mt-5">
                                        <h5>Berita</h5>
                                        {
                                            articles !== null && articles.length > 0 ? (
                                                <div className="row">
                                                    {
                                                        articles.map((news, index) => (
                                                            <div className="col-sm-12 col-md-6 col-lg-4">
                                                                <div key={index} className="box p-3 mb-3">
                                                                    <NewsBox news={news}/>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            ) : (
                                                <div className="text-center mt-5">
                                                    <img src="/img/data_not_found.svg" />
                                                </div>
                                            )
                                        }
                                    </div>
                                    <div className="mt-5">
                                        <h5>Produk</h5>
                                        {
                                            products !== null && products.length > 0 ? (
                                                <>
                                                <div className="row">
                                                    {
                                                    products.map((product, index) => (
                                                        <div key={index} className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                                                            <ProductBox product={product}/>
                                                        </div>
                                                    ))
                                                    }
                                                </div>
                                                </>
                                            ) : (
                                                <div className="text-center mt-5">
                                                    <img src="/img/no_product.svg" />
                                                </div>
                                            )
                                        }
                                    </div>
                                </>
                            )
                        }
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default SearchScreen;