import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import Loading from "../components/Loading";
import config from "../helpers/config";
import { certificate } from "../services/event";
import {
    showUserDetail
} from './../redux/slicers/userSlice';

const CertificateCheckScreen = () => {
    const params = useParams();
    const dispatch = useDispatch();

    const [token, setToken] = useState(params.token);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    //change title
    useEffect(() => {
        document.title = config.documentTitle + " - Cek Sertifikat";

        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        console.log(token);
        if(typeof token !== 'undefined' && token !== null){
            getCertificate(token);
        }
    }, []);

    const submit = e => {
        e.preventDefault();

        getCertificate(token);
    }

    const getCertificate = async (token) => {
        setLoading(true);

        const res = await certificate(token);
        if(res.error === null){
            setError(null);
            setData(res.data);
        }else{
            setError(res.error);
            setData(null);
        }

        setLoading(false);
    }

    const viewDetail = (id) => {
        dispatch(showUserDetail(id));
    }

    return (
        <div className="default-nav-mt">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 mt-5">
                        <h3><b>Cek Keabsahan Sertifikat Kegiatan</b></h3>
                        <div className="box p-5 mt-3">
                            <form onSubmit={submit}>
                                <div className="mt-3">
                                    <label>Nomor Sertifikat</label>
                                    <input type="text" value={token} onChange={e => setToken(e.target.value)} placeholder="CRT-000001" className="form-control" required/>    
                                    <button className="btn btn-default mt-3">Cek</button>
                                </div>
                            </form>
                            <div>
                                {
                                    loading ? <Loading/> : (
                                        <>
                                            {
                                                data !== null ? (
                                                    <div className="mt-5">
                                                        <h3>Sertifikat</h3>
                                                        <div className="mt-3">
                                                            <div className="row">
                                                                <div className="col-md-6 col-lg-4 mt-3">
                                                                    <b>Nomor Sertifikat: </b>
                                                                    <div>
                                                                        { data.token }
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 col-lg-4 mt-3">
                                                                    <b>Nama: </b>
                                                                    <div className="def-link" onClick={() => viewDetail(data.user_id)}>
                                                                        { data.first_name + " " + data.last_name }
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 col-lg-4 mt-3">
                                                                    <b>Email: </b>
                                                                    <div>
                                                                        { data.email_address }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6 col-lg-4 mt-3">
                                                                    <b>Nama Event: </b>
                                                                    <div>
                                                                        { data.event_title }
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 col-lg-4 mt-3">
                                                                    <b>KUM: </b>
                                                                    <div>
                                                                        { data.kum }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div hidden={error === null} className="alert alert-danger">{ error }</div>
                                                )
                                            }
                                        </>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CertificateCheckScreen;