import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Gallery from "../components/gallery/Gallery";
import config from "../helpers/config";

const GalleryScreen = () => {
    //change title
    useEffect(() => {
        document.title = config.documentTitle + " - Gallery";

        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="default-nav-mt">
            <Container>
                <Row>
                    <Col lg={12} md={12} sm={12}>
                        <Gallery />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default GalleryScreen;