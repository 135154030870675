import React, { useEffect, useState } from 'react';
import {
    licenseOrder
} from './../../services/transaction';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons'
import {
    uploadPayment
} from './../../services/license';
import swal from 'sweetalert';
import { useSelector } from 'react-redux';
import { renderLicenseType, renderStatus } from '../../helpers/license';
import { intToMoney } from '../../helpers/currency';
import { Button, Col, Row } from 'react-bootstrap';
import {useDropzone} from 'react-dropzone';
import BankList from '../bank/BankList';
import Loading from '../Loading';
import config from '../../helpers/config';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Stepper } from 'react-form-stepper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const UserLicenseDetail = (props) => {
    const id = props.id;

    const auth = useSelector((state) => state.auth);

    const [loading, setLoading] = useState(null);
    const [order, setOrder] = useState(null);
    const [image, setImage] = useState("");
    const [uploadedImage, setUploadedImage] = useState("");

    useEffect(() => {
        async function getDetail(id){
            setLoading(true);

            const res = await licenseOrder(auth.token, id);
            if (res.error === null) {
                setOrder(res.data);
                
                if(res.data.payment_proof_path !== null){
                    setUploadedImage(res.data.payment_proof_path);
                }
            }else{
                swal("Error!", res.error, "error");
            }

            setLoading(false);
        }

        getDetail(id);
    }, [auth]);

    const {acceptedFiles, fileRejections, getRootProps, getInputProps} = useDropzone({
        maxFiles:1,
        accept: 'image/jpeg, image/png',
        onDrop: files => {
            fileToBase64(files);
        }
    });
  
    const files = acceptedFiles.map(file => (
        <div key={file.path}>
            <img src={image} alt={file.name} className="img-thumbnail" style={{
                width: '100px',
            }}/>
        </div>
    ));

    const fileRejectionItems = fileRejections.map(({ file, errors  }) => { 
        return (
          <li key={file.path}>
               {file.path} - {(file.size/ 1000)} Kb
               <ul>
                 {errors.map(e => <li key={e.code}>{e.message}</li>)}
              </ul>
          </li>
        ) 
    });

    const fileToBase64 = (selectedFile) => {
        let file = null;
        //Check File is not Empty
        if (selectedFile.length > 0) {
            // Select the very first file from list
            let fileToLoad = selectedFile[0];
            // FileReader function for read the file.
            let fileReader = new FileReader();
            // Onload of file read the file content
            fileReader.onload = function(fileLoadedEvent) {
                file = fileLoadedEvent.target.result;
                // Print data in console
                setImage(file);
            };
            // Convert data to base64
            fileReader.readAsDataURL(fileToLoad);
        }
    }

    const handleUploadPayment = async () => {
        //confirm
        swal({
            title: "Apakah anda yakin?",
            text: "Unggah bukti pembayaran ini?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then(async (willUpload) => {
            if(willUpload){
                const res = await uploadPayment(auth.token, id, image);
                if(res.error === null){
                    swal('Berhasil', 'Bukti pembayaran berhasil diunggah', 'success');
                    
                    window.location.reload();
                }else{
                    swal('Error', res.error, 'error');
                }
            }
        });
    }

    return (
        <div>
            {
                loading ? <Loading /> :
                order !== null ? (
                    <>
                    {
                        order.status === config.licenseStatus.REVISION ? (
                            <div className='mb-3 box p-3 text-center'>
                                Anda perlu memperbaiki beberapa data
                                <br/>
                                <Link to={'/user-licenses/revision/'+order.id} className="btn btn-default">Perbaiki Data</Link>
                            </div>
                        ) 
                        
                        : order.status === config.licenseStatus.DRAFT ? (
                            <div className='mb-3 box p-3 text-center'>
                                <Link to={'/user-licenses/revision/'+order.id} className="btn btn-default">Ubah Data</Link>
                            </div>
                        )
                        
                        : null
                    }
                    <div className='box p-3'>
                        <Stepper
                            className="stepper"
                            steps={[
                                { label: 'Submit' + (order.status === config.licenseStatus.WAITING_FOR_PAYMENT ? " (Menunggu Pembayaran)" : "") }, 
                                { label: 'Verifikasi (UPT)' }, 
                                { label: (
                                    order.status === config.licenseStatus.REVISION ? 'Perlu Revisi' :
                                    (order.status === config.licenseStatus.APPROVED || order.status === config.licenseStatus.COMPLETED) ? 'Lulus' :
                                    order.status === config.licenseStatus.CANCELLED ? 'Tidak Lulus' :
                                    'Lulus/Tidak Lulus') }, 
                                { label: 'Surat Terbit' }
                            ]}
                            activeStep={
                                order.status === config.licenseStatus.PENDING ? 0 :
                                order.status === config.licenseStatus.PROCESSED ? 1 :
                                order.status === config.licenseStatus.REVISION ? 2 :
                                order.status === config.licenseStatus.APPROVED ? 2 :
                                order.status === config.licenseStatus.CANCELLED ? 2 :
                                order.status === config.licenseStatus.COMPLETED ? 3 :
                                order.status === config.licenseStatus.PAID ? 1 : null
                            }
                        />
                        <div className='text-center' hidden={order.result_file_path === null || order.result_file_path === ''}>
                            <a href={order.result_file_path} target="_blank" className='btn btn-default'>
                                <FontAwesomeIcon icon={faFileDownload} className="me-3"/>
                                Unduh Surat Rekomendasi
                            </a>
                        </div>
                    </div>
                    <div className='box p-3 mt-3'>
                        <Row>
                            <Col sm={12} md={8} lg={8}>
                                <Row>
                                    <Col xs={6}>Status</Col>
                                    <Col xs={6} className="text-end">{ renderStatus(order.status) }</Col>
                                </Row>
                                <Row className='mt-3'>
                                    <Col xs={6}>Catatan</Col>
                                    <Col xs={6} className="text-end">{ order.note }</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col sm={6}>No. Invoice</Col>
                                    <Col sm={6} className="text-end">#{ order.jurnal_transaction_no }</Col>
                                </Row>
                                <Row>
                                    <Col sm={6}>Tanggal Pembelian</Col>
                                    <Col sm={6} className="text-end">{ order.created_at }</Col>
                                </Row>
                            </Col>
                            <Col sm={12} md={4} lg={4} className="text-end">
                                {
                                    order.status == 1 ? (
                                        <>
                                        <div className="alert alert-warning text-center">
                                            Harap selesaikan pembayaran sebelum
                                            <div style={{
                                                fontWeight: 'bold',
                                            }}>
                                                { moment(order.payment_expired).format("HH:mm, DD-MMMM-YYYY") }
                                            </div>
                                        </div>
                                        <a href={order.jurnal_invoice_url} target="_blank" className="btn btn-sm btn-success" style={{
                                            width: '100%',
                                        }}>Bayar</a>
                                        <button hidden={true} onClick={() => window.location.reload() } className="btn btn-sm btn-danger mt-3" style={{
                                            width: '100%',
                                        }}>Saya Sudah Melakukan Pembayaran</button>
                                        </>
                                    ) : (
                                        <a href={order.jurnal_invoice_url} target="_blank" className="btn btn-sm btn-danger" style={{
                                            width: '100%',
                                        }}>Lihat Invoice</a>
                                    )
                                }
                            </Col>
                        </Row>
                    </div>
                    <div className='box p-3 mt-3'>
                        <Row>
                            <Col sm={12}>
                                <strong>Rincian Pembayaran:</strong>
                                <Row>
                                    <Col>
                                        Metode Pembayaran
                                    </Col>
                                    <Col className="text-end" style={{
                                        textTransform: 'capitalize'
                                    }}>
                                        { order.payment_method }
                                    </Col>
                                </Row>
                                <Row style={{
                                    fontWeight: 'bold',
                                }}>
                                    <Col>
                                        Total Harga
                                    </Col>
                                    <Col className="text-end">
                                        { intToMoney(order.total_cost) }
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                    <div className='box p-3 mt-3'>
                        <Row>
                            <Col sm={12}>
                                <strong>Lisensi Detil:</strong>
                                <Row>
                                    <Col>
                                        Tipe Lisensi
                                    </Col>
                                    <Col className="text-end">
                                        { order.license_name }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        Jenis Pengajuan
                                    </Col>
                                    <Col className="text-end">
                                        { renderLicenseType(order.license_type) }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        Deskripsi
                                    </Col>
                                    <Col className="text-end">
                                        { order.license_description }
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                    <div className='box p-3 mt-3'>
                        <Row>
                            <Col sm={12}>
                                <strong>Dokumen Pendukung:</strong>
                                <Row>
                                    <Col>
                                        Scan Surat Permohonan Rekomendasi
                                    </Col>
                                    <Col className="text-end" style={{
                                        textTransform: 'capitalize'
                                    }}>
                                        {
                                            order.data_recommendation_letter_path !== null ? (
                                                <a target="_blank" href={order.data_recommendation_letter_path}>Lihat Dokumen</a>
                                            ) : "-"
                                        }
                                    </Col>
                                </Row>
                                <Row className='mt-3'>
                                    <Col>
                                        Scan IPTB/Lisensi Sebelumnya
                                    </Col>
                                    <Col className="text-end">
                                        {
                                            order.data_previous_license_path !== null ? (
                                                <a target="_blank" href={order.data_previous_license_path}>Lihat Dokumen</a>
                                            ) : "-"
                                        }
                                    </Col>
                                </Row>
                                <Row className='mt-3'>
                                    <Col>
                                        Scan STRA
                                    </Col>
                                    <Col className="text-end">
                                        {
                                            order.data_stra_path !== null ? (
                                                <a target="_blank" href={order.data_stra_path}>Lihat Dokumen</a>
                                            ) : "-"
                                        }
                                    </Col>
                                </Row>
                                <Row className='mt-3'>
                                    <Col>
                                        Resume Peraturan di DKI
                                    </Col>
                                    <Col className="text-end">
                                        {
                                            order.data_rule_resume_path !== null ? (
                                                <a target="_blank" href={order.data_rule_resume_path}>Lihat Dokumen</a>
                                            ) : "-"
                                        }
                                    </Col>
                                </Row>
                                <Row className='mt-3'>
                                    <Col>
                                        Sertifikat PKA 1 atau Strata 1 dan Strata 2 IAI Jakarta (Bagi anggota IAI non Jakarta)
                                    </Col>
                                    <Col className="text-end">
                                        {
                                            order.data_certificate_path !== null ? (
                                                <a target="_blank" href={order.data_certificate_path}>Lihat Dokumen</a>
                                            ) : "-"
                                        }
                                    </Col>
                                </Row>
                                <Row className='mt-3'>
                                    <Col>
                                        Portfolio 1 Proyek (siteplan, denah tampak, potongan, foto konstruksi) Sesuai Golongan
                                    </Col>
                                    <Col className="text-end">
                                        {
                                            order.data_portfolio_detail_path !== null ? (
                                                <a target="_blank" href={order.data_portfolio_detail_path}>Lihat Dokumen</a>
                                            ) : "-"
                                        }
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                    {
                        order.projects !== null ? (
                            <div className='mt-3'>
                                <h4>List Proyek:</h4>
                                {
                                    order.projects.map((project, index) => (
                                        <div className='box ps-3 pb-3 pe-3 mb-3'>
                                            <div className='row'>
                                                <div className='col-sm-12 col-md-6 col-lg-4 col-xl-3 mt-3'>
                                                    <label className='fw-bold'>Nama Proyek</label>
                                                    <div>{ project.project_name }</div>
                                                </div>
                                                <div className='col-sm-12 col-md-6 col-lg-4 col-xl-3 mt-3'>
                                                    <label className='fw-bold'>Jenis Bangunan</label>
                                                    <div>{ project.building_category }</div>
                                                </div>
                                                <div className='col-sm-12 col-md-6 col-lg-4 col-xl-3 mt-3'>
                                                    <label className='fw-bold'>Jumlah Lantai</label>
                                                    <div>{ project.floor_level }</div>
                                                </div>
                                                <div className='col-sm-12 col-md-6 col-lg-4 col-xl-3 mt-3'>
                                                    <label className='fw-bold'>Lokasi</label>
                                                    <div>{ project.location }</div>
                                                </div>
                                                <div className='col-sm-12 col-md-6 col-lg-4 col-xl-3 mt-3'>
                                                    <label className='fw-bold'>Mulai</label>
                                                    <div>{ project.start_date }</div>
                                                </div>
                                                <div className='col-sm-12 col-md-6 col-lg-4 col-xl-3 mt-3'>
                                                    <label className='fw-bold'>Selesai</label>
                                                    <div>{ 
                                                        project.end_date === '0000-00-00' ? (
                                                            '-'
                                                        ) : (
                                                            project.end_date
                                                        )
                                                    }</div>
                                                </div>
                                                <div className='col-sm-12 col-md-6 col-lg-4 col-xl-3 mt-3'>
                                                    <label className='fw-bold'>Jabatan</label>
                                                    <div>{ project.job_position }</div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        ) : null
                    }
                    {
                        order.payment_method === 'bank transfer' ? (
                            <div className="mt-5">
                                <BankList />
                                <label><b>Unggah Bukti Transfer:</b></label>
                                <div hidden={ uploadedImage === null || uploadedImage === "" }>
                                    <div className="text-center">
                                    <img src={ uploadedImage } alt="bukti transfer" style={{ width: '200px' }} />
                                    </div>
                                    <div hidden={order.status != 1} className="alert alert-warning mt-2">
                                        Masukan foto apabila ingin mengubah Bukti Transfer
                                    </div>
                                </div>
                                {
                                    order.status == 1 ? (
                                        <>
                                            <div {...getRootProps({className: 'dropzone'})}>
                                                <input {...getInputProps()} />
                                                <p className="text-center">
                                                    Drag 'n' drop some files here<br/>or click to select files
                                                    <em>(Only *.jpeg and *.png images will be accepted)</em>
                                                </p>
                                            </div>
                                            <aside className="mt-2">
                                                <b>Selected Image:</b>
                                                {files}
                                                {
                                                    fileRejectionItems.length > 0 ? (
                                                        <>
                                                            <b>Rejected files</b>
                                                            <ul>{fileRejectionItems}</ul>
                                                        </>
                                                    ) : null
                                                }
                                            </aside>
                                            {
                                                files.length > 0 && fileRejectionItems.length === 0 ? (
                                                    <div className="mt-2">
                                                        <Button onClick={handleUploadPayment} variant="danger" style={{
                                                            width: '100%',
                                                        }}>Unggah</Button>
                                                    </div>
                                                ) : null
                                            }
                                        </>
                                    ) : null
                                }
                            </div>
                        ) : null
                    }
                    </>
                ) : null
            }
        </div>
    );
}

export default UserLicenseDetail;