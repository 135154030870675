import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router";
import CheckoutEvent from "../components/events/CheckoutEvent";

const EventCheckoutScreen = () => {
    const params = useParams();
    const url = params.url; 

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container>
            <Row>
                <Col sm={12}>
                    <CheckoutEvent url={url}/>
                </Col>
            </Row>
        </Container>
    );
}

export default EventCheckoutScreen;