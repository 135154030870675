import React, { useEffect, useState, useMemo } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
    all
} from './../../services/userShippingAddress';
import swal from 'sweetalert';
import ShippingAddressList from './../shipping_address/ShippingAddressList';
import CartList from './CartList';
import { intToMoney } from '../../helpers/currency';
import {
    getShippingCost,
    order
} from './../../services/product';
import { useNavigate } from 'react-router';
import {
    clear
} from './../../redux/slicers/cartSlice';
import Loading from '../Loading';

const Checkout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const auth = useSelector((state) => state.auth);
    const cart = useSelector((state) => state.cart);

    const [shippingAddresses, setShippingAddresses] = useState([]);
    const [shippingAddress, setShippingAddress] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState('online');
    const [shippingList, setShippingList] = useState([]);
    const [shippingMethod, setShippingMethod] = useState(null);

    const [loadingShipping, setLoadingShipping] = useState(false);
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        async function getShippingAddress(){
            const res = await all(auth.token);
            if(res.error === null){
                setShippingAddresses(res.data);

                //check if has data
                if(res.data.length > 0){
                    //get active
                    const active = res.data.filter(item => item.is_main_address === '1');
                    if(active.length > 0){
                        setShippingAddress(active[0]);
                    }else{
                        setShippingAddress(res.data[0]);
                    }
                }
            }else{
                swal('Error', res.error, 'error');
            }
        }

        getShippingAddress();
    }, [auth]);

    useEffect(() => {
        if(cart.shippingAddressChanged !== null){
            async function getShippingAddress(){
                const res = await all(auth.token);
                if(res.error === null){
                    setShippingAddresses(res.data);
    
                    //check if has data
                    if(res.data.length > 0){
                        //get active
                        const active = res.data.filter(item => item.is_main_address === '1');
                        if(active.length > 0){
                            setShippingAddress(active[0]);
                        }else{
                            setShippingAddress(res.data[0]);
                        }
                    }
                }else{
                    swal('Error', res.error, 'error');
                }
            }
    
            getShippingAddress();
        }
    }, [cart.shippingAddressChanged]);

    useEffect(() => {
        async function getShippingCostAPI(){
            setLoadingShipping(true);

            //reset shipping method
            setShippingMethod(null);

            const res = await getShippingCost(auth.token, cart.cart, shippingAddress.id);
            if(res.error === null){
                setShippingList(res.data);
            }else{
                swal('Error', res.error, 'error');
            }

            setLoadingShipping(false);
        }

        if(shippingAddress !== null && cart.cart !== null && cart.cart.length > 0){
            getShippingCostAPI();
        }
    }, [cart.cart, shippingAddress]);

    const ShippingAddressModal = useMemo(() => {
        return(() => (
            <Modal size='lg' show={show} onHide={handleClose}>
                <Modal.Body>
                    <ShippingAddressList isCheckout={true} closeModal={handleClose}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        ));
    }, [show]);

    const makeOrder = async () => {
        //check if shipping method is selected
        if(shippingMethod === null){
            swal('Error', 'Silahkan pilih metode pengiriman terlebih dahulu.', 'error');
            return;
        }

        //confirm
        const confirm = await swal({
            title: 'Konfirmasi',
            text: 'Apakah seluruh data sudah di isi dengan benar?',
            icon: 'warning',
            buttons: ['Cancel', 'Confirm']
        });

        if(confirm){
            setLoadingSubmit(true);

            const res = await order(
                auth.token, 
                cart.cart, 
                shippingAddress.id, 
                paymentMethod,

                shippingMethod.perCost.value,
                shippingMethod.courier.name,
                shippingMethod.cost.service,
                shippingMethod.perCost.etd
                );
            if(res.error === null){
                swal('Success', 'Pesanan Berhasil dibuat, silahkan lanjutkan ke pembayaran.', 'success');
                dispatch(clear());

                //navigate to order
                navigate('/product-transaction/order/'+res.data.product_order_id);
            }else{
                swal('Error', res.error, 'error');
            }

            setLoadingSubmit(false);
        }
    }

    const selectShippingMethod = (perCost, cost, courier) => {
        setShippingMethod({
            perCost: perCost,
            cost: cost,
            courier: courier,
        });
    }

    return (
        <div>
            <ShippingAddressModal/>

            <h3><b>Checkout</b></h3>
            <Row className='mt-4'>
                <Col sm={12} md={8}>
                    <div>
                        Alamat Pengiriman:
                    </div>
                    {
                        shippingAddress !== null ? (
                            <div className='box p-3' style={{
                                fontSize: '10pt',
                            }}>
                                <div>
                                    <b>{ shippingAddress.receiver_name }</b> ({ shippingAddress.address_label })
                                    <span hidden={!shippingAddress.is_main_address} className='badge bg-danger ms-2'>utama</span>
                                </div>
                                <div className='mt-1'>
                                    { shippingAddress.phone_number }
                                </div>
                                <div className='mt-1 text-muted'>
                                    { shippingAddress.full_address }
                                    <br/>
                                    { shippingAddress.city+' '+shippingAddress.postcode }
                                </div>
                                <div className='mt-1'>
                                    <i><b>catatan:</b> { shippingAddress.note }</i>
                                </div>
                            </div>
                        ) : (
                            <div className='box text-muted p-3'>
                                Belum ada Alamat Pengiriman
                            </div>
                        )
                    }
                    <div className='mt-2'>
                        <button className='btn btn-default btn-sm' onClick={() => setShow(true)}>
                            {
                                shippingAddress !== null ? 'Pilih Alamat Lain' : 'Buat Alamat Pengiriman'
                            }
                        </button>
                    </div>
                    
                    <div className='mt-3'>
                        Detil Pesanan:
                        <div className='box p-3'>
                            <CartList isCheckout={true}/>
                        </div>
                    </div>

                    <div className="mt-3">
                        Metode Pengiriman:
                        {
                            loadingShipping ? <Loading /> : (
                                shippingList !== null ? (
                                    shippingList.map((courier, index) => (
                                        //get per courier
                                        <div key={index}>
                                            

                                            {
                                                courier.data.results.map((result, resultIndex) => (
                                                    <div key={resultIndex}>
                                                        <div style={{
                                                            textTransform: 'uppercase',
                                                            fontWeight: 'bold',
                                                        }}>
                                                        {
                                                            result.name
                                                        }
                                                        </div>
                                                        <Row>
                                                        {
                                                        result.costs.map(cost => (
                                                            <div key={resultIndex+'-'+cost.service} className="col-sm-12 col-md-6 col-lg-4 mt-1 mb-3 text-center">
                                                                <div className='box p-3'>
                                                                    { cost.service }

                                                                    {
                                                                        cost.cost.map(perCost => (
                                                                            <div key={resultIndex+'-'+cost.service+'-'+perCost.etd}>
                                                                                <Row>
                                                                                    <Col sm={12}>
                                                                                        <div>
                                                                                            ETD: <b>{ perCost.etd }</b>
                                                                                        </div>
                                                                                        <div>
                                                                                            Note: { perCost.note }
                                                                                        </div>
                                                                                        <div>
                                                                                            Harga: <b>{ intToMoney(perCost.value) }</b>
                                                                                        </div>
                                                                                    </Col>
                                                                                    <Col sm={12} className="mt-3">
                                                                                        Pilih Pengiriman:
                                                                                        <br/>
                                                                                        <input type="radio" name="courier" onChange={e => selectShippingMethod(perCost, cost, result) }/>
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>
                                                        ))
                                                        }
                                                        </Row>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    ))
                                ) : null
                            )
                        }
                    </div>
                </Col>
                <Col sm={12} md={4}>
                    <div className='box p-3'>
                        <Row>
                            <Col xs={6}>Total Biaya</Col>
                            <Col xs={6} className="text-end">{ intToMoney(cart.totalPrice) }</Col>
                        </Row>
                        {
                            shippingMethod !== null ? (
                                <Row>
                                    <Col xs={6}>{ shippingMethod.courier.name } ({ shippingMethod.cost.service })</Col>
                                    <Col xs={6} className="text-end">{ intToMoney(shippingMethod.perCost.value) }</Col>
                                </Row>
                            ) : null
                        }
                        <Row style={{
                            fontWeight: 'bold',
                            marginTop: '10px',
                        }}>
                            <Col xs={6}>Total Tagihan</Col>
                            <Col xs={6} className="text-end">{ intToMoney(shippingMethod === null ? cart.totalCost : (cart.totalCost + shippingMethod.perCost.value)) }</Col>
                        </Row>
                        <button onClick={makeOrder} disabled={loadingSubmit} className='btn btn-default btn-block mt-3' style={{
                            width: '100%',
                        }}>{ loadingSubmit ? <Loading /> : 'Buat Pesanan' }</button>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default Checkout;