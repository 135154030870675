import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { search } from '../../services/membership';
import swal from 'sweetalert';
import { Button, Col, Row } from 'react-bootstrap';
import { intToMoney } from '../../helpers/currency';
import { useNavigate } from 'react-router';
import MembershipBadge from '../MembershipBadge';
import moment from 'moment';
import { Link } from 'react-router-dom';
import config from '../../helpers/config';

const MembershipInfo = (props) => {
    const account = useSelector((state) => state.account);
    const showButton = props.showButton;

    const isNeedToReactivate = useMemo(() => {
        if (!account) return null;

        if (!account.membership) return null;

        const expiredDate = account.membership.expired;

        // check if expiredDate is more that 5 years ago
        const isNeedToReactivate = moment(expiredDate).isBefore(moment().subtract(5, 'years'));

        return isNeedToReactivate;
    }, [account]);

    console.log('debug', account);

    return (
        <div className='row'>
            <div className='col-sm-12 col-md-6 mb-3'>
                Status Keanggotaan Anda Sekarang: <MembershipBadge />
            </div>
            <div className='col-sm-12 col-md-6 text-end'>
                {
                    account.is_age_priviledge ? (
                        <>
                            <div className='badge bg-warning p-3'>Lifetime Membership</div>
                        </>
                    ) : (
                        <>
                            Expired Pada:
                            <br />
                            <div style={{
                                fontWeight: 'bold',
                            }}>
                                {
                                    account !== null ? (
                                        typeof account.membership !== 'undefined' && account.membership !== null ?
                                            typeof account.membership.expired !== 'undefined' && account.membership.expired !== null ?
                                                <>
                                                    {
                                                        moment(account.membership.expired).format('HH:mm, DD MMMM YYYY')
                                                    }
                                                    {
                                                        account.membership.type === config.membershipType.MEMBER || account.membership.type === config.membershipType.MEMBER_PRO ? (
                                                            <>
                                                                <br />
                                                                <Link hidden={!showButton} to="/membership" className='btn btn-sm btn-default'>
                                                                    {isNeedToReactivate ? 'Re-aktivasi' : 'Perpanjang'}
                                                                </Link>
                                                            </>
                                                        ) : null
                                                    }
                                                </>
                                                : "-"
                                            : null
                                    ) : null
                                }


                            </div>
                        </>
                    )
                }
            </div>
        </div>
    );
}

export default MembershipInfo;