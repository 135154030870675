export const read = async (slug) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}page/read`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            slug: slug
        })
    });
    const json = await response.json();
    return json;
}