export const detail = async (id) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}account/detail`, {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            id: id
        })
    });
    const json = await response.json();
    return json;
}

export const editProfilePro = async (
    token,
    iai_id,
    scan_iai_id
) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}account/editprofile`, {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            iai_id: iai_id,
            scan_iai_id: scan_iai_id,
        })
    });
    const json = await response.json();
    return json;
}

export const editProfile = async (
    token,
    first_name,
    last_name,
    phone_number,
    avatar,
    gender,
    date_of_birth,
    address,
    province_id,
    zip_code,
    ktp_number,
    scan_ktp,
    npwp_number,
    scan_npwp,
    username,
    contact_email_address,
    contact_instagram,
    contact_facebook,
    contact_linkedin
) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}account/editprofile`, {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            first_name: first_name,
            last_name: last_name,
            phone_number: phone_number,
            avatar: avatar,
            gender: gender,
            date_of_birth: date_of_birth,
            address: address,
            province_id: province_id,
            zip_code: zip_code,
            ktp_number: ktp_number,
            scan_ktp: scan_ktp,
            npwp_number: npwp_number,
            scan_npwp: scan_npwp,
            username: username,
            contact_email_address: contact_email_address,
            contact_instagram: contact_instagram,
            contact_facebook: contact_facebook,
            contact_linkedin: contact_linkedin
        })
    });
    const json = await response.json();
    return json;
}

export const changePassword = async (token, old_password, new_password, confirm_password) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}account/change_password`, {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            old_password: old_password,
            new_password: new_password,
            confirm_password: confirm_password,
        })
    });
    const json = await response.json();
    return json;
}

export const changeEmail = async (token, email_address, password) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}account/change_email`, {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            email_address: email_address,
            password: password,
        })
    });
    const json = await response.json();
    return json;
}

export const updateFCMToken = async (
    token,
    fcm_token
) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}account/update_fcm_token`, {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            fcm_token: fcm_token,
        })
    });
    const json = await response.json();
    return json;
}

export const updatePrivacy = async (
    token,
    privacy_hide_profile
) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}account/update_privacy`, {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            privacy_hide_profile: privacy_hide_profile,
        })
    });
    const json = await response.json();
    return json;
}

export const validateIAIId = async (
    token,
    iai_id
) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}account/validate_iai_id`, {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            iai_id: iai_id,
        })
    });
    const json = await response.json();
    return json;
}

export const updateIAIId = async (
    token,
    iai_id
) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}account/update_iai_id`, {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            iai_id: iai_id,
        })
    });
    const json = await response.json();
    return json;
}