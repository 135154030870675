import React from "react";
import { Link } from "react-router-dom";
import { intToMoney } from "../../helpers/currency";

const ProductBox = (props) => {
    const { product } = props;

    return(
        <Link to={`/product/${product.id}`} className="link-default">
            <div
            style={{
            }}>
                <div className="box image-box" style={{
                    padding: '0px',
                    marginBottom:'10px',
                    overflow: 'hidden',
                }}>
                    <div className="image-box-image">
                        <img src={ product.images[0] } alt="" className="img-fluid"/>
                    </div>
                    <div style={{
                        padding:'5px',
                    }}>
                        <div className="badge bg-danger" style={{
                            fontSize:10,
                            textAlign:'right',
                        }}>{ product.product_category_name }</div>
                        <p style={{
                            fontSize:15,
                            fontWeight:'bold',
                            marginBottom:0,
                        }}>{ product.name }</p>
                        <p style={{
                            fontSize:10,
                            textAlign:'right',
                            fontWeight:'bold',
                            marginBottom:0
                        }}>Harga { intToMoney(product.price_default) }</p>
                        <p style={{
                            fontSize:10,
                            textAlign:'right',
                        }}>Tersisa { product.stock }</p>
                    </div>
                </div>
            </div>
        </Link>
    );
}

export default ProductBox;