import React, { useEffect, useState } from "react";
import {Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import Dropzone, {useDropzone} from 'react-dropzone';
import {
    update,
    create,
    read
} from './../../services/ska';
import swal from 'sweetalert';

const AddEditSKA = (props) => {
    const id = props.id;
    const refreshList = props.refreshList;

    const auth = useSelector((state) => state.auth);
    const [skaNo, setSkaNo] = useState("");
    const [skaCategory, setSkaCategory] = useState("");
    const [issuedDate, setIssuedDate] = useState("");
    const [isLoading, setIsLoading] = useState("");

    const [scanPhotoFiles, setScanPhotoFiles] = useState([]);
    const [scanPhoto, setScanPhoto] = useState('');
    const [uploadedScanPhoto, setUploadedScanPhoto] = useState('');

    const fileToBase64 = (selectedFile, callback) => {
        let file = null;
        //Check File is not Empty
        if (selectedFile.length > 0) {
            // Select the very first file from list
            let fileToLoad = selectedFile[0];
            // FileReader function for read the file.
            let fileReader = new FileReader();
            // Onload of file read the file content
            fileReader.onload = function(fileLoadedEvent) {
                file = fileLoadedEvent.target.result;
                // Print data in console
                callback(file);
            };
            // Convert data to base64
            fileReader.readAsDataURL(fileToLoad);
        }
    }

    useEffect(() => {
        //clear form
        resetForm();
        setScanPhoto("");

        if(id !== null){
            async function getData(id){
                const res = await read(id);
                if(res.error === null){
                    setSkaNo(res.data.ska_no);
                    setSkaCategory(res.data.ska_category);
                    setIssuedDate(res.data.issued_date);
                    setUploadedScanPhoto(res.data.scan_photo);
                }
            }

            getData(id);
        }
    }, [id]);

    const closeModal = () => {
        resetForm();
        props.setShowModal(false);
    }

    const resetForm = () => {
        //reset form
        setSkaNo("");
        setSkaCategory("");
        setIssuedDate("");
        setUploadedScanPhoto("");
        setScanPhotoFiles([]);
    }

    const addData = async (e) => {
        e.preventDefault();

        //set loading
        setIsLoading(true);

        let res = {
            error: null,
        }

        //check if add or update
        if(id === null){
            //insert
            res = await create(
                auth.token,
                skaNo,
                skaCategory,
                issuedDate,
                scanPhoto,
            );
        }else{
            //edit
            res = await update(
                auth.token,
                id,
                skaNo,
                skaCategory,
                issuedDate,
                scanPhoto,
            );
        }

        if(res.error === null){
            //success
            swal("Sukses!", "Berhasil "+(id == null ? 'menambah' : 'mengubah')+" data.", "success");

            resetForm();

            refreshList();

            //close modal
            closeModal();
        }else{
            //error
            swal("Gagal!", res.error, "error");
        }

        setIsLoading(false);
    }

    return (
        <Modal size="lg" show={props.isShow} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>Education</Modal.Title>
            </Modal.Header>
            <form onSubmit={addData}>
            <Modal.Body>
                <div className="row">
                    <div className="col-12 col-md-6 mt-3">
                        <label>Nomor SKA</label>
                        <input type="text" className="form-control" value={skaNo} onChange={e => setSkaNo(e.target.value)} required={true}/>
                    </div>
                    <div className="col-12 col-md-6 mt-3">
                        <label>Kategori</label>
                        <select className="form-select" value={skaCategory} onChange={e => setSkaCategory(e.target.value)} required={false}>
                            <option value="UTAMA">UTAMA</option>
                            <option value="MADYA">MADYA</option>
                            <option value="MUDA">MUDA</option>
                        </select>
                    </div>
                    <div className="col-12 col-md-6 mt-3">
                        <label>Issued Date</label>
                        <input type="date" className="form-control" value={issuedDate} onChange={e => setIssuedDate(e.target.value)} required={false}/>
                    </div>
                </div>

                <div className="mt-3" style={{
                    borderBottom: '1px solid #eee',
                }}>
                    <label>Scan Foto (.pdf)</label>
                    <div className="border p-3">
                        <div hidden={ uploadedScanPhoto === null || uploadedScanPhoto === "" }>
                            <div className="text-center">
                                <a style={{
                                    textDecoration: 'none'
                                }} href={uploadedScanPhoto} target="_blank" rel="noreferrer">Lihat Scan Foto</a>
                            </div>
                            <div className="alert alert-warning mt-2">
                                Kosongkan Kolom ini apabila tidak ingin mengganti data.
                            </div>
                            <div className="text-center mb-3">
                                <button type="button" className="btn btn-default" onClick={() => {
                                    setUploadedScanPhoto("");
                                    setScanPhoto("remove");
                                }}>Hapus Scan Foto</button>
                            </div>
                        </div>
                        <Dropzone accept={['application/pdf']} onDrop={(acceptedFiles) => {
                            let error = null;
                            acceptedFiles.forEach(file => {
                                if(file.type !== 'application/pdf'){
                                    error = 'File harus berformat PDF';
                                }
                            });

                            if(error === null){
                                setScanPhotoFiles(acceptedFiles);
                                fileToBase64(acceptedFiles, setScanPhoto);
                            }else{
                                swal('Error', error, 'error');
                            }
                        }} name="heroImage" multiple={false}>
                            {({getRootProps, getInputProps}) => (
                                <div {...getRootProps({className: 'dropzone'})}>
                                    <input {...getInputProps()} />
                                    <span style={{ fontSize: ".8rem" }}>
                                            Drop file, or click to select file
                                    </span>
                                </div>
                            )}
                        </Dropzone>
                        <aside className="mt-2" hidden={scanPhotoFiles === null || scanPhotoFiles.length < 1}>
                            <b>File yang dipilih:</b>
                            <ul>
                            {
                            scanPhotoFiles.map(file => (
                                <li key={file.path}>
                                    <a target="_blank" href={file.path}>{file.path}</a> - {(file.size / 1000)} Kb
                                </li>
                            ))
                            }
                            </ul>
                        </aside>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
                Tutup
            </Button>
            <Button variant="danger" type="submit" disabled={isLoading}>
                Simpan Perubahan
            </Button>
            </Modal.Footer>
            </form>
        </Modal>
    );
}

export default AddEditSKA;