import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useParams, useNavigate } from "react-router";
import LeftMenuPanel from "../components/LeftMenuPanel";
import config from "../helpers/config";
import EventOrderDetail from './../components/transaction/EventOrderDetail';

const EventTransactionDetailScreen = () => {
    const params = useParams();
    const navigate = useNavigate();

    //change title
    useEffect(() => {
        document.title = config.documentTitle + " - Pembelian Tiket Event";

        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="default-nav-mt">
            <Container>
                <Row>
                    <Col sm={12} md={12} lg={4} className="mb-4">
                        <LeftMenuPanel activeIndex={1}/>
                    </Col>
                    <Col sm={12} md={12} lg={8}>
                        <h3 className="mb-3"><b><FontAwesomeIcon style={{
                            cursor: "pointer"
                        }} onClick={() => navigate(-1)} icon={faChevronLeft} className="me-3"/>Detil Transaksi Pembelian Tiket</b></h3>
                        <EventOrderDetail id={params.id}/>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default EventTransactionDetailScreen;