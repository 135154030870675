export const get = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}bank/get`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        }
    });
    const json = await response.json();
    return json;
}