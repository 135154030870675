import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
//bootstrap
import { Row, Col, Offcanvas } from "react-bootstrap";
import { Link } from 'react-router-dom';
import config from "../helpers/config";
import PointBox from "./users/PointBox";
import MembershipBadge from "./MembershipBadge";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faBan, faCheck } from '@fortawesome/free-solid-svg-icons'

const LeftMenuPanel = (props) => {
    const account = useSelector((state) => state.account);
    const activeIndex = props.activeIndex;

    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const windowWidth = window.innerWidth;

    const isActiveDPT = useMemo(() => {
        const isExpired = account.is_expired;
        if (isExpired) return false;

        if (account.membership === null || typeof account.membership === 'undefined') return false;

        const expiredDateTime = account.membership.expired;
        if (expiredDateTime === null || expiredDateTime === '0000-00-00 00:00:00') return false;

        // check if more than 30 december 2024
        const expiredDate = new Date(expiredDateTime);
        const dptDate = new Date(2024, 11, 30);
        return expiredDate > dptDate;
    }, [account]);

    const MenuDisplay = () => {
        return (
            <>
                <Row className="p-4">
                    <Col
                        sm={12}
                    >
                        {
                            account.name !== '' ? (
                                <>
                                    <img src={account.avatar_url} alt="avatar" className="rounded avatar-m mb-3" />
                                    <div className="break-text" style={{
                                        fontSize: '12pt',
                                        fontWeight: 'bold'
                                    }}>
                                        {account.name}
                                    </div>

                                    <MembershipBadge />
                                </>
                            ) : null
                        }
                    </Col>
                </Row>

                <div style={{
                    backgroundColor: '#F0F0F0',
                    padding: '14px',
                    textAlign: 'center',
                }}>
                    {isActiveDPT && (

                        <a href="/check-dpt" className="btn btn-sm btn-default">
                            <FontAwesomeIcon icon={faCheck} style={{
                                marginRight: '5px'
                            }} />
                            Anda Termasuk DPT Aktif
                            <div style={{
                                fontSize: '8pt',
                            }}>
                                <u>click untuk menuju halaman vote</u>
                            </div>
                        </a>
                    )}

                    {!isActiveDPT && (
                        <Link to="/check-dpt" className="btn btn-sm btn-disabled">
                            <FontAwesomeIcon icon={faBan} style={{
                                marginRight: '5px'
                            }} />
                            Anda Belum Termasuk DPT
                            <div style={{
                                fontSize: '8pt',
                            }}>
                                <u>click untuk info lebih lanjut</u>
                            </div>
                        </Link>
                    )}
                </div>

                <ul className="menu-panel">
                    <li>
                        <Link to="/dashboard" className={activeIndex === -1 ? 'active' : ''}>
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.75 20L12 23L11 24H19L18 23L17.25 20M6 16H24M8 20H22C23.1046 20 24 19.1046 24 18V8C24 6.89543 23.1046 6 22 6H8C6.89543 6 6 6.89543 6 8V18C6 19.1046 6.89543 20 8 20Z" stroke="#C4C4C4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <rect x="1" y="1" width="28" height="28" rx="6" stroke="#C4C4C4" strokeWidth="2" />
                            </svg>

                            Dashboard
                        </Link>
                    </li>
                    <li>
                        <Link to="/edit-profile" className={activeIndex === 0 ? 'active' : ''}>
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19 10C19 12.2091 17.2091 14 15 14C12.7909 14 11 12.2091 11 10C11 7.79086 12.7909 6 15 6C17.2091 6 19 7.79086 19 10Z" stroke="#C4C4C4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M15 17C11.134 17 8 20.134 8 24H22C22 20.134 18.866 17 15 17Z" stroke="#C4C4C4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <rect x="1" y="1" width="28" height="28" rx="6" stroke="#C4C4C4" strokeWidth="2" />
                            </svg>

                            Profil Saya
                        </Link>
                    </li>
                    <li>
                        <Link to="/events/upcoming" className={activeIndex === 9 ? 'active' : ''}>
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M24 13V14C24.5523 14 25 13.5523 25 13H24ZM24 17H25C25 16.4477 24.5523 16 24 16V17ZM6 17V16C5.44772 16 5 16.4477 5 17H6ZM6 13H5C5 13.5523 5.44772 14 6 14V13ZM19 8C19 7.44772 18.5523 7 18 7C17.4477 7 17 7.44772 17 8H19ZM17 10C17 10.5523 17.4477 11 18 11C18.5523 11 19 10.5523 19 10H17ZM19 14C19 13.4477 18.5523 13 18 13C17.4477 13 17 13.4477 17 14H19ZM17 16C17 16.5523 17.4477 17 18 17C18.5523 17 19 16.5523 19 16H17ZM19 20C19 19.4477 18.5523 19 18 19C17.4477 19 17 19.4477 17 20H19ZM17 22C17 22.5523 17.4477 23 18 23C18.5523 23 19 22.5523 19 22H17ZM8 7C6.34315 7 5 8.34315 5 10H7C7 9.44772 7.44772 9 8 9V7ZM22 7H8V9H22V7ZM25 10C25 8.34315 23.6569 7 22 7V9C22.5523 9 23 9.44772 23 10H25ZM25 13V10H23V13H25ZM23 15C23 14.4477 23.4477 14 24 14V12C22.3431 12 21 13.3431 21 15H23ZM24 16C23.4477 16 23 15.5523 23 15H21C21 16.6569 22.3431 18 24 18V16ZM25 20V17H23V20H25ZM22 23C23.6569 23 25 21.6569 25 20H23C23 20.5523 22.5523 21 22 21V23ZM8 23H22V21H8V23ZM5 20C5 21.6569 6.34315 23 8 23V21C7.44772 21 7 20.5523 7 20H5ZM5 17V20H7V17H5ZM7 15C7 15.5523 6.55228 16 6 16V18C7.65685 18 9 16.6569 9 15H7ZM6 14C6.55228 14 7 14.4477 7 15H9C9 13.3431 7.65685 12 6 12V14ZM5 10V13H7V10H5ZM17 8V10H19V8H17ZM17 14V16H19V14H17ZM17 20V22H19V20H17Z" fill="#C4C4C4" />
                                <rect x="1" y="1" width="28" height="28" rx="6" stroke="#C4C4C4" strokeWidth="2" />
                            </svg>

                            Kegiatan Saya
                        </Link>
                    </li>
                    <li>
                        <Link to="/event-transaction" className={activeIndex === 1 ? 'active' : ''}>
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 13H24M10 18H11M15 18H16M9 22H21C22.6569 22 24 20.6569 24 19V11C24 9.34315 22.6569 8 21 8H9C7.34315 8 6 9.34315 6 11V19C6 20.6569 7.34315 22 9 22Z" stroke="#C4C4C4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <rect x="1" y="1" width="28" height="28" rx="6" stroke="#C4C4C4" strokeWidth="2" />
                            </svg>

                            Daftar Transaksi (Kegiatan)
                        </Link>
                    </li>
                    <li>
                        <Link to="/product-transaction" className={activeIndex === 5 ? 'active' : ''}>
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 13H24M10 18H11M15 18H16M9 22H21C22.6569 22 24 20.6569 24 19V11C24 9.34315 22.6569 8 21 8H9C7.34315 8 6 9.34315 6 11V19C6 20.6569 7.34315 22 9 22Z" stroke="#C4C4C4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <rect x="1" y="1" width="28" height="28" rx="6" stroke="#C4C4C4" strokeWidth="2" />
                            </svg>

                            Daftar Transaksi (Merchandise)
                        </Link>
                    </li>
                    <li>
                        <Link to="/shipping-address" className={activeIndex === 4 ? 'active' : ''}>
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16 19L17 19L17 19L16 19ZM16 11L15 11L15 11L16 11ZM20.2929 10.2929L21 9.58579L21 9.58579L20.2929 10.2929ZM23.7071 13.7071L24.4142 13L24.4142 13L23.7071 13.7071ZM7 9H15V7H7V9ZM15 9V19H17V9H15ZM7 19V9H5V19H7ZM8 19H7V21H8V19ZM15 19H12V21H15V19ZM5 19C5 20.1046 5.89543 21 7 21V19H5ZM15 19V21C16.1046 21 17 20.1046 17 19H15ZM15 9H15H17C17 7.89543 16.1046 7 15 7V9ZM7 7C5.89543 7 5 7.89543 5 9H7V9V7ZM17 19L17 11L15 11L15 19L17 19ZM17 11H19.5858V9H17V11ZM23 14.4142V19H25V14.4142H23ZM19.5858 11L23 14.4142L24.4142 13L21 9.58579L19.5858 11ZM18 19H17V21H18V19ZM23 19H22V21H23V19ZM25 14.4142C25 13.8838 24.7893 13.3751 24.4142 13L23 14.4142L23 14.4142H25ZM19.5858 11L19.5858 11L21 9.58579C20.6249 9.21071 20.1162 9 19.5858 9V11ZM15 19C15 20.1046 15.8954 21 17 21V19H17H15ZM23 19V21C24.1046 21 25 20.1046 25 19H23ZM17 11V11V9C15.8954 9 15 9.89543 15 11H17ZM11 20C11 20.5523 10.5523 21 10 21V23C11.6569 23 13 21.6569 13 20H11ZM10 21C9.44772 21 9 20.5523 9 20H7C7 21.6569 8.34315 23 10 23V21ZM9 20C9 19.4477 9.44772 19 10 19V17C8.34315 17 7 18.3431 7 20H9ZM10 19C10.5523 19 11 19.4477 11 20H13C13 18.3431 11.6569 17 10 17V19ZM21 20C21 20.5523 20.5523 21 20 21V23C21.6569 23 23 21.6569 23 20H21ZM20 21C19.4477 21 19 20.5523 19 20H17C17 21.6569 18.3431 23 20 23V21ZM19 20C19 19.4477 19.4477 19 20 19V17C18.3431 17 17 18.3431 17 20H19ZM20 19C20.5523 19 21 19.4477 21 20H23C23 18.3431 21.6569 17 20 17V19Z" fill="#C4C4C4" />
                                <rect x="1" y="1" width="28" height="28" rx="6" stroke="#C4C4C4" strokeWidth="2" />
                            </svg>

                            Alamat Pengiriman
                        </Link>
                    </li>
                    {
                        account.membership !== null && typeof account.membership !== 'undefined' ? (
                            account.membership.type == config.membershipType.MEMBER_PRO ? (
                                <>
                                    <li>
                                        <Link to="/user-licenses" className={activeIndex === 6 ? 'active' : ''}>
                                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect x="1" y="1" width="28" height="28" rx="6" stroke="#C4C4C4" stroke-width="2" />
                                                <path d="M13 9H8C6.89543 9 6 9.89543 6 11V20C6 21.1046 6.89543 22 8 22H22C23.1046 22 24 21.1046 24 20V11C24 9.89543 23.1046 9 22 9H17M13 9V8C13 6.89543 13.8954 6 15 6C16.1046 6 17 6.89543 17 8V9M13 9C13 10.1046 13.8954 11 15 11C16.1046 11 17 10.1046 17 9M12 17C13.1046 17 14 16.1046 14 15C14 13.8954 13.1046 13 12 13C10.8954 13 10 13.8954 10 15C10 16.1046 10.8954 17 12 17ZM12 17C13.3062 17 14.4174 17.8348 14.8292 19M12 17C10.6938 17 9.58249 17.8348 9.17065 19M18 14H21M18 18H20" stroke="#C4C4C4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                            Daftar Lisensi
                                        </Link>
                                    </li>
                                </>
                            )
                                : null
                        ) : null
                    }

                    {
                        account.membership !== null && typeof account.membership !== 'undefined' ? (
                            (account.membership.type == config.membershipType.MEMBER_PRO || account.membership.type == config.membershipType.MEMBER) ? (
                                <>
                                    {/* <li>
                                        <form target="_blank" className="ps-4 pe-4" method="post" action={`${process.env.REACT_APP_INTERN_BASE_URL}account/login_member`}>
                                            <input type="hidden" name="token" value={auth.token} />
                                            <button type="submit" className="bg-light" style={{
                                                background: '#fff !important',
                                                padding: '0',
                                                border: 'none',
                                                color: '#C4C4C4'
                                            }}>Program Magang</button>
                                        </form>
                                    </li> */}
                                    <li>
                                        <Link to="/internship" className={activeIndex === 7 ? 'active' : ''}>
                                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect x="1" y="1" width="28" height="28" rx="6" stroke="#C4C4C4" stroke-width="2" />
                                                <path d="M15 17L24 12L15 7L6 12L15 17ZM15 17L21.1591 13.5783C21.7017 14.9466 22 16.4384 22 17.9999C22 18.7013 21.9398 19.3885 21.8244 20.0569C19.2143 20.3106 16.849 21.4006 15 23.0555C13.151 21.4006 10.7857 20.3106 8.17562 20.0569C8.06017 19.3885 8 18.7012 8 17.9999C8 16.4384 8.29824 14.9466 8.84088 13.5782L15 17ZM11 22.9999V15.5L15 13.2778" stroke="#C4C4C4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                            Program Magang
                                        </Link>
                                    </li>
                                </>
                            )
                                : null
                        ) : null
                    }

                    {/* <li>
                        <Link to="/certificates" className={ activeIndex === 7 ? 'active' : '' }>Sertifikat</Link>
                    </li>
                    <li>
                        <Link to="/file-sources" className={ activeIndex === 8 ? 'active' : '' }>Materi Kegiatan</Link>
                    </li> */}
                </ul>

                <div style={{
                    borderTop: '1px solid #C4C4C4',
                    marginLeft: '20px',
                    marginRight: '20px',
                }}></div>
                {
                    account.membership !== null && typeof account.membership !== 'undefined' ? (
                        // public
                        account.membership.type == config.membershipType.PUBLIC ? (
                            <div className="p-4">
                                <div>
                                    <div className="text-muted">
                                        Daftar menjadi anggota IAI
                                    </div>
                                    <Link to="/upgrade-membership" className="def-link">Daftar</Link>
                                </div>
                                <div className="mt-3">
                                    <div className="text-muted">
                                        Daftar menjadi Mahasiswa
                                    </div>
                                    <Link to="/upgrade-student" className="def-link">Daftar</Link>
                                </div>
                                {/* <div className="mt-3">
                                    <div className="text-muted">
                                        Daftar menjadi anggota IAI Non-Jakarta
                                    </div>
                                    <Link to="/upgrade-direct-member" className="def-link">Daftar</Link>
                                </div> */}
                            </div>
                        )

                            // student
                            : account.membership.type == config.membershipType.STUDENT ? (
                                <div className="p-4">
                                    <div>
                                        <div className="text-muted">
                                            Daftar menjadi anggota IAI
                                        </div>
                                        <Link to="/upgrade-membership" className="def-link">Daftar</Link>
                                    </div>
                                    {/* <div className="mt-3">
                                    <div className="text-muted">
                                        Daftar menjadi anggota Non-IAI
                                    </div>
                                    <Link to="/upgrade-non-iai" className="def-link">Daftar</Link>
                                </div> */}
                                </div>
                            )

                                // biasa
                                : account.membership.type == config.membershipType.MEMBER ? (
                                    <div className="p-4">
                                        <div className="text-muted">
                                            Upgrade Member Profesional
                                        </div>
                                        <Link to="/upgrade-membership" className="def-link">Info Lengkap</Link>
                                    </div>
                                )

                                    //pro
                                    : account.membership.type == config.membershipType.MEMBER_PRO ? (
                                        <div className="p-4">
                                            <PointBox />
                                        </div>
                                    )

                                        : null
                    ) : null
                }
            </>
        )
    }

    return (
        windowWidth < 992 ? (
            <>
                <div onClick={() => setShowMobileMenu(true)} style={{
                    position: 'sticky',
                    textAlign: 'center',
                    backgroundColor: '#fff',
                    padding: '15px 10px',
                    boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
                    borderRadius: '6px',
                }}>
                    {
                        account.name !== '' ? (
                            <>
                                <img src={account.avatar_url} alt="avatar" className="rounded avatar-m mb-3" />
                                <div className="break-text" style={{
                                    fontSize: '12pt',
                                    fontWeight: 'bold'
                                }}>
                                    {account.name}
                                </div>

                                <MembershipBadge />
                            </>
                        ) : null
                    }

                    <div style={{
                        borderTop: '1px solid #e6e6e6',
                        marginTop: '20px',
                        paddingTop: '15px',
                        color: '#C4C4C4',
                    }}>
                        Tampilkan Menu <FontAwesomeIcon icon={faArrowDown} />
                    </div>
                </div>
                <Offcanvas
                    placement="bottom"
                    show={showMobileMenu}
                    onHide={() => setShowMobileMenu(false)}
                    style={{
                        height: '90vh'
                    }}
                >
                    <Offcanvas.Header closeButton>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <MenuDisplay />
                    </Offcanvas.Body>
                </Offcanvas>
            </>
        ) : (
            <div className="box sticky-top">
                <MenuDisplay />
            </div>
        )
    );
}

export default LeftMenuPanel;