import React, { useEffect, useState } from "react";
import 'react-image-crop/dist/ReactCrop.css';
import { Row, Col } from "react-bootstrap";
import Dropzone from 'react-dropzone';
import {
    editProfilePro
} from './../../services/account';
import { useSelector } from "react-redux";
import swal from "sweetalert";
import {
    profile
} from './../../services/auth';
import Loading from './../Loading';

const EditProfilePro = () => {
    const auth = useSelector((state) => state.auth);

    const [loading, setLoading] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);

    const [iaiId, setIaiId] = useState("");

    const [refreshProfileTime, setRefreshProfileTime] = useState(null);

    const [scanIaiIdFiles, setScanIaiIdFiles] = useState([]);
    const [scanIaiId, setScanIaiId] = useState('');
    const [uploadedScanIaiId, setUploadedScanIaiId] = useState('');

    const fileToBase64WithCallback = (selectedFile, callback) => {
        let file = null;
        //Check File is not Empty
        if (selectedFile.length > 0) {
            // Select the very first file from list
            let fileToLoad = selectedFile[0];
            // FileReader function for read the file.
            let fileReader = new FileReader();
            // Onload of file read the file content
            fileReader.onload = function(fileLoadedEvent) {
                file = fileLoadedEvent.target.result;
                // Print data in console
                callback(file);
            };
            // Convert data to base64
            fileReader.readAsDataURL(fileToLoad);
        }
    }

    useEffect(() => {
        async function getProfile(token){
            setLoading(true);

            //reset form
            setScanIaiIdFiles([]);

            const res = await profile(token);
            if(res.error === null || res.error === ""){
                setIaiId(res.data.iai_id);
                setUploadedScanIaiId(res.data.scan_iai_id);
            }else{
                swal("Error!", res.error, "error");
            }

            setLoading(false);
        }

        getProfile(auth.token);
    }, [auth, refreshProfileTime]);

    const save = async (e) => {
        e.preventDefault();

        setSubmitLoading(true);

        const res = await editProfilePro(
            auth.token,
            iaiId,
            scanIaiId,
        )

        if(res.error == null){
            swal("Success!", "Berhasil mengubah Profil!", "success");

            //get profile
            setRefreshProfileTime(new Date().getTime());
        }else{
            swal("Error!", res.error, "error");
        }

        setSubmitLoading(false);
    }

    return (
        <>
            {
                loading ? <Loading/> : (
                    <form onSubmit={save} className="mt-3 box p-3">
                        <Row>
                            <Col md={12}>
                                <div className="mb-3">
                                    <label>Nomor Keanggotaan IAI*</label>
                                    <input type="text" value={iaiId} onChange={e => setIaiId(e.target.value)}  className="form-control" required={true}/>
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className="mb-3">
                                    <label>Scan Kartu Anggota*</label>
                                    <div hidden={ uploadedScanIaiId === null || uploadedScanIaiId === "" }>
                                        <div className="text-center">
                                            <a style={{
                                                textDecoration: 'none'
                                            }} href={uploadedScanIaiId} target="_blank" rel="noreferrer">Lihat Hasil Scan Kartu Keanggotaan</a>
                                        </div>
                                        <div className="alert alert-warning mt-2">
                                            Kosongkan Kolom ini apabila tidak ingin mengganti data.
                                        </div>
                                    </div>
                                    <Dropzone accept={['image/png', 'image/jpeg', 'image/jpg']} onDrop={(acceptedFiles) => {
                                        let error = null;
                                        acceptedFiles.forEach(file => {
                                            if(file.type !== 'image/png' && file.type !== 'image/jpeg' && file.type !== 'image/jpg'){
                                                error = 'File harus berformat png, jpg, atau jpeg';
                                            }
                                        });
                
                                        if(error === null){
                                            setScanIaiIdFiles(acceptedFiles);
                                            fileToBase64WithCallback(acceptedFiles, setScanIaiId);
                                        }else{
                                            swal('Error', error, 'error');
                                        }
                                    }} name="heroImage" multiple={false}>
                                        {({getRootProps, getInputProps}) => (
                                            <div {...getRootProps({className: 'dropzone'})}>
                                                <input {...getInputProps()} />
                                                <span style={{ fontSize: ".8rem" }}>
                                                        Drop file, or click to select file (.jpg, .png or .jpeg)
                                                </span>
                                            </div>
                                        )}
                                    </Dropzone>
                                    <aside className="mt-2">
                                        <b>File yang dipilih:</b>
                                        <ul>
                                        {
                                        scanIaiIdFiles.map(file => (
                                            <li key={file.path}>
                                                <a target="_blank" href={file.path}>{file.path}</a> - {(file.size / 1000)} Kb
                                            </li>
                                        ))
                                        }
                                        </ul>
                                    </aside>
                                </div>
                            </Col>
                        </Row>
                        <div className="text-end">
                            <button type="submit" className="btn btn-default">
                                {
                                    submitLoading ? <Loading /> : 'Simpan'
                                }
                            </button>
                        </div>
                    </form>
                )
            }
        </>
    );
}

export default EditProfilePro;