import { createSlice } from '@reduxjs/toolkit'

export const notificationSlice = createSlice({
  name: 'notification',
  initialState: {
    totalUnreadNotifications : 0,
    showNotificationScreen : false
  },
  reducers: {
    setTotalUnreadNotifications: (state, action) => {
      state.totalUnreadNotifications = action.payload
    },
    setShowNotificationScreen: (state, action) => {
      state.showNotificationScreen = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setTotalUnreadNotifications, setShowNotificationScreen } = notificationSlice.actions

export default notificationSlice.reducer