import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
    search
} from './../services/product';
import swal from 'sweetalert';
import { Container, Row, Col, Button } from "react-bootstrap";
import ProductBox from "../components/products/ProductBox";
import config from "../helpers/config";
import Loading from "../components/Loading";

const ProductListScreen = () => {
    const auth = useSelector((state) => state.auth);

    const [products, setProducts] = useState([]);
    const [page, setPage] = useState(1);
    const [reset, setReset] = useState(true);
    const [isContainMore, setIsContainMore] = useState(true);
    const [loading, setLoading] = useState(true);
    const [inputKeyword, setInputKeyword] = useState("");
    const [keyword, setKeyword] = useState("");

    //change title
    useEffect(() => {
        document.title = config.documentTitle + " - Merchandise";

        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        async function getProducts(){
            if(reset){
                setLoading(true);
            }

            const res = await search(auth.token, keyword, page);
            if(res.error === null){
                if(reset){
                    setProducts(res.data);
                }else{
                    //append
                    setProducts(products => [...products, ...res.data]);
                }

                if(res.data.length < 1){
                    setIsContainMore(false);
                }else{
                    setIsContainMore(true);
                }
            }else{
                swal("Error!", res.error, "error");
            }

            if(reset){
                setLoading(false);
            }
        }

        getProducts();
    }, [auth, page, keyword, reset]);

    const loadMore = () => {
        setReset(false);
        //set last id
        setPage(page+1);
    }

    useEffect(() => {
        const timeOutId = setTimeout(() => setKeyword(inputKeyword), 2000);
        return () => clearTimeout(timeOutId);
      }, [inputKeyword]);

    return (
        <div className="default-nav-mt">
            <Container>
                <Row>
                    <Col lg={3}>
                        <div className="box p-3 mb-4">
                            <div>
                                <label>Pencarian:</label>
                                <input value={inputKeyword} onChange={e => setInputKeyword(e.target.value)} className="form-control" placeholder="Cari nama produk"/>
                            </div>
                        </div>
                    </Col>
                    <Col lg={9}>
                        {
                            loading ? <Loading /> : (
                                <>
                                    <Row>
                                        {
                                            products.map((product, index) => (
                                                <Col className="mb-3" key={product.id} xl={3} lg={3} md={6} sm={12}>
                                                    <ProductBox product={product} />
                                                </Col>
                                            ))
                                        }
                                    </Row>
                                    <div className="text-center" hidden={!isContainMore}>
                                        <Button variant="danger" className="btn-default" onClick={loadMore}>Load More</Button>
                                    </div>
                                </>
                            )
                        }
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default ProductListScreen;