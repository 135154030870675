import React, { useEffect }from "react";
import { Container, Row, Col } from "react-bootstrap";
import MembershipOrderList from "../components/membership/MembershipOrderList";
import LeftMenuPanel from "../components/LeftMenuPanel";
import MembershipList from "../components/membership/MembershipList";
import config from "../helpers/config";

const MembershipScreen = () => {
    //change title
    useEffect(() => {
        document.title = config.documentTitle + " - Pembayaran Iuran Keanggotaan";

        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="default-nav-mt">
            <Container>
                <Row>
                    <Col sm={12} md={12} lg={4} className="mb-4">
                        <LeftMenuPanel activeIndex={3}/>
                    </Col>
                    <Col sm={12} md={12} lg={8}>
                        <MembershipOrderList />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default MembershipScreen;