import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import { faCalendarAlt, faClock, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { dateRange, timeRange } from './../../helpers/datetime';
import {
    intToMoney
} from './../../helpers/currency';
import moment from "moment";

const EventBoxMini = (props) => {
    const event = props.event;

    return (
        <Link to={'/event-detail/'+event.url} style={{
            textDecoration: 'none',
        }}>
            <div className="box p-2">
                <img src={ event.cover } alt="" style={{
                    width: '100%'
                }}/>
                { event.agenda !== null ? (
                    <div style={{
                        color: '#848484',
                        marginTop: '5px'
                    }}>
                        { moment(event.agenda.date).format('DD MMMM YYYY') }
                    </div>
                ) : null }
                <div style={{
                    color: '#221F1F',
                    fontSize: '12pt',
                    fontWeight: 'bold',
                    marginTop: '5px'
                }}>
                    { event.title }
                </div>
                <div style={{
                    color: '#C7B37F'
                }}>
                {
                    event.place !== "" && event.place_online !== "" ? event.place : "online"
                }
                </div>
            </div>
        </Link>
    );
}

export default EventBoxMini;