import React, { useEffect, useMemo, useState, useRef } from "react";

//bootstrap
import { Container, Row, Col, Button, Overlay, Popover, OverlayTrigger, Modal } from "react-bootstrap";

//firebase
import { firebaseInit } from './../helpers/firebase';
import { getAuth, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider } from "firebase/auth";

//services
import {
    registerGoogle,
    registerFacebook,
    register,
    sendActivationEmail
} from './../services/auth';

//redux
import { useDispatch, useSelector } from "react-redux";
import {
    setAuthentication
} from './../redux/slicers/authSlice';
import {
    setAccount
} from './../redux/slicers/accountSlice';
import config from "../helpers/config";
import { Link, useNavigate } from "react-router-dom";
import swal from 'sweetalert';
import withReactContent from 'sweetalert2-react-content';
import Loading from "../components/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const MySwal = withReactContent(swal);

//init firebase
firebaseInit();
const auth = getAuth();
const provider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();

const RegisterScreen = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const information = useSelector((state) => state.information);

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [passwordType, setPasswordType] = useState("password");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmPasswordType, setConfirmPasswordType] = useState("password");
    const [isValid, setIsValid] = useState(false);
    const [errors, setErrors] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isResendLoading, setIsResendLoading] = useState(false);
    const [isAgree, setIsAgree] = useState(false);

    const [currentEmailAddress, setCurrentEmailAddress] = useState("");
    const [resendEmailCounter, setResendEmailCounter] = useState(0);
    const [resendModalShow, setResendModalShow] = useState(false);

    //error message
    const [firstNameError, setFirstNameError] = useState(null);
    const [lastNameError, setLastNameError] = useState(null);
    const [usernameError, setUsernameError] = useState(null);
    const [emailError, setEmailError] = useState(null);
    const [passwordError, setPasswordError] = useState(null);
    const [confirmPasswordError, setConfirmPasswordError] = useState(null);

    //change title
    useEffect(() => {
        document.title = config.documentTitle + " - Daftar";

        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if(resendEmailCounter > 0){
            setTimeout(() => {
                setResendEmailCounter(resendEmailCounter - 1);
            }, 1000);
        }
    }, [resendEmailCounter]);

    const registerWithGoogle = () => {
        signInWithPopup(auth, provider)
        .then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            //const credential = GoogleAuthProvider.credentialFromResult(result);
            //const token = credential.accessToken;
            // The signed-in user info.
            const user = result.user;

            //register google API
            registerGoogle(
                user.photoURL,
                user.displayName,
                user.email
            ).then(res=> {
                if(res.token != null){
                    //set account
                    dispatch(setAccount(res.data));
                    //login success
                    dispatch(setAuthentication({
                        isAuthenticated: true,
                        token: res.token
                    }));
                }else{
                    //login failed
                    alert(res.error);
                }
            });
        }).catch((error) => {
            // Handle Errors here.
            //const errorCode = error.code;
            //const errorMessage = error.message;
            // The email of the user's account used.
            //const email = error.email;
            // The AuthCredential type that was used.
            //const credential = GoogleAuthProvider.credentialFromError(error);

            console.log(error);
        });
    }

    const registerWithFacebook = () => {
        signInWithPopup(auth, facebookProvider)
        .then((result) => {
            // This gives you a Facebook Access Token. You can use it to access the Facebook API.
            // The signed-in user info.
            const user = result.user;

            //register facebook API
            registerFacebook(
                user.photoURL,
                user.displayName,
                user.email
            ).then(res=> {
                if(res.token != null){
                    //set account
                    dispatch(setAccount(res.data));
                    //login success
                    dispatch(setAuthentication({
                        isAuthenticated: true,
                        token: res.token
                    }));
                }else{
                    //login failed
                    alert(res.error);
                }
            });
        }).catch((error) => {
            // Handle Errors here.
            //const errorCode = error.code;
            //const errorMessage = error.message;
            // The email of the user's account used.
            //const email = error.email;
            // The AuthCredential type that was used.
            //const credential = GoogleAuthProvider.credentialFromError(error);

            console.log(error);
        });
    }

    useEffect(() => {
        let errors = [];

        let firstNameMsg = '';
        if(firstName.length < 3){
            firstNameMsg = "Nama depan minimal 3 karakter";
        }
        if(firstNameMsg.length > 0){
            errors.push(firstNameMsg);
        }
        setFirstNameError(firstNameMsg);

        let lastNameMsg = '';
        if(lastName.length < 3){
            lastNameMsg = "Nama belakang minimal 3 karakter";
        }
        if(lastNameMsg.length > 0){
            errors.push(lastNameMsg);
        }
        setLastNameError(lastNameMsg);

        let emailMsg = '';
        if(emailAddress.length < 1){
            emailMsg = "Email harus diisi";
        }else{
            //check email regex
            const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if(!regex.test(emailAddress)){
                emailMsg = "Email tidak valid";
            }
        }
        if(emailMsg.length > 0){
            errors.push(emailMsg);
        }
        setEmailError(emailMsg);
        if(emailAddress !== null && emailAddress !== ""){
            setCurrentEmailAddress(emailAddress);
        }

        let usernameMsg = '';
        if(username.length < 3){
            usernameMsg = "Username minimal 3 karakter";
        }
        if(usernameMsg.length > 0){
            errors.push(usernameMsg);
        }
        setUsernameError(usernameMsg);

        let passwordMsg = '';
        if(password.length < 6){
            passwordMsg = "Password minimal 6 karakter";
        }
        if(passwordMsg.length > 0){
            errors.push(passwordMsg);
        }
        setPasswordError(passwordMsg);

        let confirmPasswordMsg = '';
        if(confirmPassword.length < 6){
            confirmPasswordMsg = "Konfirmasi password minimal 6 karakter";
        }else{
            if(password != confirmPassword){
                confirmPasswordMsg = "Password dan konfirmasi password tidak sama";
            }
        }
        if(confirmPasswordMsg.length > 0){
            errors.push(confirmPasswordMsg);
        }
        setConfirmPasswordError(confirmPasswordMsg);

        if(!isAgree){
            errors.push("Anda harus menyetujui syarat dan ketentuan");
        }

        setErrors(errors);

        if(errors.length > 0){
            setIsValid(false);
        }else{
            setIsValid(true);
        }
    }, [firstName, lastName, emailAddress, username, password, confirmPassword, isAgree]);

    const submitRegister = async e => {
        e.preventDefault();

        setIsLoading(true);

        if(emailAddress !== null || emailAddress !== ''){
            setCurrentEmailAddress(emailAddress);
        }

        const res = await register(
            firstName,
            lastName,
            emailAddress,
            username,
            password,
            confirmPassword
        );

        if(res.error === null){
            resetForm();
            
            showSuccessModal();
        }else{
            new swal("Gagal", res.error, "error");
        }

        setIsLoading(false);
    }

    const resetForm = () => {
        setFirstName("");
        setLastName("");
        setEmailAddress("");
        setUsername("");
        setPassword("");
        setConfirmPassword("");
    }

    const showSuccessModal = () => {
        MySwal.fire({
            html : (
                <div className="text-center p-4">
                    <img src={information.main_logo} alt="logo" style={{width: "150px"}}/>
                    <div className="mt-3" style={{
                        fontSize: "18pt",
                        fontWeight: "bold",
                    }}>
                        Verifikasi Akun Anda
                    </div>
                    <div className="mt-3 text-muted" style={{
                        fontSize: "12pt",
                        fontWeight: "normal",
                    }}>
                        Kami telah mengirimkan tautan untuk aktivasi akun Anda ke alamat email<br/>{currentEmailAddress}.
                    </div>
                    <div className="mt-3 text-muted" style={{
                        fontSize: "12pt",
                        fontWeight: "normal",
                    }}>
                        Tidak mendapatkan email? <span onClick={() => {
                            setResendModalShow(true);
                            MySwal.close();
                            resendEmail();
                        }} className="def-link" style={{
                            cursor: "pointer"
                        }}>Kirim ulang</span>
                    </div>
                </div>
            ),
            showConfirmButton: false,
        });
    }

    const resendEmail = async () => {
        setIsResendLoading(true);

        const res = await sendActivationEmail(currentEmailAddress);
        if(res.error === null){
            setResendEmailCounter(30);
        }else{
            new swal("Gagal", res.error, "error");
        }

        setIsResendLoading(false);
    }

    return (
        <>
            <Modal show={resendModalShow} onHide={() => setResendModalShow(false)}>
                <Modal.Body>
                    <div className="text-center p-4">
                        <img src={information.main_logo} alt="logo" style={{width: "150px"}}/>
                        {
                            isResendLoading ? <div className="mt-4"><Loading /></div> : (
                                <>
                                    <div className="mt-3" style={{
                                        fontSize: "18pt",
                                        fontWeight: "bold",
                                    }}>
                                        Verifikasi Akun Anda
                                    </div>
                                    <div className="mt-3 text-muted" style={{
                                        fontSize: "12pt",
                                        fontWeight: "normal",
                                    }}>
                                        Kami telah mengirimkan tautan untuk aktivasi akun Anda ke alamat email<br/>{currentEmailAddress}.
                                    </div>
                                    <div className="mt-3 text-muted" style={{
                                        fontSize: "12pt",
                                        fontWeight: "normal",
                                    }}>
                                        Kirim ulang dalam:&nbsp; 
                                        { 
                                        resendEmailCounter > 0 ? 
                                            (
                                                <span className="def-link" style={{
                                                    cursor: "pointer"
                                                }}>{ resendEmailCounter } Detik</span>
                                            ) : 
                                            (
                                                <span className="def-link" onClick={() => resendEmail()} style={{
                                                    cursor: "pointer"
                                                }}>Kirim Ulang</span>
                                            )
                                        }
                                    </div>
                                </>
                            )
                        }
                    </div>
                </Modal.Body>
            </Modal>

            <div className="login-container">
                <div className="login-image d-none d-md-none d-xl-block d-xxl-block p-3" style={{
                    backgroundImage: `url(/img/register.png)`,
                    backgroundSize: "cover",
                }}>
                    <img onClick={() => navigate('/') } style={{
                        cursor: "pointer",
                    }} src={information.white_logo} className="icon"/>
                </div>
                <div className="login-content">
                    <img onClick={() => navigate('/') } style={{
                        width: "150px",
                        cursor: "pointer",
                    }} src={information.main_logo} className="m-4 d-block d-md-block d-xl-none d-xxl-none"/>
                    <div className="text-end p-5 d-none d-md-none d-xl-block d-xxl-block">
                        Sudah memiliki akun? <Link to="/login" className="def-link">Masuk</Link>
                    </div>
                    <Container>
                        <Row className="justify-content-center">
                            <Col 
                            sm={{
                                span: 12
                            }} 
                            md={{
                                span: 10
                            }}
                            lg={{
                                span: 10
                            }}
                            xl={{
                                span: 10
                            }}
                            xxl={{
                                span: 6
                            }}
                            >
                                <div className="pb-5 p-4">
                                    <div style={{
                                        fontWeight: "bold",
                                        fontSize: "14pt",
                                    }}>
                                        Registrasi Akun
                                    </div>
                                    <div className="d-flex mt-3">
                                        <button onClick={() => registerWithGoogle()} className="btn-google me-3">
                                            <img src="/img/google.png" />
                                            Masuk dengan Google
                                        </button>
                                        <img onClick={() => registerWithFacebook()} src="/img/btn-fb.png" style={{
                                            width: '50px',
                                            height: '50px',
                                            cursor: 'pointer'
                                        }}/>
                                    </div>
                                    <div className="mt-5 mb-5">
                                        <div className="lined">
                                            <span onClick={() => showSuccessModal()}>Atau</span> 
                                        </div>
                                    </div>

                                    <form onSubmit={submitRegister}>
                                        <div className="row">
                                            <div className="col-6 col-sm-6 col-md-6">
                                                <div className="mt-4">
                                                    <label><b>Jataban Depan + Nama Depan</b></label>
                                                    <OverlayTrigger trigger="focus" placement="top" overlay={
                                                        firstNameError !== null && firstNameError !== "" ? (
                                                            <Popover id="popover-contained">
                                                                <Popover.Body>
                                                                    { firstNameError }
                                                                </Popover.Body>
                                                            </Popover>
                                                        ) : <></>
                                                    }>
                                                        <input disabled={isLoading} type="text" value={firstName} onChange={e => setFirstName(e.target.value)} placeholder="Drs. Agung" className="form-control" required/>
                                                    </OverlayTrigger>
                                                </div>
                                            </div>
                                            <div className="col-6 col-sm-6 col-md-6">
                                                <div className="mt-4">
                                                    <label><b>Nama Belakang + Jabatan Belakang</b></label>
                                                    <OverlayTrigger trigger="focus" placement="top" overlay={
                                                        lastNameError !== null && lastNameError !== "" ? (
                                                            <Popover id="popover-contained">
                                                                <Popover.Body>
                                                                    { lastNameError }
                                                                </Popover.Body>
                                                            </Popover>
                                                        ) : <></>
                                                    }>
                                                        <input disabled={isLoading} type="text" value={lastName} onChange={e => setLastName(e.target.value)} placeholder="Sanjaya, S.T." className="form-control" required/>
                                                    </OverlayTrigger>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6 col-sm-6 col-md-6">
                                                <div className="mt-4">
                                                    <label><b>Email</b></label>
                                                    <OverlayTrigger trigger="focus" placement="top" overlay={
                                                        emailError !== null && emailError !== "" ? (
                                                            <Popover id="popover-contained">
                                                                <Popover.Body>
                                                                    { emailError }
                                                                </Popover.Body>
                                                            </Popover>
                                                        ) : <></>
                                                    }>
                                                        <input disabled={isLoading} type="text" value={emailAddress} onChange={e => setEmailAddress(e.target.value)} placeholder="" className="form-control" autoComplete="off" required/>
                                                    </OverlayTrigger>
                                                </div>
                                            </div>
                                            <div className="col-6 col-sm-6 col-md-6">
                                                <div className="mt-4">
                                                    <label><b>Username</b></label>
                                                    <OverlayTrigger trigger="focus" placement="top" overlay={
                                                        usernameError !== null && usernameError !== "" ? (
                                                            <Popover id="popover-contained">
                                                                <Popover.Body>
                                                                    { usernameError }
                                                                </Popover.Body>
                                                            </Popover>
                                                        ) : <></>
                                                    }>
                                                        <input disabled={isLoading} type="text" value={username} onChange={e => setUsername(e.target.value)} placeholder="" className="form-control" required/>
                                                    </OverlayTrigger>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6 col-sm-6 col-md-6">
                                                <div className="mt-4">
                                                    <label><b>Kata Sandi</b></label>
                                                    <OverlayTrigger trigger="focus" placement="top" overlay={
                                                        passwordError !== null && passwordError !== "" ? (
                                                            <Popover id="popover-contained">
                                                                <Popover.Body>
                                                                    { passwordError }
                                                                </Popover.Body>
                                                            </Popover>
                                                        ) : <></>
                                                    }>
                                                        <div>
                                                            <input disabled={isLoading} type={passwordType} value={password} onChange={e => setPassword(e.target.value)} placeholder="minimal 6 karakter" className="form-control" required/>
                                                            <span onClick={() => {
                                                                setPasswordType(passwordType === 'password' ? 'text' : 'password');
                                                            }} style={{
                                                                cursor: "pointer",
                                                                marginTop: "-30px",
                                                                float: "right",
                                                                marginRight: "10px",
                                                            }}><FontAwesomeIcon icon={ passwordType === 'password' ? faEye : faEyeSlash }/></span>
                                                        </div>
                                                    </OverlayTrigger>
                                                </div>
                                            </div>
                                            <div className="col-6 col-sm-6 col-md-6">
                                                <div className="mt-4">
                                                    <label><b>Konfirmasi Kata Sandi</b></label>
                                                    <OverlayTrigger trigger="focus" placement="top" overlay={
                                                        confirmPasswordError !== null && confirmPasswordError !== "" ? (
                                                            <Popover id="popover-contained">
                                                                <Popover.Body>
                                                                    { confirmPasswordError }
                                                                </Popover.Body>
                                                            </Popover>
                                                        ) : <></>
                                                    }>
                                                        <div>
                                                            <input disabled={isLoading} type={confirmPasswordType} value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} placeholder="" className="form-control" required/>
                                                            <span onClick={() => {
                                                                    setConfirmPasswordType(confirmPasswordType === 'password' ? 'text' : 'password');
                                                                }} style={{
                                                                    cursor: "pointer",
                                                                    marginTop: "-30px",
                                                                    float: "right",
                                                                    marginRight: "10px",
                                                                }}><FontAwesomeIcon icon={ confirmPasswordType === 'password' ? faEye : faEyeSlash }/></span>
                                                        </div>
                                                    </OverlayTrigger>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-sm-12 col-md-12 mt-4">
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="checkbox" id="inlineCheckbox1" onChange={e => setIsAgree(e.target.checked)} checked={isAgree} />
                                                    <label className="form-check-label" htmlFor="inlineCheckbox1">
                                                        Dengan membuat akun ini berarti Anda telah setuju dengan <a href="#" className="def-link">Syarat dan Ketentuan</a> serta <a href="#" className="def-link">Kebijakan Privasi</a> layanan ini.
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-5">
                                            {
                                                !isValid ? (
                                                    <div className="alert alert-danger"  style={{
                                                        display:"none"
                                                    }}>
                                                        {
                                                            errors !== null ?
                                                            <ul>
                                                                {
                                                                errors.map((error, index) => (
                                                                    <li key={index}>
                                                                        {error}
                                                                    </li>
                                                                ))
                                                                }
                                                            </ul> : null
                                                        }
                                                    </div>
                                                ) : null
                                            }
                                            <button disabled={!isValid || isLoading} className="btn btn-default mt-3 ps-5 pe-5" type="submit">
                                                { isLoading ? "Membuat Akun..." : "Buat Akun" }
                                            </button>
                                        </div>
                                    </form>
                                   
                                    <div className="text-center p-5 d-block d-md-block d-xl-none d-xxl-none">
                                        Sudah memiliki akun? <Link to="/login" className="def-link">Masuk</Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    );
}

export default RegisterScreen;