import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router";
import { setRedirectUrl } from "../redux/slicers/authSlice";

const UnauthorizedRoute = ({ children }) => {
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const [url, setUrl] = useState("/");
 
    useEffect(() => {
        if(auth.redirectUrl !== null){
            setUrl(auth.redirectUrl);
        }
    }, [auth]);


    return !auth.isAuthenticated ? children : <Navigate to={url} />;
}

export default UnauthorizedRoute;