import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router";
import { setRedirectUrl } from "../redux/slicers/authSlice";

const PrivateRoute = ({ children }) => {
    let location = useLocation();
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    
    useEffect(() => {
        if(auth.isAuthenticated){
            dispatch(setRedirectUrl(null));
        }else{
            //check path name
            if(location.pathname !== '/login' && location.pathname !== '/'){
                dispatch(setRedirectUrl(location.pathname));
            }
        }
    }, [location, auth]);

    return auth.isAuthenticated ? children : <Navigate to="/login" state={{ from: location }} replace/>;
}

export default PrivateRoute;