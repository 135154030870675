import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
    license
} from './../../services/transaction';
import {
    cancel as cancelApi
} from './../../services/license';
import swal from "sweetalert";
import { Col, Nav, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import moment from "moment";
import { intToMoney } from "../../helpers/currency";
import { renderStatus, renderLicenseType } from "../../helpers/license";
import { Link } from "react-router-dom";
import config from "../../helpers/config";

const UserLicenses = () => {
    const navigate = useNavigate();
    const auth = useSelector((state) => state.auth);
    const account = useSelector((state) => state.account);

    const [orders, setOrders] = useState(null);
    const [status, setStatus] = useState(null);
    const [keyword, setKeyword] = useState("");
    const [page, setPage] = useState(1);
    const [reset, setReset] = useState(true);
    const [isContainMore, setIsContainMore] = useState(true);
    const [showAddModal, setShowAddModal] = useState(false);

    useEffect(() => {
        async function getOrders(){
            const res = await license(
                auth.token,
                status,
                keyword,
                page
            );

            if(res.error === null){
                if(reset){
                    setOrders(res.data);
                }else{
                    //append
                    setOrders(orders => [...orders, ...res.data]);
                }

                if(res.data.length < 1){
                    setIsContainMore(false);
                }else{
                    setIsContainMore(true);
                }
            }else{
                swal('Error', res.error, 'error');
            }
        }

        //check member type
        if(account !== null && typeof account.membership !== "undefined" && account.membership !== null){
            if(account.membership.type !== config.membershipType.MEMBER_PRO){
                //show alert
                swal('Error', 'Anda tidak memiliki akses ke halaman ini', 'error');
                navigate('/');
            }else{
                getOrders();
            }
        }
    }, [account, auth, page, keyword, reset, status]);

    const loadMore = () => {
        setReset(false);
        //set last id
        setPage(page+1);
    }

    const filterStatus = (newStatus) => {
        setReset(true);
        setPage(1);
        setKeyword("");
        setStatus(newStatus);
    }

    const cancel = (order) => {
        //show confirm
        swal({
            title: "Konfirmasi",
            text: "Apakah anda yakin ingin membatalkan "+order.license_name+"?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then(async (willDelete) => {
            if(willDelete){
                const res = await cancelApi(auth.token, order.id);
                if(res.error == null){
                    swal('Berhasil', 'Pesanan berhasil dibatalkan', 'success');

                    filterStatus(-1)
                }else{
                    swal('Error', res.error, 'error');
                }
            }
        });
    }

    const closeModal = () => {
        setShowAddModal(false);
    }

    return (
        <div>
            <h4>Lisensi</h4>
            <div className="box p-3">
                Silahkan klik tombol dibawah untuk mengajukan lisensi.
                <br/>
                <Link className="btn btn-default btn-sm" to="/manage-user-license">Ajukan Lisensi</Link>
            </div>
            <Nav variant="pills" className="mt-3" defaultActiveKey="#semua">
                <Nav.Item>
                    <Nav.Link active={status == null} href="#semua" onClick={() => filterStatus(null)}>Semua</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link active={status == 0} href="#pending" onClick={() => filterStatus("0")}>Pending</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link active={status == 1} href="#menunggu-pembayaran" onClick={() => filterStatus(1)}>Menunggu Pembayaran</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link active={status == 2} href="#berhasil" onClick={() => filterStatus(2)}>Berhasil</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link active={status == 3} href="#pembayaran-gagal" onClick={() => filterStatus(3)}>Pembayaran Gagal</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link active={status == 4} href="#diproses" onClick={() => filterStatus(4)}>Sedang Diproses</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link active={status == 5} href="#revisi" onClick={() => filterStatus(5)}>Harus Revisi</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link active={status == 6} href="#disetujui" onClick={() => filterStatus(6)}>Disetujui</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link active={status == 7} href="#selesai" onClick={() => filterStatus(7)}>Selesai</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link active={status == 8} href="#draft" onClick={() => filterStatus(8)}>Draft</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link active={status == -1} href="#dibatalkan" onClick={() => filterStatus(-1)}>Dibatalkan</Nav.Link>
                </Nav.Item>
            </Nav>
            {
                orders !== null ? (
                    orders.map((order, index) => (
                        <div key={index} className="box p-4 mb-3 mt-3">
                        <Row style={{
                            cursor: 'pointer'
                        }}>
                            <Col md={6}>
                                <div>
                                    <span style={{
                                        fontSize: '14px',
                                        color: '#696969',
                                        fontWeight: 'bold'
                                    }}>
                                        { order.license_name }
                                    </span>
                                    <span style={{
                                        fontSize: '12px',
                                        color: '#868686',
                                        marginLeft: '10px'
                                    }}>
                                        { moment(order.created_at).format('HH:mm, DD MMM YYYY') }
                                    </span>
                                    <span style={{
                                        fontSize: '12px',
                                        color: '#868686',
                                        marginLeft: '10px'
                                    }}>
                                        { renderStatus(order.status) }
                                    </span>
                                    <span style={{
                                        fontSize: '12px',
                                        color: '#868686',
                                        marginLeft: '10px'
                                    }}>
                                        #{ order.jurnal_transaction_no }
                                    </span>
                                </div>
                                <div style={{
                                    fontSize: '14px',
                                    fontWeight: 'light',
                                    marginTop: '10px',
                                    color: '#828282'
                                }}>Jenis Pengajuan:</div>
                                <div style={{
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                    textTransform: 'capitalize',
                                    marginBottom: '10px'
                                }}>
                                    { renderLicenseType(order.license_type) }
                                </div>  
                            </Col>
                            <Col md={6} className="text-end ">
                                <div style={{
                                        fontSize: '14px',
                                        fontWeight: 'light',
                                        color: '#828282'
                                    }}>Metode Pembayaran:</div>
                                <div style={{
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                    textTransform: 'capitalize',
                                }}>
                                    { order.payment_method }
                                </div>
                                <div style={{
                                    fontSize: '14px',
                                    fontWeight: 'light',
                                    marginTop: '10px',
                                    color: '#828282'
                                }}>Total Harga:</div>
                                <div style={{
                                    fontSize: '14px',
                                    fontWeight: 'bold'
                                }}>
                                    { intToMoney(order.total_cost) }
                                </div>
                            </Col>
                        </Row>
                        {
                            order.note !== null && order.note !== '' ? (
                                <Row>
                                    <Col md={12}>
                                        <div style={{
                                                fontSize: '14px',
                                                textTransform: 'capitalize',
                                            }} className="alert alert-warning">
                                                <b>Catatan:</b><br/>
                                            { order.note }
                                        </div>
                                    </Col>
                                </Row>
                            ) : null
                        }
                        <Row>
                            <Col sm={6}>
                                <div className="text-start">
                                    {
                                        order.status == config.licenseStatus.DRAFT ? (
                                            <span className="me-5" style={{
                                                color: "blue",
                                                cursor: "pointer"
                                            }} onClick={() => navigate('/user-licenses/revision/'+order.id)}>Ubah Data</span>
                                        ) : null
                                    }
                                    <span style={{
                                        color: "blue",
                                        cursor: "pointer"
                                    }} onClick={() => navigate('/user-licenses/order/'+order.id)}>Lihat Detil</span>
                                </div>
                            </Col>
                            <Col sm={6}>
                                <div hidden={order.status !== "1"} className="text-end">
                                    <span style={{
                                        color: "blue",
                                        cursor: "pointer"
                                    }} onClick={() => cancel(order)}>Batalkan</span>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    ))
                ) : null
            }
        </div>
    );
}

export default UserLicenses;