export const event = async (token, status, keyword, page) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}transaction/event`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            status: status,
            keyword: keyword,
            page: page
        })
    });
    const json = await response.json();
    return json;
}

export const eventOrder = async (token, id) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}transaction/event_order`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            id: id,
        })
    });
    const json = await response.json();
    return json;
}

export const product = async (token, status, keyword, page) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}transaction/product`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            status: status,
            keyword: keyword,
            page: page
        })
    });
    const json = await response.json();
    return json;
}

export const productOrder = async (token, id) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}transaction/product_order`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            id: id,
        })
    });
    const json = await response.json();
    return json;
}

export const productOrderUpdate = async (token, id, status) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}transaction/product_order_update`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            id: id,
            status: status,
        })
    });
    const json = await response.json();
    return json;
}

export const license = async (token, status, keyword, page) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}transaction/license`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            status: status,
            keyword: keyword,
            page: page
        })
    });
    const json = await response.json();
    return json;
}

export const licenseOrder = async (token, id) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}transaction/license_order`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            id: id,
        })
    });
    const json = await response.json();
    return json;
}