import React, { useEffect }from "react";
import { Container, Row, Col } from "react-bootstrap";
import MembershipOrder from "../components/membership/MembershipOrder";
import LeftMenuPanel from "../components/LeftMenuPanel";
import config from "../helpers/config";
import { useParams } from "react-router";

const MembershipOrderScreen = () => {
    let params = useParams();

    //change title
    useEffect(() => {
        document.title = config.documentTitle + " - Membership Order";

        window.scrollTo(0, 0);
    }, []);

    return (
        <Container>
            <Row>
                <Col sm={12} md={12} lg={4} className="mb-4">
                    <LeftMenuPanel activeIndex={3}/>
                </Col>
                <Col sm={12} md={12} lg={8}>
                    <div className="box p-3">
                        <MembershipOrder id={params.id}/>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default MembershipOrderScreen;