import React, { useState ,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    detail
} from './../../services/account';
import swal from "sweetalert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { getWorkDuration } from "../../helpers/datetime";
import { Col, Row } from "react-bootstrap";
import {
    showPortfolioDetail
} from './../../redux/slicers/portfolioSlice';
import PortofolioBox from './../portfolios/PortfolioBox';
import MembershipBadge from "../MembershipBadge";
import moment from "moment";

const UserDetail = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const [userDetail, setUserDetail] = useState(null); 
    const [isVisible, setIsVisible] = useState(true); 

    useEffect(() => {
        if(user.selectedUserId !== null){
            async function getDetail(id){
                const res = await detail(id);
                if(res.error === null){
                    setUserDetail(res.data);
                    setIsVisible(res.data.privacy_hide_profile === "0" ? true : false);
                }else{
                    swal("Error!", res.error.message, "error");
                }
            }

            getDetail(user.selectedUserId);
        }
    }, [user]);

    const showDetail = (id) => {
        dispatch(showPortfolioDetail(id));
    }

    return (
        <div>
            {
                userDetail !== null ? (
                    <div>
                        <div style={{
                            backgroundColor: '#fff',
                        }}>
                            <div style={{
                                backgroundImage: `url(/img/bg.png)`,
                                backgroundPosition: 'center',
                                height: '100px',
                                width: '100%',
                            }}></div>
                            <div className="d-flex" style={{
                                marginTop: '-30px',
                                padding: '0px 30px',
                            }}>
                                <img src={ userDetail.avatar } alt={ userDetail.name } className="mb-3 img-thumbnail" style={{
                                    width: '100px',
                                    height: '100px',
                                    borderRadius: '50%'
                                }} />
                                <div style={{
                                    marginLeft: '20px',
                                    marginTop: '35px'
                                }}>
                                    <div style={{
                                        fontSize: '14pt',
                                        fontWeight: 'bold',
                                        textTransform: 'capitalize'
                                    }}>
                                        { userDetail.first_name+' '+userDetail.last_name }
                                    </div>
                                    {
                                        userDetail.membership_name !== null && userDetail.membership_name !== "" ? (
                                            <MembershipBadge membershipType={userDetail.membership_type} membershipLabel={userDetail.membership_name}/>
                                        ) : (
                                            <div className="badge bg-secondary">
                                                Non member
                                            </div>
                                        )
                                    }
                                    <div className="mt-3 mb-3" hidden={!isVisible}>
                                        { userDetail.province_name !== "" && userDetail.province_name !== null ? (
                                            <div className="row">
                                                <div className="col-sm-12 col-md-12">
                                                    <svg style={{
                                                        marginTop: '-5px',
                                                        marginRight: '12px'
                                                    }} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M5.05025 4.05025C7.78392 1.31658 12.2161 1.31658 14.9497 4.05025C17.6834 6.78392 17.6834 11.2161 14.9497 13.9497L10 18.8995L5.05025 13.9497C2.31658 11.2161 2.31658 6.78392 5.05025 4.05025ZM10 11C11.1046 11 12 10.1046 12 9C12 7.89543 11.1046 7 10 7C8.89543 7 8 7.89543 8 9C8 10.1046 8.89543 11 10 11Z" fill="#111826"/>
                                                    </svg>

                                                    {userDetail.province_name}
                                                </div>
                                            </div>
                                        ) : null }
                                        { userDetail.contact_email_address !== "" && userDetail.contact_email_address !== null ? (
                                            <div className="row">
                                                <div className="col-sm-12 col-md-12 mt-1">
                                                    <FontAwesomeIcon icon={faEnvelope} className="me-3"/><a href={"mailto:"+userDetail.contact_email_address}>{userDetail.contact_email_address}</a>
                                                </div>
                                            </div>
                                        ) : null }
                                        <div className="row" style={{
                                            fontSize: '10pt'
                                        }}>
                                            { userDetail.contact_instagram !== "" && userDetail.contact_instagram !== null ? (
                                                <div className="col-sm-12 col-md-12 mt-2">
                                                    <svg style={{
                                                        marginTop: '-5px',
                                                        marginRight: '10px'
                                                    }} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M0 5C0 2.23858 2.23858 0 5 0H15C17.7614 0 20 2.23858 20 5V15C20 17.7614 17.7614 20 15 20H5C2.23858 20 0 17.7614 0 15V5ZM14 10C14 12.2091 12.2091 14 10 14C7.79086 14 6 12.2091 6 10C6 7.79086 7.79086 6 10 6C12.2091 6 14 7.79086 14 10ZM15.5 6C16.3284 6 17 5.32843 17 4.5C17 3.67157 16.3284 3 15.5 3C14.6716 3 14 3.67157 14 4.5C14 5.32843 14.6716 6 15.5 6Z" fill="#111826"/>
                                                    </svg>

                                                    { userDetail.contact_instagram }
                                                </div>
                                            ) : null }
                                            { userDetail.contact_facebook !== "" && userDetail.contact_facebook !== null ? (
                                                <div className="col-sm-12 col-md-12 mt-2">
                                                    <svg style={{
                                                        marginLeft: '5px',
                                                        marginTop: '-5px',
                                                        marginRight: '15px'
                                                    }} width="11" height="20" viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M10.2347 11.165L10.8023 7.57282H7.25157V5.24272C7.25157 4.25971 7.74668 3.30097 9.33703 3.30097H10.9524V0.242718C10.9524 0.242718 9.48706 0 8.08676 0C5.16112 0 3.25071 1.72087 3.25071 4.83495V7.57282H0V11.165H3.25071V19.8495C3.90335 19.949 4.57099 20 5.25114 20C5.93129 20 6.59893 19.949 7.25157 19.8495V11.165H10.2347Z" fill="#111826"/>
                                                    </svg>

                                                    { userDetail.contact_facebook }
                                                </div>
                                            ) : null }
                                            { userDetail.contact_linkedin !== "" && userDetail.contact_linkedin !== null ? (
                                                <div className="col-sm-12 col-md-12 mt-2">
                                                    <svg style={{
                                                        marginTop: '-5px',
                                                        marginRight: '10px'
                                                    }} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M4.65548 6.43457H0.640452C0.462258 6.43457 0.317871 6.57902 0.317871 6.75715V19.6557C0.317871 19.8339 0.462258 19.9783 0.640452 19.9783H4.65548C4.83368 19.9783 4.97807 19.8339 4.97807 19.6557V6.75715C4.97807 6.57902 4.83368 6.43457 4.65548 6.43457Z" fill="#111826"/>
                                                        <path d="M2.64942 0.0224609C1.18852 0.0224609 0 1.20969 0 2.66898C0 4.12891 1.18852 5.31659 2.64942 5.31659C4.10916 5.31659 5.29671 4.12885 5.29671 2.66898C5.29677 1.20969 4.10916 0.0224609 2.64942 0.0224609Z" fill="#111826"/>
                                                        <path d="M14.868 6.11426C13.2554 6.11426 12.0634 6.80748 11.3403 7.59516V6.75742C11.3403 6.57929 11.1959 6.43484 11.0177 6.43484H7.17265C6.99445 6.43484 6.85007 6.57929 6.85007 6.75742V19.656C6.85007 19.8342 6.99445 19.9786 7.17265 19.9786H11.1789C11.3571 19.9786 11.5015 19.8342 11.5015 19.656V13.2742C11.5015 11.1237 12.0856 10.2859 13.5847 10.2859C15.2174 10.2859 15.3471 11.629 15.3471 13.3848V19.6561C15.3471 19.8343 15.4915 19.9786 15.6697 19.9786H19.6774C19.8556 19.9786 20 19.8343 20 19.6561V12.581C20 9.38323 19.3903 6.11426 14.868 6.11426Z" fill="#111826"/>
                                                    </svg>

                                                    { userDetail.contact_linkedin }
                                                </div>
                                            ) : null }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div hidden={!isVisible} style={{
                            backgroundColor: '#f8f8f8',
                            padding: '20px 35px'
                        }}>
                            <div>
                                {
                                    userDetail.educations.length > 0 ? (
                                        <>
                                            <b>Educations</b>
                                            {
                                            userDetail.educations.map((education, index) => (
                                                <div key={index} className="shadow-low" style={{
                                                    backgroundColor: '#fff',
                                                    padding: '10px',
                                                    borderRadius: '10px',
                                                    marginTop: '10px',
                                                }}>
                                                    <div>
                                                        { education.institution }
                                                        {
                                                            education.strata !== null && education.strata !== "" ? (
                                                                ' | '+education.strata
                                                            ) : null
                                                        }
                                                        {
                                                            education.major !== null && education.major !== "" ? (
                                                                ' | '+education.major
                                                            ) : null
                                                        }
                                                    </div>
                                                    <div style={{
                                                        fontSize: '10pt',
                                                    }}>
                                                        <FontAwesomeIcon icon={faMapMarkerAlt}/> { education.city }
                                                    </div>
                                                    <div hidden={education.is_active_student === '1'} style={{
                                                        fontSize: '10pt',
                                                        color: '#999'
                                                    }}>
                                                        <i>Graduated on { education.graduated_on }</i>
                                                    </div>
                                                    <div hidden={education.is_active_student === '0'} style={{
                                                        fontSize: '10pt',
                                                        color: '#999'
                                                    }}>
                                                        <i>Masih Aktif</i>
                                                    </div>
                                                </div>
                                            ))
                                            }
                                        </>
                                    ) : null
                                }
                                {
                                    userDetail.employments.length > 0 ? (
                                        <div className="mt-4">
                                            <b>Employements</b>
                                            {
                                            userDetail.employments.map((employment, index) => (
                                                <div key={index} className="shadow-low" style={{
                                                    backgroundColor: '#fff',
                                                    padding: '10px',
                                                    borderRadius: '10px',
                                                    marginTop: '10px',
                                                }}>
                                                    <div style={{
                                                        fontWeight: 'bold',
                                                    }}>
                                                        { employment.job_title }
                                                    </div>
                                                    <div>
                                                        { employment.company_name+' | '+employment.state+', '+employment.country }
                                                    </div>
                                                    <div style={{
                                                        fontSize: '10pt',
                                                        color: '#999'
                                                    }}>{ getWorkDuration(employment.start_date, employment.is_current_company === '1' ? moment.now() : employment.end_date, employment.is_current_company) }</div>
                                                </div>
                                            ))
                                            }
                                        </div>
                                    ) : null
                                }
                                {
                                    userDetail.portfolios.length > 0 ? (
                                        <div className="mt-4">
                                            <b>Portfolios</b>
                                            <Row>
                                            {
                                            userDetail.portfolios.map((portfolio, index) => (
                                                <Col key={index} sm={12} md={6} lg={4}>
                                                    <PortofolioBox portfolio={portfolio}/>
                                                </Col>
                                            ))
                                            }
                                            </Row>
                                        </div>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>
                ) : null
            }
        </div>
    );
}

export default UserDetail;