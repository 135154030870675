import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { history as getHistoriesAPI, request } from '../../services/points';
import swal from 'sweetalert';
import { Button, Col, Row } from 'react-bootstrap';
import moment from 'moment';
import {useDropzone} from 'react-dropzone';
import { useNavigate } from 'react-router';
import Loading from '../Loading';

const RequestPoint = () => {
    const auth = useSelector((state) => state.auth);
    const navigate = useNavigate();

    const [from, setFrom] = useState('');
    const [point, setPoint] = useState(0);
    const [image, setImage] = useState("");
    const [loading, setLoading] = useState(false);

    const {acceptedFiles, fileRejections, getRootProps, getInputProps} = useDropzone({
        maxFiles:1,
        accept: 'image/jpeg, image/png',
        onDrop: files => {
            fileToBase64(files);
        }
    });
  
    const files = acceptedFiles.map(file => (
        <div key={file.path}>
            <img src={image} alt={file.name} className="img-thumbnail" style={{
                width: '100px',
            }}/>
        </div>
    ));

    const fileRejectionItems = fileRejections.map(({ file, errors  }) => { 
        return (
          <li key={file.path}>
               {file.path} - {(file.size/ 1000)} Kb
               <ul>
                 {errors.map(e => <li key={e.code}>{e.message}</li>)}
              </ul>
          </li>
        ) 
    });

    const fileToBase64 = (selectedFile) => {
        let file = null;
        //Check File is not Empty
        if (selectedFile.length > 0) {
            // Select the very first file from list
            let fileToLoad = selectedFile[0];
            // FileReader function for read the file.
            let fileReader = new FileReader();
            // Onload of file read the file content
            fileReader.onload = function(fileLoadedEvent) {
                file = fileLoadedEvent.target.result;
                // Print data in console
                setImage(file);
            };
            // Convert data to base64
            fileReader.readAsDataURL(fileToLoad);
        }
    }

    const submit = async (e) => {
        e.preventDefault();

        //show swal confirm
        const confirm = await swal({
            title: "Apakah Anda Yakin?",
            text: "data yang sudah di submit tidak akan dapat di rubah lagi",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        });

        if(confirm){
            setLoading(true);

            const res = await request(
                auth.token,
                from,
                point,
                image
            );

            if(res.error === null){
                swal("Success", "Request KUM berhasi kami terima, kami akan mereview permintaan anda terlebih dahulu", "success");
                navigate('/point-history');
            }else{
                swal("Error", res.error, "error");
            }

            setLoading(false);
        }
    }

    return (
        <div className='box p-4'>
            <form onSubmit={submit}>
                <Row>
                    <Col lg={12}>
                        <div>
                            <label>Point From</label>
                            <input disabled={loading} type="text" className="form-control" value={from} onChange={e => setFrom(e.target.value)} required={true}/>
                        </div>
                    </Col>
                    <Col lg={12}>
                        <div className='mt-3'>
                            <label>Total Point(KUM)</label>
                            <input disabled={loading} maxlength="10" pattern="[0-9]+(\.[0-9]){0,5}" type="text" className="form-control" value={point} onChange={e => setPoint(e.target.value)} required={true}/>
                            <div class="text-muted">
                                gunakan titik untuk memisahkan koma, contoh: 2.5
                            </div>
                        </div>
                    </Col>
                    <Col lg={12}>
                        <div className="mt-3">
                            <label>Image</label>
                            <div {...getRootProps({className: 'dropzone'})}>
                                <input {...getInputProps()} />
                                <p className="text-center">
                                    Drag 'n' drop some files here<br/>or click to select files
                                    <em>(Only *.jpeg and *.png images will be accepted)</em>
                                </p>
                            </div>
                            <aside className="mt-2">
                                <b>Selected Image:</b>
                                {files}
                                {
                                    fileRejectionItems.length > 0 ? (
                                        <>
                                            <b>Rejected files</b>
                                            <ul>{fileRejectionItems}</ul>
                                        </>
                                    ) : null
                                }
                            </aside>
                        </div>
                    </Col>
                    <Col lg={12} className="mt-3">
                        <button disabled={loading} type="submit" className='btn btn-default'>
                            { loading ? <Loading /> : 'Submit' }
                        </button>
                    </Col>
                </Row>
            </form>
        </div>
    );
}

export default RequestPoint;