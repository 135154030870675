export const history = async (token, page) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}points/history`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            page: page
        })
    });
    const json = await response.json();
    return json;
}

export const request = async (token, from, point, image) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}points/request`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            from: from,
            point: point,
            image: image
        })
    });
    const json = await response.json();
    return json;
}