import React from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router";
import PageNav from "../components/PageNav";
import PeopleList from "../components/peoples/PeopleList";

const OrganizationScreen = () => {
    const params = useParams();

    return (
        <div  className="default-nav-mt">
            <Container>
                <PageNav title={params.name} links={
                    [
                        
                    ]
                }/>
            
                <div className="mt-3">
                    <PeopleList name={params.name}/>
                </div>
            </Container>
        </div>
    );
}

export default OrganizationScreen;