import React, { useEffect, useState } from "react";
import {Button, Modal, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import {useDropzone} from 'react-dropzone';
import {
    update,
    create,
    read
} from './../../services/portfolio';
import swal from 'sweetalert';

//wysiwyg
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const AddEditPortfolio = (props) => {
    const id = props.id;
    const setRefreshList = props.setRefreshList;

    const auth = useSelector((state) => state.auth);

    const [image, setImage] = useState("");
    const [uploadedImage, setUploadedImage] = useState("");
    const [title, setTitle] = useState("");
    const [body, setBody] = useState(EditorState.createEmpty());
    const [link, setLink] = useState("");
    const [instagram, setInstagram] = useState("");
    const [facebook, setFacebook] = useState("");
    const [client, setClient] = useState("");
    const [isLoading, setIsLoading] = useState("");

    const {acceptedFiles, fileRejections, getRootProps, getInputProps} = useDropzone({
        maxFiles:1,
        accept: 'image/jpeg, image/png',
        onDrop: files => {
            fileToBase64(files);
        }
    });
  
    const files = acceptedFiles.map(file => (
        <div key={file.path}>
            <img src={image} alt={file.name} className="img-thumbnail" style={{
                width: '100px',
            }}/>
        </div>
    ));

    const fileRejectionItems = fileRejections.map(({ file, errors  }) => { 
        return (
          <li key={file.path}>
               {file.path} - {(file.size/ 1000)} Kb
               <ul>
                 {errors.map(e => <li key={e.code}>{e.message}</li>)}
              </ul>
          </li>
        ) 
    });

    const fileToBase64 = (selectedFile) => {
        let file = null;
        //Check File is not Empty
        if (selectedFile.length > 0) {
            // Select the very first file from list
            let fileToLoad = selectedFile[0];
            // FileReader function for read the file.
            let fileReader = new FileReader();
            // Onload of file read the file content
            fileReader.onload = function(fileLoadedEvent) {
                file = fileLoadedEvent.target.result;
                // Print data in console
                setImage(file);
            };
            // Convert data to base64
            fileReader.readAsDataURL(fileToLoad);
        }
    }

    useEffect(() => {
        if(id !== null){
            async function getData(id){
                const res = await read(id);
                if(res.error === null){
                    setTitle(res.data.title);

                    const contentBlock = htmlToDraft(res.data.body);
                    console.log(contentBlock);
                    if (contentBlock) {
                        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                        const editorState = EditorState.createWithContent(contentState);
                        setBody(editorState);
                    }

                    setLink(res.data.link);
                    setInstagram(res.data.instagram);
                    setFacebook(res.data.facebook);
                    setClient(res.data.client);
                    setUploadedImage(res.data.cover);
                }
            }

            getData(id);
        }
    }, [id]);

    const closeModal = () => {
        resetForm();
        props.setShowModal(false);
    }

    const resetForm = () => {
        //reset form
        setTitle("");
        setBody(EditorState.createEmpty());
        setLink("");
        setInstagram("");
        setFacebook("");
        setClient("");
        setImage("");
        setUploadedImage("");
    }

    const addData = async (e) => {
        e.preventDefault();

        //set loading
        setIsLoading(true);

        let res = {
            error: null,
        }

        //check if add or update
        const htmlBody = draftToHtml(convertToRaw(body.getCurrentContent()));

        if(id === null){
            //insert
            res = await create(
                auth.token,
                title,
                htmlBody,
                link,
                instagram,
                facebook,
                client,
                image
            );
        }else{
            //edit
            res = await update(
                auth.token,
                id,
                title,
                htmlBody,
                link,
                instagram,
                facebook,
                client,
                image
            );
        }

        if(res.error === null){
            //success
            swal("Sukses!", "Berhasil "+(id == null ? 'menambah' : 'mengubah')+" data.", "success");

            resetForm();

            //set refresh detail
            setRefreshList(new Date());

            //close modal
            closeModal();
        }else{
            //error
            swal("Gagal!", res.error, "error");
        }

        setIsLoading(false);
    }

    return (
        <Modal size="lg" show={props.isShow} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>Portfolio</Modal.Title>
            </Modal.Header>
            <form onSubmit={addData}>
            <Modal.Body>
                <Row>
                    <Col lg={12}>
                        <div>
                            <label>Title</label>
                            <input type="text" className="form-control" value={title} onChange={e => setTitle(e.target.value)} required={true}/>
                        </div>
                    </Col>
                    <Col lg={12}>
                        <div className="mt-3">
                            <label>Description Body</label>
                            <Editor
                                editorState={body}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="border"
                                editorClassName="p-3"
                                onEditorStateChange={e => setBody(e)}
                            />
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className="mt-3">
                            <label>Website</label>
                            <input type="text" className="form-control" value={link} onChange={e => setLink(e.target.value)} required={false}/>
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className="mt-3">
                            <label>Instagram</label>
                            <input type="text" className="form-control" value={instagram} onChange={e => setInstagram(e.target.value)} required={false}/>
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className="mt-3">
                            <label>Facebook</label>
                            <input type="text" className="form-control" value={facebook} onChange={e => setFacebook(e.target.value)} required={false}/>
                        </div>
                    </Col>
                    <Col lg={12}>
                        <div className="mt-3">
                            <label>Client</label>
                            <input type="text" className="form-control" value={client} onChange={e => setClient(e.target.value)} required={false}/>
                        </div>
                    </Col>
                </Row>
                
                <div className="mt-3">
                    <label>Image</label>
                    <div hidden={ uploadedImage === null || uploadedImage === "" }>
                        <div className="text-center">
                           <img src={ uploadedImage } alt={ title } style={{ width: '200px' }} />
                        </div>
                        <div className="alert alert-warning mt-2">
                            Leave this field empty if you dont want to change photo.
                        </div>
                    </div>
                    <div {...getRootProps({className: 'dropzone'})}>
                        <input {...getInputProps()} />
                        <p className="text-center">
                            Drag 'n' drop some files here<br/>or click to select files
                            <em>(Only *.jpeg and *.png images will be accepted)</em>
                        </p>
                    </div>
                    <aside className="mt-2">
                        <b>Selected Image:</b>
                        {files}
                        {
                            fileRejectionItems.length > 0 ? (
                                <>
                                    <b>Rejected files</b>
                                    <ul>{fileRejectionItems}</ul>
                                </>
                            ) : null
                        }
                    </aside>
                </div>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
                Close
            </Button>
            <Button variant="danger" type="submit" disabled={isLoading}>
                Save Changes
            </Button>
            </Modal.Footer>
            </form>
        </Modal>
    );
}

export default AddEditPortfolio;