export const getByCategory = async (name) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}link/get_by_category`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            name: name
        })
    });
    const json = await response.json();
    return json;
}

export const socialMedia = async (name) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}link/social_media`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        }
    });
    const json = await response.json();
    return json;
}