export const create = async (token, company_name, job_title, is_current_company, start_date, end_date, phone, fax, website, country, state, address) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}employment/create`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            company_name: company_name,
            job_title: job_title,
            is_current_company: is_current_company,
            start_date: start_date,
            end_date: end_date,
            phone: phone,
            fax: fax,
            website: website,
            country: country,
            state: state,
            address: address,
        })
    });
    const json = await response.json();
    return json;
}

export const update = async (token, id, company_name, job_title, is_current_company, start_date, end_date, phone, fax, website, country, state, address) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}employment/update`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            employment_id: id,
            company_name: company_name,
            job_title: job_title,
            is_current_company: is_current_company,
            start_date: start_date,
            end_date: end_date,
            phone: phone,
            fax: fax,
            website: website,
            country: country,
            state: state,
            address: address,
        })
    });
    const json = await response.json();
    return json;
}

export const remove = async (token, employment_id) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}employment/delete`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            employment_id: employment_id,
        })
    });
    const json = await response.json();
    return json;
}

export const read = async (id) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}employment/read`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            id: id,
        })
    });
    const json = await response.json();
    return json;
}

export const all = async (token) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}employment/all`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        }
    });
    const json = await response.json();
    return json;
}