import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
    getByCategory,
    socialMedia
} from './../services/link';
import {
    read
} from './../services/page';
import ContentView from "./ContentView";
import WaFloat from "./WaFloat";

const Footer = () => {
    const [footerMidLinks, setFooterMidLinks] = useState([]);
    const [footerLeftLinks, setFooterLeftLinks] = useState([]);
    const [socialMedias, setSocialMedias] = useState([]);
    const [footerContent, setFooterContent] = useState(null);

    useEffect(() => {
        async function getSocialMedia(){
            const res = await socialMedia();
            if(res.error === null){
                setSocialMedias(res.data);
            }else{
                console.log(res);
            }
        }

        async function getFooterLeft(){
            const res = await getByCategory('Footer Left');
            if(res.error === null){
                setFooterLeftLinks(res.data);
            }else{
                console.log(res);
            }
        }

        async function getFooterMid(){
            const res = await getByCategory('Footer Mid');
            if(res.error === null){
                setFooterMidLinks(res.data);
            }else{
                console.log(res);
            }
        }

        async function getFooterContent(){
            const res = await read('footer-content');
            if(res.error === null){
                setFooterContent(res.data);
            }else{
                console.log(res);
            }
        }

        getSocialMedia();
        getFooterLeft();
        getFooterMid();
        getFooterContent();
    }, []);

    return(
        <div className="footer" style={{
            borderTop: "1px solid #ebebeb",
        }}>
            <Container>
                <Row>
                    <Col sm={12} md={6} lg={3}>
                        {
                            footerContent !== null ? (
                                <>
                                    <div dangerouslySetInnerHTML={{ __html: footerContent.body }} />
                                </>
                            ) : null
                        }
                    </Col>
                    <Col sm={12} md={6} lg={3}>
                        <b style={{
                            textDecoration: "underline",
                        }}>Webcorner</b>
                        <ul className="footer-link">
                            {
                                footerLeftLinks !== null && footerLeftLinks.map((link, index) => (
                                    <li key={index}>
                                        <a href={link.url}>{link.name}</a>
                                    </li>
                                ))
                            }
                        </ul>
                    </Col>
                    <Col sm={12} md={6} lg={3}>
                        <b style={{
                            textDecoration: "underline",
                        }}>Sekretariat IAI Jakarta</b>
                        <div className="mt-3">
                            <ContentView slug="footer-contact"/>
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={3}>
                        <b style={{
                            textDecoration: "underline",
                        }}>Ikuti Kami:</b>
                        <div className="d-flex mt-3 mb-4">
                        {
                            socialMedias !== null ? (
                                socialMedias.map((socialMedia, index) => (
                                    <div key={index}>
                                        <a href={socialMedia.url} target="_blank" rel="noopener noreferrer">
                                            <img src={socialMedia.icon} style={{
                                                marginRight: '30px',
                                            }}/>
                                        </a>
                                    </div>
                                ))
                            ) : null
                        }
                        </div>
                        <ContentView slug="footer-right"/>
                    </Col>
                </Row>
            </Container>

            <WaFloat />
        </div>
    );
}

export default Footer;