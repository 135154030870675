import React, { useEffect, useState } from "react";
//bootstrap
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faTrash, faMapMarkerAlt, faFileDownload } from "@fortawesome/free-solid-svg-icons";
import {
    all,
    remove
} from './../../services/education';
import AddEditEducation from './AddEditEducation';
import { useDispatch, useSelector } from "react-redux";
import {
    setRefreshDetail
} from './../../redux/slicers/accountSlice';
import swal from 'sweetalert';
import Loading from "../Loading";

const EducationList = () => {
    const auth = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [editId, setEditId] = useState(null);
    const [educations, setEducations] = useState([]);
    const [loading, setLoading] = useState(false);
    const [refreshTime, setRefreshTime] = useState(null);

    useEffect(() => {
        if(editId !== null){
            setShowModal(true);
        }
    }, [editId]);

    useEffect(() => {
        setRefreshTime(new Date().getTime());
    }, []);

    useEffect(() => {
        async function getEducations(token){
            setLoading(true);

            const res = await all(token);
            if(res.error === null){
                if(res.data.length > 0){
                    setEducations(res.data);
                }else{
                    setEducations(null);
                }
            }else{
                swal("Error!", res.error.message, "error");
            }

            setLoading(false);
        }

        if(refreshTime !== null){
            getEducations(auth.token);
        }
    }, [refreshTime, auth]);

    const closeModal = () => {
        setEditId(null);
        setShowModal(false);
    }

    const deleteData = async (id) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then(async (willDelete) => {
            if (willDelete) {
                const res = await remove(auth.token, id);
                if(res.error === null){
                    swal("Sukses!", "Berhasil menghapus data.", "success");

                    setRefreshTime(new Date().getTime());
                }
            } 
        });
    }

    return(
        <div>
            <div className="text-end">
                <button className="btn btn-default ms-2 mt-3 btn-sm" onClick={() => setShowModal(true)}>Tambah Education</button>
            </div>

            <AddEditEducation id={editId} isShow={ showModal } refreshList={() => setRefreshTime(new Date().getTime())} setShowModal={closeModal}/>

            {
                loading ? <Loading /> :
                educations === null ? (
                    <div className="text-center p-4">
                        <h4>Belum ada Pendidikan</h4>
                        <img src="img/empty.svg" style={{
                            width: "200px",
                        }}/>
                    </div>
                ) : 
                educations.map((education, index) => (
                    <div key={education.id}>
                        <div className="box p-3 mt-3">
                            <h6>
                                <span style={{
                                    fontWeight: "bold"
                                }}>
                                    { education.institution }
                                    {
                                        education.strata !== null && education.strata !== "" ? (
                                            ' | '+education.strata
                                        ) : null
                                    }
                                     {
                                        education.major !== null && education.major !== "" ? (
                                            ' | '+education.major
                                        ) : null
                                    }
                                </span>
                                <div className="float-end d-inline-block">
                                    &nbsp;<a href="#!" className="action-link" onClick={() => setEditId(education.id)}><FontAwesomeIcon icon={faCog} />Ubah</a>
                                    &nbsp;<a href="#!" className="action-link" onClick={() => deleteData(education.id)}><FontAwesomeIcon icon={faTrash} />Hapus</a>
                                </div>
                            </h6>
                            <div style={{
                                fontSize: "16px",
                                marginTop: '-5px',
                                color: "#333"
                            }}><FontAwesomeIcon icon={faMapMarkerAlt}/> { education.city }</div>
                            <div hidden={education.is_active_student === '1'} style={{
                                fontSize: "14px",
                                fontStyle: 'italic',
                                color: "#333"
                            }}>Graduated on: { education.graduated_on }</div>
                            <div hidden={education.is_active_student === '0'} style={{
                                fontSize: "14px",
                                fontStyle: 'italic',
                                color: "#333"
                            }}>Masih Aktif</div>
                            <div style={{
                                fontSize: "14px"
                            }} hidden={education.certificates_path === null || education.certificates_path === ""}>
                                <a style={{
                                    textDecoration: "none",
                                }} href={education.certificates_path} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faFileDownload}/> Download Sertifikat</a>
                            </div>
                            <div style={{
                                fontSize: "14px"
                            }} hidden={education.student_card_path === null || education.student_card_path === ""}>
                                <a style={{
                                    textDecoration: "none",
                                }} href={education.student_card_path} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faFileDownload}/> Download Kartu Mahasiswa</a>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>

    );
}

export default EducationList;