import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import LeftMenuPanel from "../components/LeftMenuPanel";
import ShippingAddressList from "../components/shipping_address/ShippingAddressList";
import config from "../helpers/config";

const ShippingAddressScreen = () => {
    //change title
    useEffect(() => {
        document.title = config.documentTitle + " - Alamat Pengiriman";

        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="default-nav-mt">
            <Container>
                <Row>
                    <Col sm={12} md={12} lg={4} className="mb-4">
                        <LeftMenuPanel activeIndex={4}/>
                    </Col>
                    <Col sm={12} md={12} lg={8}>
                        <ShippingAddressList isCheckout={false}/>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default ShippingAddressScreen;