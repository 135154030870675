import React from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import {
    showUserDetail
} from './../../redux/slicers/userSlice';

const Comment = (props) => {
    const comment = props.comment;

    const dispatch = useDispatch();

    const viewUserDetail = (id) => {
        dispatch(showUserDetail(id));
    }

    return(
        <div className="d-flex mb-3">
            <img src={ comment.avatar } alt="" style={{
                width: '30px',
                height: '30px',
                marginTop: '10px',
                marginRight: '10px',
                borderRadius: '50%',
            }} />
            <div>
                <div style={{
                    border: '1px solid #c7c7c7',
                    backgroundColor : '#fff',
                    textAlign: 'left',
                    borderRadius: '10px',
                    padding: '5px 10px',
                }}>
                    <div onClick={() => viewUserDetail(comment.user_id)} style={{
                        fontSize: '10pt',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                    }}>
                        { comment.name }
                    </div>
                    { comment.body }
                </div>
                <div style={{
                    fontSize: '8pt',
                    textAlign: 'right',
                }}>
                    { moment(comment.created_at).fromNow() }
                </div>
            </div>
        </div>
    );
}

export default Comment;