import { useState } from "react";
import { useNavigate } from "react-router";

export default function NotFoundScreen() {
    const navigate = useNavigate();

    const [keyword, setKeyword] = useState('');

    const search = e => {
        e.preventDefault();

        navigate('/search/' + keyword);
    }

    return (
        <div className="default-nav-mt">
            <div className="container" style={{
                            height: '500px'
                        }}>
                <div className="row align-items-center h-100">
                    <div className="col-12">
                        <div className="text-center">
                            <h1>404</h1>
                            <h2>Halaman Tidak Ditemukan</h2>

                            <form onSubmit={search}>
                                <div className="row">
                                    <div className="col-12 col-md-8 col-lg-10">
                                        <input type="text" value={keyword} onChange={e => setKeyword(e.target.value)} placeholder="Cari Sesuatu..." className="form-control bg-white"/>
                                    </div>
                                    <div className="col-12 col-md-4 col-lg-2">
                                        <button className="btn btn-default btn-block w-100">Cari</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}