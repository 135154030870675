import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import LeftMenuPanel from "../components/LeftMenuPanel";
import UpgradeDirectMemberForm from "../components/membership/UpgradeDirectMemberForm";
import config from "../helpers/config";

const UpgradeDirectMemberScreen = () => {
    //change title
    useEffect(() => {
        document.title = config.documentTitle + " - Upgrade Keanggotaan";

        window.scrollTo(0, 0);
    }, []);

    return (
        <Container>
            <Row>
                <Col sm={12} md={12} lg={4} className="mb-4">
                    <LeftMenuPanel activeIndex={-1}/>
                </Col>
                <Col sm={12} md={12} lg={8}>
                    <UpgradeDirectMemberForm />
                </Col>
            </Row>
        </Container>
    );
}

export default UpgradeDirectMemberScreen;