export const history = async (token, membership_type) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}membership_application/history`, {
        crossDomain:true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            membership_type: membership_type
        })
    });
    const json = await response.json();
    return json;
}