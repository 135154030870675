import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

//bootstrap
import { Container, Row, Col, Tab, Tabs } from "react-bootstrap";

//services
import {
    profile
} from './../services/auth';

import {
    detail
} from './../services/account';

import PortfolioList from './../components/portfolios/PortfolioList';
import EducationList from './../components/educations/EducationList';
import EmploymentList from './../components/employments/EmploymentList';
import LeftMenuPanel from "../components/LeftMenuPanel";
import config from "../helpers/config";

const ProfileScreen = () => {
    //change title
    useEffect(() => {
        document.title = config.documentTitle + " - Profil";

        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="default-nav-mt">
            <Container>
                <Row>
                    <Col sm={12} md={12} lg={4} className="mb-4">
                        <LeftMenuPanel activeIndex={0}/>
                    </Col>
                    <Col sm={12} md={12} lg={8}>
                        <Tabs defaultActiveKey="portfolio" className="mb-3 nav nav-pills">
                            <Tab eventKey="portfolio" title="Portfolios">
                                <PortfolioList />
                            </Tab>
                            <Tab eventKey="education" title="Educations">
                                <EducationList />
                            </Tab>
                            <Tab eventKey="employment" title="Employments">
                                <EmploymentList/>
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default ProfileScreen;