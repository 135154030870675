import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Security from "../components/account/Security";
import LeftMenuPanel from "../components/LeftMenuPanel";
import ProfileNav from "../components/ProfileNav";
import config from "../helpers/config";

const SecurityScreen = () => {
    //change title
    useEffect(() => {
        document.title = config.documentTitle + " - Keamanan";

        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="default-nav-mt">
            <Container>
                <Row>
                    <Col sm={12} md={12} lg={4} className="mb-4">
                        <LeftMenuPanel activeIndex={2}/>
                    </Col>
                    <Col sm={12} md={12} lg={8}>
                        <ProfileNav activeIndex={2}/>
                        <Security />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default SecurityScreen;